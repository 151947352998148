import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { format } from "date-fns";

// style components
import { RootState } from "../../../../redux-toolkit/store";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart = () => {
  const clientHubSlice = useSelector((state: RootState) => state.clientHubSlice);

  // variable for label and value for the chart
  let chartRecord = [
    {
      label: "Positive",
      value: 0,
    },
    {
      label: "Too fast",
      value: 0,
    },
    {
      label: "Too slow",
      value: 0,
    },
    {
      label: "Bad language",
      value: 0,
    },
    {
      label: "Poor quality",
      value: 0,
    },
    {
      label: "Annoying",
      value: 0,
    },
    {
      label: "Overplayed",
      value: 0,
    },
    {
      label: "Other",
      value: 0,
    },
  ];

  const clientFeedback = clientHubSlice.feedback.filter((row: any) => {
    let filterPass = true;
    if (clientHubSlice.dateFilter.startDate && clientHubSlice.dateFilter.endDate) {
      filterPass =
        format(new Date(row.date), "yyyy-MM-dd") > clientHubSlice.dateFilter.startDate &&
        format(new Date(row.date), "yyyy-MM-dd") < clientHubSlice.dateFilter.endDate;
    }

    //if filterPass comes back `false` the row is filtered out.
    return filterPass;
  });

  // count the total value for each type
  chartRecord.forEach(async (ele) => {
    const result = clientFeedback.filter((element: any) => {
      if (element.report && element.report.type.toLowerCase() === ele.label.toLowerCase()) return true;
      return false;
    });
    ele.value = result.length;
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
        display: true,
        labels: {
          boxWidth: 50,
          fontSize: 25,
          fontColor: "gray",
        },
      },
    },
  };

  const pieData = {
    labels: chartRecord.map((data) => {
      return data.label;
    }),
    datasets: [
      {
        label: "# of Feedback",
        data: chartRecord.map((data) => {
          return data.value;
        }),
        backgroundColor: ["#00c9a7", "#ed4c78", "#09a5be", "#71869d", "#FFA500", "#0000ff", "#FFFF00", "#ebe0ff"],
        borderColor: "#fff",
        borderWidth: 0,
      },
    ],
  };
  return <Doughnut data={pieData} height={50} width={100} options={options} />;
};
