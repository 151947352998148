import axiosInterceptors from "../lib/axiosInterceptors";
import { getEnvironment } from "../config/config";

/**
 * Get core channel lists
 */
export async function getCoreChannelsList(itemsPerPage: number, pageNumber: number, filter?: string) {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/core-channels`, {
    filter,
    itemsPerPage,
    pageNumber,
  });
}

/**
 * Get core channel lists for select options
 */
export async function getCoreChannelsListForTackInjectionModal() {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/core-channels/all`, {});
}

/**
 * Get a core channel tracks
 */
export async function getCoreChannelTracks(coreChannelId: number) {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/core-channels/tracks/${coreChannelId}`, {});
}
