import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";

//style components
import { Login } from "../pages/users/login";
import { ForgotPassword } from "../pages/users/forgotPassword";
import { ResetPassword } from "../pages/users/resetPassword";
import { HeaderPage } from "./header";
import { RootState } from "../redux-toolkit/store";
import { shakeAnimationSuccess } from "../redux-toolkit/reducers/shakeAnimationSlice";
import styles from "./layout.module.scss";
import { login_page, forgot_password_page, reset_password_page } from "../constants/routePath";
import { TIMEOUT_INTERVAL_SHAKE_ANIMATION } from "../constants/defaultValues";
import { CyanLogoSvg } from "../assets/images/logo/logo";
import { NotFound } from "../pages/notFound";

export const Unauthenticated = () => {
  const shakeAnimation = useSelector((state: RootState) => state.shakeAnimationSlice.shakeAnimation);
  const dispatch = useDispatch();

  useEffect(() => {
    let timer = setTimeout(() => {
      if (shakeAnimation) {
        dispatch(shakeAnimationSuccess());
      }
    }, TIMEOUT_INTERVAL_SHAKE_ANIMATION);
    return () => {
      clearTimeout(timer);
    };
  }, [shakeAnimation, dispatch]);

  return (
    <HeaderPage>
      <div className={classnames(styles.unContainer)}>
        <div className={classnames(styles.unContent)}>
          <div className="container py-5 py-sm-7">
            <a className="d-flex justify-content-center mb-5" href="/">
              <img className="zi-2" src={CyanLogoSvg} alt="Ambie Admin Portal" style={{ width: "7rem" }} />
            </a>

            <div
              className={classnames("mx-auto", shakeAnimation && styles.shakeInputBox)}
              style={{ maxWidth: "40rem" }}
            >
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path={login_page} element={<Login />} />
                <Route path={forgot_password_page} element={<ForgotPassword />} />
                <Route path={reset_password_page} element={<ResetPassword />} />

                {/* not found page */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </HeaderPage>
  );
};
