import { useDispatch, useSelector } from "react-redux";

// style components
import { LoadingData } from "../../../../components/loading";
import { GlobalModal } from "../../../../components/modal";
import { RootState } from "../../../../redux-toolkit/store";
import { GlobalErrorMessage } from "../../../users/components/errorMessage";
import styles from "../../../locations/components/location-actions/location-actions.module.scss";
import { Panel } from "../../../../components/panel";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { Tab, Tabs } from "react-bootstrap";
import { BsXCircle } from "react-icons/bs";

import {
  hideActionsSuccess,
  isLoadingSuccess,
  showErrorMessageSuccess,
  showSubmissionErrorSuccess,
} from "../../../../redux-toolkit/reducers/actionsSlice";
import { Input, InputWrapper } from "../../../../components/fields/input";
import { GlobalButton } from "../../../../components/button";
import { ACTIONS_ERROR_MESSAGE } from "../../../../constants/globalText";
import panelStyles from "../../../../components/panel/panel.module.scss";
import { ReactSelect } from "../../../../components/fields/select";
import { SearchWithOptions } from "../../../../components/fields/search";
import { useContext, useEffect, useState } from "react";
import { getClientActiveLocations } from "../../../../services/locationsServices";
import { WebSocketContext } from "../../../../components/websocket/websocket";
import { getAmbieUsers, getClientUsers } from "../../../../services/usersServices";
import { getClientForAmbieUser } from "../../../../services/clientsServices";
import { briefSuccess, clientDetailsSuccess } from "../../../../redux-toolkit/reducers/clientHubSlice";
import { SingleDatePicker } from "../../../../components/fields/datePicker";
import moment from "moment";
import { Textarea } from "../../../../components/fields/texarea";
import { getTotalPlaylistsAndTracks } from "../../../../services/playlistsServices";
import { addNewBrief, editBrief, getBrief } from "../../../../services/briefsServices";
import { BriefSubmissionAndCompletionStatus, BriefStatus } from "../status";
import { Toggle } from "../../../../components/fields/toggle";
import { PlaylistMood } from "../../../client-hub/components/playlistMood/playlistMood";
import { Checkbox } from "../../../../components/fields/checkbox";
import { PlaylistModal } from "../../../playlists/components/playlist-modal/modal";

interface BriefModalProps {
  footer?: any;
  title?: string;
  show: boolean;
  handleClose: any;
  messages?: [];
}

/**
 * Add new brief
 */
export const AddBrief = ({ show }: { show: boolean }) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const [locations, setLocations] = useState<[]>([]);
  const [playlists, setPlaylists] = useState<[]>([]);
  const ambieUsers = useSelector((state: RootState) => state.authSlice.ambieUsers);
  const usersSlice = useSelector((state: RootState) => state.authSlice);

  const clientDetails = useSelector((state: RootState) => state.clientHubSlice.clientDetails);
  const socket = useContext(WebSocketContext);
  const dispatch = useDispatch();

  const clientId = searchClient.clientId;

  useEffect(() => {
    let isSubscribed = true;

    socket.on("updateClient", (payload: { id: number }) => {
      if (payload.id === clientId) getClientData();
    });

    const getClientData = async () => {
      if (clientId) {
        await getClientForAmbieUser(clientId)
          .then((response) => {
            if (isSubscribed) {
              dispatch(clientDetailsSuccess({ clientDetails: response.data }));
            }
          })
          .catch((error) => {});
      }
    };

    const getAmbieUsersData = async () => {
      await getAmbieUsers();
    };

    const getClientUsersData = async () => {
      await getClientUsers(clientId);
    };

    const getLocationsData = async () => {
      await getClientActiveLocations(clientId)
        .then((response) => {
          if (isSubscribed) setLocations(response.data);
        })
        .catch((error) => {});
    };

    const getActivePlaylistsByClientIdData = async () => {
      await getTotalPlaylistsAndTracks(clientId)
        .then((response) => {
          setPlaylists(response.playlists);
        })
        .catch((error) => {});
    };

    getClientData();
    getAmbieUsersData();
    getClientUsersData();
    getLocationsData();
    getActivePlaylistsByClientIdData();

    return () => {
      isSubscribed = false;
      socket.off("updateClient", (payload: { id: number }) => {
        if (payload.id === clientId) getClientData();
      });
    };
  }, [searchClient, clientId, dispatch]);

  const hideActions = () => {
    dispatch(hideActionsSuccess());
  };

  const addBriefData = async (values: any) => {
    let briefErrorMessages: any = [];
    let customerEmail: any = [];
    let customerEmailFail: any = [];

    // clean customer email
    if (values.clientEmail) {
      customerEmail = values.clientEmail
        .filter((value: any) => value !== "")
        .map((email: any) => {
          let emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

          // check email validation
          if (!emailReg.test(email)) {
            customerEmailFail.push(email);
          }
          return email;
        });
    }

    if (
      !values.title ||
      !values.type ||
      !values.locations.length ||
      !values.curationOwnerId ||
      customerEmailFail.length ||
      (values.type === "curation_refresh" && !values.refreshPlaylists.length)
    ) {
      if (!values.title) {
        briefErrorMessages.push({
          message: "The title is required in [BRIEF DETAILS SECTION]",
        });
      }
      if (!values.type) {
        briefErrorMessages.push({
          message: "The type is required in [BRIEF DETAILS SECTION]",
        });
      }

      if (!values.locations.length) {
        briefErrorMessages.push({
          message: "At least one location is required in [BRIEF DETAILS SECTION]",
        });
      }

      if (!values.curationOwnerId) {
        briefErrorMessages.push({
          message: "The curation owner is required in [BRIEF DETAILS SECTION]",
        });
      }

      if (customerEmailFail.length) {
        const text =
          customerEmailFail.length === 1
            ? `The following customer email "${customerEmailFail
                .join(", ")
                .replace(/,([^,]*)$/, " and$1")}" is not valid in [BRIEF DETAILS SECTION]`
            : `The following customer emails "${customerEmailFail
                .join(", ")
                .replace(/,([^,]*)$/, " and$1")}" are not valid in [BRIEF DETAILS SECTION]`;
        briefErrorMessages.push({
          message: text,
        });
      }

      if (values.type === "curation_refresh" && !values.refreshPlaylists.length) {
        briefErrorMessages.push({
          message:
            "At least one Refresh Playlist is required when choosing Curation Refresh as type in [BRIEF DESCRIPTION SECTION]",
        });
      }

      dispatch(
        showSubmissionErrorSuccess({
          submissionError: { status: true, messages: briefErrorMessages },
        })
      );
      return;
    }

    const data = {
      client_id: clientId,
      type: values.type,
      source: "manual",
      locations: values.locations,
      curation_owner_id: values.curationOwnerId,
      status: "pending",
      title: values.title,
      notifications_receiver: values.receiveNotification,
      client_notifications_receiver: values.clientReceiveNotification,
      due_date: values.dueDate,
      estimated_delivery_date: values.estimatedDeliveryDate,
      brand_identity: values.brandIdentify,
      customer_demographics: values.customerDemographics,
      music_references: values.musicReferences,
      other_notes: values.otherNotes,
      trading_patterns: values.tradingPatterns,
      requested_notes: values.requestedNotes,
      refresh_playlists: values.refreshPlaylists,
      ambie_to_schedule: values.ambieToSchedule,
      website: values.website,
      customer_email: customerEmail,
    };

    dispatch(isLoadingSuccess(true));
    await addNewBrief(data)
      .then((response) => {
        hideActions();
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  const locationsLists = locations.length
    ? locations.map((location: any) => ({
        value: location.id,
        label: location.name,
      }))
    : [];

  const refreshPlaylistsLists = playlists.length
    ? playlists
        .filter((publish: { is_published: boolean }) => publish.is_published)
        .map((playlist: any) => ({
          value: playlist.playlist_id,
          label: playlist.name,
          energyLevel: playlist.energy_level,
          duration: playlist.duration,
        }))
    : [];

  const ambieUsersLists = ambieUsers.length
    ? ambieUsers
        .filter((hasName: any) => hasName.users_user && hasName.users_user.name)
        .map((user: any) => ({
          value: user.users_user && user.users_user.id,
          label: user.users_user && user.users_user.name,
        }))
    : [];

  const clientUsersLists = usersSlice.users.length
    ? usersSlice.users
        .filter((hasName: any) => hasName.users_user && hasName.users_user.name)
        .map((user: any) => ({
          value: user.users_user && user.users_user.id,
          label: `${user.users_user && user.users_user.name} - (${user.permission_level.name})`,
          email: user.users_user && user.users_user.email,
        }))
    : [];

  const customerOwnerId = clientDetails && clientDetails.account_owner_id;

  const usersReceiveNotification = ambieUsers.length
    ? ambieUsers
        .filter(
          (ambieUser: any) =>
            ambieUser.users_user && ambieUser.users_user.name && ambieUser.users_user.id === customerOwnerId
        )
        .map((user: any) => ({
          value: user.users_user && user.users_user.id,
          label: user.users_user && user.users_user.name,
        }))
    : [];

  const initialValues = {
    title: "",
    clientName: searchClient.clientName,
    type: "",
    locations: [],
    curationOwnerId: (clientDetails && clientDetails.curator_owner_id) || null,
    receiveNotification: usersReceiveNotification,
    clientReceiveNotification: [],
    dueDate: null,
    estimatedDeliveryDate: null,
    brandIdentify: "",
    customerDemographics: "",
    musicReferences: "",
    otherNotes: "",
    tradingPatterns: "",
    requestedNotes: "",
    refreshPlaylists: [],
    ambieToSchedule: false,
    website: "",
    selectAllLocations: false,
    clientEmail: [],
  };

  const resetBriefContentError = () => {
    dispatch(showSubmissionErrorSuccess({ submissionError: { status: false, messages: [] } }));
  };

  return (
    <>
      {actions.submissionError.status && (
        <BriefSubmissionErrorModal
          show={actions.submissionError.status}
          handleClose={() => resetBriefContentError()}
          messages={actions.submissionError.messages}
          footer={
            <GlobalButton format="info" size="sm" onClick={() => resetBriefContentError()}>
              Close
            </GlobalButton>
          }
        />
      )}

      {((actions.editPlaylist && actions.editPlaylist.status) || actions.addPlaylist) && <PlaylistModal />}

      <Formik form initialValues={initialValues} onSubmit={addBriefData} enableReinitialize>
        {({ handleChange, handleBlur, setFieldValue, values, errors }) => {
          const selectedReceiveNotification = values.receiveNotification;
          const selectedClientReceiveNotification = values.clientReceiveNotification;

          const selectedLocations = values.locations;

          return (
            <Form>
              <Panel
                show={show}
                handleClose={() => {
                  hideActions();
                }}
                title="Add New Brief"
                width="50%"
                maxWidth="1000px"
                footer={
                  <div className={panelStyles.footer}>
                    <div className={panelStyles.footer_left}>
                      <GlobalButton
                        format="white"
                        size="sm"
                        onClick={() => {
                          hideActions();
                        }}
                      >
                        Close
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton type="submit" format="primary" size="sm">
                        Add Brief
                      </GlobalButton>
                    </div>
                  </div>
                }
              >
                <div>
                  <div>
                    <div>
                      {actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}
                    </div>
                    {actions.actionsIsLoading && <LoadingData />}
                    <Tabs defaultActiveKey="details" id="fill-tab-example" className="nav-segment my-3" fill>
                      <Tab eventKey="details" title="Brief Details">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="col-sm-6" style={{ marginBottom: 20 }}>
                              <Input
                                label="Title"
                                id="title"
                                name="title"
                                type="text"
                                required
                                placeholder="Title"
                                onChange={handleChange("title")}
                                value={values.title}
                                onBlur={handleBlur("title")}
                                autoCapitalize="none"
                                error={errors.title}
                                size="form-control-sm"
                                onChangeCapture={() =>
                                  actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))
                                }
                              />
                            </div>
                          </div>

                          <div className="col-sm-12">
                            <div className="col-sm-6" style={{ marginBottom: 20 }}>
                              <Input
                                label="Client"
                                id="clientName"
                                name="clientName"
                                type="text"
                                required
                                disabled
                                placeholder="Client"
                                onChange={handleChange("clientName")}
                                value={values.clientName}
                                onBlur={handleBlur("clientName")}
                                autoCapitalize="none"
                                error={errors.clientName}
                                size="form-control-sm"
                                onChangeCapture={() =>
                                  actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-12" style={{ marginBottom: 20 }}>
                            <SearchWithOptions
                              label="Locations"
                              additionalContent={
                                <div className="col-auto">
                                  <Checkbox
                                    type="checkbox"
                                    name="selectAllLocations"
                                    id="selectAllLocations"
                                    value="Select All Locations"
                                    onChange={() => {
                                      if (values.selectAllLocations === false) {
                                        setFieldValue(`locations`, locationsLists);
                                      } else {
                                        setFieldValue(`locations`, []);
                                      }
                                    }}
                                  />
                                </div>
                              }
                              placeholder="Search Locations"
                              id="locations"
                              required
                              options={locationsLists}
                              labelKey="label"
                              filterBy={["label"]}
                              rightIcon={true}
                              size="sm"
                              multiple={true}
                              selected={
                                locationsLists.filter(
                                  (c: any) =>
                                    selectedLocations &&
                                    selectedLocations.length &&
                                    selectedLocations
                                      .map((location: { value: number }) => location.value)
                                      .includes(c.value)
                                ) || null
                              }
                              clearButton
                              maxWidth="100%"
                              maxResults={30}
                              tooltipIcon={false}
                              tooltipMessage="Message about the permission to be displayed here.."
                              onChange={(selected: any) => {
                                if (selected && selected.length) {
                                  setFieldValue(`locations`, selected);
                                }
                                if (!selected.length) {
                                  setFieldValue(`locations`, []);
                                }

                                if (selected && selected.length === locationsLists.length) {
                                  setFieldValue(`selectAllLocations`, true);
                                } else {
                                  setFieldValue(`selectAllLocations`, false);
                                }
                              }}
                            />
                          </div>
                          <div className="col-sm-12">
                            <div className="row">
                              <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                <ReactSelect
                                  label="Type"
                                  id="type"
                                  required
                                  name="type"
                                  options={briefTypeOptions().filter((type) => type.value !== "all")}
                                  onChange={(selected: any) =>
                                    selected && selected.value && setFieldValue(`type`, selected.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12">
                            <div className="row">
                              <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                <SearchWithOptions
                                  label="Curation Owner"
                                  placeholder="Search Curation Owner"
                                  id="curationOwnerId"
                                  required
                                  selected={
                                    ambieUsersLists.filter((c: any) => c.value === values.curationOwnerId) || null
                                  }
                                  options={ambieUsersLists}
                                  // leftIcon={<BsSearch />}
                                  labelKey="label"
                                  filterBy={["label"]}
                                  rightIcon={true}
                                  size="sm"
                                  clearButton
                                  maxWidth="100%"
                                  maxResults={30}
                                  tooltipIcon={false}
                                  onChange={(selected: any) => {
                                    if (selected && selected[0]) {
                                      setFieldValue(`curationOwnerId`, selected[0].value);
                                    }
                                    if (!selected.length) {
                                      setFieldValue(`curationOwnerId`, null);
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                <SearchWithOptions
                                  label="Watching Brief"
                                  placeholder="Search Users"
                                  id="receiveNotification"
                                  options={ambieUsersLists}
                                  labelKey="label"
                                  filterBy={["label"]}
                                  rightIcon={true}
                                  size="sm"
                                  multiple={true}
                                  clearButton
                                  maxWidth="100%"
                                  maxResults={30}
                                  tooltipIcon={true}
                                  tooltipMessage="Message about the notification to be displayed here.."
                                  selected={
                                    ambieUsersLists.filter(
                                      (c: any) =>
                                        selectedReceiveNotification &&
                                        selectedReceiveNotification.length &&
                                        selectedReceiveNotification
                                          .map((user: { value: number }) => user.value)
                                          .includes(c.value)
                                    ) || null
                                  }
                                  onChange={(selected: any) => {
                                    if (selected && selected.length) {
                                      setFieldValue(`receiveNotification`, selected);
                                    }
                                    if (!selected.length) {
                                      setFieldValue(`receiveNotification`, []);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                <SingleDatePicker
                                  id="dueDate"
                                  name="dueDate"
                                  label="Due Date"
                                  error={<ErrorMessage component="span" className="field_error" name={`dueDate`} />}
                                  onChange={(values: any) => {
                                    setFieldValue(`dueDate`, (values && new Date(values)) || null);
                                  }}
                                />
                              </div>
                              <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                <SingleDatePicker
                                  id="estimatedDeliveryDate"
                                  name="estimatedDeliveryDate"
                                  label="Estimated Delivery Date"
                                  error={
                                    <ErrorMessage
                                      component="span"
                                      className="field_error"
                                      name={`estimatedDeliveryDate`}
                                    />
                                  }
                                  onChange={(values: any) => {
                                    setFieldValue(`estimatedDeliveryDate`, (values && new Date(values)) || null);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-sm-12" style={{ marginBottom: 20 }}>
                                <SearchWithOptions
                                  label="Client user"
                                  placeholder="Search Users"
                                  id="clientReceiveNotification"
                                  options={clientUsersLists}
                                  labelKey="label"
                                  filterBy={["label"]}
                                  rightIcon={true}
                                  size="sm"
                                  multiple={true}
                                  clearButton
                                  maxWidth="100%"
                                  maxResults={30}
                                  tooltipIcon={true}
                                  tooltipMessage="Message about the notification to be displayed here.."
                                  selected={
                                    clientUsersLists.filter(
                                      (c: any) =>
                                        selectedClientReceiveNotification &&
                                        selectedClientReceiveNotification.length &&
                                        selectedClientReceiveNotification
                                          .map((user: { value: number }) => user.value)
                                          .includes(c.value)
                                    ) || null
                                  }
                                  onChange={(selected: any) => {
                                    if (selected && selected.length) {
                                      setFieldValue(`clientReceiveNotification`, selected);
                                    }
                                    if (!selected.length) {
                                      setFieldValue(`clientReceiveNotification`, []);
                                    }
                                  }}
                                />
                              </div>

                              <div className="col-sm-12">
                                <FieldArray
                                  name="clientEmail"
                                  render={(arrayHelpers) => {
                                    const blankEmail = "";

                                    const addNewEmail = () => {
                                      if (values.clientEmail && values.clientEmail.length) {
                                        arrayHelpers.push(blankEmail);
                                      } else {
                                        arrayHelpers.insert(values.clientEmail.length + 1, blankEmail);
                                      }
                                    };

                                    return (
                                      <div>
                                        <div>
                                          {values.clientEmail.length ? (
                                            values.clientEmail.map((email: any, index: number) => {
                                              return (
                                                <div key={index}>
                                                  <div
                                                    className="row g-3 align-items-center"
                                                    style={{ marginBottom: 20 }}
                                                  >
                                                    <div className="col-11">
                                                      <Input
                                                        min={1}
                                                        id={`clientEmail.${index}`}
                                                        name={`clientEmail.${index}`}
                                                        type="text"
                                                        placeholder="Customer Email"
                                                        label="Customer Email"
                                                        onChange={handleChange(`clientEmail.${index}`)}
                                                        onBlur={handleBlur(`clientEmail.${index}`)}
                                                        autoCapitalize="none"
                                                        error={errors.clientEmail}
                                                        size="form-control-sm"
                                                        onChangeCapture={() =>
                                                          actions.actionsErrorMessage &&
                                                          dispatch(showErrorMessageSuccess(""))
                                                        }
                                                      />
                                                    </div>

                                                    <div className="col-auto" style={{ marginTop: 40 }}>
                                                      <BsXCircle
                                                        size="20"
                                                        className="text-danger"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => arrayHelpers.remove(index)}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <div>
                                              <p>No Customer Email</p>
                                            </div>
                                          )}
                                        </div>

                                        <div>
                                          <GlobalButton
                                            size="sm"
                                            format="secondary"
                                            className="mb-3"
                                            onClick={() => {
                                              addNewEmail();
                                            }}
                                          >
                                            Add Email
                                          </GlobalButton>
                                        </div>
                                      </div>
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="description" title="Brief Description">
                        <div className="row">
                          {values.type === "new_account" && (
                            <>
                              <div className="col-sm-12" style={{ marginBottom: 20 }}>
                                <Textarea
                                  name="brandIdentify"
                                  id="brandIdentify"
                                  label="Brand Identity"
                                  rows={3}
                                  style={{
                                    height: "auto",
                                    minHeight: "135px",
                                  }}
                                  onChangeCapture={(event: any) => {
                                    event.target.style.height = "auto";
                                    event.target.style.height = event.target.scrollHeight + "px";
                                  }}
                                />
                              </div>
                              <div className="col-sm-12" style={{ marginBottom: 20 }}>
                                <Textarea
                                  name="customerDemographics"
                                  id="customerDemographics"
                                  label="Customer Demographics"
                                  rows={3}
                                  style={{
                                    height: "auto",
                                    minHeight: "135px",
                                  }}
                                  onChangeCapture={(event: any) => {
                                    event.target.style.height = "auto";
                                    event.target.style.height = event.target.scrollHeight + "px";
                                  }}
                                />
                              </div>
                              <div className="col-sm-12" style={{ marginBottom: 20 }}>
                                <Textarea
                                  name="tradingPatterns"
                                  id="tradingPatterns"
                                  label="Trading Patterns"
                                  rows={3}
                                  style={{
                                    height: "auto",
                                    minHeight: "135px",
                                  }}
                                  onChangeCapture={(event: any) => {
                                    event.target.style.height = "auto";
                                    event.target.style.height = event.target.scrollHeight + "px";
                                  }}
                                />
                              </div>
                            </>
                          )}

                          <div className="col-sm-12" style={{ marginBottom: 20 }}>
                            <Textarea
                              name="requestedNotes"
                              id="requestedNotes"
                              label="Brief Notes"
                              rows={4}
                              style={{
                                height: "auto",
                                minHeight: "135px",
                              }}
                              onChangeCapture={(event: any) => {
                                event.target.style.height = "auto";
                                event.target.style.height = event.target.scrollHeight + "px";
                              }}
                            />
                          </div>
                          <div className="col-sm-12" style={{ marginBottom: 20 }}>
                            <Textarea
                              name="otherNotes"
                              id="otherNotes"
                              label="Other Notes"
                              rows={4}
                              style={{
                                height: "auto",
                                minHeight: "135px",
                              }}
                              onChangeCapture={(event: any) => {
                                event.target.style.height = "auto";
                                event.target.style.height = event.target.scrollHeight + "px";
                              }}
                            />
                          </div>

                          <div className="col-sm-12" style={{ marginBottom: 20 }}>
                            <Textarea
                              name="musicReferences"
                              id="musicReferences"
                              label="Music References"
                              rows={3}
                              style={{}}
                              onChangeCapture={(event: any) => {
                                event.target.style.height = "auto";
                                event.target.style.height = event.target.scrollHeight + "px";
                              }}
                            />
                          </div>
                          {values.type === "new_account" && (
                            <div className="col-sm-12" style={{ marginBottom: 20 }}>
                              <Input
                                label="Website"
                                id="website"
                                name="website"
                                type="text"
                                placeholder="https://www.ambie.fm"
                                onChange={handleChange("website")}
                                value={values.website}
                                onBlur={handleBlur("website")}
                                autoCapitalize="none"
                                error={errors.website}
                                size="form-control-sm"
                                onChangeCapture={() =>
                                  actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))
                                }
                              />
                            </div>
                          )}
                          <div className="col-sm-12" style={{ marginBottom: 20 }}>
                            <Toggle
                              type="checkbox"
                              name="ambieToSchedule"
                              id="ambieToSchedule"
                              label="Ambie To Schedule"
                              rightLabel={true}
                              checked={true}
                              style={{ cursor: "pointer" }}
                              onChange={(value: any) => {
                                const ambieToSchedule = value.target && value.target.value === "true" ? false : true;
                                setFieldValue(`ambieToSchedule`, ambieToSchedule);
                              }}
                            />
                          </div>
                          {values.type === "curation_refresh" && (
                            <>
                              <div className="col-sm-12" style={{ marginBottom: 20 }}>
                                <SearchWithOptions
                                  label="Refresh Playlists Names"
                                  placeholder="Search Refresh Playlists Names"
                                  id="refreshPlaylists"
                                  options={refreshPlaylistsLists}
                                  labelKey="label"
                                  filterBy={["label"]}
                                  rightIcon={true}
                                  size="sm"
                                  multiple={true}
                                  clearButton
                                  maxWidth="100%"
                                  maxResults={30}
                                  onChange={(selected: any) => {
                                    if (selected && selected.length) {
                                      setFieldValue(`refreshPlaylists`, selected);
                                    }
                                    if (!selected.length) {
                                      setFieldValue(`refreshPlaylists`, []);
                                    }
                                  }}
                                />
                              </div>
                              <div>
                                <div>
                                  {(values.refreshPlaylists &&
                                    values.refreshPlaylists.length &&
                                    PlaylistMood(values.refreshPlaylists)) ||
                                    null}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </Panel>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

/**
 * Edit brief
 */
export const EditBrief = ({ show }: { show: boolean }) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const [locations, setLocations] = useState<[]>([]);
  const [playlists, setPlaylists] = useState<[]>([]);
  const ambieUsers = useSelector((state: RootState) => state.authSlice.ambieUsers);
  const clientHubSlice = useSelector((state: RootState) => state.clientHubSlice);
  const usersSlice = useSelector((state: RootState) => state.authSlice);

  const socket = useContext(WebSocketContext);
  const dispatch = useDispatch();

  const clientId = searchClient.clientId;
  const briefId = actions.editBrief.id;

  useEffect(() => {
    let isSubscribed = true;

    socket.on("updateClient", (payload: { id: number }) => {
      if (payload.id === clientId) getClientData();
    });

    socket.on("updateBrief", (payload: { id: number }) => {
      if (payload.id === briefId) getBriefData();
    });

    const getClientData = async () => {
      if (clientId) {
        await getClientForAmbieUser(clientId)
          .then((response) => {
            if (isSubscribed) {
              dispatch(clientDetailsSuccess({ clientDetails: response.data }));
            }
          })
          .catch((error) => {});
      }
    };

    const getBriefData = async () => {
      await getBrief(briefId);
    };

    const getAmbieUsersData = async () => {
      await getAmbieUsers();
    };

    const getClientUsersData = async () => {
      await getClientUsers(clientId);
    };

    const getLocationsData = async () => {
      await getClientActiveLocations(clientId)
        .then((response) => {
          if (isSubscribed) setLocations(response.data);
        })
        .catch((error) => {});
    };

    const getActivePlaylistsByClientIdData = async () => {
      await getTotalPlaylistsAndTracks(clientId)
        .then((response) => {
          setPlaylists(response.playlists);
        })
        .catch((error) => {});
    };

    if (briefId) getBriefData();
    getClientData();
    getAmbieUsersData();
    getClientUsersData();
    getLocationsData();
    getActivePlaylistsByClientIdData();

    return () => {
      isSubscribed = false;
      socket.off("updateClient", (payload: { id: number }) => {
        if (payload.id === clientId) getClientData();
      });

      socket.off("updateBrief", (payload: { id: number }) => {
        if (payload.id === briefId) getBriefData();
      });
    };
  }, [searchClient, clientId, dispatch, briefId]);

  const briefInfo: any = clientHubSlice.brief;

  const hideActions = () => {
    dispatch(hideActionsSuccess());
    dispatch(briefSuccess({ brief: {} }));
  };

  const editBriefData = async (values: any) => {
    let briefErrorMessages: any = [];
    let customerEmail: any = [];
    let customerEmailFail: any = [];

    // clean customer email
    if (values.clientEmail) {
      customerEmail = values.clientEmail
        .filter((value: any) => value !== "")
        .map((email: any) => {
          let emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

          // check email validation
          if (!emailReg.test(email)) {
            customerEmailFail.push(email);
          }
          return email;
        });
    }

    if (
      !values.title ||
      !values.type ||
      !values.locations.length ||
      !values.curationOwnerId ||
      customerEmailFail.length ||
      (values.type === "curation_refresh" && !values.refreshPlaylists.length)
    ) {
      if (!values.title) {
        briefErrorMessages.push({
          message: "The title is required in [BRIEF DETAILS SECTION]",
        });
      }
      if (!values.type) {
        briefErrorMessages.push({
          message: "The type is required in [BRIEF DETAILS SECTION]",
        });
      }

      if (!values.locations.length) {
        briefErrorMessages.push({
          message: "At least one location is required in [BRIEF DETAILS SECTION]",
        });
      }

      if (!values.curationOwnerId) {
        briefErrorMessages.push({
          message: "The curation owner is required in [BRIEF DETAILS SECTION]",
        });
      }
      if (customerEmailFail.length) {
        const text =
          customerEmailFail.length === 1
            ? `The following customer email "${customerEmailFail
                .join(", ")
                .replace(/,([^,]*)$/, " and$1")}" is not valid in [BRIEF DETAILS SECTION]`
            : `The following customer emails "${customerEmailFail
                .join(", ")
                .replace(/,([^,]*)$/, " and$1")}" are not valid in [BRIEF DETAILS SECTION]`;
        briefErrorMessages.push({
          message: text,
        });
      }

      if (values.type === "curation_refresh" && !values.refreshPlaylists.length) {
        briefErrorMessages.push({
          message:
            "At least one Refresh Playlist is required when choosing Curation Refresh as type in [BRIEF DESCRIPTION SECTION]",
        });
      }

      dispatch(
        showSubmissionErrorSuccess({
          submissionError: { status: true, messages: briefErrorMessages },
        })
      );

      return;
    }

    let setCompletionDate: any = briefInfo.completion_date;

    if (values.status === "pending" || (values.setStatus === "progress" && values.status === "progress")) {
      setCompletionDate = null;
    } else if (!setCompletionDate && values.setStatus === "completed") {
      setCompletionDate = new Date();
    }

    const data = {
      client_id: clientId,
      type: values.type,
      source: values.source,
      locations: values.locations,
      curation_owner_id: values.curationOwnerId,
      status: values.setStatus === "completed" ? "completed" : values.status,
      title: values.title,
      notifications_receiver: values.receiveNotification,
      client_notifications_receiver: values.clientReceiveNotification,
      due_date: values.dueDate,
      estimated_delivery_date: values.estimatedDeliveryDate,
      brand_identity: values.brandIdentify,
      customer_demographics: values.customerDemographics,
      music_references: values.musicReferences,
      other_notes: values.otherNotes,
      trading_patterns: values.tradingPatterns,
      requested_notes: values.requestedNotes,
      refresh_playlists: values.refreshPlaylists,
      ambie_to_schedule: values.ambieToSchedule,
      completion_notes: values.completionNotes,
      completed_playlists: values.completedPlaylists,
      completion_date: setCompletionDate,
      website: values.website,
      customer_email: customerEmail,
    };

    dispatch(isLoadingSuccess(true));
    await editBrief(briefId, data)
      .then((response) => {
        hideActions();
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  const statusOptions = [
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "In Progress",
      value: "progress",
    },
    {
      label: "Completed",
      value: "completed",
    },
  ];

  const locationsLists = locations.length
    ? locations.map((location: any) => ({
        value: location.id,
        label: location.name,
      }))
    : [];

  const completedPlaylistsLists = playlists.length
    ? playlists.map((playlist: any) => ({
        value: playlist.playlist_id,
        label: playlist.name,
        energyLevel: playlist.energy_level,
      }))
    : [];

  const refreshPlaylistsLists = playlists.length
    ? playlists
        .filter((publish: { is_published: boolean }) => publish.is_published)
        .map((playlist: any) => ({
          value: playlist.playlist_id,
          label: playlist.name,
          energyLevel: playlist.energy_level,
          duration: playlist.duration,
        }))
    : [];

  const ambieUsersLists = ambieUsers.length
    ? ambieUsers
        .filter((hasName: any) => hasName.users_user && hasName.users_user.name)
        .map((user: any) => ({
          value: user.users_user && user.users_user.id,
          label: user.users_user && user.users_user.name,
        }))
    : [];

  const clientUsersLists = usersSlice.users.length
    ? usersSlice.users
        .filter((hasName: any) => hasName.users_user && hasName.users_user.name)
        .map((user: any) => ({
          value: user.users_user && user.users_user.id,
          label: `${user.users_user && user.users_user.name} - (${user.permission_level.name})`,
          email: user.users_user && user.users_user.email,
        }))
    : [];

  const locationsRequested =
    briefInfo.briefs_locations_request && briefInfo.briefs_locations_request.length
      ? briefInfo.briefs_locations_request.map((location: { businesses_business: { id: number; name: string } }) => ({
          value: location.businesses_business && location.businesses_business.id,
          label: location.businesses_business && location.businesses_business.name,
        }))
      : [];

  const usersReceiveNotification =
    briefInfo.briefs_watchers && briefInfo.briefs_watchers.length
      ? briefInfo.briefs_watchers.map((user: { users_user: { id: number; name: string } }) => ({
          value: user.users_user && user.users_user.id,
          label: user.users_user && user.users_user.name,
        }))
      : [];

  const clientUsersReceiveNotification =
    briefInfo.briefs_watchers && briefInfo.briefs_client_notifications_receivers.length
      ? briefInfo.briefs_client_notifications_receivers.map(
          (user: { users_user: { id: number; name: string; email: string }; permission_level: { name: string } }) => ({
            value: user.users_user && user.users_user.id,
            label: `${user.users_user?.name} - (${user.permission_level?.name})`,
            email: user.users_user?.email,
          })
        )
      : [];

  const refreshPlaylistsRequestedIds =
    briefInfo.briefs_playlists_refresh && briefInfo.briefs_playlists_refresh.length
      ? briefInfo.briefs_playlists_refresh.map((playlist: any) => playlist.playlist_id)
      : [];

  const refreshPlaylistsRequested = playlists.length
    ? playlists
        .filter((publish: { playlist_id: boolean }) => refreshPlaylistsRequestedIds.includes(publish.playlist_id))
        .map((playlist: any) => ({
          value: playlist.playlist_id,
          label: playlist.name,
          energyLevel: playlist.energy_level,
          duration: playlist.duration,
        }))
    : [];

  const completedPlaylistsAssignedIds =
    briefInfo.briefs_playlists_completed && briefInfo.briefs_playlists_completed.length
      ? briefInfo.briefs_playlists_completed.map((playlist: any) => playlist.playlist_id)
      : [];

  const completedPlaylistsAssigned = playlists.length
    ? playlists
        .filter((publish: { playlist_id: boolean }) => completedPlaylistsAssignedIds.includes(publish.playlist_id))
        .map((playlist: any) => ({
          value: playlist.playlist_id,
          label: playlist.name,
          energyLevel: playlist.energy_level,
          duration: playlist.duration,
        }))
    : [];

  const briefSourceLists = (source: string) => {
    const sourcelists: any = {
      manual: "Manual",
      dashboard: "Dashboard",
      "my-ambie-app": "My Ambie App",
    };
    return sourcelists[source];
  };
  const getUserPermissionLevel = briefInfo?.submitted_by_user?.users_clients.filter(
    (permissionLevel: any) => !permissionLevel.switch_client
  );

  const initialValues = {
    title: briefInfo.title || "",
    clientName: briefInfo.businesses_client && briefInfo.businesses_client.name,
    userName: briefInfo.submitted_by_user && briefInfo.submitted_by_user.name,
    permissionLevel: getUserPermissionLevel && getUserPermissionLevel[0]?.permission_level.name,
    type: briefInfo.type,
    source: briefInfo.source && briefSourceLists(briefInfo.source),
    locations: locationsRequested,
    curationOwnerId: (briefInfo.curation_owner && briefInfo.curation_owner.id) || null,
    receiveNotification: usersReceiveNotification,
    clientReceiveNotification: clientUsersReceiveNotification,
    dueDate: briefInfo.due_date || null,
    estimatedDeliveryDate: briefInfo.estimated_delivery_date || null,
    brandIdentify: briefInfo.brand_identity || "",
    customerDemographics: briefInfo.customer_demographics || "",
    musicReferences: briefInfo.music_references || "",
    otherNotes: briefInfo.other_notes || "",
    tradingPatterns: briefInfo.trading_patterns || "",
    completionNotes: briefInfo.completion_notes || "",
    requestedNotes: briefInfo.requested_notes || "",
    completedPlaylists: completedPlaylistsAssigned,
    refreshPlaylists: refreshPlaylistsRequested,
    status: briefInfo.status,
    ambieToSchedule: briefInfo.ambie_to_schedule,
    setStatus: briefInfo.status,
    created: briefInfo.created || null,
    completiondDate: briefInfo.completion_date || null,
    website: briefInfo.website,
    selectAllLocations: (locationsRequested.length === locationsLists.length && true) || false,
    clientEmail: [],
  };

  const resetBriefContentError = () => {
    dispatch(showSubmissionErrorSuccess({ submissionError: { status: false, messages: [] } }));
  };

  return (
    <>
      {actions.submissionError.status && (
        <BriefSubmissionErrorModal
          show={actions.submissionError.status}
          handleClose={() => resetBriefContentError()}
          messages={actions.submissionError.messages}
          footer={
            <GlobalButton format="info" size="sm" onClick={() => resetBriefContentError()}>
              Close
            </GlobalButton>
          }
        />
      )}

      {((actions.editPlaylist && actions.editPlaylist.status) || actions.addPlaylist) && <PlaylistModal />}

      <Formik form initialValues={initialValues} onSubmit={editBriefData} enableReinitialize>
        {({ handleChange, handleBlur, setFieldValue, values, errors }) => {
          const selectedLocations = values.locations;
          const selectedReceiveNotification = values.receiveNotification;
          const selectedClientReceiveNotification = values.clientReceiveNotification;
          const selectedRefreshPlaylists = values.refreshPlaylists;
          const selectedCompletedPlaylists = values.completedPlaylists;

          return (
            <Form>
              <Panel
                show={show}
                handleClose={() => {
                  hideActions();
                }}
                title={
                  <div>
                    <p>Edit Brief - {values.title}</p>
                    <span>
                      {BriefStatus(values.status)}{" "}
                      <span style={{ marginLeft: 15 }}>
                        {BriefSubmissionAndCompletionStatus(briefInfo.created, briefInfo.completion_date)}
                      </span>
                    </span>
                  </div>
                }
                width="50%"
                maxWidth="1000px"
                footer={
                  <div className={panelStyles.footer}>
                    <div className={panelStyles.footer_left}>
                      <GlobalButton
                        format="white"
                        size="sm"
                        onClick={() => {
                          hideActions();
                        }}
                      >
                        Close
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        type="submit"
                        format="primary"
                        size="sm"
                        className="me-3"
                        onClick={() => {
                          setFieldValue("setStatus", "progress");
                        }}
                      >
                        Save Brief
                      </GlobalButton>
                      <GlobalButton
                        type="submit"
                        format="success"
                        size="sm"
                        disabled={values.status !== "pending" ? false : true}
                        onClick={() => {
                          setFieldValue("setStatus", "completed");
                        }}
                      >
                        Mark as Completed
                      </GlobalButton>
                    </div>
                  </div>
                }
              >
                <div>
                  {values.type && (
                    <div>
                      <div>
                        {actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}
                      </div>
                      {actions.actionsIsLoading && <LoadingData />}
                      <Tabs defaultActiveKey="details" id="fill-tab-example" className="nav-segment mb-3" fill>
                        <Tab eventKey="details" title="Brief Details">
                          <div className="row">
                            <div className="col-sm-6" style={{ marginBottom: 20 }}>
                              <Input
                                label="Title"
                                id="title"
                                name="title"
                                type="text"
                                required
                                placeholder="Title"
                                onChange={handleChange("title")}
                                value={values.title}
                                onBlur={handleBlur("title")}
                                autoCapitalize="none"
                                error={errors.title}
                                size="form-control-sm"
                                onChangeCapture={() =>
                                  actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))
                                }
                              />
                            </div>
                            <div className="col-sm-6" style={{ marginBottom: 20 }}>
                              <ReactSelect
                                label="Status"
                                id="status"
                                name="status"
                                required
                                options={statusOptions.filter((status: any) => {
                                  if (briefInfo.status !== "completed") {
                                    return status.value !== "completed";
                                  }
                                  return status;
                                })}
                                value={statusOptions.find((c: any) => c.value === values.status)}
                                onChange={(selected: any) =>
                                  selected && selected.value && setFieldValue(`status`, selected.value)
                                }
                              />
                            </div>

                            <div className="col-sm-6" style={{ marginBottom: 20 }}>
                              <Input
                                label="Client"
                                id="clientName"
                                name="clientName"
                                type="text"
                                required
                                disabled
                                placeholder="Client"
                                onChange={handleChange("clientName")}
                                value={values.clientName}
                                onBlur={handleBlur("clientName")}
                                autoCapitalize="none"
                                error={errors.clientName}
                                size="form-control-sm"
                                onChangeCapture={() =>
                                  actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))
                                }
                              />
                            </div>
                            <div className="col-sm-6" style={{ marginBottom: 20 }}>
                              <InputWrapper
                                label="Submitted By"
                                id="userName"
                                name="userName"
                                type="text"
                                required
                                disabled
                                placeholder="Client"
                                onChange={handleChange("userName")}
                                value={values.userName}
                                onBlur={handleBlur("userName")}
                                autoCapitalize="ca"
                                error={errors.userName}
                                size="form-control-sm"
                                // onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                              >
                                <span>
                                  {values.userName} -{" "}
                                  <small className="badge bg-soft-dark text-dark p-1">{values.permissionLevel}</small>
                                </span>
                              </InputWrapper>
                            </div>
                            <div className="col-sm-12" style={{ marginBottom: 20 }}>
                              <SearchWithOptions
                                label="Locations"
                                additionalContent={
                                  <div className="col-auto">
                                    <Checkbox
                                      type="checkbox"
                                      name="selectAllLocations"
                                      id="selectAllLocations"
                                      value="Select All Locations"
                                      onChange={() => {
                                        if (values.selectAllLocations === false) {
                                          setFieldValue(`locations`, locationsLists);
                                        } else {
                                          setFieldValue(`locations`, []);
                                        }
                                      }}
                                    />
                                  </div>
                                }
                                placeholder="Search Locations"
                                id="locations"
                                options={locationsLists}
                                labelKey="label"
                                filterBy={["label"]}
                                rightIcon={true}
                                size="sm"
                                multiple={true}
                                selected={
                                  locationsLists.filter(
                                    (c: any) =>
                                      selectedLocations &&
                                      selectedLocations.length &&
                                      selectedLocations
                                        .map((location: { value: number }) => location.value)
                                        .includes(c.value)
                                  ) || null
                                }
                                required
                                clearButton
                                maxWidth="100%"
                                maxResults={30}
                                tooltipIcon={false}
                                tooltipMessage="Message about the permission to be displayed here.."
                                onChange={(selected: any) => {
                                  if (selected && selected.length) {
                                    setFieldValue(`locations`, selected);
                                  }
                                  if (!selected.length) {
                                    setFieldValue(`locations`, []);
                                  }
                                  if (selected && selected.length === locationsLists.length) {
                                    setFieldValue(`selectAllLocations`, true);
                                  } else {
                                    setFieldValue(`selectAllLocations`, false);
                                  }
                                }}
                              />
                            </div>

                            <div className="col-sm-6" style={{ marginBottom: 20 }}>
                              <ReactSelect
                                label="Type"
                                id="type"
                                name="type"
                                required
                                options={briefTypeOptions().filter((type) => type.value !== "all")}
                                value={briefTypeOptions().find((c: any) => c.value === values.type)}
                                onChange={(selected: any) =>
                                  selected && selected.value && setFieldValue(`type`, selected.value)
                                }
                              />
                            </div>

                            <div className="col-sm-6" style={{ marginBottom: 20 }}>
                              <Input
                                label="Source"
                                id="source"
                                name="source"
                                type="text"
                                required
                                disabled
                                placeholder="Source"
                                onChange={handleChange("source")}
                                value={values.source}
                                onBlur={handleBlur("source")}
                                autoCapitalize="none"
                                error={errors.source}
                                size="form-control-sm"
                                onChangeCapture={() =>
                                  actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))
                                }
                              />
                            </div>

                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                  <SearchWithOptions
                                    label="Curation Owner"
                                    placeholder="Search Curation Owner.."
                                    id="curationOwnerId"
                                    required
                                    selected={
                                      ambieUsersLists.filter((c: any) => c.value === values.curationOwnerId) || null
                                    }
                                    options={ambieUsersLists}
                                    // leftIcon={<BsSearch />}
                                    labelKey="label"
                                    filterBy={["label"]}
                                    rightIcon={true}
                                    size="sm"
                                    clearButton
                                    maxWidth="100%"
                                    maxResults={30}
                                    tooltipIcon={false}
                                    onChange={(selected: any) => {
                                      if (selected && selected[0]) {
                                        setFieldValue(`curationOwnerId`, selected[0].value);
                                      }
                                      if (!selected.length) {
                                        setFieldValue(`curationOwnerId`, null);
                                      }
                                    }}
                                  />
                                </div>
                                <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                  <SearchWithOptions
                                    label="Watching Brief"
                                    placeholder="Search Users"
                                    id="receiveNotification"
                                    options={ambieUsersLists}
                                    labelKey="label"
                                    filterBy={["label"]}
                                    rightIcon={true}
                                    size="sm"
                                    multiple={true}
                                    clearButton
                                    maxWidth="100%"
                                    maxResults={30}
                                    tooltipIcon={true}
                                    tooltipMessage="Message about the notification to be displayed here.."
                                    selected={
                                      ambieUsersLists.filter(
                                        (c: any) =>
                                          selectedReceiveNotification &&
                                          selectedReceiveNotification.length &&
                                          selectedReceiveNotification
                                            .map((user: { value: number }) => user.value)
                                            .includes(c.value)
                                      ) || null
                                    }
                                    onChange={(selected: any) => {
                                      if (selected && selected.length) {
                                        setFieldValue(`receiveNotification`, selected);
                                      }
                                      if (!selected.length) {
                                        setFieldValue(`receiveNotification`, []);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="row">
                                <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                  <SingleDatePicker
                                    id="dueDate"
                                    name="dueDate"
                                    label="Due Date"
                                    error={<ErrorMessage component="span" className="field_error" name={`dueDate`} />}
                                    defaultPickerValue={
                                      (values &&
                                        values.dueDate &&
                                        moment(new Date(values.dueDate).getTime()).startOf("day")) ||
                                      null
                                    }
                                    onChange={(values: any) => {
                                      setFieldValue(`dueDate`, (values && new Date(values)) || null);
                                    }}
                                  />
                                </div>
                                <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                  <SingleDatePicker
                                    id="estimatedDeliveryDate"
                                    name="estimatedDeliveryDate"
                                    label="Estimated Delivery Date"
                                    error={
                                      <ErrorMessage
                                        component="span"
                                        className="field_error"
                                        name={`estimatedDeliveryDate`}
                                      />
                                    }
                                    defaultPickerValue={
                                      (values &&
                                        values.estimatedDeliveryDate &&
                                        moment(new Date(values.estimatedDeliveryDate).getTime()).startOf("day")) ||
                                      null
                                    }
                                    onChange={(values: any) => {
                                      setFieldValue(`estimatedDeliveryDate`, (values && new Date(values)) || null);
                                    }}
                                  />
                                </div>

                                <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                  <SingleDatePicker
                                    id="created"
                                    name="created"
                                    label="Submission Date"
                                    error={<ErrorMessage component="span" className="field_error" name={`created`} />}
                                    disabled
                                    defaultPickerValue={
                                      (values &&
                                        values.created &&
                                        moment(new Date(values.created).getTime()).startOf("day")) ||
                                      null
                                    }
                                  />
                                </div>

                                {values.completiondDate && (
                                  <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                    <SingleDatePicker
                                      id="completiondDate"
                                      name="completiondDate"
                                      label="Completion Date"
                                      disabled
                                      error={
                                        <ErrorMessage
                                          component="span"
                                          className="field_error"
                                          name={`completiondDate`}
                                        />
                                      }
                                      defaultPickerValue={
                                        (values &&
                                          values.completiondDate &&
                                          moment(new Date(values.completiondDate).getTime()).startOf("day")) ||
                                        null
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="row">
                                <div className="col-sm-12" style={{ marginBottom: 20 }}>
                                  <SearchWithOptions
                                    label="Client user"
                                    placeholder="Search Users"
                                    id="clientReceiveNotification"
                                    options={clientUsersLists}
                                    labelKey="label"
                                    filterBy={["label"]}
                                    rightIcon={true}
                                    size="sm"
                                    multiple={true}
                                    clearButton
                                    maxWidth="100%"
                                    maxResults={30}
                                    tooltipIcon={true}
                                    tooltipMessage="Message about the notification to be displayed here.."
                                    selected={
                                      clientUsersLists.filter(
                                        (c: any) =>
                                          selectedClientReceiveNotification &&
                                          selectedClientReceiveNotification.length &&
                                          selectedClientReceiveNotification
                                            .map((user: { value: number }) => user.value)
                                            .includes(c.value)
                                      ) || null
                                    }
                                    onChange={(selected: any) => {
                                      if (selected && selected.length) {
                                        setFieldValue(`clientReceiveNotification`, selected);
                                      }
                                      if (!selected.length) {
                                        setFieldValue(`clientReceiveNotification`, []);
                                      }
                                    }}
                                  />
                                </div>

                                <div className="col-sm-12">
                                  <FieldArray
                                    name="clientEmail"
                                    render={(arrayHelpers) => {
                                      const blankEmail = "";

                                      const addNewEmail = () => {
                                        if (values.clientEmail && values.clientEmail.length) {
                                          arrayHelpers.push(blankEmail);
                                        } else {
                                          arrayHelpers.insert(values.clientEmail.length + 1, blankEmail);
                                        }
                                      };

                                      return (
                                        <div>
                                          <div>
                                            {values.clientEmail.length ? (
                                              values.clientEmail.map((email: any, index: number) => {
                                                return (
                                                  <div key={index}>
                                                    <div
                                                      className="row g-3 align-items-center"
                                                      style={{ marginBottom: 20 }}
                                                    >
                                                      <div className="col-11">
                                                        <Input
                                                          min={1}
                                                          id={`clientEmail.${index}`}
                                                          name={`clientEmail.${index}`}
                                                          type="email"
                                                          placeholder="Customer Email"
                                                          label="Customer Email"
                                                          onChange={handleChange(`clientEmail.${index}`)}
                                                          onBlur={handleBlur(`clientEmail.${index}`)}
                                                          autoCapitalize="none"
                                                          error={errors.clientEmail}
                                                          size="form-control-sm"
                                                          onChangeCapture={() =>
                                                            actions.actionsErrorMessage &&
                                                            dispatch(showErrorMessageSuccess(""))
                                                          }
                                                        />
                                                      </div>

                                                      <div className="col-auto" style={{ marginTop: 40 }}>
                                                        <BsXCircle
                                                          size="20"
                                                          className="text-danger"
                                                          style={{ cursor: "pointer" }}
                                                          onClick={() => arrayHelpers.remove(index)}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              })
                                            ) : (
                                              <div>
                                                <p>No Customer Email</p>
                                              </div>
                                            )}
                                          </div>

                                          <div>
                                            <GlobalButton
                                              size="sm"
                                              format="secondary"
                                              className="mb-3"
                                              onClick={() => {
                                                addNewEmail();
                                              }}
                                            >
                                              Add Email
                                            </GlobalButton>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="description" title="Brief Description">
                          <div className="row">
                            {values.type === "new_account" && (
                              <>
                                <div className="col-sm-12" style={{ marginBottom: 20 }}>
                                  <Textarea
                                    name="brandIdentify"
                                    id="brandIdentify"
                                    label="Brand Identity"
                                    rows={4}
                                    style={{
                                      height: "auto",
                                      minHeight: "135px",
                                    }}
                                    onChangeCapture={(event: any) => {
                                      event.target.style.height = "auto";
                                      event.target.style.height = event.target.scrollHeight + "px";
                                    }}
                                  />
                                </div>
                                <div className="col-sm-12" style={{ marginBottom: 20 }}>
                                  <Textarea
                                    name="customerDemographics"
                                    id="customerDemographics"
                                    label="Customer Demographics"
                                    rows={4}
                                    style={{
                                      height: "auto",
                                      minHeight: "135px",
                                    }}
                                    onChangeCapture={(event: any) => {
                                      event.target.style.height = "auto";
                                      event.target.style.height = event.target.scrollHeight + "px";
                                    }}
                                  />
                                </div>
                                <div className="col-sm-12" style={{ marginBottom: 20 }}>
                                  <Textarea
                                    name="tradingPatterns"
                                    id="tradingPatterns"
                                    label="Trading Patterns"
                                    rows={4}
                                    style={{
                                      height: "auto",
                                      minHeight: "135px",
                                    }}
                                    onChangeCapture={(event: any) => {
                                      event.target.style.height = "auto";
                                      event.target.style.height = event.target.scrollHeight + "px";
                                    }}
                                  />
                                </div>
                              </>
                            )}

                            <div className="col-sm-12" style={{ marginBottom: 20 }}>
                              <Textarea
                                name="requestedNotes"
                                id="requestedNotes"
                                label="Brief Notes"
                                rows={4}
                                style={{
                                  height: "auto",
                                  minHeight: "135px",
                                }}
                                onChangeCapture={(event: any) => {
                                  event.target.style.height = "auto";
                                  event.target.style.height = event.target.scrollHeight + "px";
                                }}
                              />
                            </div>

                            <div className="col-sm-12" style={{ marginBottom: 20 }}>
                              <Textarea
                                name="otherNotes"
                                id="otherNotes"
                                label="Other Notes"
                                rows={4}
                                style={{
                                  height: "auto",
                                  minHeight: "135px",
                                }}
                                onChangeCapture={(event: any) => {
                                  event.target.style.height = "auto";
                                  event.target.style.height = event.target.scrollHeight + "px";
                                }}
                              />
                            </div>

                            <div className="col-sm-12" style={{ marginBottom: 20 }}>
                              <Textarea
                                name="musicReferences"
                                id="musicReferences"
                                label="Music References"
                                rows={4}
                                style={{
                                  height: "auto",
                                  minHeight: "135px",
                                }}
                                onChangeCapture={(event: any) => {
                                  event.target.style.height = "auto";
                                  event.target.style.height = event.target.scrollHeight + "px";
                                }}
                              />
                            </div>
                            {values.type === "new_account" && (
                              <div className="col-sm-12" style={{ marginBottom: 20 }}>
                                <Input
                                  label="Website"
                                  id="website"
                                  name="website"
                                  type="text"
                                  placeholder="https://www.ambie.fm"
                                  onChange={handleChange("website")}
                                  value={values.website}
                                  onBlur={handleBlur("website")}
                                  autoCapitalize="none"
                                  error={errors.website}
                                  size="form-control-sm"
                                  onChangeCapture={() =>
                                    actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))
                                  }
                                />
                              </div>
                            )}
                            <div className="col-sm-12" style={{ marginBottom: 20 }}>
                              <Toggle
                                type="checkbox"
                                name="ambieToSchedule"
                                id="ambieToSchedule"
                                label="Ambie To Schedule"
                                rightLabel={true}
                                checked={true}
                                style={{ cursor: "pointer" }}
                                onChange={(value: any) => {
                                  const ambieToSchedule = value.target && value.target.value === "true" ? false : true;
                                  setFieldValue(`ambieToSchedule`, ambieToSchedule);
                                }}
                              />
                            </div>
                            {values.type === "curation_refresh" && (
                              <>
                                <div className="col-sm-12" style={{ marginBottom: 20 }}>
                                  <SearchWithOptions
                                    label="Refresh Playlists Names"
                                    placeholder="Search Refresh Playlists Names"
                                    id="refreshPlaylists"
                                    options={refreshPlaylistsLists}
                                    labelKey="label"
                                    filterBy={["label"]}
                                    rightIcon={true}
                                    size="sm"
                                    multiple={true}
                                    clearButton
                                    maxWidth="100%"
                                    maxResults={30}
                                    selected={
                                      refreshPlaylistsLists.filter(
                                        (c: any) =>
                                          selectedRefreshPlaylists &&
                                          selectedRefreshPlaylists.length &&
                                          selectedRefreshPlaylists
                                            .map((user: { value: number }) => user.value)
                                            .includes(c.value)
                                      ) || null
                                    }
                                    onChange={(selected: any) => {
                                      if (selected && selected.length) {
                                        setFieldValue(`refreshPlaylists`, selected);
                                      }
                                      if (!selected.length) {
                                        setFieldValue(`refreshPlaylists`, []);
                                      }
                                    }}
                                  />
                                </div>
                                <div>
                                  {(values.refreshPlaylists &&
                                    values.refreshPlaylists.length &&
                                    PlaylistMood(values.refreshPlaylists)) ||
                                    null}
                                </div>
                              </>
                            )}
                          </div>
                        </Tab>
                        <Tab eventKey="notes" title="Completion Notes">
                          <div className="row">
                            <div className="col-sm-12" style={{ marginBottom: 20 }}>
                              <Textarea
                                name="completionNotes"
                                id="completionNotes"
                                label="Completion Notes"
                                rows={5}
                                style={{
                                  height: "auto",
                                  minHeight: "300px",
                                }}
                                onChangeCapture={(event: any) => {
                                  event.target.style.height = "auto";
                                  event.target.style.height = event.target.scrollHeight + "px";
                                }}
                              />
                            </div>

                            <div className="col-sm-12" style={{ marginBottom: 20 }}>
                              <SearchWithOptions
                                label="Completed Playlist Names"
                                placeholder="Search Completed Playlist Names"
                                id="completedPlaylists"
                                options={completedPlaylistsLists}
                                labelKey="label"
                                filterBy={["label"]}
                                rightIcon={true}
                                size="sm"
                                multiple={true}
                                clearButton
                                maxWidth="100%"
                                maxResults={30}
                                selected={
                                  completedPlaylistsLists.filter(
                                    (c: any) =>
                                      selectedCompletedPlaylists &&
                                      selectedCompletedPlaylists.length &&
                                      selectedCompletedPlaylists
                                        .map((user: { value: number }) => user.value)
                                        .includes(c.value)
                                  ) || null
                                }
                                onChange={(selected: any) => {
                                  if (selected && selected.length) {
                                    setFieldValue(`completedPlaylists`, selected);
                                  }
                                  if (!selected.length) {
                                    setFieldValue(`completedPlaylists`, []);
                                  }
                                }}
                              />
                            </div>
                            <div>
                              <div>
                                {(values.completedPlaylists &&
                                  values.completedPlaylists.length &&
                                  PlaylistMood(values.completedPlaylists)) ||
                                  null}
                              </div>
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  )}
                </div>
              </Panel>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export const BriefSubmissionErrorModal = ({ show, messages = [], handleClose, footer }: BriefModalProps) => {
  return (
    <GlobalModal
      title="Error Contents"
      show={show}
      handleClose={handleClose}
      className={styles.errorModal}
      titleClassName={styles.titleClassName}
    >
      <div style={{ margin: "0 20px 20px 20px" }}>
        <ul>
          {messages.length
            ? messages.map((message: any, index: number) => {
                return <li key={index}>{message.message}</li>;
              })
            : null}
        </ul>
        {footer && <div className={styles.closeButton}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

export const briefTypeOptions = () => {
  return [
    {
      label: "All Type",
      value: "all",
    },
    {
      label: "New Account",
      value: "new_account",
    },
    {
      label: " Playlist Request",
      value: "playlist_request",
    },
    {
      label: "Curation Refresh",
      value: "curation_refresh",
    },
    {
      label: "Sampler",
      value: "sampler",
    },
    {
      label: "Add / Remove Track",
      value: "add_remove_track",
    },
    {
      label: "Spotify Playlist",
      value: "spotify_playlist",
    },
    {
      label: "Other Request",
      value: "other_request",
    },
  ];
};
