import React, { useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// style components
import { Input } from "../../../../components/fields/input";
import { addOrEditClientForm } from "../../../../components/formValidation/formValidation";
import { GlobalModal } from "../../../../components/modal";
import {
  hideActionsSuccess,
  showErrorMessageSuccess,
  isLoadingSuccess,
} from "../../../../redux-toolkit/reducers/actionsSlice";
import styles from "./clients-modal.module.scss";
import { RootState } from "../../../../redux-toolkit/store";
import { getAmbieUsers } from "../../../../services/usersServices";
import { GlobalErrorMessage } from "../../../users/components/errorMessage";
import { LoadingData } from "../../../../components/loading";
import { ACTIONS_ERROR_MESSAGE } from "../../../../constants/globalText";
import { SearchWithOptions } from "../../../../components/fields/search";
import { GlobalButton } from "../../../../components/button";
import { Panel } from "../../../../components/panel";
import panelStyles from "../../../../components/panel/panel.module.scss";
import { BsSearch } from "react-icons/bs";

import { addNewClient, getClientForAmbieUser, updateClientRecord } from "../../../../services/clientsServices";
import { clientDetailsSuccess } from "../../../../redux-toolkit/reducers/clientHubSlice";
import { WebSocketContext } from "../../../../components/websocket/websocket";
import { searchClientSuccess } from "../../../../redux-toolkit/reducers/searchClientSlice";
import { useNavigate } from "react-router-dom";
import { locations_page_navigate } from "../../../../constants/routePath";

interface UserActionsProps {
  size?: "sm" | "lg" | "xl";
  backdrop?: "static" | true | false;
  centered?: true | false;
  scrollable?: true | false;
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  footer?: any;
  title?: string;
  show: boolean;
  link?: string;
  location?: { name: string; email: string; id: number };
  user?: { name: string; email: string; id: number };
  client?: { name: string; status: boolean; id: number };
  handleClose?: any;
  children?: any;
}

/**
 * Edit client details
 */
export const EditClientDetails = ({ show }: UserActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const ambieUsers = useSelector((state: RootState) => state.authSlice.ambieUsers);
  const clientDetails = useSelector((state: RootState) => state.clientHubSlice.clientDetails);
  const socket = useContext(WebSocketContext);

  const dispatch = useDispatch();
  const clientId = actions.clientDetails && actions.clientDetails.id;

  useEffect(() => {
    let isSubscribed = true;

    socket.on("updateClient", (payload: { id: number }) => {
      if (payload.id === clientId) getClientData();
    });

    const getClientData = async () => {
      if (clientId) {
        await getClientForAmbieUser(clientId)
          .then((response) => {
            if (isSubscribed) {
              dispatch(clientDetailsSuccess({ clientDetails: response.data }));
            }
          })
          .catch((error) => {});
      }
    };

    const getAmbieUsersData = async () => {
      await getAmbieUsers();
    };

    getClientData();
    getAmbieUsersData();

    return () => {
      isSubscribed = false;
      socket.off("updateClient", (payload: { id: number }) => {
        if (payload.id === clientId) getClientData();
      });
    };
  }, [clientId, dispatch, socket]);

  const editClientDetailsData = async (values: any) => {
    const data = {
      name: values.name,
      syb_id: values.syb_id,
      curator_owner_id: values.curatorOwnerId,
      account_owner_id: values.accountOwnerId,
    };
    dispatch(isLoadingSuccess(true));
    await updateClientRecord(clientId, data)
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  const ambieUsersLists = ambieUsers.length
    ? ambieUsers
        .filter((hasName: any) => hasName.users_user && hasName.users_user.name)
        .map((user: any) => ({
          value: user.users_user && user.users_user.id,
          label: user.users_user && user.users_user.name,
        }))
    : [];

  const initialValues = {
    name: clientDetails.name,
    syb_id: clientDetails.syb_id,
    curatorOwnerId: (clientDetails && clientDetails.curator_owner_id) || null,
    accountOwnerId: (clientDetails && clientDetails.account_owner_id) || null,
  };

  console.log(clientDetails);

  return (
    <Formik
      form
      initialValues={initialValues}
      validationSchema={addOrEditClientForm}
      onSubmit={editClientDetailsData}
      enableReinitialize
    >
      {({ handleChange, handleBlur, setFieldValue, values, errors }) => (
        <Form>
          <Panel
            show={show}
            handleClose={() => {
              dispatch(hideActionsSuccess());
            }}
            title="Edit client"
            width="50%"
            maxWidth="500px"
            footer={
              <div className={panelStyles.footer}>
                <div className={panelStyles.footer_left}>
                  <GlobalButton
                    format="white"
                    size="sm"
                    onClick={() => {
                      dispatch(hideActionsSuccess());
                    }}
                  >
                    Close
                  </GlobalButton>
                </div>
                <div>
                  <GlobalButton type="submit" format="primary" size="sm">
                    Save
                  </GlobalButton>
                </div>
              </div>
            }
          >
            <div>
              <div>
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <div>
                  <div style={{ marginBottom: 20 }}>
                    <Input
                      label="Name"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Name"
                      onChange={handleChange("name")}
                      value={values.name}
                      onBlur={handleBlur("name")}
                      autoCapitalize="none"
                      error={errors.name}
                      size="form-control-sm"
                      onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                    />
                  </div>

                  <div style={{ marginBottom: 20 }}>
                    <Input
                      label="SYB ID"
                      id="syb_id"
                      name="syb_id"
                      type="text"
                      placeholder="SYB ID"
                      onChange={handleChange("syb_id")}
                      value={values.name}
                      onBlur={handleBlur("syb_id")}
                      autoCapitalize="syb_id"
                      error={errors.name}
                      size="form-control-sm"
                      onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                    />
                  </div>

                  <div style={{ marginBottom: 20 }}>
                    <SearchWithOptions
                      label="Curation Owner"
                      placeholder="Search Curation Owner"
                      id="curatorOwnerId"
                      selected={ambieUsersLists.filter((c: any) => c.value === values.curatorOwnerId) || null}
                      options={ambieUsersLists}
                      leftIcon={<BsSearch />}
                      labelKey="label"
                      filterBy={["label"]}
                      rightIcon={true}
                      size="sm"
                      clearButton
                      maxWidth="100%"
                      maxResults={30}
                      tooltipIcon={false}
                      onChange={(selected: any) => {
                        if (selected && selected[0]) {
                          setFieldValue(`curatorOwnerId`, selected[0].value);
                        }
                        if (!selected.length) {
                          setFieldValue(`curatorOwnerId`, null);
                        }
                      }}
                    />
                  </div>

                  <div style={{ marginBottom: 20 }}>
                    <SearchWithOptions
                      label="Customer Owner"
                      placeholder="Search Customer Owner"
                      id="accountOwnerId"
                      options={ambieUsersLists}
                      selected={ambieUsersLists.filter((c: any) => c.value === values.accountOwnerId) || null}
                      leftIcon={<BsSearch />}
                      labelKey="label"
                      filterBy={["label"]}
                      rightIcon={true}
                      size="sm"
                      clearButton
                      maxWidth="100%"
                      maxResults={30}
                      tooltipIcon={false}
                      onChange={(selected: any) => {
                        if (selected && selected[0]) {
                          setFieldValue(`accountOwnerId`, selected[0].value);
                        }
                        if (!selected.length) {
                          setFieldValue(`accountOwnerId`, null);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Form>
      )}
    </Formik>
  );
};

/**
 * Add new client
 */
export const AddNewClient = ({ show }: UserActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const ambieUsers = useSelector((state: RootState) => state.authSlice.ambieUsers);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getAmbieUsersData = async () => {
      await getAmbieUsers();
    };

    getAmbieUsersData();
  }, [actions]);

  const addNewClientDetailsData = async (values: any) => {
    const data = {
      name: values.name,
      account_owner_id: values.accountOwnerId,
      curator_owner_id: values.curatorOwnerId,
    };

    dispatch(isLoadingSuccess(true));
    await addNewClient(data)
      .then((response) => {
        const clientData = response.data;

        dispatch(
          searchClientSuccess({
            clientId: clientData.id,
            clientName: clientData.name,
            clentEmail: clientData.contact_email,
            clentContactName: clientData.contact_name,
            isFrozen: clientData.is_frozen,
          })
        );
        dispatch(hideActionsSuccess());
        navigate(locations_page_navigate);
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  const ambieUsersLists = ambieUsers.length
    ? ambieUsers
        .filter((hasName: any) => hasName.users_user && hasName.users_user.name)
        .map((user: any) => ({
          value: user.users_user && user.users_user.id,
          label: user.users_user && user.users_user.name,
        }))
    : [];

  const initialValues = {
    name: "",
    curatorOwnerId: null,
    accountOwnerId: null,
  };

  return (
    <Formik
      form
      initialValues={initialValues}
      validationSchema={addOrEditClientForm}
      onSubmit={addNewClientDetailsData}
      enableReinitialize
    >
      {({ handleChange, handleBlur, setFieldValue, values, errors }) => (
        <Form>
          <Panel
            show={show}
            handleClose={() => {
              dispatch(hideActionsSuccess());
            }}
            title="Add New Client"
            width="50%"
            maxWidth="500px"
            footer={
              <div className={panelStyles.footer}>
                <div className={panelStyles.footer_left}>
                  <GlobalButton
                    format="white"
                    size="sm"
                    onClick={() => {
                      dispatch(hideActionsSuccess());
                    }}
                  >
                    Close
                  </GlobalButton>
                </div>
                <div>
                  <GlobalButton type="submit" format="primary" size="sm">
                    Add client
                  </GlobalButton>
                </div>
              </div>
            }
          >
            <div>
              <div>
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <div>
                  <div style={{ marginBottom: 20 }}>
                    <Input
                      label="Name"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Name"
                      onChange={handleChange("name")}
                      value={values.name}
                      onBlur={handleBlur("name")}
                      autoCapitalize="none"
                      error={errors.name}
                      size="form-control-sm"
                      onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                    />
                  </div>

                  <div style={{ marginBottom: 20 }}>
                    <SearchWithOptions
                      label="Curation Owner"
                      placeholder="Search Curation Owner"
                      id="curationOwnerId"
                      options={ambieUsersLists}
                      leftIcon={<BsSearch />}
                      labelKey="label"
                      filterBy={["label"]}
                      rightIcon={true}
                      size="sm"
                      clearButton
                      maxWidth="100%"
                      maxResults={30}
                      tooltipIcon={false}
                      onChange={(selected: any) => {
                        if (selected && selected[0]) {
                          setFieldValue(`curatorOwnerId`, selected[0].value);
                        }
                        if (!selected.length) {
                          setFieldValue(`curatorOwnerId`, null);
                        }
                      }}
                    />
                  </div>

                  <div style={{ marginBottom: 20 }}>
                    <SearchWithOptions
                      label="Customer Owner"
                      placeholder="Search Customer Owner"
                      id="accountOwnerId"
                      options={ambieUsersLists}
                      leftIcon={<BsSearch />}
                      labelKey="label"
                      filterBy={["label"]}
                      rightIcon={true}
                      size="sm"
                      clearButton
                      maxWidth="100%"
                      maxResults={30}
                      tooltipIcon={false}
                      onChange={(selected: any) => {
                        if (selected && selected[0]) {
                          setFieldValue(`accountOwnerId`, selected[0].value);
                        }
                        if (!selected.length) {
                          setFieldValue(`accountOwnerId`, null);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Form>
      )}
    </Formik>
  );
};

/**
 * Deactivate client
 */
export const DeactivateClient = ({ footer, show, client, handleClose }: UserActionsProps) => {
  return (
    <GlobalModal title="Deactivate Client" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Are you sure you want to deactivate <span style={{ fontWeight: "bold" }}>{client && client.name}</span>{" "}
          client?
        </p>

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Activate client
 */
export const ActivateClient = ({ footer, show, client, handleClose }: UserActionsProps) => {
  return (
    <GlobalModal title="Deactivate Client" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Are you sure you want to activate <span style={{ fontWeight: "bold" }}>{client && client.name}</span> client?
        </p>

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};
