import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { SearchWithOptions } from "../../../../components/fields/search";
import { BsSearch } from "react-icons/bs";

// style components
import { GlobalModal } from "../../../../components/modal";
import { changeTimezoneSuccess, showTimezoneModalSuccess } from "../../../../redux-toolkit/reducers/schedulesSlice";
import { RootState } from "../../../../redux-toolkit/store";
import modalStyles from "../../../locations/components/location-actions/location-actions.module.scss";
import { updateLocationRecord } from "../../../../services/locationsServices";
import {
  hideActionsSuccess,
  isLoadingSuccess,
  showErrorMessageSuccess,
} from "../../../../redux-toolkit/reducers/actionsSlice";
import { LoadingData } from "../../../../components/loading";
import { ACTIONS_ERROR_MESSAGE } from "../../../../constants/globalText";
import { GlobalErrorMessage } from "../../../users/components/errorMessage";

interface ScheduleProps {
  size?: "sm" | "lg" | "xl";
  backdrop?: "static" | true | false;
  centered?: true | false;
  scrollable?: true | false;
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  footer?: any;
  title?: string;
  show: boolean;
  location?: { name: string; email: string; id: number };
  handleClose: any;
}

/**
 * Change timezone
 */
export const ChangeTimezone = ({ footer, show, handleClose }: ScheduleProps) => {
  const schedulesSlice = useSelector((state: RootState) => state.schedulesSlice);
  const actions = useSelector((state: RootState) => state.actionsSlice);

  const dispatch = useDispatch();

  const updateTimezone = async (timezone: any) => {
    dispatch(isLoadingSuccess(true));
    await updateLocationRecord(actions.location.id, { timezone })
      .then((response) => {
        dispatch(
          changeTimezoneSuccess({
            timezoneName: timezone.name,
          })
        );
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  return (
    <GlobalModal title="Change Timezone" show={show} handleClose={handleClose} footer={footer}>
      <div className={modalStyles.modal}>
        <p>Change the timezone of this schedule to: {schedulesSlice.timezone}</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}
        <div>
          <Formik initialValues={{}} onSubmit={(values) => {}}>
            <SearchWithOptions
              id="searchTimezone"
              placeholder="Search Timezone"
              labelKey="name"
              emptyLabel={""}
              leftIcon={<BsSearch />}
              clearButton
              highlightOnlyResult={true}
              autoFocus
              maxResults={50}
              filterBy={["name", "offsetDisplay"]}
              options={schedulesSlice.timezones.filter((t: any) => !schedulesSlice.timezoneOffset !== t.offsetDisplay)}
              onChange={(selected: any) => {
                const selectedTimezone = selected && selected[0] && selected[0];
                if (selectedTimezone && actions.timezone) return updateTimezone(selected[0]);
                if (selectedTimezone) {
                  dispatch(
                    changeTimezoneSuccess({
                      timezoneName: selected[0].name,
                    })
                  );

                  dispatch(showTimezoneModalSuccess(false));
                }
                actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""));
              }}
              renderMenuItemChildren={(option: any) => (
                <div>
                  {option.offsetDisplay}
                  <div>
                    <small>{option.name.replace("_", " ")}</small>
                  </div>
                </div>
              )}
            />
          </Formik>
        </div>

        {footer && <div className={modalStyles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};
