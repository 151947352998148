import { differenceInCalendarDays } from "date-fns";

export const BriefStatus = (status: any) => {
  if (status === "completed") {
    return <span className="badge bg-soft-success text-success p-2">Completed</span>;
  } else if (status === "progress") {
    return <span className="badge bg-soft-info text-info p-2">In Progress</span>;
  } else if (status === "pending") {
    return <span className="badge bg-soft-warning text-warning p-2">Pending</span>;
  }
};

export const BriefDueStatus = (briefDueDate: any) => {
  const daysDifference = differenceInCalendarDays(new Date(briefDueDate), new Date());

  if (daysDifference === 0) {
    return <span className="badge bg-warning p-2">Due today</span>;
  } else if (daysDifference > 0) {
    return (
      <span className="badge bg-info p-2">
        Due in {daysDifference === 1 ? `${Math.abs(daysDifference)} day` : `${Math.abs(daysDifference)} days`}{" "}
      </span>
    );
  } else if (daysDifference < 0) {
    return (
      <span className="badge bg-danger p-2">
        {daysDifference === 1 ? `${Math.abs(daysDifference)} day` : `${Math.abs(daysDifference)} days`} overdue{" "}
      </span>
    );
  }
};

export const BriefCompletionStatus = (submissionDate: any, completionDate: any) => {
  const daysDifference = differenceInCalendarDays(new Date(completionDate), new Date(submissionDate));

  if (daysDifference === 0) {
    return <span className="badge bg-success p-2">Same day completion</span>;
  } else if (daysDifference > 0) {
    return (
      <span className="badge bg-success p-2">
        Completed in {daysDifference === 1 ? `${Math.abs(daysDifference)} day` : `${Math.abs(daysDifference)} days`}{" "}
      </span>
    );
  } else {
    return <span>test {daysDifference}</span>;
  }
};

export const BriefType = (type: string) => {
  const briefTypelist: any = {
    new_account: "New Account",
    playlist_request: "Playlist Request",
    curation_refresh: "Curation Refresh",
    sampler: "Sampler",
    add_remove_track: "Add / Remove Track",
    spotify_playlist: "Spotify Playlist",
    other_request: "Other Request",
  };
  return briefTypelist[type];
};

export const BriefSubmissionAndCompletionStatus = (submissionDate: any, completionDate: any) => {
  const daysDifference = differenceInCalendarDays(new Date(), new Date(submissionDate));
  const daysDifferenceForCompetion = differenceInCalendarDays(new Date(completionDate), new Date(submissionDate));

  if (completionDate) {
    return (
      <span>
        {" "}
        Completed in{" "}
        {daysDifferenceForCompetion > 1 ? `${daysDifferenceForCompetion} days` : `${daysDifferenceForCompetion} day`}
      </span>
    );
  } else {
    return <span> Open for {daysDifference > 1 ? `${daysDifference} days` : `${daysDifference} day`}</span>;
  }
};
