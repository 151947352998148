import React from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { nanoid } from "nanoid";
import { CSSTransition } from "react-transition-group";

import {
  BsFillGridFill,
  BsGeoAltFill,
  BsCalendarWeek,
  BsMusicNoteList,
  BsPersonCircle,
  BsPeopleFill,
  BsXOctagon,
  BsCloud,
} from "react-icons/bs";

// style components
import {
  locations_page,
  schedules_page,
  playlists_page,
  dashboard_page,
  users_page,
  schedules_migration_page,
} from "../../../constants/routePath";
import { requestPasswordReset } from "../../../services/usersServices";
import { RootState } from "../../../redux-toolkit/store";
import { SearchClientBar } from "./searchClient";
import styles from "../../../pages/locations/locations.module.scss";

import {
  ChangeClientEmailAddress,
  CopyGenerateClientSetPasswordLink,
  GenerateClientSetPasswordLink,
  RenameClient,
  SendClientPasswordResetEmail,
  ToggleFreezeClient,
} from "../../../pages/locations/components/location-actions/ClientActions";
import {
  hideActionsSuccess,
  isLoadingSuccess,
  showAddClientActionsSuccess,
  showCopyGenerateClientSetPasswordActionsSuccess,
  showEditClientActionsSuccess,
  showErrorMessageSuccess,
  showFreezeClientActionsSuccess,
  showSuccessMessageSuccess,
  showViewClientCommercialInjectionRulesSuccess,
} from "../../../redux-toolkit/reducers/actionsSlice";
import { GlobalButton } from "../../../components/button";
import { ACTIONS_ERROR_MESSAGE } from "../../../constants/globalText";
import { ViewCommercialInjectionRules } from "../../../pages/locations/components/location-actions/LocationAreaActions";
import { AddNewClient, EditClientDetails } from "../../../pages/clients/components/modal";
import { freezeClient, getClientForAmbieUser } from "../../../services/clientsServices";
import { searchClientSuccess } from "../../../redux-toolkit/reducers/searchClientSlice";
import { storeClient } from "../../../utils/localStorage";

export const SecondLevelNavBar = () => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const userInfo = useSelector((state: RootState) => state.authSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const user = (userInfo.user && userInfo.user.user && userInfo.user.user.users_user) || {};
  const currentTimestamp = Date.now();
  const navigate = useNavigate();

  // Create a Date object from the current timestamp
  const date = new Date(currentTimestamp);

  // Add 5 minutes to the date
  date.setMinutes(date.getMinutes() + 5);

  const updatedTimestamp = date.getTime();

  const dispatch = useDispatch();
  const randomString = nanoid();

  let referrer: string = "";
  if (process.env.REACT_APP_AMBIE_ENV === "dev") {
    referrer = "localhost:7777";
  } else if (process.env.REACT_APP_AMBIE_ENV === "staging") {
    referrer = "dashboard-stage.ambie.fm";
  } else if (process.env.REACT_APP_AMBIE_ENV === "production") {
    referrer = "dashboard.ambie.fm";
  }

  const sendOrGeneratePasswordResetEmailData = async () => {
    dispatch(isLoadingSuccess(true));
    await requestPasswordReset(searchClient.clentEmail, referrer)
      .then((response) => {
        if (actions.generateClientSetPassword) {
          const token = response && response.data && response.data.accessToken;
          const link = `${referrer}/reset-password?token=${token}`;
          dispatch(isLoadingSuccess(false));
          dispatch(showErrorMessageSuccess(""));
          dispatch(showCopyGenerateClientSetPasswordActionsSuccess({ linkClient: link }));
        } else {
          dispatch(hideActionsSuccess());
        }
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  /**
   * reset client info
   */
  const resetClientInfoData = async () => {
    await getClientForAmbieUser(searchClient.clientId)
      .then((response) => {
        const data = response.data;
        const clientInfo = {
          clientId: data.id,
          clientName: data.name,
          clentEmail: data.contact_email,
          clentContactName: data.contact_name,
          isFrozen: data.is_frozen,
        };
        dispatch(searchClientSuccess(clientInfo));

        storeClient(clientInfo);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  /**
   * freeze client account
   */
  const freezeClientData = async () => {
    dispatch(isLoadingSuccess(true));
    const isFrozen = searchClient.isFrozen;
    await freezeClient(searchClient.clientId, { is_frozen: !isFrozen })
      .then((response) => {
        resetClientInfoData();

        dispatch(isLoadingSuccess(false));
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  const gott = () => {
    // alert("working");
    navigate(schedules_migration_page);
  };

  return (
    <>
      <div className="bg-white navbar navbar-expand-lg navbar-bordered">
        <div className="container">
          <SearchClientBar />

          {/* Client actions */}
          <>
            {actions.renameClient && (
              <RenameClient
                show={actions.renameClient}
                handleClose={() => dispatch(hideActionsSuccess())}
                footer={
                  <>
                    <div className={styles.flexRowWrapModalFooter}>
                      <div className={styles.footerLeft}>
                        <GlobalButton
                          format="white"
                          size="sm"
                          disabled={actions.actionsIsLoading ? true : false}
                          onClick={() => dispatch(hideActionsSuccess())}
                        >
                          Cancel
                        </GlobalButton>
                      </div>
                      <div>
                        <GlobalButton
                          disabled={actions.actionsIsLoading ? true : false}
                          format="success"
                          type="submit"
                          size="sm"
                        >
                          Submit
                        </GlobalButton>
                      </div>
                    </div>
                  </>
                }
              />
            )}

            <CSSTransition
              in={actions.editClient}
              timeout={100}
              classNames="panel-animate"
              onEnter={() => document.body.classList.add("css-transition-modal-open")}
              onExited={() => document.body.classList.remove("css-transition-modal-open")}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              <EditClientDetails show={actions.editClient} />
            </CSSTransition>

            <CSSTransition
              in={actions.addClient}
              timeout={100}
              classNames="panel-animate"
              onEnter={() => document.body.classList.add("css-transition-modal-open")}
              onExited={() => document.body.classList.remove("css-transition-modal-open")}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              <AddNewClient show={actions.addClient} />
            </CSSTransition>

            {actions.changeClientEmail && (
              <ChangeClientEmailAddress
                show={actions.changeClientEmail}
                handleClose={() => dispatch(hideActionsSuccess())}
                footer={
                  <>
                    <div className={styles.flexRowWrapModalFooter}>
                      <div className={styles.footerLeft}>
                        <GlobalButton
                          format="white"
                          size="sm"
                          disabled={actions.actionsIsLoading ? true : false}
                          onClick={() => dispatch(hideActionsSuccess())}
                        >
                          Cancel
                        </GlobalButton>
                      </div>
                      <div>
                        <GlobalButton
                          disabled={actions.actionsIsLoading ? true : false}
                          format="success"
                          type="submit"
                          size="sm"
                        >
                          Submit
                        </GlobalButton>
                      </div>
                    </div>
                  </>
                }
              />
            )}

            {actions.sendClientPasswordReset && (
              <SendClientPasswordResetEmail
                show={actions.sendClientPasswordReset}
                handleClose={() => dispatch(hideActionsSuccess())}
                footer={
                  <>
                    <div className={styles.flexRowWrapModalFooter}>
                      <div className={styles.footerLeft}>
                        <GlobalButton
                          format="white"
                          size="sm"
                          disabled={actions.actionsIsLoading ? true : false}
                          onClick={() => dispatch(hideActionsSuccess())}
                        >
                          No
                        </GlobalButton>
                      </div>
                      <div>
                        <GlobalButton
                          format="success"
                          disabled={actions.actionsIsLoading ? true : false}
                          onClick={() => sendOrGeneratePasswordResetEmailData()}
                          size="sm"
                        >
                          Yes
                        </GlobalButton>
                      </div>
                    </div>
                  </>
                }
              />
            )}

            {actions.generateClientSetPassword && (
              <GenerateClientSetPasswordLink
                show={actions.generateClientSetPassword}
                handleClose={() => dispatch(hideActionsSuccess())}
                footer={
                  <>
                    <div className={styles.flexRowWrapModalFooter}>
                      <div className={styles.footerLeft}>
                        <GlobalButton
                          format="white"
                          disabled={actions.actionsIsLoading ? true : false}
                          size="sm"
                          onClick={() => dispatch(hideActionsSuccess())}
                        >
                          No
                        </GlobalButton>
                      </div>
                      <div>
                        <GlobalButton
                          format="success"
                          disabled={actions.actionsIsLoading ? true : false}
                          onClick={() => sendOrGeneratePasswordResetEmailData()}
                          size="sm"
                        >
                          Yes
                        </GlobalButton>
                      </div>
                    </div>
                  </>
                }
              />
            )}
            {actions.copyGenerateClientSetPassword && (
              <CopyGenerateClientSetPasswordLink
                show={actions.copyGenerateClientSetPassword}
                link={actions.linkClient}
                handleClose={() => dispatch(hideActionsSuccess())}
                footer={
                  <>
                    <div className={styles.flexRowWrapModalFooter}>
                      <div className={styles.footerLeft}>
                        <GlobalButton
                          format="white"
                          disabled={actions.actionsIsLoading ? true : false}
                          size="sm"
                          onClick={() => dispatch(hideActionsSuccess())}
                        >
                          Close
                        </GlobalButton>
                      </div>
                      <div>
                        <CopyToClipboard text={`${actions.linkClient}`}>
                          <GlobalButton
                            format="success"
                            disabled={actions.actionsIsLoading ? true : false}
                            onClick={() => dispatch(showSuccessMessageSuccess("Copied, you can now close the mobal"))}
                            size="sm"
                          >
                            Copy
                          </GlobalButton>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </>
                }
              />
            )}

            {actions.viewClientCommercialInjectionRules && (
              <ViewCommercialInjectionRules
                show={actions.viewClientCommercialInjectionRules}
                handleClose={() => dispatch(hideActionsSuccess())}
                type="client"
                clientIdOrAreaIdOrPlaylistId={searchClient.clientId}
                nameDisplay={searchClient.clientName}
                footer={
                  <div className={styles.rightAligned}>
                    <GlobalButton
                      disabled={actions.actionsIsLoading ? true : false}
                      format="success"
                      type="submit"
                      size="sm"
                    >
                      Submit
                    </GlobalButton>
                  </div>
                }
              />
            )}

            {actions.freezeClient && (
              <ToggleFreezeClient
                show={actions.freezeClient}
                handleClose={() => dispatch(hideActionsSuccess())}
                footer={
                  <>
                    <div className={styles.flexRowWrapModalFooter}>
                      <div className={styles.footerLeft}>
                        <GlobalButton
                          format="white"
                          disabled={actions.actionsIsLoading ? true : false}
                          size="sm"
                          onClick={() => dispatch(hideActionsSuccess())}
                        >
                          No
                        </GlobalButton>
                      </div>
                      <div>
                        <GlobalButton
                          format="success"
                          disabled={actions.actionsIsLoading ? true : false}
                          onClick={() => freezeClientData()}
                          size="sm"
                        >
                          Yes
                        </GlobalButton>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </>

          <nav className="js-mega-menu hs-menu-initialized hs-menu-horizontal">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  to={dashboard_page}
                  className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}
                >
                  <BsFillGridFill className="dropdown-item-icon" />
                  Dashboard
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to={locations_page}
                  className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}
                >
                  <BsGeoAltFill className="dropdown-item-icon" />
                  Locations
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to={schedules_page}
                  className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}
                >
                  <BsCalendarWeek className="dropdown-item-icon" />
                  Schedules
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to={playlists_page}
                  className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}
                >
                  <BsMusicNoteList className="dropdown-item-icon" />
                  Playlists
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to={users_page}
                  className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}
                >
                  <BsPeopleFill className="dropdown-item-icon" />
                  Users
                </NavLink>
              </li>

              <li className="nav-item dropdown">
                <NavLink
                  to="#"
                  className={({ isActive }) => (isActive ? "nav-link dropdown-toggle" : "nav-link dropdown-toggle")}
                >
                  <BsPersonCircle className="dropdown-item-icon" />
                  Account
                </NavLink>

                <div
                  className="dropdown-menu navbar-dropdown-menu-borderless hs-sub-menu-desktop-lg"
                  style={{ minWidth: "14rem", right: 0 }}
                >
                  <span className="dropdown-header">Actions</span>
                  <Link
                    to="#"
                    onClick={() => {
                      window.open(
                        `${
                          referrer === "localhost:7777" ? `http://${referrer}` : `https://${referrer}`
                        }/login-as-ambie-users?clientId=${searchClient.clientId}&&clientName=${
                          searchClient.clientName
                        }&&username=${user.username}&&userId=${user.id}&&token=${randomString}$$$${updatedTimestamp}`,
                        "_blank"
                      );
                    }}
                    className="dropdown-item nav-item"
                  >
                    Dashboard Login as Ambie User
                  </Link>
                  <Link
                    to="#"
                    onClick={() =>
                      dispatch(
                        showViewClientCommercialInjectionRulesSuccess({ viewClientCommercialInjectionRules: true })
                      )
                    }
                    className="dropdown-item nav-item"
                  >
                    View Commercial Rules
                  </Link>

                  <Link
                    to="#"
                    onClick={() => {
                      dispatch(
                        showEditClientActionsSuccess({
                          editClient: true,
                          clientDetails: { status: true, id: searchClient.clientId, name: searchClient.clientName },
                        })
                      );
                    }}
                    className="dropdown-item nav-item"
                  >
                    Edit Client
                  </Link>
                  <div className="dropdown-divider"></div>
                  <div className="dropdown-item text-danger" style={{ marginLeft: -10 }}>
                    <GlobalButton size="sm" format="none" onClick={() => navigate(schedules_migration_page)}>
                      <BsCalendarWeek className="dropdown-item-icon" />
                      View Schedule Migrations
                    </GlobalButton>
                  </div>
                  <div className="dropdown-item text-danger" style={{ marginLeft: -10 }}>
                    <GlobalButton
                      size="sm"
                      format="none"
                      className="text-danger"
                      onClick={() => {
                        dispatch(
                          showFreezeClientActionsSuccess({
                            freezeClient: true,
                          })
                        );
                      }}
                    >
                      {searchClient.isFrozen ? (
                        <>
                          {" "}
                          <BsCloud className="dropdown-item-icon text-danger" /> Unfreeze Client
                        </>
                      ) : (
                        <>
                          <BsXOctagon className="dropdown-item-icon text-danger" /> Freeze Client
                        </>
                      )}
                    </GlobalButton>
                  </div>
                  {/* <Link
                    to="#"
                    onClick={() => {
                      dispatch(
                        showAddClientActionsSuccess({ 
                          addClient: true,
                        })
                      );
                    }}
                    className="dropdown-item nav-item"
                    style={{ background: "#198754", color: "white" }}
                  >
                    Add Client
                  </Link> */}
                </div>
              </li>
              <li className="nav-item" style={{ marginTop: 10 }}>
                <GlobalButton
                  format="success"
                  type="submit"
                  size="xs"
                  onClick={() => {
                    dispatch(
                      showAddClientActionsSuccess({
                        addClient: true,
                      })
                    );
                  }}
                >
                  Add Client
                </GlobalButton>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Outlet />
    </>
  );
};
