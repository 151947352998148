import React from "react";
import ReactPaginate from "react-paginate";
import styles from "./pagination.module.scss";

interface PaginationProps {
  pageCount: number;
  changePage: any;
}
export function Pagination({ pageCount, changePage }: PaginationProps) {
  return (
    <ReactPaginate
      nextLabel="next"
      previousLabel="previous"
      pageCount={pageCount}
      onPageChange={changePage}
      containerClassName={styles.paginationBttns}
      previousLinkClassName={styles.previousBttn}
      nextLinkClassName={styles.nextBttn}
      disabledClassName={styles.paginationDisabled}
      activeClassName={styles.paginationActive}
    />
  );
}
