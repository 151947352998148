import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// style theme
import "./styles/theme.scss";

import App from "./App";
import "./App.scss";
//import "./styles.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux-toolkit/store";
import { Provider } from "react-redux";
// import { FreshchatIntegration } from "./components/freshchatIntegration";
import { FreshchatIntegration } from "./components/freshchatIntegration";
const status = true;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        {status && <FreshchatIntegration />}
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
// // If you want to start measuring performance in your app, pass a function
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
