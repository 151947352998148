import React from "react";

// Bootstrap dropdown
import Dropdown from "react-bootstrap/Dropdown";

import classnames from "classnames";

// styles components
import dropdownStyles from "./dropdown.module.scss";

interface DropdownProps {
  format?: "primary" | "secondary" | "info" | "success" | "danger" | "white" | "none";
  size?: "lg" | "md" | "sm" | "xs" | "none";
  name: string;
  icon?: any;
  width?: string;
  height?: string;
  align?: "start" | "end";
  className?: any;
  children: any;
}
export const GlobalDropdown = ({
  format = "white",
  size = "md",
  name,
  icon,
  width = "auto",
  height = "320px",
  align = "start",
  className,
  children,
}: DropdownProps) => {
  return (
    <Dropdown align={align}>
      <Dropdown.Toggle
        variant="none"
        id="dropdown-basic"
        className={classnames(
          "btn",
          dropdownStyles["btn-" + format] !== "button_none__z-kfw" && dropdownStyles.btn,
          dropdownStyles["btn-" + format],
          dropdownStyles["btn-" + size],
          className
        )}
        style={{ width: width }}
      >
        {icon && <span className="me-2">{icon}</span>}
        <span style={{ overflow: "hidden" }}>{name}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ maxHeight: height }}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
};
