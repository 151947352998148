export const InternalUsersPermissions = () => {
  return ["SUPERUSER", "CURATOR", "CSM", "ROYALTY", "SUPPORT"];
};

export const AccessWithoutTeam = () => {
  return ["ADMIN", "MANAGER", "SUPERUSER", "CURATOR", "CSM", "ROYALTY", "SUPPORT"];
};

export const AccessWithoutManagerAndTeam = () => {
  return ["ADMIN", "SUPERUSER", "CURATOR", "CSM", "ROYALTY", "SUPPORT"];
};

export const ManagerAccess = () => {
  return ["MANAGER"];
};

export const TeamAccess = () => {
  return ["TEAM"];
};

export const ManagerAndTeamAccess = () => {
  return ["MANAGER", "TEAM"];
};

export const ClientPermissions = () => {
  return ["ADMIN", "MANAGER", "TEAM"];
};

export const SuperuserPermission = () => {
  return "SUPERUSER";
};

export const CuratorPermission = () => {
  return "CURATOR";
};

export const CsmPermission = () => {
  return "CSM";
};

export const RoyaltyPermission = () => {
  return "ROYALTY";
};

export const SupportPermission = () => {
  return "SUPPORT";
};

export const AdminPermission = () => {
  return "ADMIN";
};

export const ManagerPermission = () => {
  return "MANAGER";
};

export const TeamPermission = () => {
  return "TEAM";
};
