import classNames from "classnames";
import FirstLevelMenuItems from "./FirstLevelMenuItems";
import styles from "./firstLevel.module.scss";

interface FirstLevelDropdownProps {
  submenus?: any;
  dropdown?: any;
  depthLevel?: any;
}
const FirstLevelDropdown = ({ submenus, dropdown, depthLevel }: FirstLevelDropdownProps) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? styles.firstLevelDropdownSubmenu : "";
  return (
    <ul
      className={classNames(`${styles.firstLevelDropdown} ${dropdownClass} ${dropdown ? styles.firstLevelShow : ""}`)}
    >
      {submenus.map((submenu: {}, index: number) => (
        <FirstLevelMenuItems items={submenu} key={index} depthLevel={depthLevel} />
      ))}
    </ul>
  );
};

export default FirstLevelDropdown;
