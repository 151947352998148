import { useSelector } from "react-redux";
import { GlobalButton } from "../../../../components/button";

// style components
import { GlobalModal } from "../../../../components/modal";
import { RootState } from "../../../../redux-toolkit/store";
import modalStyles from "../../../locations/components/location-actions/location-actions.module.scss";
import locationStyles from "../../../locations/locations.module.scss";

interface ScheduleProps {
  size?: "sm" | "lg" | "xl";
  backdrop?: "static" | true | false;
  centered?: true | false;
  scrollable?: true | false;
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  footer?: any;
  title?: string;
  show: boolean;
  location?: { name: string; email: string; id: number };
  handleClose: any; //function
  handleReturnToEditSchedule: any; //function
  handleSaveEditSchedule: any; //function
  handleContinueWithoutSavingEditSchedule: any; //function
}

/**
 * Generate reset password link
 */
export const ScheduleDiscardScheduleEditModePopup = ({
  footer,
  show,
  handleClose,
  handleReturnToEditSchedule,
  handleSaveEditSchedule,
  handleContinueWithoutSavingEditSchedule,
}: ScheduleProps) => {
  const schedulesSlice = useSelector((state: RootState) => state.schedulesSlice);

  return (
    <GlobalModal title="Cancel Editing" show={show} handleClose={handleClose} footer={footer}>
      <div className={modalStyles.modal}>
        <p>Are you sure you want to cancel editing?</p>
        <p>The changes you have made will be lost, unless you save now</p>

        <div className={locationStyles.flexRowWrapModalFooter} style={{ marginTop: "20px" }}>
          {schedulesSlice.isEditPlaylist && (
            <div className={locationStyles.footerLeft}>
              <GlobalButton format="danger" size="sm" onClick={() => handleContinueWithoutSavingEditSchedule()}>
                Exit without saving
              </GlobalButton>
            </div>
          )}
          <div className={locationStyles.footerLeft}>
            <GlobalButton format="white" size="sm" onClick={() => handleReturnToEditSchedule()}>
              Continue editing
            </GlobalButton>
          </div>
          <div>
            <GlobalButton format="success" type="submit" size="sm" onClick={() => handleSaveEditSchedule()}>
              Save changes
            </GlobalButton>
          </div>
        </div>
      </div>
    </GlobalModal>
  );
};
