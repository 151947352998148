import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// style components
import { getClient } from "../../utils/localStorage";
// import Cookies from "js-cookie";
const client = getClient();

// const test = Cookies.get();

export interface SearchClientState {
  clientId: number;
  clientName: string;
  clentEmail: string;
  clentContactName: string;
  isFrozen: boolean;
}
const initialState: SearchClientState = {
  clientId: (client && client.clientId) || 1,
  clientName: (client && client.clientName) || "",
  clentEmail: (client && client.clentEmail) || "",
  clentContactName: (client && client.clentContactName) || "",
  isFrozen: client?.isFrozen || false,
};

const searchClientSlice = createSlice({
  name: "searchClient",
  initialState,
  reducers: {
    searchClientSuccess: (state, action: PayloadAction<any>) => {
      state.clientId = action.payload.clientId;
      state.clientName = action.payload.clientName;
      state.clentEmail = action.payload.clentEmail;
      state.clentContactName = action.payload.clentContactName;
      state.isFrozen = action.payload.isFrozen;
    },
  },
});

export const { searchClientSuccess } = searchClientSlice.actions;
export default searchClientSlice.reducer;
