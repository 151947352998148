// MOODS
const colors = {
  moodRelaxed: "#34CFCD",
  moodHappy: "#FFDB61",
  moodLively: "#C160FF",
  moodParty: "#A841D6",
  moodSpecial: "#F5663C",
  moodAmbient: "#65E2E2",

  // spa & wellness moods
  moodMildRelaxation: "#EEABFA",
  moodDeepRelaxation: "#65E2E2",
  moodEnergyActivation: "#F5663C",
  moodSpaWellness: "#90EE90",
};

const MOOD_MAP = {
  ambient: {
    name: "ambient",
    description: "creating a calm atmosphere",
    tempo: 1,
    displayName: "Ambient",
    color: colors.moodAmbient,
    gradient: {
      from: "#8087d4",
      to: "#79d5dc",
    },
    shadow: "rgba(124, 175, 216, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/ambient.jpeg",
  },
  relaxed: {
    name: "relaxed",
    description: "slowing down the tempo",
    tempo: 2,
    displayName: "Relaxed",
    color: colors.moodRelaxed,
    gradient: {
      from: "#3471ae",
      to: "#68c9bb",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/relaxed.jpeg",
  },
  happy: {
    name: "happy",
    description: "brightening the mood",
    tempo: 3,
    displayName: "Happy",
    color: colors.moodHappy,
    gradient: {
      from: "#ff5d5f",
      to: "#f1ce2e",
    },
    shadow: "rgba(248, 150, 70, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/happy.jpeg",
  },
  lively: {
    name: "lively",
    description: "stepping up the pace",
    tempo: 4,
    displayName: "Lively",
    color: colors.moodLively,
    gradient: {
      from: "#f938ab",
      to: "#fc8b82",
    },
    shadow: "rgba(250, 98, 150, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/lively.jpeg",
  },
  party: {
    name: "party",
    description: "creating a party atmosphere",
    tempo: 5,
    displayName: "Party Vibes",
    color: colors.moodParty,
    gradient: {
      from: "#a147ef",
      to: "#dc5bb7",
    },
    shadow: "rgba(191, 84, 194, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/party.jpeg",
  },
  special: {
    name: "special",
    description: "a special occasion",
    tempo: 6,
    displayName: "Special Occasion",
    color: colors.moodSpecial,
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(243, 69, 102, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/special-occasion.jpeg",
  },
  spaWellness: {
    name: "SpaAndWellness",
    description: "stepping up the pace",
    tempo: 7,
    displayName: "Spa & Wellness",
    color: colors.moodSpaWellness,
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/spa-and-wellness.jpg",
  },
  mildRelaxation: {
    name: "mildRelaxation",
    description: "mid-length spa treatments",
    tempo: 8,
    displayName: "Mild Relaxation",
    color: colors.moodMildRelaxation,
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/lively-2.jpeg",
  },
  deepRelaxation: {
    name: "deepRelaxation",
    description: "longer spa treatments",
    tempo: 9,
    displayName: "Deep Relaxation",
    color: colors.moodDeepRelaxation,
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/ambient.jpeg",
  },
  energyActivation: {
    name: "energyActivation",
    description: "shorter spa treatments",
    tempo: 10,
    displayName: "Energy Activation",
    color: colors.moodEnergyActivation,
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/special-occasion.jpeg",
  },
};

export const MOODS: any = {
  low: MOOD_MAP.relaxed,
  medium: MOOD_MAP.happy,
  high: MOOD_MAP.lively,
  ambient: MOOD_MAP.ambient,
  relaxed: MOOD_MAP.relaxed,
  happy: MOOD_MAP.happy,
  lively: MOOD_MAP.lively,
  party: MOOD_MAP.party,
  special: MOOD_MAP.special,
  spaWellness: MOOD_MAP.spaWellness,
  mildRelaxation: MOOD_MAP.mildRelaxation,
  deepRelaxation: MOOD_MAP.deepRelaxation,
  energyActivation: MOOD_MAP.energyActivation,
};
