import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  BsSearch,
  BsJournalText,
  BsArrowRepeat,
  BsFillCheckCircleFill,
  BsFillXCircleFill,
  BsDownload,
  BsGeoAltFill,
} from "react-icons/bs";
// style components
import { RootState } from "../../redux-toolkit/store";
import { MiniCard, KeyValueCard } from "../../components/cards";
import { SearchWithOptions, SearchInput } from "../../components/fields/search";
import { GlobalButton } from "../../components/button";
import { AgGridReactTable } from "../../components/table";
import { differenceInMilliseconds, format } from "date-fns";
import { LoadingData } from "../../components/loading";
import { GlobalDropdown } from "../../components/dropdown";
import { isLoadingSuccess } from "../../redux-toolkit/reducers/actionsSlice";
import { getDeviceStatusList } from "../../services/devceStatusServices";
import { useLocation, useNavigate } from "react-router-dom";
import { devices_tools_page_navigate, locations_page_navigate } from "../../constants/routePath";
import {
  DevicesStatus,
  AreaStatus,
  DevicePlayingStatus,
  DevicesStatusCSV,
  AreaStatusCSV,
  DevicePlayingStatusCSV,
} from "./components/status";
import { HandleExport } from "../../components/csv/export";
import { searchClientSuccess } from "../../redux-toolkit/reducers/searchClientSlice";
import { getClientsList } from "../../services/clientsServices";
import { WebSocketContext } from "../../components/websocket/websocket";
import { TIMEOUT_FOR_LOADING_DEVICE_PAGE } from "../../constants/defaultValues";

export const Devices = () => {
  const [generalFilterString, setGeneralFilter] = useState<string>("");
  const [devices, setDevices] = useState<[]>([]);
  const [areasStatus, setAreasStatus] = useState<[]>([]);
  const [playerStatus, setPlayerStatus] = useState<[]>([]);
  const [areasFilter, setAreasFilter] = useState("all");
  const [playersStatus, setPlayersStatus] = useState("all");
  const dispatch = useDispatch();

  const [clientFilter, setClientFilter] = useState<number>(0);
  const [clientBusinessesFilter, setClientBusinessesFilter] = useState<number>(0);

  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const authSlice = useSelector((state: RootState) => state.authSlice);

  const actions = useSelector((state: RootState) => state.actionsSlice);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const pathWithoutSlash = path.replace(/^\//, "");
  const socket = useContext(WebSocketContext);

  useEffect(() => {
    let isSubscribed = true;

    const getDeviceStatusListData = async (isLoading: boolean) => {
      dispatch(isLoadingSuccess(isLoading));
      await getDeviceStatusList()
        .then((response) => {
          if (isSubscribed) {
            setDevices(response.playerRecords || []);
            setAreasStatus(response.areasStatus);
            setPlayerStatus(response.playerStatus);
            dispatch(isLoadingSuccess(false));
          }
        })
        .catch((error) => {
          dispatch(isLoadingSuccess(false));
          throw new Error(error);
        });
    };

    const getClientsListData = async () => {
      await getClientsList();
    };

    socket.on("createClient", (payload: any) => {
      getClientsListData();
    });

    socket.on("updateClient", (payload: any) => {
      getClientsListData();
    });

    getDeviceStatusListData(true);
    getClientsListData();

    if (pathWithoutSlash === "tools") {
      navigate(devices_tools_page_navigate);
    }

    let interval = setInterval(() => {
      getDeviceStatusListData(false);
    }, TIMEOUT_FOR_LOADING_DEVICE_PAGE);

    return () => {
      clearInterval(interval);
      isSubscribed = false;
      socket.off("createClient", (payload: any) => {
        getClientsListData();
      });

      socket.off("updateClient", (payload: any) => {
        getClientsListData();
      });
    };
  }, [searchClient, dispatch, pathWithoutSlash, navigate]);

  // get client list
  const clientsList = authSlice.clientsList.map((client: any) => ({
    client_name: client.name,
    client_id: client.id,
    businesses: client.businesses_business.map((location: any) => ({
      client_id: client.id,
      client_name: client.name,
      location_id: location.id,
      loction_name: location.name,
    })),
  }));

  // get client businesses list
  const locationsList = authSlice.clientsList
    .filter((client: any) => {
      let filterPass = true;
      if (clientFilter) {
        filterPass = clientFilter === client.id;
      }
      return filterPass;
    })
    .map((element: any) =>
      element.businesses_business.map((location: any) => {
        return location;
      })
    );

  let devicesTableRowsExcel: any = [];

  const getSelectedAreas = (filterString: any, filter: any, filterPass: boolean) => {
    const player_id = filter.player_id || "";
    const area = filter.area || "";
    const version = filter.version || "";

    const filteredAreas =
      area.toString().toLowerCase().match(filterString.toString().toLowerCase()) ||
      player_id.toString().toLowerCase().match(filterString.toString().toLowerCase()) ||
      version.toString().toLowerCase().match(filterString.toString().toLowerCase());

    return filteredAreas;
  };

  const goToLocations = () => {
    navigate(locations_page_navigate);
  };

  const devicesTableRows: any = devices
    .sort((a: any, b: any) => a.player_id.localeCompare(b.player_id))
    .filter(
      (filter: {
        name: string;
        id: number;
        webPlayer: any;
        player_id: string;
        is_active: boolean;
        last_heart_beat: number;
        client_id: number;
        location_id: number;
        version: string;
        area: string;
      }) => {
        let filterPass: any = true;
        let areasStatusFilterPass: any = true;
        let playersStatusFilterPass: any = true;
        let locationFilterPass: any = true;

        if (playersStatus === "all") {
          playersStatusFilterPass = true;
        }
        // online players
        else if (
          differenceInMilliseconds(Date.now(), filter.last_heart_beat) / 60000 < 10 &&
          playersStatus === "online"
        ) {
          playersStatusFilterPass = true;
        }
        // offline players
        else if (
          differenceInMilliseconds(Date.now(), filter.last_heart_beat) / 60000 > 10 &&
          playersStatus === "offline"
        ) {
          playersStatusFilterPass = true;
        }
        // no-player
        else if (filter.player_id === "No Player" && playersStatus === "no-player") {
          playersStatusFilterPass = true;
        } else {
          playersStatusFilterPass = false;
        }

        if (areasFilter === "all") {
          areasStatusFilterPass = true;
        } else if (areasFilter === "active") {
          areasStatusFilterPass = filter.is_active;
        } else if (areasFilter === "inactive") {
          areasStatusFilterPass = !filter.is_active;
        }

        // search by client, business, area, player, version
        if (clientBusinessesFilter) {
          locationFilterPass = clientBusinessesFilter === filter.location_id;
        } else if (clientFilter) {
          locationFilterPass = clientFilter === filter.client_id;
        }

        if (generalFilterString) {
          const selectedRecords = getSelectedAreas(generalFilterString, filter, filterPass);
          filterPass = selectedRecords;
        }

        return playersStatusFilterPass && filterPass && areasStatusFilterPass && locationFilterPass;
      }
    )
    .map((data: any) => {
      const rowData = {
        serial: data.player_id,
        version: data.version,
        tlg: data.tlg_version,
        streaming: data.web_player && data.web_player.streaming && data.web_player.streaming.enabled,
        area: data.area,
        location: data.location,
        client: data.client,
        last_heart_beat: data.last_heart_beat ? format(new Date(data.last_heart_beat), "dd/MM/yy H:mm") : null,
        last_played: data.last_played ? format(new Date(data.last_played), "dd/MM/yy H:mm") : null,
        is_active: data.is_active,
        isFrozen: data.is_frozen,
        actions: data,
      };
      const rowDataExcel = {
        serial: data.player_id,
        version: `${data.version}`,
        tlg: "v2",
        streaming: data.web_player && data.web_player.streaming && data.web_player.streaming.enabled ? "Yes" : "No",
        area: data.area,
        location: data.location,
        client: data.client,
        last_heart_beat: format(new Date(data.last_heart_beat), "dd/MM/yyyy H:mm"),
        last_played: format(new Date(data.last_played), "dd/MM/yyyy H:mm"),
        is_active: AreaStatusCSV(data.is_active),
        player_status: DevicesStatusCSV(data),
        is_playing: DevicePlayingStatusCSV(data),
      };
      devicesTableRowsExcel.push(rowDataExcel);
      return rowData;
    });

  const filterAreas = [
    {
      label: "All Areas",
      value: "all",
    },
    {
      label: "Active Areas",
      value: "active",
    },
    {
      label: "Inactive Areas",
      value: "inactive",
    },
  ];

  const filterPlayerStatus = [
    {
      label: "All Players",
      value: "all",
    },
    {
      label: "Online",
      value: "online",
    },
    {
      label: "Offline",
      value: "offline",
    },
    {
      label: "No Player",
      value: "no-player",
    },
  ];

  const areaTableColumns = [
    {
      field: "serial",
      headerName: "Serial",
      width: 90,
      maxWidth: 90,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.serial}</span>;
      },
    },
    {
      field: "version",
      headerName: "Version",
      width: 100,
      maxWidth: 100,
    },
    {
      field: "tlg",
      headerName: "TLG",
      width: 70,
      maxWidth: 70,
    },
    {
      field: "streaming",
      headerName: "Web",
      width: 80,
      maxWidth: 80,
      cellRenderer: (params: any) => {
        return (
          <>
            {params.data.streaming ? (
              <BsFillCheckCircleFill className="text-success" />
            ) : (
              <BsFillXCircleFill className="text-danger" />
            )}
          </>
        );
      },
    },
    {
      field: "area",
      headerName: "Area",
      width: 170,
      maxWidth: 170,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.area}</span>;
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 180,
      maxWidth: 180,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.location}</span>;
      },
    },
    {
      field: "client",
      headerName: "Client",
      width: 100,
      maxWidth: 100,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.client}</span>;
      },
    },

    {
      field: "last_heart_beat",
      headerName: "Last Seen",
      //type: "rightAligned",
      width: 150,
      maxWidth: 150,
    },
    {
      field: "last_played",
      headerName: "Last Play",
      //type: "rightAligned",
      width: 150,
      maxWidth: 150,
    },
    {
      field: "is_active",
      headerName: "Status",
      width: 150,
      maxWidth: 150,
      cellRenderer: (params: any) => {
        return <>{AreaStatus(params.data.is_active)}</>;
      },
    },
    {
      field: "is_active",
      headerName: "Player",
      width: 100,
      maxWidth: 100,
      cellRenderer: (params: any) => {
        return <>{DevicesStatus(params.data.actions)}</>;
      },
    },
    {
      field: "is_active",
      headerName: "Playing",
      width: 130,
      maxWidth: 130,
      cellRenderer: (params: any) => {
        return <>{DevicePlayingStatus(params.data.actions)}</>;
      },
    },

    {
      field: "actions",
      headerName: "Action",
      width: 150,
      maxWidth: 150,
      cellClass: "actions-dropdown-cell",
      filter: false,
      sorting: false,
      cellRenderer: (params: any) => {
        return (
          <GlobalDropdown name="Actions" format="white" size="sm" align="end">
            <GlobalButton
              size="sm"
              format="none"
              onClick={() => {
                alert("NEED TO DISCUSS WITH BEN");
              }}
            >
              <BsArrowRepeat className="dropdown-item-icon" /> Resend Schedule
            </GlobalButton>
            <div className="dropdown-divider"></div>
            <GlobalButton
              size="sm"
              format="none"
              href={`https://papertrailapp.com/systems/${params.data.serial}/events`}
              target="_blank"
              onClick={() => {
                // dispatch(isLoadingSuccess(true));
              }}
            >
              <BsJournalText className="dropdown-item-icon" /> Open Papertrail
            </GlobalButton>
            <div className="dropdown-divider"></div>
            <GlobalButton
              size="sm"
              format="none"
              target="_blank"
              onClick={() => {
                dispatch(
                  searchClientSuccess({
                    clientId: params.data.actions.client_id,
                    clientName: params.data.actions.client,
                    clentEmail: "",
                    clentContactName: "",
                    isFrozen: params.data.isFrozen,
                  })
                );
                goToLocations();
              }}
            >
              <BsGeoAltFill className="dropdown-item-icon" /> Locations
            </GlobalButton>
          </GlobalDropdown>
        );
      },
    },
  ];

  // export to csv
  const handleExportData = () => {
    const headings = [
      [
        "Player",
        "Version",
        "Streaming",
        "Area",
        "Location",
        "Client",
        "Last Played",
        "Last Seen",
        "Area Status",
        "Player Status",
        "IS Playing",
      ],
    ];
    HandleExport(devicesTableRowsExcel, headings, "device-status");
  };

  return (
    <div className="content container py-3">
      {/* <div> */}
      <div className="row align-items-center">
        <div className="col-sm-4" style={{ zoom: 0.95 }}>
          <MiniCard options={areasStatus} columns="col-lg-4" />
        </div>
        <div className="col-sm-8" style={{ zoom: 0.95 }}>
          <MiniCard options={playerStatus} columns="col-lg-2" />
        </div>
        {/* <div className="col-sm-3" style={{ zoom: 0.95 }}>
            <MiniCard options={trackListsGenerated} columns="col-lg-6" />
          </div> */}
      </div>
      {actions.actionsIsLoading && <LoadingData />}
      <div style={{ marginTop: "1rem" }}>
        <Formik
          initialValues={{
            action: "",
          }}
          onSubmit={() => {}}
        >
          <Form>
            <KeyValueCard
              title={
                <SearchInput
                  placeholder="Search by player, area or version..."
                  onSearch={(value: any) => setGeneralFilter(value)}
                  allowClear
                  size="large"
                  style={{ width: 350, fontSize: 10 }}
                />
              }
              filter={
                <div className="d-flex align-items-center">
                  <div style={{ width: "220px", marginRight: "10px" }}>
                    <SearchWithOptions
                      placeholder="Search by client"
                      id="client_selected"
                      options={clientsList}
                      labelKey="client_name"
                      filterBy={["client_name"]}
                      leftIcon={<BsSearch />}
                      size="sm"
                      clearButton
                      maxResults={30}
                      onChange={(selected: any) => {
                        if (selected && selected[0]) {
                          setClientFilter(selected[0].client_id);
                        } else {
                          setClientFilter(0);
                        }
                      }}
                      renderMenuItemChildren={(option: any) => {
                        return (
                          <div>
                            {option.client_name}
                            {option.businesses.map((business: any) => (
                              <ul key={business.loction_name}>
                                <li>
                                  <small>{business.loction_name}</small>
                                </li>
                              </ul>
                            ))}
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div style={{ width: "220px", marginRight: "10px" }}>
                    <SearchWithOptions
                      placeholder="Search by business"
                      id="business_selected"
                      options={locationsList.flat()}
                      labelKey="name"
                      filterBy={["name", "client_name"]}
                      leftIcon={<BsSearch />}
                      size="sm"
                      clearButton
                      maxResults={30}
                      onChange={(selected: any) => {
                        if (selected && selected[0]) {
                          setClientBusinessesFilter(selected[0].id);
                        } else {
                          setClientBusinessesFilter(0);
                        }
                      }}
                      renderMenuItemChildren={(option: any) => {
                        return (
                          <div>
                            {option.name}
                            <ul>
                              <li>
                                <small> {option.client_name}</small>
                              </li>
                            </ul>
                          </div>
                        );
                      }}
                    />
                  </div>
                  {/* <span style={{ marginRight: "10px" }}>Players</span> */}
                  <div style={{ width: "150px", marginRight: "10px" }}>
                    <Select
                      id="players"
                      className="flex-grow-4 time-select channel-list"
                      options={filterAreas}
                      value={filterAreas.find((c: any) => c.value === areasFilter)}
                      onChange={(selected) => selected && selected.value && setAreasFilter(selected.value)}
                    />
                  </div>

                  {/* <span style={{ marginRight: "10px" }}>Status</span> */}
                  <div style={{ width: "140px" }}>
                    <Select
                      id="players-status"
                      className="flex-grow-4 time-select channel-list"
                      options={filterPlayerStatus}
                      value={filterPlayerStatus.find((c: any) => c.value === playersStatus)}
                      onChange={(selected) => selected && selected.value && setPlayersStatus(selected.value)}
                    />
                  </div>
                  <div style={{ width: "140px" }}>
                    <GlobalButton
                      size="sm"
                      format="white"
                      leftIcon={<BsDownload className="me-2" />}
                      onClick={handleExportData}
                    >
                      Export CSV
                    </GlobalButton>
                  </div>
                </div>
              }
            >
              <div className="row">
                <div className="col-sm-12">
                  <AgGridReactTable
                    className="ag-theme-alpine"
                    rowData={devicesTableRows}
                    columnDefs={areaTableColumns}
                    filter={true}
                    sortable={true}
                    animateRows={true}
                    pagination={true}
                    paginationPageSize={10}
                    domLayout="autoHeight"
                  />
                </div>
              </div>
            </KeyValueCard>
          </Form>
        </Formik>
      </div>
      {/* </div> */}
    </div>
  );
};
