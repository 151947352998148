import styles from "./errorMessage.module.scss";
export const GlobalErrorMessage = ({ message }: any) => {
  return (
    <div>
      <div className={"alert alert-danger " + styles.message} role="alert">
        {message}
      </div>
    </div>
  );
};

export const SuccessMessage = ({ message }: any) => {
  return (
    <div>
      <div className={"alert alert-success " + styles.successMessage} role="alert">
        {message}
      </div>
    </div>
  );
};
