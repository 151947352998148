import { ACTIONS_ERROR_MESSAGE } from "../../../constants/globalText";
import {
  hideActionsSuccess,
  isLoadingSuccess,
  showCopyGenerateSetPasswordActionsSuccess,
  showErrorMessageSuccess,
} from "../../../redux-toolkit/reducers/actionsSlice";
import { store } from "../../../redux-toolkit/store";
import { requestPasswordReset, updateUserRecord } from "../../../services/usersServices";

/**
 * Activate user
 */
export const activateUser = async (user: { name: string; email: string; id: number }) => {
  store.dispatch(isLoadingSuccess(true));
  await updateUserRecord(user.id, { is_active: true })
    .then((response) => {
      store.dispatch(hideActionsSuccess());
    })
    .catch((error) => {
      store.dispatch(isLoadingSuccess(false));
      store.dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      throw new Error(error);
    });
};

/**
 * Deactivate user
 */
export const deactivateUser = async (user: { name: string; email: string; id: number }) => {
  store.dispatch(isLoadingSuccess(true));
  await updateUserRecord(user.id, { is_active: false })
    .then((response) => {
      store.dispatch(hideActionsSuccess());
    })
    .catch((error) => {
      store.dispatch(isLoadingSuccess(false));
      store.dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      throw new Error(error);
    });
};

/**
 * send or generate password reset email
 */
export const sendOrGenerateUserPasswordResetEmail = async (
  user: { name: string; email: string; id: number },
  generateSetUserPassword: boolean
) => {
  let referrer: string = "";
  if (process.env.REACT_APP_AMBIE_ENV === "dev") {
    referrer = "localhost:7777";
  } else if (process.env.REACT_APP_AMBIE_ENV === "staging") {
    referrer = "dashboard-stage.ambie.fm";
  } else if (process.env.REACT_APP_AMBIE_ENV === "production") {
    referrer = "dashboard.ambie.fm";
  }

  store.dispatch(isLoadingSuccess(true));
  await requestPasswordReset(user.email, referrer)
    .then((response) => {
      if (generateSetUserPassword) {
        const token = response && response.data && response.data.accessToken;
        const link = `${referrer}/reset-password?token=${token}`;
        store.dispatch(isLoadingSuccess(false));
        store.dispatch(showErrorMessageSuccess(""));
        store.dispatch(showCopyGenerateSetPasswordActionsSuccess({ link: link }));
      } else {
        store.dispatch(hideActionsSuccess());
      }
    })
    .catch((error) => {
      store.dispatch(isLoadingSuccess(false));
      store.dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      throw new Error(error);
    });
};
