import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// style components

export interface PlaylistsState {
  playlists: [];
  playlistTracks: [];
  playlistTrack: any;
  isTracks: boolean;
  playlistsTracksCount: [];
}
const initialState: PlaylistsState = {
  playlists: [],
  playlistTracks: [],
  playlistTrack: {},
  isTracks: false,
  playlistsTracksCount: [],
};

const PlaylistsSlice = createSlice({
  name: "playlists",
  initialState,
  reducers: {
    playlistsSuccess: (state, action: PayloadAction<any>) => {
      state.playlists = action.payload.playlists;
    },
    playlistTracksSuccess: (state, action: PayloadAction<any>) => {
      state.playlistTracks = action.payload.playlistTracks;
      state.isTracks = action.payload.isTracks;
    },
    playlistsTracksCountSuccess: (state, action: PayloadAction<any>) => {
      state.playlistsTracksCount = action.payload.playlistsTracksCount;
    },
    playlistTrackSuccess: (state, action: PayloadAction<any>) => {
      state.playlistTrack = action.payload.playlistTrack;
    },
    clearPlaylistSuccess: (state) => {
      // state.playlists = [];
      state.playlistTracks = [];
      state.playlistTrack = {};
      state.isTracks = false;
    },
  },
});

export const {
  playlistsSuccess,
  playlistTracksSuccess,
  playlistTrackSuccess,
  clearPlaylistSuccess,
  playlistsTracksCountSuccess,
} = PlaylistsSlice.actions;
export default PlaylistsSlice.reducer;
