import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";

// style components
import { RootState } from "../../redux-toolkit/store";
import { MiniCard, KeyValueCard } from "../../components/cards";
import { SearchWithOptions } from "../../components/fields/search";
import { GlobalButton } from "../../components/button";
import { AgGridReactTable } from "../../components/table";
import { LoadingData } from "../../components/loading";
import { GlobalDropdown } from "../../components/dropdown";
import { BsFillGridFill, BsPencil, BsSearch } from "react-icons/bs";
import { showEditBriefActionsSuccess } from "../../redux-toolkit/reducers/actionsSlice";

import { WebSocketContext } from "../../components/websocket/websocket";
import { useLocation, useNavigate } from "react-router-dom";
import { BriefCompletionStatus, BriefDueStatus, BriefStatus, BriefType } from "./components/status";
import { getBriefs } from "../../services/briefsServices";
import { format } from "date-fns";
import { getClients } from "../../services/clientsServices";
import { AddBrief, briefTypeOptions, EditBrief } from "./components/modal";
import { briefs_ambie_page_navigate, dashboard_page_navigate } from "../../constants/routePath";
import { searchClientSuccess } from "../../redux-toolkit/reducers/searchClientSlice";
import { getAmbieUsers } from "../../services/usersServices";
import { DateRangePicker } from "../../components/fields/datePicker";

export const Briefs = () => {
  const dispatch = useDispatch();
  const socket = useContext(WebSocketContext);

  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const clientsSlice = useSelector((state: RootState) => state.authSlice.clients);
  const briefsSlice = useSelector((state: RootState) => state.briefsSlice);
  const usersSlice = useSelector((state: RootState) => state.authSlice.ambieUsers);

  const [filterStatusList, setFilterStatusList] = useState<{
    briefsStatus: string;
    briefsType: string;
    clientFilter: number;
    watcherFilter: number;
    curationOwnerFilter: number;
    dateFilter: {
      startDate: string;
      endDate: string;
    };
  }>({
    briefsStatus: "all",
    briefsType: "all",
    clientFilter: 0,
    watcherFilter: 0,
    curationOwnerFilter: 0,
    dateFilter: {
      startDate: "",
      endDate: "",
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const pathWithoutSlash = path.replace(/^\//, "");

  useEffect(() => {
    const getBriefsData = async () => {
      await getBriefs();
    };

    const getUsersData = async () => {
      await getAmbieUsers();
    };

    const getClientsData = async () => {
      await getClients();
    };

    const getAmbieUsersData = async () => {
      await getClients();
    };

    socket.on("updateClient", () => {
      getClientsData();
    });

    socket.on("newClient", () => {
      getClientsData();
    });

    socket.on("newBrief", () => {
      getBriefsData();
    });

    socket.on("updateBrief", () => {
      getBriefsData();
    });

    socket.on("newUser", () => {
      getAmbieUsersData();
    });

    socket.on("updateUser", () => {
      getAmbieUsersData();
    });

    getBriefsData();
    getUsersData();
    getClientsData();
    getAmbieUsersData();

    if (pathWithoutSlash === "ambie") {
      navigate(briefs_ambie_page_navigate);
    }

    return () => {
      socket.off("updateClient", () => {
        getClientsData();
      });
      socket.off("newClient", () => {
        getClientsData();
      });
      socket.off("newBrief", () => {
        getBriefsData();
      });
      socket.off("updateBrief", () => {
        getBriefsData();
      });
      socket.off("newUser", () => {
        getAmbieUsersData();
      });
      socket.off("updateUser", () => {
        getAmbieUsersData();
      });
    };
  }, [searchClient, pathWithoutSlash, navigate]);

  let briefsTableRows: any = [];

  const filterBriefs = [
    {
      label: "All Statuses",
      value: "all",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "In Progress",
      value: "progress",
    },
    {
      label: "Completed",
      value: "completed",
    },
  ];

  const usersFilter = usersSlice
    .filter((user: any) => user.users_user.name)
    .sort((a: { users_user: { name: string } }, b: { users_user: { name: string } }) => {
      return a.users_user.name.localeCompare(b.users_user.name);
    })
    .map((data: any) => {
      return { name: data.users_user.name, id: data.users_user.id };
    });

  const clients = clientsSlice
    .filter((client: any) => client.name)
    .map((data: any) => {
      return { name: data.name, id: data.id };
    });

  briefsSlice.briefs
    .filter((row: any) => {
      let filterStatus = true;
      let filterClient = true;
      let filterByWatcher = true;
      let filterType = true;
      let filterByCurationOwner = true;
      let dateFilter = true;

      if (filterStatusList.clientFilter) {
        filterClient = row.businesses_client && row.businesses_client.id === filterStatusList.clientFilter;
      }

      if (filterStatusList.watcherFilter) {
        let watcherIds: any = [];

        if (row.briefs_watchers) {
          watcherIds =
            row.briefs_watchers.length &&
            row.briefs_watchers.map((watcher: any) => (watcher.users_user && watcher.users_user.id) || null);
        }
        filterByWatcher = watcherIds && watcherIds.length && watcherIds.includes(filterStatusList.watcherFilter);
      }

      if (filterStatusList.curationOwnerFilter) {
        filterByCurationOwner = row.curation_owner_id === filterStatusList.curationOwnerFilter ? true : false;
      }

      if (filterStatusList.briefsStatus !== "all") {
        filterStatus = row.status === filterStatusList.briefsStatus ? true : false;
      }

      if (filterStatusList.briefsType !== "all") {
        filterType = row.type === filterStatusList.briefsType ? true : false;
      }

      if (filterStatusList.dateFilter.startDate && filterStatusList.dateFilter.endDate) {
        dateFilter =
          format(new Date(row.created), "yyyy-MM-dd") > filterStatusList.dateFilter.startDate &&
          format(new Date(row.created), "yyyy-MM-dd") < filterStatusList.dateFilter.endDate;
      }

      return filterStatus && filterClient && filterByWatcher && filterType && filterByCurationOwner && dateFilter;
    })
    .sort((a: any, b: any) => {
      const dateA: any = new Date(a.created);
      const dateB: any = new Date(b.created);
      return dateB - dateA;
    })
    .map((brief: any) => {
      const rowData = {
        briefId: brief.id,
        status: brief.status,
        title: brief.title,
        type: brief.type,
        createdAt: brief.created && format(new Date(brief.created), "dd/MM/yyy HH:mm"),
        dueDate: brief.due_date,
        completionDate: brief.completion_date,
        submissionDate: brief.created,
        curationOwner: brief.curation_owner && brief.curation_owner.name,
        submittedBy: brief.submitted_by_user && brief.submitted_by_user.name,
        clientName: brief.businesses_client && brief.businesses_client.name,
        clientId: brief.businesses_client && brief.businesses_client.id,
        isFrozen: brief.businesses_client && brief.businesses_client.is_frozen,
      };

      briefsTableRows.push(rowData);
      return rowData;
    });

  const briefsStatusList = (status: any, submissionDate: any, completionDate: any, dueDate: any) => {
    if (dueDate && status !== "completed") {
      return BriefDueStatus(dueDate);
    } else if (completionDate && status === "completed") {
      return BriefCompletionStatus(submissionDate, completionDate);
    } else {
      return "-";
    }
  };

  const goToDashboard = () => {
    navigate(dashboard_page_navigate);
  };

  const briefsColumns = [
    {
      field: "status",
      headerName: "Status",
      wrapText: true,
      width: 130,
      maxWidth: 130,
      cellRenderer: (params: any) => {
        return <span>{BriefStatus(params.data.status)}</span>;
      },
    },
    {
      field: "clientName",
      headerName: "Client",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.clientName}</span>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      wrapText: true,
      width: 150,
      maxWidth: 150,
      cellRenderer: (params: any) => {
        return <span className="text">{BriefType(params.data.type)}</span>;
      },
    },

    {
      field: "title",
      headerName: "Title",
      wrapText: true,
      width: 500,
      maxWidth: 500,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.title}</span>;
      },
    },
    {
      field: "createdAt",
      headerName: "Submission date",
      wrapText: true,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.createdAt}</span>;
      },
    },
    {
      field: "createdAt",
      headerName: "",
      wrapText: true,
      cellRenderer: (params: any) => {
        return (
          <span className="text">
            {briefsStatusList(
              params.data.status,
              params.data.submissionDate,
              params.data.completionDate,
              params.data.dueDate
            )}
          </span>
        );
        // return (
        //   <div className={styles.flexColumn}>
        //     <div>{params.data.createdAt}</div>
        //     <div style={{ marginLeft: 15 }}>
        //       {briefsStatusList(params.data.status, params.data.completionDate, params.data.dueDate)}
        //     </div>
        //   </div>
        // );
      },
    },

    {
      field: "owner",
      headerName: "Curation Owner",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.curationOwner}</span>;
      },
    },

    {
      field: "submittedBy",
      headerName: "Submitted By",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.submittedBy}</span>;
      },
    },

    {
      field: "actions",
      headerName: "Action",
      width: 180,
      maxWidth: 180,
      cellClass: "actions-dropdown-cell",
      filter: false,
      sortingOrder: false,
      cellRenderer: (params: any) => {
        return (
          <GlobalDropdown name="Actions" format="white" size="xs" align="end">
            <div className="dropdown-item">
              <GlobalButton
                size="sm"
                format="none"
                onClick={() =>
                  dispatch(
                    showEditBriefActionsSuccess({
                      editBrief: { status: true, id: params.data.briefId },
                    })
                  )
                }
              >
                <BsPencil className="dropdown-item-icon" /> Edit Brief
              </GlobalButton>
            </div>

            <div className="dropdown-item">
              <GlobalButton
                size="sm"
                format="none"
                target="_blank"
                onClick={() => {
                  dispatch(
                    searchClientSuccess({
                      clientId: params.data.clientId,
                      clientName: params.data.clientName,
                      clentEmail: "",
                      clentContactName: "",
                      isFrozen: params.data.isFrozen,
                    })
                  );
                  goToDashboard();
                }}
              >
                <BsFillGridFill className="dropdown-item-icon" /> View Client
              </GlobalButton>
            </div>
          </GlobalDropdown>
        );
      },
    },
  ];

  let channelTableRows: any = [];
  const channelRowData = {
    channel: "Silver Lyan 5-7",
    tracks: 66,
    percentage: 50,
    actions: "",
  };
  channelTableRows.push(channelRowData);

  const pendingStatus =
    briefsSlice.briefs?.length && briefsSlice.briefs.filter((brief: any) => brief.status === "pending").length;
  const inProgressStatus =
    briefsSlice.briefs?.length && briefsSlice.briefs.filter((brief: any) => brief.status === "progress").length;

  const completetedStatus =
    briefsSlice.briefs?.length && briefsSlice.briefs.filter((brief: any) => brief.status === "completed").length;

  const briefsCount: any = [
    {
      message: "Number based on total briefs.",
      name: "Total Briefs",
      info: true,
      value: briefsSlice.briefs?.length || 0,
    },
    {
      message: "Number based on total pending briefs.",
      name: "Pending Briefs",
      info: true,
      value: pendingStatus || 0,
    },
    {
      message: "Number based on total in progress briefs.",
      name: "In Progress Briefs",
      info: true,
      value: inProgressStatus || 0,
    },
    {
      message: "Number based on total completed briefs.",
      name: "Completed Briefs",
      info: true,
      value: completetedStatus || 0,
    },
  ];

  const initialValues = {};

  return (
    <div className="content container py-3">
      <>
        <CSSTransition
          in={actions.addBrief}
          timeout={100}
          classNames="panel-animate"
          onEnter={() => document.body.classList.add("css-transition-modal-open")}
          onExited={() => document.body.classList.remove("css-transition-modal-open")}
          unmountOnExit={true}
          mountOnEnter={true}
        >
          <AddBrief show={actions.addBrief} />
        </CSSTransition>

        <CSSTransition
          in={actions.editBrief && actions.editBrief.status}
          timeout={100}
          classNames="panel-animate"
          onEnter={() => document.body.classList.add("css-transition-modal-open")}
          onExited={() => document.body.classList.remove("css-transition-modal-open")}
          unmountOnExit={true}
          mountOnEnter={true}
        >
          <EditBrief show={actions.editBrief && actions.editBrief.status} />
        </CSSTransition>
      </>
      <div>
        <div className="row align-items-center">
          <div className="col-sm-6" style={{ zoom: 0.95 }}>
            <MiniCard options={briefsCount} columns="col-lg-3" />
          </div>
          {/* <div className="col-sm-auto ms-auto">
            <GlobalButton
              onClick={() => {
                dispatch(
                  showAddClientActionsSuccess({
                    addClient: true,
                  })
                );
              }}
            >
              Add client
            </GlobalButton>
          </div> */}
        </div>
        {actions.actionsIsLoading && <LoadingData />}
        <div style={{ marginTop: "1rem" }}>
          <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
            <Form>
              <KeyValueCard
                title={
                  <div style={{ width: "40%", marginRight: "10px" }}>
                    <SearchWithOptions
                      id="searchClient"
                      placeholder="Search client..."
                      options={clients}
                      labelKey="name"
                      leftIcon={<BsSearch />}
                      clearButton
                      onChange={(selected: any) => {
                        setFilterStatusList({
                          ...filterStatusList,
                          clientFilter: (selected && selected[0] && selected[0].id) || 0,
                        });
                      }}
                    />
                  </div>
                }
                filter={
                  <div className="d-flex align-items-center">
                    <div style={{ width: "200px", marginRight: "20px" }}>
                      <SearchWithOptions
                        id="searchWatcher"
                        placeholder="Search watcher..."
                        options={usersFilter}
                        labelKey="name"
                        leftIcon={<BsSearch />}
                        clearButton
                        onChange={(selected: any) => {
                          setFilterStatusList({
                            ...filterStatusList,
                            watcherFilter: (selected && selected[0] && selected[0].id) || 0,
                          });
                        }}
                      />
                    </div>

                    <div style={{ width: "220px", marginRight: "20px" }}>
                      <SearchWithOptions
                        id="searchCurationOwner"
                        placeholder="Search curation owner..."
                        options={usersFilter}
                        labelKey="name"
                        leftIcon={<BsSearch />}
                        clearButton
                        onChange={(selected: any) => {
                          setFilterStatusList({
                            ...filterStatusList,
                            curationOwnerFilter: (selected && selected[0] && selected[0].id) || 0,
                          });
                        }}
                      />
                    </div>
                    <div style={{ width: "230px", marginRight: "20px" }}>
                      <DateRangePicker
                        onChange={(values: any) => {
                          setFilterStatusList({
                            ...filterStatusList,
                            dateFilter: {
                              startDate: (values && values[0] && format(new Date(values[0]), "yyyy-MM-dd")) || "",
                              endDate: (values && values[1] && format(new Date(values[1]), "yyyy-MM-dd")) || "",
                            },
                          });
                        }}
                      />
                    </div>

                    <div style={{ width: "180px", marginRight: "10px" }}>
                      <Select
                        id="brief-status"
                        className="flex-grow-6 time-select channel-list"
                        options={briefTypeOptions()}
                        value={briefTypeOptions().find((c: any) => c.value === filterStatusList.briefsType)}
                        onChange={(selected) =>
                          selected &&
                          selected.value &&
                          setFilterStatusList({ ...filterStatusList, briefsType: selected.value })
                        }
                      />
                    </div>

                    <div style={{ width: "150px", marginRight: "10px" }}>
                      <Select
                        id="brief-status"
                        className="flex-grow-6 time-select channel-list"
                        options={filterBriefs}
                        value={filterBriefs.find((c: any) => c.value === filterStatusList.briefsStatus)}
                        onChange={(selected) =>
                          selected &&
                          selected.value &&
                          setFilterStatusList({ ...filterStatusList, briefsStatus: selected.value })
                        }
                      />
                    </div>
                  </div>
                }
              >
                <div className="row">
                  <div className="col-sm-12">
                    <AgGridReactTable
                      className="ag-theme-alpine"
                      rowData={briefsTableRows}
                      columnDefs={briefsColumns}
                      filter={true}
                      sortable={true}
                      animateRows={true}
                      pagination={true}
                      paginationPageSize={100}
                      domLayout="autoHeight"
                    />
                  </div>
                </div>
              </KeyValueCard>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
