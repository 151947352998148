import { useSelector, useDispatch } from "react-redux";
import { GlobalButton } from "../../../../components/button";

// style components
import { GlobalModal } from "../../../../components/modal";
import { hideActionsSuccess } from "../../../../redux-toolkit/reducers/actionsSlice";
import { RootState } from "../../../../redux-toolkit/store";
import modalStyles from "../../../locations/components/location-actions/location-actions.module.scss";
import locationStyles from "../../../locations/locations.module.scss";
import { GlobalErrorMessage } from "../../../users/components/errorMessage";

interface ScheduleProps {
  size?: "sm" | "lg" | "xl";
  backdrop?: "static" | true | false;
  centered?: true | false;
  scrollable?: true | false;
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  footer?: any;
  title?: string;
  show: boolean;
  location?: { name: string; email: string; id: number };
  handleClose: any; //function
  handleCloseDeleteSchedule: any; //function
  handleDeleteSchedule: any; //function
  //
  affectedAreas: any;
  selectedSchedule: any;
}

/**
 * Delete Schedule Popup
 */
export const ScheduleDeleteSchedulePopup = ({
  footer,
  show,
  handleClose,
  handleCloseDeleteSchedule,
  handleDeleteSchedule,
  affectedAreas,
  selectedSchedule,
}: ScheduleProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const dispatch = useDispatch();

  return (
    <GlobalModal title="Delete schedule" show={show} handleClose={handleClose} footer={footer}>
      <div className={modalStyles.modal}>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        <p>
          Are you sure you want to delete <strong>{selectedSchedule.name}</strong> schedule?
        </p>
        <div>
          {typeof affectedAreas !== "undefined" && affectedAreas.length > 0 ? (
            <div>
              <p>Deleting this schedule might affect this area (s): </p>
              <ul>
                {affectedAreas.map((A: any, Adx: number) => (
                  <li key={"affectedAreas" + Adx}>{A.name} </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
        <div className={locationStyles.flexRowWrapModalFooter}>
          <div className={locationStyles.footerLeft}>
            <GlobalButton
              format="white"
              size="sm"
              onClick={() => {
                handleCloseDeleteSchedule();
                dispatch(hideActionsSuccess());
              }}
            >
              Cancel
            </GlobalButton>
          </div>
          <div>
            <GlobalButton
              format="success"
              type="submit"
              size="sm"
              onClick={() => {
                handleDeleteSchedule();
                dispatch(hideActionsSuccess());
              }}
            >
              Yes
            </GlobalButton>
          </div>
        </div>
      </div>
    </GlobalModal>
  );
};
