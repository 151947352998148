import { NavBar } from "./navbar";
// import styles from "./layout.module.scss";

interface LayoutProps {
  title?: string;
  className?: any;
  content?: string;
  href?: any;
  children: any;
}
export const Layout = ({ title, content, href, children }: LayoutProps) => {
  return (
    <NavBar title={title} content={content} href={href}>
      {children}
    </NavBar>
  );
};
