import { GlobalButton } from "../../../../components/button";

// style components
import { GlobalModal } from "../../../../components/modal";
import TimezoneHelper from "../../../../components/timezoneHelper";
import modalStyles from "../../../locations/components/location-actions/location-actions.module.scss";
import locationStyles from "../../../locations/locations.module.scss";

interface ScheduleProps {
  size?: "sm" | "lg" | "xl";
  backdrop?: "static" | true | false;
  centered?: true | false;
  scrollable?: true | false;
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  footer?: any;
  title?: string;
  show: boolean;
  location?: { name: string; email: string; id: number };
  handleClose: any;
  timezone: any;
  timezoneName: any;
  handleConfirm: any; // function
  currentSchedule: any;
  affectedAreas: any;
}

/**
 * Generate reset password link
 */
export const ScheduleSaveChannelPopup = ({
  footer,
  show,
  handleClose,
  timezone,
  timezoneName,
  handleConfirm,
  currentSchedule,
  affectedAreas,
}: ScheduleProps) => {
  const _isGapsInSchedule = () => {
    return TimezoneHelper.isGapsInSchedule(currentSchedule && currentSchedule.timezoneAdjustedSchedule);
  };

  const _computeAffectedAreas = () => {
    if (typeof affectedAreas !== "undefined" && affectedAreas.length > 0) {
      const wording = affectedAreas.length === 1 ? "the following location" : "the following locations";
      return (
        <div>
          <p>Saving changes will affect {wording}: </p>
          <ul style={{ padding: "0" }}>
            {affectedAreas.map((A: any, Adx: number) => {
              return (
                <li key={"affectedAreas" + Adx} style={{ listStyle: "none" }}>
                  {" "}
                  {A.name}{" "}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  const showWarning = { display: _isGapsInSchedule() ? "block" : "none" };
  const affectedAreasDom = _computeAffectedAreas();
  const timezoneString = `You are editing this schedule in the timezone: `;
  const timezoneData = `${timezoneName} (${timezone}).`;
  const timezoneStringTwo = `If this is incorrect, please cancel the changes and change the timezone using the timezone button on the top right of the schedules page, before making your changes.`;

  return (
    <GlobalModal title="Save changes to Schedule" show={show} handleClose={handleClose} footer={footer}>
      <div className={modalStyles.modal}>
        <div className="row save-shcedule-popup-details">
          <div className="col-sm-12">
            {affectedAreasDom}
            <div style={showWarning} className="alert alert-danger">
              There are some blank gaps in your calendar that will cause interruptions in the music
            </div>
            <p>
              {timezoneString}
              <span>
                <strong>{timezoneData}</strong>
              </span>
            </p>
            <p>{timezoneStringTwo}</p>
            <p>
              <strong>Are you sure you want to proceed?</strong>
            </p>
          </div>
        </div>

        <div className={locationStyles.flexRowWrapModalFooter}>
          <div>
            <GlobalButton format="white" size="sm" onClick={() => handleClose()}>
              Cancel
            </GlobalButton>
          </div>
          <div>
            <GlobalButton format="success" type="submit" size="sm" onClick={() => handleConfirm()}>
              Save
            </GlobalButton>
          </div>
        </div>
      </div>
    </GlobalModal>
  );
};
