import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

// style components
import { loginSuccess } from "../../redux-toolkit/reducers/authSlice";
import { Input } from "../../components/fields/input";
import { GlobalButton } from "../../components/button";
import { Toggle } from "../../components/fields/toggle";
import { client_page, forgot_password_page } from "../../constants/routePath";
import styles from "./users.module.scss";
import { loginForm } from "../../components/formValidation/formValidation";
import { login } from "../../services/usersServices";
import { LOCAL_STORAGE_USER_TOKEN, LOCAL_STORAGE_USER_REFRESH_TOKEN } from "../../constants/defaultValues";
import { GlobalErrorMessage } from "./components/errorMessage";
import { shakeAnimationSuccess } from "../../redux-toolkit/reducers/shakeAnimationSlice";
import {
  hideActionsSuccess,
  isLoadingSuccess,
  showErrorMessageSuccess,
} from "../../redux-toolkit/reducers/actionsSlice";
import { RootState } from "../../redux-toolkit/store";
import { LoadingData } from "../../components/loading";

export const Login: React.FC = () => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  type userDetailParams = { username: string; password: string };

  async function loginData(values: userDetailParams) {
    dispatch(isLoadingSuccess(true));

    // .replace(/ /g, "") regx is used to remove empty space
    login(values.username.replace(/ /g, ""), values.password.replace(/ /g, ""))
      .then((user: any) => {
        Cookies.set(LOCAL_STORAGE_USER_TOKEN, user.token, {
          // expires: 7,
          // path: '/',
          // domain: 'example.com',
          // secure: true,
          // sameSite: 'lax'
        });
        Cookies.set(LOCAL_STORAGE_USER_REFRESH_TOKEN, user.refreshToken, {
          // expires: 7,
          // path: '/',
          // domain: 'example.com',
          // secure: true,
          // sameSite: 'lax'
        });
        dispatch(hideActionsSuccess());
        dispatch(loginSuccess({ user: user.userInfo, token: user.token }));
        navigate(client_page);
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess("Username or password is incorrect"));
        dispatch(shakeAnimationSuccess());
      });
  }

  const initialValues = { username: "", password: "", isEnabled: false };

  return (
    <div className="card card-lg mb-5">
      <div className="card-body">
        <div className="text-center">
          <div className="mb-5">
            <h1 className="display-5">Admin Portal</h1>
          </div>
        </div>

        <Formik form initialValues={initialValues} validationSchema={loginForm} onSubmit={loginData}>
          {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors }) => (
            <div>
              <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
              {actions.actionsIsLoading && <LoadingData />}

              <div className="mb-4">
                <Input
                  placeholder="Username or Email"
                  name="username"
                  id="username"
                  label="Username or Email"
                  onBlur={handleBlur("username")}
                  autoCapitalize="none"
                  onChange={handleChange("username")}
                  error={errors.username}
                  size="form-control-lg"
                  //testID="username"
                  onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                />
              </div>

              <div className="mb-4">
                <Input
                  placeholder="Password"
                  name="password"
                  id="password"
                  label="Password"
                  type={values.isEnabled ? "text" : "password"}
                  onBlur={handleBlur("password")}
                  onChange={handleChange("password")}
                  error={errors.password}
                  size="form-control-lg"
                  //testID="username"
                  onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                />
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <Toggle
                    type="checkbox"
                    name="isEnabled"
                    id="isEnabled"
                    label={values.isEnabled ? "Hide Password" : "Show Password"}
                    className="mb-4"
                    rightLabel
                    onChange={(value: any) => {
                      const targetValue = value.target && value.target.value === "true" ? false : true;
                      setFieldValue(`isEnabled`, targetValue);
                    }}
                  />
                </div>
                <div className="col-sm-6 text-right">
                  <GlobalButton
                    size="none"
                    format="none"
                    to={forgot_password_page}
                    className={styles.rightSide}
                    onClick={() => {
                      dispatch(showErrorMessageSuccess(""));
                    }}
                  >
                    Forgot password?
                  </GlobalButton>
                </div>
              </div>

              <div className="d-grid">
                <GlobalButton
                  size="lg"
                  format="primary"
                  onClick={() => handleSubmit()}
                  className={styles.loginSubmitButton}
                >
                  Login
                </GlobalButton>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
