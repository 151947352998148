import { GlobalButton } from "../../../../components/button";

// style components
import { GlobalModal } from "../../../../components/modal";
import modalStyles from "../../../locations/components/location-actions/location-actions.module.scss";
import locationStyles from "../../../locations/locations.module.scss";

interface ScheduleProps {
  size?: "sm" | "lg" | "xl";
  backdrop?: "static" | true | false;
  centered?: true | false;
  scrollable?: true | false;
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  footer?: any;
  title?: string;
  show: boolean;
  location?: { name: string; email: string; id: number };
  handleClose: any; //function
  handleCancelOverlapsChannel: any; //function
  handleConfirmedOverlapsChannel: any; //function
}

/**
 * Generate reset password link
 */
export const ScheduleOverlapsConfirmationPopup = ({
  footer,
  show,
  handleClose,
  handleCancelOverlapsChannel,
  handleConfirmedOverlapsChannel,
}: ScheduleProps) => {
  return (
    <GlobalModal
      title="This will overwrite an existing scheduled playlist"
      show={show}
      handleClose={handleClose}
      footer={footer}
    >
      <div className={modalStyles.modal}>
        <p>Are you sure you want to do this?</p>
        {/* <div>
          <h3 className="text-center">This will overwrite an existing scheduled playlist</h3>
          <h3 className="text-center">Are you sure you want to do this?</h3>
          <div className="action-btns">
            <div className="btn btn-default btn-border btn-pill" onClick={handleCancelOverlapsChannel}>
              NO
            </div>
            <div className="btn btn-primary btn-border btn-pill" onClick={handleConfirmedOverlapsChannel}>
              YES
            </div>
          </div>
        </div> */}
        <div className={locationStyles.flexRowWrapModalFooter}>
          <div className={locationStyles.footerLeft}>
            <GlobalButton format="white" size="sm" onClick={() => handleCancelOverlapsChannel()}>
              Cancel
            </GlobalButton>
          </div>
          <div>
            <GlobalButton format="success" type="submit" size="sm" onClick={() => handleConfirmedOverlapsChannel()}>
              Yes
            </GlobalButton>
          </div>
        </div>
      </div>
    </GlobalModal>
  );
};
