import React, { useContext, useEffect } from "react";
import { HeaderPage } from "../header";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// style components
import { WhiteSvg } from "../../assets/images/logo/symbol/Symbol";

import { client_page } from "../../constants/routePath";
import { GlobalButton } from "../../components/button";

import { menuItems } from "./firstLevel/menuItems";
import FirstLevelMenuItems from "./firstLevel/FirstLevelMenuItems";
import { logoutUser } from "../../services/usersServices";
import { RootState } from "../../redux-toolkit/store";
import { userProfile } from "../../services/usersServices";
import { WebSocketContext } from "../../components/websocket/websocket";

interface NavbarProps {
  title?: string;
  className?: any;
  content?: string;
  href?: any;
  children: any;
}
export const NavBar = ({ title, content, href, children }: NavbarProps) => {
  const authReducer = useSelector((state: RootState) => state.authSlice);
  const user = authReducer.user && authReducer.user.user && authReducer.user.user.users_user;
  const socket = useContext(WebSocketContext);

  useEffect(() => {
    socket.on("updateUser", (payload: any) => {
      if (payload.id === user && user.id && !payload.is_active) logout();
    });
    const getUserProfile = async () => {
      await userProfile()
        .then((user) => {})
        .catch((error) => {
          if (["No auth token!", "Invalid token!"].includes(error.response.data.message)) {
            logout();
          }
        });
    };

    getUserProfile();

    return () => {
      socket.off("updateUser", (payload: any) => {
        if (payload.id === user && user.id && !payload.is_active) logout();
      });
    };
  }, [user]);

  const logout = async () => {
    await logoutUser();
  };

  return (
    <HeaderPage title={title} content={content} href={href}>
      <header id="header" className="bg-white navbar navbar-expand-lg flex-lg-column">
        <div className="navbar-dark w-100 bg-dark">
          <div className="container">
            <div className="navbar-nav-wrap">
              <Link to={client_page} className="navbar-brand">
                <img className="navbar-brand-logo" src={WhiteSvg} alt="Ambie Admin Portal" />
              </Link>

              <div className="navbar-nav-wrap-content-start">
                <span className="lead text-white" style={{ opacity: 0.7 }}>
                  Admin Portal
                </span>
              </div>

              <div className="navbar-nav-wrap-content-end">
                <ul className="nav navbar-nav">
                  {menuItems.map((menu, index) => {
                    const depthLevel = 0;
                    return <FirstLevelMenuItems items={menu} key={index} depthLevel={depthLevel} />;
                  })}
                  <li className="nav-item dropdown ms-3">
                    <Link className="navbar-dropdown-account-wrapper  dropdown-toggle" to="#">
                      <span className="avatar avatar-sm avatar-secondary avatar-circle">
                        <span className="avatar-initials">{(user && user.name && user.name.charAt(0)) || "A"}</span>
                      </span>
                    </Link>
                    {/* <a
                      className="navbar-dropdown-account-wrapper  dropdown-toggle"
                      href="#"
                      id="accountNavbarDropdown"
                      data-bs-toggle="dropdown"
                    >
                      <span className="avatar avatar-sm avatar-secondary avatar-circle">
                        <span className="avatar-initials">AP</span>
                      </span>
                    </a> */}
                    <div
                      className="dropdown-menu navbar-dropdown-menu-borderless hs-sub-menu-desktop-lg"
                      style={{ minWidth: "14rem", right: 0 }}
                    >
                      {/* <a href="##" onClick={() => logout()} className="dropdown-item nav-item">
                        Logout
                      </a> */}
                      <GlobalButton size="sm" format="none" onClick={() => logout()} className="dropdown-item nav-item">
                        Logout
                      </GlobalButton>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div>{children}</div>
    </HeaderPage>
  );
};
