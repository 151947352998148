import { differenceInMilliseconds } from "date-fns";

// style components
import { Tooltip } from "../../../../components/tooltip";
import tooltipStyles from "../../../../components/cards/cards.module.scss";
import styles from "./device-status.module.scss";

import { BsPauseFill } from "react-icons/bs";

export const AmbiePlayerStatus = (item: any) => {
  const deviceHasFields = item.is_active && item.device.serial && item.device.last_seen && item.locationStatus;

  if (deviceHasFields && differenceInMilliseconds(Date.now(), item.device.last_seen) / 60000 < 10) {
    return <span className="badge bg-soft-success text-success p-2">Online</span>;
  } else if (deviceHasFields && differenceInMilliseconds(Date.now(), item.device.last_seen) / 60000 > 10) {
    return <span className="badge bg-soft-danger text-danger p-2">Offline</span>;
  } else if (!item.is_active && item.device.serial && item.locationStatus) {
    return <span className="badge bg-soft-dark text-dark p-2">Inactive</span>;
  } else if (!item.locationStatus) {
    return <span className="badge bg-soft-dark text-dark p-2">Inactive location</span>;
  } else if ((item.device && !item.device.serial) || !item.device) {
    return <span className="badge bg-soft-dark text-dark p-2">No Player</span>;
  }
};

export const AmbieMusicPlaybackStatus = (item: any) => {
  const deviceHasFields =
    item &&
    item.is_active &&
    item.device &&
    item.device.current_track &&
    item.device.current_track.start &&
    item.device.current_track.duration &&
    item.locationStatus;
  const playerIsSteaming = item && item.streaming && item.streaming.enabled && item.streaming.online;

  if (
    deviceHasFields &&
    item.device.status === "play" &&
    !playerIsSteaming &&
    differenceInMilliseconds(Date.now() - 1000 * 60 * 10, item.device.current_track.start) / 1000 <
      item.device.current_track.duration
  ) {
    return (
      <span className="position-relative">
        <span className={tooltipStyles.tooltipIcon}>
          <div className={styles.playingState}>
            <span />
            <span />
            <span />
          </div>
        </span>
        <span className={tooltipStyles.tooltipIconHide} style={{ width: "70px" }}>
          <Tooltip message="Playing" />
        </span>
      </span>
    );
  } else if (
    deviceHasFields &&
    item.device.status === "play" &&
    differenceInMilliseconds(Date.now() - 1000 * 120, item.device.current_track.start) / 1000 >
      item.device.current_track.duration
  ) {
    return (
      <span className="position-relative">
        <span className={tooltipStyles.tooltipIcon}>
          <div className={styles.notPlayingState}>
            <span />
            <span />
            <span />
          </div>
        </span>
        <span className={tooltipStyles.tooltipIconHide} style={{ width: "90px" }}>
          <Tooltip message="Not Playing" />
        </span>
      </span>
    );
  } else if (deviceHasFields && item.device.status === "pause") {
    return (
      <span className="position-relative">
        <span className={tooltipStyles.tooltipIcon}>
          <BsPauseFill size="24" style={{ marginLeft: "-3px", marginRight: "-4px" }} />
        </span>
        <span className={tooltipStyles.tooltipIconHide} style={{ width: "70px" }}>
          <Tooltip message="Paused" />
        </span>
      </span>
    );
  } else if (item.device.serial && !item.device.status) {
    return (
      <span className="position-relative">
        <span className={tooltipStyles.tooltipIcon}>
          <div className={styles.noPlayerState}>
            <span />
            <span />
            <span />
          </div>
        </span>
        <span className={tooltipStyles.tooltipIconHide} style={{ width: "90px" }}>
          <Tooltip message="No Status" />
        </span>
      </span>
    );
  } else if (item.device.status === "stop" || !item.locationStatus || item.current_track === null) {
    return (
      <span className="position-relative">
        <span className={tooltipStyles.tooltipIcon}>
          <div className={styles.notPlayingState}>
            <span />
            <span />
            <span />
          </div>
        </span>
        <span className={tooltipStyles.tooltipIconHide} style={{ width: "90px" }}>
          <Tooltip message="Not Playing" />
        </span>
      </span>
    );
  }
};

export const WebPlayerStatus = (item: any) => {
  const playerIsSteaming = item && item.is_active && item.streaming && item.streaming.streaming;

  if (playerIsSteaming && playerIsSteaming.enabled && playerIsSteaming.online) {
    return <span className="badge bg-soft-success text-success p-2">Online</span>;
  } else if (playerIsSteaming && playerIsSteaming.enabled && !playerIsSteaming.online) {
    return <span className="badge bg-soft-danger text-danger p-2">Offline</span>;
  }
};

export const WebMusicPlaybackStatus = (item: any) => {
  const playerIsSteaming = item && item.is_active && item.streaming && item.streaming.streaming;

  if (playerIsSteaming && playerIsSteaming.enabled && playerIsSteaming.online && playerIsSteaming.status === "play") {
    return (
      <span className="position-relative">
        <span className={tooltipStyles.tooltipIcon}>
          <div className={styles.playingState}>
            <span />
            <span />
            <span />
          </div>
        </span>
        <span className={tooltipStyles.tooltipIconHide} style={{ width: "70px" }}>
          <Tooltip message="Playing" />
        </span>
      </span>
    );
  } else if (playerIsSteaming && playerIsSteaming.enabled && !playerIsSteaming.online) {
    return (
      <span className="position-relative">
        <span className={tooltipStyles.tooltipIcon}>
          <div className={styles.notPlayingState}>
            <span />
            <span />
            <span />
          </div>
        </span>
        <span className={tooltipStyles.tooltipIconHide} style={{ width: "90px" }}>
          <Tooltip message="Not Playing" />
        </span>
      </span>
    );
  } else if (playerIsSteaming && playerIsSteaming.enabled && playerIsSteaming.online && item.device.status === "stop") {
    return (
      <span className="position-relative">
        <span className={tooltipStyles.tooltipIcon}>
          <BsPauseFill size="24" style={{ marginLeft: "-3px", marginRight: "-4px" }} />
        </span>
        <span className={tooltipStyles.tooltipIconHide} style={{ width: "70px" }}>
          <Tooltip message="Paused" />
        </span>
      </span>
    );
  }
};
