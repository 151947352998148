import {
  LOCAL_STORAGE_CLIENT,
  LOCAL_STORAGE_SKIP_TRACK,
  LOCAL_STORAGE_SWITCH_PLAYLIST,
  LOCAL_STORAGE_USER,
} from "../constants/defaultValues";

interface UserInfo {
  status?: number;
  isGod?: boolean;
  permissionGroup?: string;
  username?: string;
}

interface clientInfo {
  clientId?: number;
  clientName?: string;
  clentEmail?: string;
  clentContactName?: string;
}

/**
 * Store local storage for user record
 */
const storeUser = (user: UserInfo) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
  } catch (error: any) {
    throw new Error(error);
  }
};

/**
 * Get local storage for user record
 */
const getUser = () => {
  try {
    const jsonValue = localStorage.getItem(LOCAL_STORAGE_USER);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (error: any) {
    throw new Error(error);
  }
};

/**
 * Store skip track
 */
const storeSkipTrack = (tracks: any) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_SKIP_TRACK, JSON.stringify(tracks));
  } catch (error: any) {
    throw new Error(error);
  }
};

/**
 * Get skip track
 */
const getSkipTrack = () => {
  try {
    const jsonValue = localStorage.getItem(LOCAL_STORAGE_SKIP_TRACK);
    return jsonValue != null ? JSON.parse(jsonValue) : [];
  } catch (error: any) {
    throw new Error(error);
  }
};

/**
 * Store switch playlist
 */
const storeSwitchPlaylist = (areas: any) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_SWITCH_PLAYLIST, JSON.stringify(areas));
  } catch (error: any) {
    throw new Error(error);
  }
};

/**
 * Get switch playlist
 */
const getSwitchPlaylist = () => {
  try {
    const jsonValue = localStorage.getItem(LOCAL_STORAGE_SWITCH_PLAYLIST);
    return jsonValue != null ? JSON.parse(jsonValue) : [];
  } catch (error: any) {
    throw new Error(error);
  }
};

/**
 * Store local storage for client record
 */
const storeClient = (client: clientInfo) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_CLIENT, JSON.stringify(client));
  } catch (error: any) {
    throw new Error(error);
  }
};

/**
 * Get local storage for client record
 */
const getClient = () => {
  try {
    const jsonValue = localStorage.getItem(LOCAL_STORAGE_CLIENT);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (error: any) {
    throw new Error(error);
  }
};

export {
  storeUser,
  getUser,
  storeSkipTrack,
  getSkipTrack,
  storeSwitchPlaylist,
  getSwitchPlaylist,
  storeClient,
  getClient,
};

export default Object.freeze({
  storeUser,
  getUser,
  storeSkipTrack,
  getSkipTrack,
  storeSwitchPlaylist,
  getSwitchPlaylist,
  storeClient,
  getClient,
});
