import React, { useState } from "react";
import { FaBeer, FaQuestionCircle, FaFilter } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { Formik, Form } from "formik";
// styles components
import { GlobalButton } from "../../components/button";
import { Input } from "../../components/fields/input";
import { Select } from "../../components/fields/select";
import { Textarea } from "../../components/fields/texarea";
import { Toggle } from "../../components/fields/toggle";
import { Checkbox } from "../../components/fields/checkbox";
import { SearchWithOptions } from "../../components/fields/search";
import { DateRangePicker, SingleDatePicker } from "../../components/fields/datePicker";
import { RowCard, MiniCard, KeyValueCard } from "../../components/cards";
import { LoadingData } from "../../components/loading";
import { KeyValueTable } from "../../components/table";
import { Countries } from "../../data";
import styles from "./dashboard.module.scss";
import { Pagination } from "../../components/pagination";
import { PieChart } from "./components/chart";

export default function ReusableComponents() {
  const countriesPage = 3;
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * countriesPage;
  const pageCount = Math.ceil(Countries.length / countriesPage);

  const changePage = ({ selected }: any) => {
    setPageNumber(selected);
  };

  const initialValues = {
    country: true,
    county: false,
    isEnabled: true,
    isDesabled: false,
  };

  return (
    <>
      <div className="jumbotron text-center">
        <h1>Ambie Admin Portal</h1>
        <p>List of our reusable components!</p>
      </div>

      <div className="container">
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          {({ handleChange, handleBlur, setFieldValue, values, errors, touched }) => {
            return (
              <Form>
                <div className="row">
                  {/* Tables */}
                  <div className="col-sm-12">
                    <h3>Table</h3>
                    <div className="row">
                      <div className="col-sm-6">
                        <p>Table with filter icon</p>
                        <KeyValueTable
                          columns={[
                            {
                              title: "First",
                              style: styles.colour,
                              filterIcon: <FaFilter />,
                            },
                            {
                              title: "Last",
                              filterIcon: <FaFilter />,
                            },
                            {
                              title: "Age",
                              infoIcon: <FaQuestionCircle />,
                              filterIcon: <FaFilter />,
                            },
                          ]}
                          rows={
                            <tr>
                              {[{ body: "Cyrille" }, { body: "Hounvio" }, { body: "31" }].map(
                                (bodyList: any, index: number) => {
                                  return <td key={index}>{bodyList.body}</td>;
                                }
                              )}
                            </tr>
                          }
                        />
                      </div>
                      <div className="col-sm-6">
                        <p>Table without filter icon</p>
                        <KeyValueTable
                          columns={[
                            {
                              title: "First",
                            },
                            {
                              title: "Last",
                            },
                            {
                              title: "Date",
                            },
                          ]}
                          rows={
                            <tr>
                              {[{ body: "Cyrille" }, { body: "Hounvio" }, { body: "31" }].map(
                                (bodyList: any, index: number) => {
                                  return <td key={index}>{bodyList.body}</td>;
                                }
                              )}
                            </tr>
                          }
                        />
                      </div>
                    </div>
                    <hr className={styles.line} />
                  </div>

                  {/* Cards */}
                  <div className="col-sm-12">
                    <h3>Cards</h3>
                    <div className="row">
                      <div className="col-sm-6" style={{ marginBottom: 15 }}>
                        <p>Key value card with all functions</p>
                        <KeyValueCard
                          title="name"
                          subTitle="Europe/London"
                          vButton={"view all"}
                          pButton={"pending"}
                          cButton={"complete"}
                          filter={
                            <Select
                              name="name"
                              options={[
                                {
                                  label: "Male",
                                  value: "male",
                                },
                                {
                                  label: "Female",
                                  value: "female",
                                },
                              ]}
                            />
                          }
                        >
                          <RowCard>
                            <p>message here</p>
                          </RowCard>
                          <RowCard>
                            <p>message here</p>
                          </RowCard>
                        </KeyValueCard>
                      </div>
                      <div className="col-sm-6" style={{ marginBottom: 15 }}>
                        <p>Key value card with view all button</p>
                        <KeyValueCard
                          title="name"
                          subTitle="Europe/London"
                          vButton={"view all"}
                          filter={
                            <Select
                              name="name"
                              options={[
                                {
                                  label: "Male",
                                  value: "male",
                                },
                                {
                                  label: "Female",
                                  value: "female",
                                },
                              ]}
                            />
                          }
                        >
                          <RowCard>
                            <p>message here</p>
                          </RowCard>
                          <RowCard>
                            <p>message here</p>
                          </RowCard>
                        </KeyValueCard>
                      </div>
                      <div className="col-sm-6" style={{ marginBottom: 15 }}>
                        <p>Key value card with pending and complete</p>
                        <KeyValueCard title="name" pButton={"pending"} cButton={"complete"}>
                          <RowCard>
                            <p>message here</p>
                          </RowCard>
                          <RowCard>
                            <p>message here</p>
                          </RowCard>
                        </KeyValueCard>
                      </div>
                      <div className="col-sm-6" style={{ marginBottom: 15 }}>
                        <p>Key value card with filter</p>
                        <KeyValueCard
                          title="name"
                          subTitle="Europe/London"
                          filter={
                            <Select
                              name="name"
                              options={[
                                {
                                  label: "Male",
                                  value: "male",
                                },
                                {
                                  label: "Female",
                                  value: "female",
                                },
                              ]}
                            />
                          }
                        >
                          <RowCard>
                            <p>message here</p>
                          </RowCard>
                          <RowCard>
                            <p>message here</p>
                          </RowCard>
                        </KeyValueCard>
                      </div>

                      <div className="col-sm-6" style={{ marginBottom: 15 }}>
                        <p>Mini card</p>
                        <MiniCard
                          options={[
                            {
                              name: "Total playlists",
                              info: true,
                              value: 124,
                            },
                            {
                              name: "Total tracks",
                              info: true,
                              value: 6078,
                            },
                          ]}
                          columns="col-lg-6"
                        />
                      </div>
                      <div className="col-sm-6" style={{ marginBottom: 15 }}>
                        <p>Mini card with status</p>
                        <MiniCard
                          options={[
                            {
                              name: "Playlist status",
                              info: true,
                              value: 12,
                              status: "online",
                            },
                            {
                              info: true,
                              value: 2,
                              status: "offline",
                            },
                            {
                              info: true,
                              value: 0,
                              status: "inactive",
                            },
                          ]}
                          columns="col-lg-4"
                        />
                      </div>
                    </div>
                    <hr className={styles.line} />
                  </div>

                  {/* Button column */}
                  <div className="col-sm-12">
                    <h3>Buttons</h3>
                    <div className="row">
                      {/* Buttons */}
                      <div className="col-sm-3" style={{ marginBottom: 15 }}>
                        <GlobalButton
                          size="lg"
                          format="success"
                          leftIcon={<FaBeer />}
                          onClick={() => alert("Large button with success color and left icon")}
                        >
                          Large button
                        </GlobalButton>
                      </div>
                      <div className="col-sm-3" style={{ marginBottom: 15 }}>
                        <GlobalButton
                          leftIcon={<FaBeer />}
                          rightIcon={<FaBeer />}
                          onClick={() => alert("Normal button with left and right icon ")}
                        >
                          Normal button
                        </GlobalButton>
                      </div>
                      <div className="col-sm-3" style={{ marginBottom: 15 }}>
                        <GlobalButton
                          format="secondary"
                          size="sm"
                          onClick={() => alert("Small button with secondary color")}
                        >
                          Small button
                        </GlobalButton>
                      </div>
                      <div className="col-sm-3" style={{ marginBottom: 15 }}>
                        <GlobalButton
                          format="secondary"
                          size="xs"
                          rightIcon={<FaBeer />}
                          onClick={() => alert("X Small button with secondary color and right icon")}
                        >
                          X small button
                        </GlobalButton>
                      </div>

                      {/* React dom link for react navigation*/}
                      <div className="col-sm-3" style={{ marginBottom: 15 }}>
                        <GlobalButton
                          to=""
                          format="info"
                          onClick={() => alert("Normal react dom link with info color")}
                        >
                          Normal link
                        </GlobalButton>
                      </div>

                      <div className="col-sm-3" style={{ marginBottom: 15 }}>
                        <GlobalButton to="" format="none" onClick={() => alert("Small react dom link with info color")}>
                          Link without border?
                        </GlobalButton>
                      </div>

                      {/* Using href with or without target */}
                      <div className="col-sm-3" style={{ marginBottom: 15 }}>
                        <GlobalButton
                          href="https://www.npmjs.com/package/react-router-dom"
                          size="sm"
                          target="_blank"
                          format="danger"
                        >
                          Href Link with target
                        </GlobalButton>
                      </div>
                      <div className="col-sm-3" style={{ marginBottom: 15 }}>
                        <GlobalButton href="https://www.npmjs.com/package/react-router-dom" format="danger">
                          Href Link without target
                        </GlobalButton>
                      </div>
                    </div>
                    <hr className={styles.line} />
                  </div>

                  {/* fields column */}
                  <div className="col-sm-12">
                    <h3>Fileds</h3>

                    <div className="row">
                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <Input label="Input" id="text" name="text" placeholder="Text input" />
                      </div>
                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <Input label="Input with left icon" id="text" name="text" placeholder="Text input" />
                      </div>
                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <Input label="Input with right icon" id="text" name="text" placeholder="Text input" />
                      </div>
                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <Input label="Input with left and right icon" id="text" name="text" placeholder="Text input" />
                      </div>

                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <Select
                          label="Select option"
                          name="name"
                          options={[
                            {
                              label: "Male",
                              value: "male",
                            },
                            {
                              label: "Female",
                              value: "female",
                            },
                          ]}
                        />
                      </div>
                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <Select
                          label="Select option with right icon"
                          name="name"
                          options={[
                            {
                              label: "Male",
                              value: "male",
                            },
                            {
                              label: "Female",
                              value: "female",
                            },
                          ]}
                        />
                      </div>

                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <SearchWithOptions
                          id="text"
                          label="Search without icon (with options)"
                          placeholder="Search clients..."
                          options={Countries}
                          labelKey="label"
                        />
                      </div>
                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <SearchWithOptions
                          id="text"
                          label="Search with left icon (with options)"
                          placeholder="Search clients..."
                          options={Countries}
                          rightIcon
                          labelKey="label"
                        />
                      </div>
                      {/* search 
      https://www.npmjs.com/package/react-bootstrap-typeahead
      http://ericgio.github.io/react-bootstrap-typeahead/#basic-example
      */}
                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <SearchWithOptions
                          id="text"
                          label="Search with left icon (with options)"
                          placeholder="Search clients..."
                          options={Countries}
                          leftIcon={<BsSearch />}
                          labelKey="label"
                        />
                      </div>
                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <SearchWithOptions
                          id="text"
                          label="Search with left and right icon (with options)"
                          placeholder="Search clients..."
                          options={Countries}
                          leftIcon={<BsSearch />}
                          rightIcon
                          labelKey="label"
                        />
                      </div>
                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <SearchWithOptions
                          id="text"
                          label="Select multiple without icon (with options)"
                          placeholder="Search clients..."
                          options={Countries}
                          multiple
                          labelKey="label"
                        />
                      </div>
                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <SearchWithOptions
                          id="text"
                          label="Select multiple with right icon (with options)"
                          placeholder="Search clients..."
                          options={Countries}
                          multiple
                          rightIcon
                          labelKey="label"
                        />
                      </div>

                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <SingleDatePicker label="Date picker" />
                      </div>
                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <DateRangePicker label="Date range picker" />
                      </div>
                      <div className="col-sm-12" style={{ marginBottom: 15 }}>
                        <Textarea rows={5} label="Message" name="text" placeholder="Text area" />
                      </div>
                      <div className="col-sm-3" style={{ marginBottom: 15 }}>
                        <Checkbox type="checkbox" name="country" id="checkbox" value="Country" />
                      </div>
                      <div className="col-sm-3" style={{ marginBottom: 15 }}>
                        <Checkbox type="checkbox" name="county" id="checkbox" value="County" />
                      </div>
                      <div className="col-sm-3" style={{ marginBottom: 15 }}>
                        <Toggle
                          type="checkbox"
                          name="isEnabled"
                          id="isEnabled"
                          label="Enabled"
                          onChange={(value: any) => {
                            const targetValue = value.target && value.target.value === "true" ? false : true;
                            setFieldValue(`isEnabled`, targetValue);
                          }}
                        />
                      </div>
                      <div className="col-sm-3" style={{ marginBottom: 15 }}>
                        <Toggle
                          type="checkbox"
                          name="isDesabled"
                          id="isEnabled"
                          label="Desabled"
                          onChange={(value: any) => {
                            const targetValue = value.target && value.target.value === "true" ? false : true;
                            setFieldValue(`isEnabled`, targetValue);
                          }}
                        />
                      </div>
                    </div>
                    <hr className={styles.line} />
                  </div>
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-6" style={{ marginBottom: 15 }}>
                        <h3>Loading</h3>
                        {2 + 2 === 5 && <LoadingData />}
                      </div>
                    </div>
                    <hr className={styles.line} />
                  </div>
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-12" style={{ marginBottom: 15 }}>
                        <h3>Pagination</h3>
                        <div>
                          <KeyValueTable
                            columns={[
                              {
                                title: "First",
                                style: styles.colour,
                                filterIcon: <FaFilter />,
                              },
                              {
                                title: "Last",
                                filterIcon: <FaFilter />,
                              },
                              {
                                title: "Age",
                                infoIcon: <FaQuestionCircle />,
                                filterIcon: <FaFilter />,
                              },
                            ]}
                            rows={
                              <tr>
                                {Countries.slice(pageVisited, pageVisited + countriesPage).map(
                                  (bodyList: any, index: number) => {
                                    return <td key={index}>{bodyList.label}</td>;
                                  }
                                )}
                              </tr>
                            }
                          />
                        </div>
                        <Pagination pageCount={pageCount} changePage={changePage} />
                      </div>
                    </div>
                    <hr className={styles.line} />
                  </div>
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-4" style={{ marginBottom: 15 }}>
                        <h3>Chart</h3>
                        <PieChart />
                      </div>
                    </div>
                    <hr className={styles.line} />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
