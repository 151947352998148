import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// style components

export interface ActionsState {
  // location
  renameLocation: boolean;
  changeEmail: boolean;
  sendPasswordReset: boolean;
  generateSetPassword: boolean;
  copyGenerateSetPassword: boolean;
  deactivateLocation: boolean;
  activateLocation?: boolean;
  link: string;
  timezone: boolean;
  devicesId: []; //todo -> should be deviceIds
  addLocation: boolean;
  deviceStatusFail: { status: boolean; devices: [] };
  freezeLocation: { status: boolean; message: string; isFrozen: boolean };

  // area
  renameArea?: boolean;
  streamingStatus?: boolean;
  location: { name: string; email: string; id: number };
  area: {
    name: string;
    id: number;
    tlgVersion: string;
    audioSetting: string;
    streaming: any;
    serial: string;
    schedule: any;
  };
  actionsErrorMessage: string;
  actionsSuccessMessage: string;
  actionsIsLoading: Boolean;
  deactivateArea: boolean;
  activateArea: boolean;
  removeDeviceFromArea: boolean;
  resendSchedule: boolean;
  assignDevice: boolean;
  actionRequired: boolean;
  actionRequiredInfo: {
    actionRequired: boolean;
    areaId: number;
    businessId: number;
    deviceId: string;
    deviceType: string;
    message: string;
    scheduleDeviceId: number;
  };
  updateSoftware: boolean;
  changeSchedule: boolean;
  viewSchedule: { status: boolean; timezoneName: string };
  viewCommercialInjectionRules: boolean;
  generateWebPlayer: { deviceCode: string; thingName: string };
  addArea: boolean;
  changeAudioSetting: boolean;

  // client
  renameClient: boolean;
  changeClientEmail: boolean;
  sendClientPasswordReset: boolean;
  generateClientSetPassword: boolean;
  copyGenerateClientSetPassword: boolean;
  linkClient: string;
  viewClientCommercialInjectionRules: boolean;
  addClient: boolean;
  editClient: boolean;
  activateClient: boolean;
  deactivateClient: boolean;
  clientDetails: { status: boolean; id: number; name: string };
  addBrief: boolean;
  editBrief: { status: boolean; id: number };
  freezeClient: boolean;
  clientId: number;

  // mini playyer
  switchPlaylist: boolean;
  switchPlaylistStatus: boolean;
  feedbackAction: boolean;
  sendFeedback: boolean;
  negativeFeedbackAction: boolean;
  playlistTrack: any;
  playlistSelectedTrack: any;
  skipTrack: boolean;
  deviceStatusShow: boolean;
  deviceStatus: string;

  // playlist
  addPlaylist: boolean;
  clonePlaylist: { status: boolean; playlistId: number };
  deletePlaylist: boolean;
  editPlaylist: { status: boolean; clone: boolean; playlistId: number; inUse: boolean; isPublished: boolean };
  playlistTitle: string;
  briefStatus: boolean;
  editFromOlympus: boolean;
  submissionError: { status: boolean; messages: [] };
  publishPlaylist: boolean;
  unpublishPlaylist: boolean;
  savePlaylist: { status: boolean; values: any };

  //user
  editUserDetails: boolean;
  addNewUser: boolean;
  ambieUser: boolean;
  resetUserPassword: boolean;
  editUserNameDetails: boolean;
  changeUserEmail: boolean;
  deactivateUser: boolean;
  activateUser: boolean;
  sendUserPasswordReset: boolean;
  generateSetUserPassword: boolean;
  user: { name: string; email: string; id: number };
  switchClient: { status: boolean; clientId: number };
}
const initialState: ActionsState = {
  //location
  renameLocation: false,
  changeEmail: false,
  sendPasswordReset: false,
  generateSetPassword: false,
  copyGenerateSetPassword: false,
  deactivateLocation: false,
  activateLocation: false,
  link: "",
  timezone: false,
  devicesId: [],
  addLocation: false,
  deviceStatusFail: { status: false, devices: [] },
  freezeLocation: { status: false, message: "", isFrozen: false },

  // area
  renameArea: false,
  streamingStatus: false,
  location: { name: "", email: "", id: 0 },
  area: { name: "", id: 0, tlgVersion: "", audioSetting: "", streaming: {}, serial: "", schedule: {} },
  actionsErrorMessage: "",
  actionsSuccessMessage: "",
  actionsIsLoading: false,
  deactivateArea: false,
  activateArea: false,
  removeDeviceFromArea: false,
  resendSchedule: false,
  assignDevice: false,
  actionRequired: false,
  actionRequiredInfo: {
    actionRequired: false,
    areaId: 0,
    businessId: 0,
    deviceId: "",
    deviceType: "",
    message: "",
    scheduleDeviceId: 0,
  },
  updateSoftware: false,
  changeSchedule: false,
  viewSchedule: { status: false, timezoneName: "" },
  viewCommercialInjectionRules: false,
  generateWebPlayer: { deviceCode: "", thingName: "" },
  addArea: false,
  changeAudioSetting: false,

  // client
  renameClient: false,
  changeClientEmail: false,
  sendClientPasswordReset: false,
  generateClientSetPassword: false,
  copyGenerateClientSetPassword: false,
  linkClient: "",
  viewClientCommercialInjectionRules: false,
  addClient: false,
  editClient: false,
  activateClient: false,
  deactivateClient: false,
  clientDetails: { status: false, id: 0, name: "" },
  addBrief: false,
  editBrief: { status: false, id: 0 },
  freezeClient: false,
  clientId: 0,

  // mini player
  switchPlaylist: false,
  switchPlaylistStatus: false,
  feedbackAction: false,
  sendFeedback: false,
  negativeFeedbackAction: false,
  playlistTrack: {},
  playlistSelectedTrack: {},
  skipTrack: false,
  deviceStatusShow: false,
  deviceStatus: "",

  // playlist
  addPlaylist: false,
  clonePlaylist: { status: false, playlistId: 0 },
  deletePlaylist: false,
  editPlaylist: { status: false, clone: false, playlistId: 0, inUse: false, isPublished: false },
  playlistTitle: "",
  briefStatus: false,
  editFromOlympus: false,
  submissionError: { status: false, messages: [] },
  publishPlaylist: false,
  unpublishPlaylist: false,
  savePlaylist: { status: false, values: "" },

  // user
  editUserDetails: false,
  addNewUser: false,
  ambieUser: false,
  resetUserPassword: false,
  editUserNameDetails: false,
  changeUserEmail: false,
  deactivateUser: false,
  activateUser: false,
  sendUserPasswordReset: false,
  generateSetUserPassword: false,
  user: { name: "", email: "", id: 0 },
  switchClient: { status: false, clientId: 0 },
};

const ActionsSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    // location actions
    showRenameLocationActionsSuccess: (state, action: PayloadAction<any>) => {
      state.renameLocation = action.payload.renameLocation;
      state.location = action.payload.location;
    },
    showChangeEmailActionsSuccess: (state, action: PayloadAction<any>) => {
      state.changeEmail = action.payload.changeEmail;
      state.location = action.payload.location;
    },
    showSendPasswordResetActionsSuccess: (state, action: PayloadAction<any>) => {
      state.sendPasswordReset = action.payload.sendPasswordReset;
      state.location = action.payload.location;
    },
    showGenerateSetPasswordActionsSuccess: (state, action: PayloadAction<any>) => {
      state.generateSetPassword = action.payload.generateSetPassword;
      state.location = action.payload.location;
    },
    showDeactivateLocationActionsSuccess: (state, action: PayloadAction<any>) => {
      state.deactivateLocation = action.payload.deactivateLocation;
      state.location = action.payload.location;
      state.devicesId = action.payload.devicesId;
    },
    showActivateLocationActionsSuccess: (state, action: PayloadAction<any>) => {
      state.activateLocation = action.payload.activateLocation;
      state.location = action.payload.location;
      state.devicesId = action.payload.devicesId;
    },
    showCopyGenerateSetPasswordActionsSuccess: (state, action: PayloadAction<any>) => {
      state.link = action.payload.link;
      state.copyGenerateSetPassword = true;
      state.generateSetPassword = false;
    },
    showTimezoneActionsSuccess: (state, action: PayloadAction<any>) => {
      state.timezone = action.payload.timezone;
      state.location = action.payload.location;
    },

    showAddLocationActionsSuccess: (state, action: PayloadAction<any>) => {
      state.addLocation = action.payload.addLocation;
    },

    showDeviceStatusFailActionsSuccess: (state, action: PayloadAction<any>) => {
      state.deviceStatusFail = action.payload.deviceStatusFail;
    },

    showFreezeLocationActionsSuccess: (state, action: PayloadAction<any>) => {
      state.freezeLocation = action.payload.freezeLocation;
      state.location = action.payload.location;
    },

    // area actions

    showRenameAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.renameArea = action.payload.renameArea;
      state.area = action.payload.area;
    },
    showAreaStreamingActionsSuccess: (state, action: PayloadAction<any>) => {
      state.streamingStatus = action.payload.streamingStatus;
      state.area = action.payload.area;
    },
    showDeactivateAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.deactivateArea = action.payload.deactivateArea;
      state.area = action.payload.area;
    },
    showActivateAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.activateArea = action.payload.activateArea;
      state.area = action.payload.area;
    },
    showRemoveDeviceFromAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.removeDeviceFromArea = action.payload.removeDeviceFromArea;
      state.area = action.payload.area;
    },
    showResendScheduleAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.resendSchedule = action.payload.resendSchedule;
      state.area = action.payload.area;
    },
    showChangeAudioSettingSuccess: (state, action: PayloadAction<any>) => {
      state.changeAudioSetting = action.payload.changeAudioSetting;
      state.area = action.payload.area;
    },

    showAssignDeviceAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.assignDevice = action.payload.assignDevice;
      state.area = action.payload.area;
      state.location = action.payload.location;
    },
    showActionRequiredDeviceAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.actionRequired = action.payload.actionRequired;
      state.actionRequiredInfo = action.payload.actionRequiredInfo;
    },
    showGenerateWebPlayerAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.generateWebPlayer = action.payload.generateWebPlayer;
    },
    showUpdateSoftwareAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.updateSoftware = action.payload.updateSoftware;
      state.area = action.payload.area;
    },
    showChangeScheduleAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.changeSchedule = action.payload.changeSchedule;
      state.area = action.payload.area;
      state.location = action.payload.location;
    },
    showViewScheduleAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.viewSchedule = action.payload.viewSchedule;
      state.area = action.payload.area;
    },
    showViewCommercialInjectionRulesAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.viewCommercialInjectionRules = action.payload.viewCommercialInjectionRules;
      state.area = action.payload.area;
    },

    showAddAreaActionsSuccess: (state, action: PayloadAction<any>) => {
      state.addArea = action.payload.addArea;
      state.location = action.payload.location;
    },

    // client actions
    showRenameClientActionsSuccess: (state, action: PayloadAction<any>) => {
      state.renameClient = action.payload.renameClient;
      state.clientDetails = action.payload.clientDetails;
    },

    showFreezeClientActionsSuccess: (state, action: PayloadAction<any>) => {
      state.freezeClient = action.payload.freezeClient;
    },

    clientIdActionsSuccess: (state, action: PayloadAction<any>) => {
      state.clientId = action.payload;
    },

    showChangeClientEmailActionsSuccess: (state, action: PayloadAction<any>) => {
      state.changeClientEmail = action.payload.changeClientEmail;
    },

    showSendClientPasswordResetActionsSuccess: (state, action: PayloadAction<any>) => {
      state.sendClientPasswordReset = action.payload.sendClientPasswordReset;
    },
    showGenerateClientSetPasswordActionsSuccess: (state, action: PayloadAction<any>) => {
      state.generateClientSetPassword = action.payload.generateClientSetPassword;
    },
    showCopyGenerateClientSetPasswordActionsSuccess: (state, action: PayloadAction<any>) => {
      state.linkClient = action.payload.linkClient;
      state.copyGenerateClientSetPassword = true;
      state.generateClientSetPassword = false;
    },

    showViewClientCommercialInjectionRulesSuccess: (state, action: PayloadAction<any>) => {
      state.viewClientCommercialInjectionRules = action.payload.viewClientCommercialInjectionRules;
    },
    showAddClientActionsSuccess: (state, action: PayloadAction<any>) => {
      state.addClient = action.payload.addClient;
    },

    showActivateClientActionsSuccess: (state, action: PayloadAction<any>) => {
      state.activateClient = action.payload.activateClient;
      state.clientDetails = action.payload.clientDetails;
    },
    showDeactivateClientActionsSuccess: (state, action: PayloadAction<any>) => {
      state.deactivateClient = action.payload.deactivateClient;
      state.clientDetails = action.payload.clientDetails;
    },

    showEditClientActionsSuccess: (state, action: PayloadAction<any>) => {
      state.editClient = action.payload.editClient;
      state.clientDetails = action.payload.clientDetails;
    },

    showAddBriefActionsSuccess: (state, action: PayloadAction<any>) => {
      state.addBrief = action.payload.addBrief;
    },
    showEditBriefActionsSuccess: (state, action: PayloadAction<any>) => {
      state.editBrief = action.payload.editBrief;
    },

    //message and loading
    showErrorMessageSuccess: (state, action: PayloadAction<any>) => {
      state.actionsErrorMessage = action.payload;
    },
    showSuccessMessageSuccess: (state, action: PayloadAction<any>) => {
      state.actionsSuccessMessage = action.payload;
    },
    isLoadingSuccess: (state, action: PayloadAction<any>) => {
      state.actionsIsLoading = action.payload;
    },

    // mini player
    showSwitchPlaylistSuccess: (state, action: PayloadAction<any>) => {
      state.switchPlaylist = action.payload.switchPlaylist;
      state.switchPlaylistStatus = action.payload.switchPlaylistStatus;
      state.area = action.payload.area;
    },
    showFeedbackActionSuccess: (state, action: PayloadAction<any>) => {
      state.feedbackAction = action.payload.feedbackAction;
      state.playlistTrack = action.payload.playlistTrack;
    },

    showSendFeedbackSuccess: (state, action: PayloadAction<any>) => {
      state.sendFeedback = action.payload.sendFeedback;
      state.feedbackAction = false;
      state.negativeFeedbackAction = false;
      state.playlistSelectedTrack = action.payload.playlistSelectedTrack;
    },

    showNegativeFeedbackActionSuccess: (state, action: PayloadAction<any>) => {
      state.negativeFeedbackAction = action.payload.negativeFeedbackAction;
      state.feedbackAction = false;
      state.playlistSelectedTrack = action.payload.playlistSelectedTrack;
    },
    showSkipTrackSuccess: (state, action: PayloadAction<any>) => {
      state.skipTrack = action.payload.skipTrack;
      state.area = action.payload.area;
    },

    showDeviceStatusSuccess: (state, action: PayloadAction<any>) => {
      state.deviceStatus = action.payload.deviceStatus;
      state.deviceStatusShow = action.payload.deviceStatusShow;
    },

    // playlist
    showAddPlaylistSuccess: (state, action: PayloadAction<any>) => {
      state.addPlaylist = action.payload.addPlaylist;
      state.playlistTitle = action.payload.playlistTitle;
    },
    showEditPlaylistSuccess: (state, action: PayloadAction<any>) => {
      state.editPlaylist = action.payload.editPlaylist;
      state.playlistTitle = action.payload.playlistTitle;
      state.briefStatus = action.payload.briefStatus;
      state.editFromOlympus = action.payload.editFromOlympus;
    },
    showClonePlaylistSuccess: (state, action: PayloadAction<any>) => {
      state.clonePlaylist = action.payload.clonePlaylist;
    },

    // not use
    showDeletePlaylistSuccess: (state, action: PayloadAction<any>) => {
      state.deletePlaylist = action.payload.deletePlaylist;
    },
    showSubmissionErrorSuccess: (state, action: PayloadAction<any>) => {
      state.submissionError = action.payload.submissionError;
    },

    showPublishPlaylistSuccess: (state, action: PayloadAction<any>) => {
      state.publishPlaylist = action.payload.publishPlaylist;
      state.editPlaylist = action.payload.editPlaylist;
    },
    showUnpublishPlaylistSuccess: (state, action: PayloadAction<any>) => {
      state.unpublishPlaylist = action.payload.unpublishPlaylist;
      state.editPlaylist = action.payload.editPlaylist;
    },
    showSavePlaylistSuccess: (state, action: PayloadAction<any>) => {
      state.savePlaylist = action.payload;
    },

    // user
    showEditUserActionsSuccess: (state, action: PayloadAction<any>) => {
      state.editUserDetails = action.payload.editUserDetails;
      state.user = action.payload.user;
      state.ambieUser = action.payload.ambieUser;
    },
    showAddNewUserActionsSuccess: (state, action: PayloadAction<any>) => {
      state.addNewUser = action.payload.addNewUser;
      state.ambieUser = action.payload.ambieUser;
    },
    showResetUserPasswordActionsSuccess: (state, action: PayloadAction<any>) => {
      state.resetUserPassword = action.payload.resetUserPassword;
      state.user = action.payload.user;
    },
    showEditUserNameActionsSuccess: (state, action: PayloadAction<any>) => {
      state.editUserNameDetails = action.payload.editUserNameDetails;
      state.user = action.payload.user;
    },
    showChangeUserEmailActionsSuccess: (state, action: PayloadAction<any>) => {
      state.changeUserEmail = action.payload.changeUserEmail;
      state.user = action.payload.user;
    },
    showDeactivateUserActionsSuccess: (state, action: PayloadAction<any>) => {
      state.deactivateUser = action.payload.deactivateUser;
      state.user = action.payload.user;
    },
    showActivateUserActionsSuccess: (state, action: PayloadAction<any>) => {
      state.activateUser = action.payload.activateUser;
      state.user = action.payload.user;
    },
    showSendUserPasswordResetActionsSuccess: (state, action: PayloadAction<any>) => {
      state.sendUserPasswordReset = action.payload.sendUserPasswordReset;
      state.user = action.payload.user;
    },
    showGenerateSetUserPasswordActionsSuccess: (state, action: PayloadAction<any>) => {
      state.generateSetUserPassword = action.payload.generateSetUserPassword;
      state.user = action.payload.user;
    },

    showSwitchClientActionsSuccess: (state, action: PayloadAction<any>) => {
      state.switchClient = action.payload.switchClient;
      state.user = action.payload.user;
    },

    // hide action
    hideActionsSuccess: (state) => {
      // location
      state.renameLocation = false;
      state.changeEmail = false;
      state.generateSetPassword = false;
      state.copyGenerateSetPassword = false;
      state.sendPasswordReset = false;
      state.deactivateLocation = false;
      state.activateLocation = false;
      state.link = "";
      state.timezone = false;
      state.devicesId = [];
      state.addLocation = false;
      state.deviceStatusFail = { status: false, devices: [] };
      state.freezeLocation = { status: false, message: "", isFrozen: false };

      // area
      state.renameArea = false;
      state.streamingStatus = false;
      state.location = { name: "", email: "", id: 0 };
      state.area = { name: "", id: 0, tlgVersion: "", audioSetting: "", streaming: {}, serial: "", schedule: {} };
      state.actionsIsLoading = false;
      state.actionsErrorMessage = "";
      state.actionsSuccessMessage = "";
      state.deactivateArea = false;
      state.activateArea = false;
      state.removeDeviceFromArea = false;
      state.resendSchedule = false;
      state.assignDevice = false;
      state.actionRequired = false;
      state.actionRequiredInfo = {
        actionRequired: false,
        areaId: 0,
        businessId: 0,
        deviceId: "",
        deviceType: "",
        message: "",
        scheduleDeviceId: 0,
      };
      state.updateSoftware = false;
      state.changeSchedule = false;
      state.viewSchedule = { status: false, timezoneName: "" };
      state.viewCommercialInjectionRules = false;
      state.generateWebPlayer = { deviceCode: "", thingName: "" };
      state.addArea = false;
      state.changeAudioSetting = false;

      // client
      state.renameClient = false;
      state.changeClientEmail = false;
      state.sendClientPasswordReset = false;
      state.generateClientSetPassword = false;
      state.copyGenerateClientSetPassword = false;
      state.linkClient = "";
      state.viewClientCommercialInjectionRules = false;
      state.addClient = false;
      state.editClient = false;
      state.activateClient = false;
      state.deactivateClient = false;
      state.clientDetails = { status: false, id: 0, name: "" };
      state.addBrief = false;
      state.editBrief = { status: false, id: 0 };
      state.freezeClient = false;
      state.clientId = 0;

      // mini player
      state.switchPlaylist = false;
      state.switchPlaylistStatus = false;
      state.feedbackAction = false;
      state.sendFeedback = false;
      state.negativeFeedbackAction = false;
      state.playlistTrack = {};
      state.playlistSelectedTrack = {};
      state.skipTrack = false;
      state.deviceStatusShow = false;
      state.deviceStatus = "";

      // playlist
      state.addPlaylist = false;
      state.playlistTitle = "";
      state.briefStatus = false;
      state.editFromOlympus = false;
      state.clonePlaylist = { status: false, playlistId: 0 };
      state.deletePlaylist = false;
      state.editPlaylist = { status: false, clone: false, playlistId: 0, inUse: false, isPublished: false };
      state.submissionError = { status: false, messages: [] };
      state.publishPlaylist = false;
      state.unpublishPlaylist = false;
      state.savePlaylist = { status: false, values: "" };

      // user
      state.editUserDetails = false;
      state.addNewUser = false;
      state.ambieUser = false;
      state.resetUserPassword = false;
      state.editUserNameDetails = false;
      state.changeUserEmail = false;
      state.deactivateUser = false;
      state.activateUser = false;
      state.sendUserPasswordReset = false;
      state.generateSetUserPassword = false;
      state.user = { name: "", email: "", id: 0 };
      state.switchClient = { status: false, clientId: 0 };
    },
  },
});

export const {
  showRenameLocationActionsSuccess,
  showChangeEmailActionsSuccess,
  showSendPasswordResetActionsSuccess,
  showGenerateSetPasswordActionsSuccess,
  showCopyGenerateSetPasswordActionsSuccess,
  showActivateLocationActionsSuccess,
  showDeactivateLocationActionsSuccess,
  showRenameAreaActionsSuccess,
  showAreaStreamingActionsSuccess,
  hideActionsSuccess,
  showErrorMessageSuccess,
  showSuccessMessageSuccess,
  isLoadingSuccess,
  showAddAreaActionsSuccess,

  // location area
  showDeactivateAreaActionsSuccess,
  showActivateAreaActionsSuccess,
  showRemoveDeviceFromAreaActionsSuccess,
  showResendScheduleAreaActionsSuccess,
  showAssignDeviceAreaActionsSuccess,
  showActionRequiredDeviceAreaActionsSuccess,
  showUpdateSoftwareAreaActionsSuccess,
  showViewCommercialInjectionRulesAreaActionsSuccess,
  showChangeScheduleAreaActionsSuccess,
  showViewScheduleAreaActionsSuccess,
  showGenerateWebPlayerAreaActionsSuccess,
  showTimezoneActionsSuccess,
  showAddLocationActionsSuccess,
  showChangeAudioSettingSuccess,
  showDeviceStatusFailActionsSuccess,
  showFreezeLocationActionsSuccess,

  // client
  showRenameClientActionsSuccess,
  showChangeClientEmailActionsSuccess,
  showSendClientPasswordResetActionsSuccess,
  showGenerateClientSetPasswordActionsSuccess,
  showCopyGenerateClientSetPasswordActionsSuccess,
  showViewClientCommercialInjectionRulesSuccess,
  showAddClientActionsSuccess,
  showActivateClientActionsSuccess,
  showDeactivateClientActionsSuccess,
  showEditClientActionsSuccess,
  showAddBriefActionsSuccess,
  showEditBriefActionsSuccess,
  showFreezeClientActionsSuccess,
  clientIdActionsSuccess,

  // mini player
  showSwitchPlaylistSuccess,
  showFeedbackActionSuccess,
  showSendFeedbackSuccess,
  showNegativeFeedbackActionSuccess,
  showSkipTrackSuccess,
  showDeviceStatusSuccess,

  // playlist
  showAddPlaylistSuccess,
  showEditPlaylistSuccess,
  showClonePlaylistSuccess,
  showDeletePlaylistSuccess,
  showSubmissionErrorSuccess,
  showPublishPlaylistSuccess,
  showUnpublishPlaylistSuccess,
  showSavePlaylistSuccess,

  // user
  showEditUserActionsSuccess,
  showAddNewUserActionsSuccess,
  showResetUserPasswordActionsSuccess,
  showEditUserNameActionsSuccess,
  showChangeUserEmailActionsSuccess,
  showDeactivateUserActionsSuccess,
  showActivateUserActionsSuccess,
  showSendUserPasswordResetActionsSuccess,
  showGenerateSetUserPasswordActionsSuccess,
  showSwitchClientActionsSuccess,
} = ActionsSlice.actions;
export default ActionsSlice.reducer;
