import { useSelector } from "react-redux";

// style components
import { LoadingData } from "../../../../components/loading";
import { GlobalModal } from "../../../../components/modal";
import { RootState } from "../../../../redux-toolkit/store";
import { GlobalErrorMessage } from "../../../users/components/errorMessage";
import styles from "../../../locations/components/location-actions/location-actions.module.scss";

interface BriefActionsProps {
  size?: "sm" | "lg" | "xl";
  footer?: any;
  title?: string;
  show: boolean;
  status: string;
  handleClose: any;
  children?: any;
}

/**
 * Toggle admin approval status
 */
export const ToggleAdminApproval = ({ footer, show, handleClose, status }: BriefActionsProps) => {
  const clientHubSlice = useSelector((state: RootState) => state.clientHubSlice);

  return (
    <GlobalModal
      title={`${status && status.charAt(0).toUpperCase() + status.slice(1)} admin approval`}
      show={show}
      handleClose={handleClose}
      footer={footer}
    >
      <div className={styles.modal}>
        <p>Are you sure you want to {status} admin approval?</p>
        <div>
          {clientHubSlice.adminApprovalIsError && <GlobalErrorMessage message={clientHubSlice.adminApprovalIsError} />}
        </div>
        {clientHubSlice.adminApprovalIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};
