import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classnames from "classnames";

import styles from "./header.module.scss";
import icon from "../../assets/favicon.png";
import appleTouchIcon from "../../assets/logo192.png";
interface HeaderPageProps {
  title?: string;
  content?: string;
  href?: any;
  children: any;
}
export function HeaderPage({ title, content, href, children }: HeaderPageProps) {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title key="title">{title ? `${title}` : "Admin Portal"}</title>
          <meta name="description" content={content ? content : "Admin Portal"} />
          <link rel="canonical" href={href ? href : "/"} />
          <link rel="icon" href={icon} />
          <link rel="apple-touch-icon" href={appleTouchIcon} />
        </Helmet>
      </HelmetProvider>
      <main className={classnames(styles.page)}>{children}</main>
    </>
  );
}
