// import { differenceInMilliseconds } from "date-fns";
import axiosInterceptors from "../lib/axiosInterceptors";
import { getEnvironment } from "../config/config";
import { differenceInMilliseconds } from "date-fns";

/**
 * Get device lists
 */
export async function getDeviceStatusList() {
  const response = await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/devices`, {});
  const areas = response.data && response.data;

  let playerRecords: any = [];
  const areasStatus: any = [
    {
      message: "Display the total areas and group them by active and inactive status",
      name: "Area status",
      info: true,
      value: (areas && areas.length) || 0,
      status: "Total",
    },
    {
      info: false,
      value: 0,
      colour: "text-success",
      status: "Active",
    },
    {
      info: false,
      value: 0,
      colour: "text-danger",
      status: "Inactive",
    },
  ];
  const playerStatus: any = [
    {
      message: "Show player status based on (online/offline & playing/paused)",
      name: "Player status",
      info: true,
      value: 0,
      status: "Total",
      colour: "text-secondary",
    },
    {
      info: false,
      value: 0,
      status: "Online",
      colour: "text-success",
    },
    {
      info: false,
      value: 0,
      status: "Offline",
      colour: "text-danger",
    },
    {
      info: false,
      value: 0,
      status: "Playing",
      colour: "text-success",
    },
    {
      info: false,
      value: 0,
      status: "Paused",
      colour: "text-secondary",
    },
    {
      info: false,
      value: 0,
      status: "Not Playing",
      colour: "text-danger",
    },
  ];

  areas.forEach(
    (area: {
      player_id: string;
      last_heart_beat: number;
      area: any;
      is_active: boolean;
      last_played: number;
      duration: number;
      player_status: string;
    }) => {
      // const deviceHasFields = area.player_id && area.last_heart_beat;
      const deviceHasFields = area.player_id !== "No Player"; //What is the point of this if this is either a player serial or "No Player"
      const onlinePlayers = deviceHasFields && differenceInMilliseconds(Date.now(), area.last_heart_beat) / 60000 < 10;

      // get players status based on playing, paused and not playing
      const deviceHasLastPlayedFields = area.is_active && area.last_played && area.duration && area.player_id;

      // count players status based on Online, Offline No-player and  Inactive
      if (onlinePlayers) {
        playerStatus[1].value = playerStatus[1].value += 1;
      } else if (deviceHasFields && differenceInMilliseconds(Date.now(), area.last_heart_beat) / 60000 > 10) {
        playerStatus[2].value = playerStatus[2].value += 1;
      }

      if (area.player_id) {
        playerStatus[0].value = playerStatus[0].value += 1;
      }

      // get players status based on playing, paused and not playing
      if (
        deviceHasLastPlayedFields &&
        area.player_status === "play" &&
        onlinePlayers &&
        differenceInMilliseconds(Date.now(), area.last_played) / 1000 < area.duration
      ) {
        playerStatus[3].value = playerStatus[3].value += 1;
      } else if (deviceHasLastPlayedFields && onlinePlayers && area.player_status === "pause") {
        playerStatus[4].value = playerStatus[4].value += 1;
      } else {
        playerStatus[5].value = playerStatus[5].value += 1;
      }

      // get areas status based on active or inactive
      if (area.is_active) {
        areasStatus[1].value = areasStatus[1].value += 1;
      } else {
        areasStatus[2].value = areasStatus[2].value += 1;
      }

      playerRecords.push(area);
    }
  );

  return { response, playerRecords, areasStatus, playerStatus };
}

/**
 * Update device software
 */
export async function updateDeviceSoftwareVersion(deviceId: string) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/devices/update-software`, { deviceId });
}

/**
 * Update device status
 */
export async function updatePlayersWithoutStatus(deviceIds: []) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/devices/update-status`, deviceIds);
}

/**
 * Generate a web player id
 */
export const generateWebPlayerThing = async () => {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/devices/things/web-players`, {});
};
