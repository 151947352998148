import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import Cookies from "js-cookie";
import { getEnvironment } from "../config/config";

// style components
import {
  DEFAULT_TIMEOUT_AXIOS_INTERCEPTOR,
  LOCAL_STORAGE_USER_REFRESH_TOKEN,
  LOCAL_STORAGE_USER_TOKEN,
} from "../constants/defaultValues";

import { logoutUser } from "../services/usersServices";

const requestHandler: AxiosInstance = axios.create({
  baseURL: "",
  timeout: DEFAULT_TIMEOUT_AXIOS_INTERCEPTOR,
  responseType: "json",
});

const refreshAuthLogic = (failedRequest: any) => {
  console.log("TOKEN EXPIRED -> ASKING FOR REFRESH");
  const refreshToken: any = Cookies.get(LOCAL_STORAGE_USER_REFRESH_TOKEN);
  return axios
    .post(`${getEnvironment().HOST_ROCK_API}/auth/refresh-token`, { refreshToken })
    .then((tokenRefreshResponse) => {
      console.log("NEW ACCESSTOKEN ARRIVED, ITS STILL WARM");
      Cookies.set(LOCAL_STORAGE_USER_TOKEN, tokenRefreshResponse.data.accessToken, {});
      failedRequest.response.config.headers["Authorization"] = "Bearer " + tokenRefreshResponse.data.accesToken;
      return Promise.resolve();
    })
    .catch(() => logoutUser());
};

/*
 Instantiate the interceptor
 Additional options - AxiosAuthRefreshOptions - to change the default behaviour, can be passed
 to the `createAuthRefreshInterceptor` function -> docs in https://www.npmjs.com/package/axios-auth-refresh
*/
createAuthRefreshInterceptor(requestHandler, refreshAuthLogic);

requestHandler.interceptors.request.use(
  async function (config: any) {
    const token: string | undefined = Cookies.get(LOCAL_STORAGE_USER_TOKEN);
    config.headers["x-ambie-token"] = token;
    config.headers["Authorization"] = "Bearer " + token;
    config.headers["Content-Type"] = "application/json";
    config.headers["application"] = "Ambie Admin Portal";

    return config;
  },

  async function (error: string) {
    return await Promise.reject(error);
  }
);

export const appendArgsToUrl = (url: string, queryParams: any) => {
  const queryString: any = [];
  Object.keys(queryParams).forEach(function (key) {
    if (queryParams[key] !== null) {
      queryString.push(`${key}=${encodeURIComponent(queryParams[key])}`);
    }
  });
  if (!queryString.length) {
    return url;
  }
  return `${url}?${queryString.join("&")}`;
};

function post(url: string, data: {}, config?: AxiosRequestConfig<{}> | undefined) {
  return requestHandler.post(url, data, config);
}
function get(url: string, parameter: {}, config?: AxiosRequestConfig<{}> | undefined) {
  const finalUrl = appendArgsToUrl(url, parameter);
  return requestHandler.get(finalUrl, config);
}

function put(url: string, data: {}, config?: AxiosRequestConfig<{}> | undefined) {
  return requestHandler.put(url, data, config);
}

function remove(url: string, config?: AxiosRequestConfig<{}> | any) {
  return requestHandler.delete(url, config);
}

function patch(url: string, data: {}, config?: AxiosRequestConfig<{}> | undefined) {
  return requestHandler.patch(url, data, config);
}

export default Object.freeze({
  get,
  post,
  put,
  remove,
  patch,
});
