// import { differenceInMilliseconds } from "date-fns";
import axiosInterceptors from "../lib/axiosInterceptors";
import { getEnvironment } from "../config/config";
import { store } from "../redux-toolkit/store";
import { feedbackSuccess } from "../redux-toolkit/reducers/clientHubSlice";
import { isLoadingSuccess } from "../redux-toolkit/reducers/actionsSlice";
interface SendFeedbackProps {
  deviceId: string;
  track: string;
  artist: string;
  playlist: string;
  playlist_id: number;
  type: string;
  message: string;
}

/**
 * Get feedback
 */
export async function getFeedback(clientId: number) {
  store.dispatch(isLoadingSuccess(true));
  const response = await axiosInterceptors.get(
    `${getEnvironment()?.HOST_ROCK_API}/clients/feedback/ambie/user/${clientId}`,
    {}
  );

  if (response.data && response.data.feedback) {
    store.dispatch(feedbackSuccess({ feedback: response.data.feedback }));
  }

  store.dispatch(isLoadingSuccess(false));

  return response;
}

/**
 * Send feedback
 */
export async function sendFeedback(report: SendFeedbackProps) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/clients/feedback`, report);
}
