import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";

// style components
import { RootState } from "../../redux-toolkit/store";
import { KeyValueCard, RowCard } from "../../components/cards";
import styles from "./schedules-migration.module.scss";

import { GlobalButton } from "../../components/button";
import { LoadingData } from "../../components/loading";

import { BsCheck2, BsDot } from "react-icons/bs";
import {
  assignSYBSchedule,
  getSchedules,
  migrateActiveSchedules,
  migrateAllSchedules,
  migrateSchedule,
} from "../../services/schedulesServices";
import Select from "react-select";
import { isLoadingSuccess, showErrorMessageSuccess } from "../../redux-toolkit/reducers/actionsSlice";
import { GlobalModal } from "../../components/modal";
import { GlobalErrorMessage } from "../users/components/errorMessage";

export default function SchedulesMigration() {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const [schedules, setSchedules] = useState([]);
  const [migrationsFilter, setMigrationsFilter] = useState("all");
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let isSubscribed = true;

    const getSchedulesData = () => {
      getSchedules(searchClient.clientId)
        .then((response) => {
          if (isSubscribed) setSchedules(response.response?.data);
        })
        .catch((error) => {});
    };

    getSchedulesData();

    return () => {
      isSubscribed = false;
    };
  }, [searchClient.clientId]);

  const getSchedulesList = () => {
    getSchedules(searchClient.clientId)
      .then((response) => {
        setSchedules(response.response?.data);
      })
      .catch((error) => {});
  };

  const migrateAllSchedulesData = () => {
    dispatch(isLoadingSuccess(true));
    migrateAllSchedules(searchClient.clientId)
      .then((response) => {
        setSchedules(response.data);
        dispatch(isLoadingSuccess(false));
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        setShowError(true);
        dispatch(
          showErrorMessageSuccess(
            `${error?.response?.data?.message || "client not found in sound track your brand database"}`
          )
        );
        getSchedulesList();
      });
  };

  const migrateActiveSchedulesData = () => {
    dispatch(isLoadingSuccess(true));
    migrateActiveSchedules(searchClient.clientId)
      .then((response) => {
        setSchedules(response.data);
        dispatch(isLoadingSuccess(false));
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        setShowError(true);
        dispatch(
          showErrorMessageSuccess(
            `${error?.response?.data?.message || "client not found in sound track your brand database"}`
          )
        );
        getSchedulesList();
      });
  };

  const migrateScheduleData = (scheduleId: string) => {
    dispatch(isLoadingSuccess(true));
    migrateSchedule(searchClient.clientId, scheduleId)
      .then((response) => {
        setSchedules(response.data.schedule);
        dispatch(isLoadingSuccess(false));
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        setShowError(true);
        dispatch(
          showErrorMessageSuccess(
            `${error?.response?.data?.message || "client not found in sound track your brand database"}`
          )
        );
        getSchedulesList();
      });
  };

  const assignMigrateScheduleData = (scheduleId: string) => {
    dispatch(isLoadingSuccess(true));
    assignSYBSchedule(searchClient.clientId, scheduleId)
      .then((response) => {
        setSchedules(response.data);
        dispatch(isLoadingSuccess(false));
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        setShowError(true);
        dispatch(showErrorMessageSuccess(`${error?.response?.data?.message || "assign was not successful"}`));
        getSchedulesList();
      });
  };

  const assignAllMigrateScheduleData = () => {
    dispatch(isLoadingSuccess(true));
    migrateActiveSchedules(searchClient.clientId)
      .then((response) => {
        setSchedules(response.data);
        dispatch(isLoadingSuccess(false));
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        setShowError(true);
        dispatch(
          showErrorMessageSuccess(
            `${error?.response?.data?.message || "client not found in sound track your brand database"}`
          )
        );
        getSchedulesList();
      });
  };

  const filterMigrations = [
    {
      label: "All Migrations",
      value: "all",
    },
    {
      label: "Migrated",
      value: "migrated",
    },
    {
      label: "Not Migrated",
      value: "not-migrated",
    },
    {
      label: "Failed Migration",
      value: "failed-migration",
    },
    {
      label: "Assigned Schedule",
      value: "assigned-schedule",
    },
  ];

  const isScheduleMigrations = schedules?.some((schedule: any) => !schedule?.migrationSuccess);

  return (
    <div className="content container py-3">
      {showError && (
        <div>
          <GlobalModal
            title="Schedule migration error"
            show={showError}
            handleClose={() => {
              dispatch(showErrorMessageSuccess(""));
              setShowError(false);
            }}
          >
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
            </div>
          </GlobalModal>
        </div>
      )}
      <div className="page-header" style={{ borderBottom: "none", paddingBottom: 0, marginBottom: "1rem" }}>
        {actions.actionsIsLoading && <LoadingData />}

        <Formik enableReinitialize initialValues={{}} onSubmit={() => {}}>
          {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => {
            return (
              <Form>
                <div style={{ marginBottom: 20 }}>
                  <KeyValueCard
                    title="Schedule Migrations"
                    filter={
                      <div className="d-flex align-items-center">
                        <div style={{ width: "180px", marginRight: "10px" }}>
                          <Select
                            id="permissions"
                            className="flex-grow-4 time-select channel-list"
                            options={filterMigrations}
                            value={filterMigrations.find((c: any) => c.value === migrationsFilter)}
                            onChange={(selected) => selected && selected.value && setMigrationsFilter(selected.value)}
                          />
                        </div>
                        {/* <div style={{ marginRight: "20px" }}>
                          <GlobalButton
                            format="info"
                            size="sm"
                            onClick={() => {
                              assignAllMigrateScheduleData();
                            }}
                          >
                            Assign Migrated
                          </GlobalButton>
                        </div> */}
                        <div style={{ marginRight: "20px" }}>
                          <GlobalButton
                            format="success"
                            size="sm"
                            disabled={!isScheduleMigrations}
                            onClick={() => {
                              migrateActiveSchedulesData();
                            }}
                          >
                            Migrate Active
                          </GlobalButton>
                        </div>
                        <div>
                          <GlobalButton
                            format="secondary"
                            size="sm"
                            disabled={!isScheduleMigrations}
                            onClick={() => {
                              migrateAllSchedulesData();
                            }}
                          >
                            Migrate All
                          </GlobalButton>
                        </div>
                      </div>
                    }
                  >
                    <div>
                      {schedules
                        ?.filter((migration: any) => {
                          let migrationPass = true;
                          if (migrationsFilter === "all") {
                            migrationPass = true;
                          } else if (migrationsFilter === "migrated") {
                            migrationPass = migration.migrationSuccess;
                          } else if (migrationsFilter === "not-migrated") {
                            migrationPass = migration.migrationSuccess === null;
                          } else if (migrationsFilter === "failed-migration") {
                            migrationPass = migration.migrationSuccess !== null && !migration.migrationSuccess;
                          } else if (migrationsFilter === "assigned-schedule") {
                            migrationPass = migration.isAssigned;
                          }

                          return migrationPass;
                        })
                        .map((schedule: any, index: number) => {
                          const migrationSuccessDetail = () => {
                            if (schedule.migrationSuccess === null) {
                              return <p className={styles.notMigrated}>Not migrated</p>;
                            } else if (schedule.migrationSuccess) {
                              return <p className={styles.migrationSuccess}>Migration successful</p>;
                            } else {
                              return (
                                <div>
                                  <p className={styles.migrationFailed}>Migration failed</p>
                                  <div>
                                    {schedule?.errors?.map((error: any, index: number) => {
                                      return (
                                        <div key={index} style={{ marginBottom: 20 }}>
                                          <BsCheck2 /> <span>{error.msg}</span>
                                          <div className={styles.slots}>
                                            {error?.slots?.map((slot: any) => {
                                              return (
                                                <div>
                                                  <BsDot /> <span>{slot}</span>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            }
                          };
                          return (
                            <div key={index}>
                              <RowCard>
                                <div className="row">
                                  <div className="col-sm-3">
                                    <p>{schedule.name}</p>
                                  </div>
                                  <div className="col-sm-4">
                                    <span style={{ marginRight: 10 }}>
                                      <GlobalButton
                                        size="xs"
                                        format="secondary"
                                        disabled={schedule.migrationSuccess}
                                        onClick={() => migrateScheduleData(schedule.id)}
                                      >
                                        Migrate
                                      </GlobalButton>
                                    </span>
                                    {schedule.migrationSuccess && schedule.isActive && (
                                      <span style={{ marginRight: 10 }}>
                                        {schedule.isAssigned ? (
                                          <GlobalButton size="xs" format="info" disabled={schedule.migrationSuccess}>
                                            Assigned
                                          </GlobalButton>
                                        ) : (
                                          <GlobalButton
                                            size="xs"
                                            format="info"
                                            onClick={() => assignMigrateScheduleData(schedule.id)}
                                          >
                                            Assign
                                          </GlobalButton>
                                        )}
                                      </span>
                                    )}
                                    <span>
                                      {schedule.isActive && (
                                        <GlobalButton size="xs" format="success" disabled>
                                          Active
                                        </GlobalButton>
                                      )}
                                    </span>
                                  </div>
                                  <div className="col-sm-5">
                                    <div className="">{migrationSuccessDetail()}</div>
                                  </div>
                                </div>
                              </RowCard>
                            </div>
                          );
                        })}
                    </div>
                  </KeyValueCard>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
