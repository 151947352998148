import axiosInterceptors from "../lib/axiosInterceptors";
import { getEnvironment } from "../config/config";

/**
 * Get commercial rules
 */
export async function getCommercialRules(type: "area" | "playlist" | "client", clientIdOrAreaIdOrPlaylistId: number) {
  return await axiosInterceptors.get(
    `${getEnvironment()?.HOST_ROCK_API}/clients/commercial/rule/${type}/${clientIdOrAreaIdOrPlaylistId}`,
    {}
  );
}

/**
 * Add or Update commercial rules
 */
export async function postCommercialRule(
  type: "area" | "playlist" | "client",
  clientIdOrAreaIdOrPlaylistId: number,
  rule: any
) {
  return await axiosInterceptors.post(
    `${getEnvironment()?.HOST_ROCK_API}/clients/commercial/rule/${type}/${clientIdOrAreaIdOrPlaylistId}`,
    rule
  );
}
