import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_USER_TOKEN } from "../../constants/defaultValues";
import { dashboard_page_navigate, login_page } from "../../constants/routePath";
import { RootState } from "../../redux-toolkit/store";

export const NotFound = () => {
  const tokenReducer = useSelector((state: RootState) => state.authSlice.token);
  const [token] = useState(tokenReducer || Cookies.get(LOCAL_STORAGE_USER_TOKEN));
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate(dashboard_page_navigate);
    } else {
      navigate(login_page);
    }
  }, [token]);

  return null;
};
