export const BulletType = ({ type }: { type: string }) => {
  const getFeedbackType = () => {
    switch (type && type.toString().toLowerCase()) {
      case "positive":
        return <span className="legend-indicator" style={{ backgroundColor: "#04c9a6" }}></span>;
      case "too fast":
        return <span className="legend-indicator" style={{ backgroundColor: "#ed4c78" }}></span>;
      case "too slow":
        return <span className="legend-indicator" style={{ backgroundColor: "#ed4c78" }}></span>;
      case "bad language":
        return <span className="legend-indicator" style={{ backgroundColor: "#71869d" }}></span>;
      case "poor quality":
        return <span className="legend-indicator" style={{ backgroundColor: "#FFA500" }}></span>;
      case "annoying":
        return <span className="legend-indicator" style={{ backgroundColor: "#0000ff" }}></span>;
      case "overplayed":
        return <span className="legend-indicator" style={{ backgroundColor: "#FFFF00" }}></span>;
      default:
        return <span className="legend-indicator" style={{ backgroundColor: "#ebe0ff" }}></span>;
    }
  };

  return <>{getFeedbackType()}</>;
};
