import { differenceInMilliseconds } from "date-fns";
import axiosInterceptors from "../lib/axiosInterceptors";
import { getEnvironment } from "../config/config";
import { store } from "../redux-toolkit/store";
import {
  locationsAreasStatusSuccess,
  locationsSuccess,
  playerStatusSuccess,
  playerStatusWithLocationsSuccess,
} from "../redux-toolkit/reducers/locationsSlice";
import {
  hideActionsSuccess,
  isLoadingSuccess,
  showActionRequiredDeviceAreaActionsSuccess,
  showErrorMessageSuccess,
} from "../redux-toolkit/reducers/actionsSlice";
import { ACTIONS_ERROR_MESSAGE } from "../constants/globalText";

/**
 * Get player status
 */
export async function getClientLocationsWithAreas(
  client: { clientId: number },
  loading: boolean = false,
  fromSchedule: boolean = false
) {
  if (!fromSchedule) store.dispatch(isLoadingSuccess(loading));
  const response = await axiosInterceptors.get(
    `${getEnvironment()?.HOST_ROCK_API}/locations/get-client-locations-areas-devices/${client.clientId}`,
    {}
  );
  let players: any = [];
  const activeLocations =
    (response.data && response.data.length && response.data.filter((active: any) => active.is_active)) || [];

  let playerStatus: any = [
    {
      name: "Player status",
      info: false,
      value: 0,
      status: "Online",
      colour: "text-success",
    },
    {
      info: false,
      value: 0,
      status: "Offline",
      colour: "text-danger",
    },
    {
      info: false,
      value: 0,
      status: "Inactive",
      colour: "text-secondary",
    },
    {
      info: false,
      value: 0,
      status: "No Player",
      colour: "text-secondary",
    },
  ];

  let playerStatusWithLocations: any = [
    {
      info: false,
      value: 0,
      status: "Players Online",
      colour: "text-success",
    },
    {
      info: false,
      value: 0,
      status: "Players Offline",
      colour: "text-danger",
    },
    {
      info: false,
      value: 0,
      status: "Players Inactive",
      colour: "text-secondary",
    },
    {
      info: false,
      value: 0,
      status: "No Players",
      colour: "text-secondary",
    },
  ];

  let locationsAreasStatus: any = [
    {
      info: false,
      value: activeLocations.length || 0,
      status: "Locations",
      colour: "text-secondary",
    },
    {
      info: false,
      value: 0,
      status: "Areas",
      colour: "text-secondary",
    },
  ];

  // get the list of areas array into players array variable
  if (activeLocations) {
    for (let { areas } of activeLocations) {
      if (areas.length) {
        players.push(areas);
        locationsAreasStatus[1].value = locationsAreasStatus[1].value += areas.length;
      }
    }

    // combine all sub arrays into one array
    players.flat().forEach((area: { is_active: boolean; device: { serial: string; last_seen: number } }) => {
      const deviceHasFields = area.is_active && area.device.serial && area.device.last_seen;

      // count players status based on Online, Offline and  Inactive
      if (deviceHasFields && differenceInMilliseconds(Date.now(), area.device.last_seen) / 60000 < 10) {
        playerStatus[0].value = playerStatus[0].value += 1;
        playerStatusWithLocations[0].value = playerStatusWithLocations[0].value += 1;
      } else if (deviceHasFields && differenceInMilliseconds(Date.now(), area.device.last_seen) / 60000 > 10) {
        playerStatus[1].value = playerStatus[1].value += 1;
        playerStatusWithLocations[1].value = playerStatusWithLocations[1].value += 1;
      } else if (!area.is_active && area.device.serial) {
        playerStatus[2].value = playerStatus[2].value += 1;
        playerStatusWithLocations[2].value = playerStatusWithLocations[2].value += 1;
      } else if ((area.device && !area.device.serial) || !area.device) {
        playerStatus[3].value = playerStatus[3].value += 1;
        playerStatusWithLocations[3].value = playerStatusWithLocations[3].value += 1;
      }
    });
  }
  const locations = response.data;
  const clientLocationsIds = await locations.map((location: any) => location.id);

  if (activeLocations) {
    store.dispatch(locationsSuccess({ locations: locations || [] }));
    store.dispatch(playerStatusSuccess({ playerStatus: playerStatus || [] }));
    store.dispatch(
      playerStatusWithLocationsSuccess({
        playerStatusWithLocations: playerStatusWithLocations || [],
      })
    );
    store.dispatch(
      locationsAreasStatusSuccess({
        locationsAreasStatus: locationsAreasStatus || [],
      })
    );
  }

  if (!fromSchedule) store.dispatch(isLoadingSuccess(false));

  return { response, locations, clientLocationsIds, playerStatus, playerStatusWithLocations, locationsAreasStatus };
}

/**
 * update location record
 */
export async function updateLocationRecord(locationId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/locations/${locationId}`, data);
}

/**
 * deactivate location
 */
export async function deactivateLocation(locationId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/locations/deactivate/${locationId}`, data);
}

/**
 * freeze location
 */
export async function freezeLocation(locationId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/locations/freeze/${locationId}`, data);
}

/**
 * activate location
 */
export async function activateLocation(locationId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/locations/activate/${locationId}`, data);
}

/**
 * Get location
 */
export async function getLocation(locationId: number) {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/locations/${locationId}`, {});
}

/**
 * Get location
 */
export async function getLocations() {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/locations`, {});
}

/**
 * Get client location
 */
export async function getClientLocations(clientId: number) {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/locations/client/${clientId}`, {});
}

/**
 * Get client location
 */
export async function getClientActiveLocations(clientId: number) {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/locations/client/active/${clientId}`, {});
}

/**
 * update location record
 */
export async function updateAreaRecord(areaId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/areas/${areaId}`, data);
}

/**
 *  Enable stream
 */
export async function enableStreaming(areaId: number) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/areas/streaming/${areaId}/enable`, {});
}

/**
 *  Disable stream
 */
export async function disableStreaming(areaId: number) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/areas/streaming/${areaId}/disable`, {});
}

/**
 * Remove device from area
 */
export async function removeDeviceFromArea(deviceId: string) {
  await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/areas/remove-area-from-device/${deviceId}`, {});
}

/**
 * Assign a device to an area
 */
export async function changeDevice(changeDeviceRecord: {
  areaId: number;
  businessId: number;
  deviceId: string;
  deviceType: string;
  actionRequired?: boolean;
  scheduleDeviceId?: number;
}) {
  store.dispatch(isLoadingSuccess(true));
  return await axiosInterceptors
    .post(`${getEnvironment()?.HOST_ROCK_API}/areas/add-device`, changeDeviceRecord)
    .then((response) => {
      store.dispatch(hideActionsSuccess());
      if (response.data && response.data.actionRequired) {
        const data = response.data;
        store.dispatch(
          showActionRequiredDeviceAreaActionsSuccess({
            actionRequired: true,
            actionRequiredInfo: {
              actionRequired: true,
              areaId: data.areaId,
              businessId: data.businessId,
              deviceId: data.deviceId,
              deviceType: data.deviceType,
              message: data.message,
              scheduleDeviceId: data.scheduleDeviceId,
            },
          })
        );
      }
    })
    .catch((error) => {
      store.dispatch(isLoadingSuccess(false));
      store.dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      throw new Error(error);
    });
}

/**
 * override playlist
 */
export async function sendOverride(
  deviceId: string,
  overrideRecord: { areaId: number; playlistId: number; tlgVersion: string; time: number }
) {
  return await axiosInterceptors.post(
    `${getEnvironment()?.HOST_ROCK_API}/devices/override/${deviceId}`,
    overrideRecord
  );
}

/**
 * Revert playlist overrride
 */
export async function revertOverride(deviceId: string, overrideRecord: { areaId: number; tlgVersion: string }) {
  return await axiosInterceptors.post(
    `${getEnvironment()?.HOST_ROCK_API}/devices/revert-override/${deviceId}`,
    overrideRecord
  );
}

/**
 * Skip track
 */
export async function updatePlayerSkip(deviceId: string) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/devices/skip-track/${deviceId}`, {});
}

/**
 * Update play status
 */
export async function updatePlayerStatus(
  deviceId: string,
  newStatus: {
    status: string;
    volume: number;
  }
) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/devices/update-status/${deviceId}`, {
    status: newStatus.status,
    volume: newStatus.volume,
  });
}

/**
 * Add new area
 */
export async function addArea(data: any) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/areas`, data);
}

/**
 * Add new area
 */
export async function addLocation(data: any) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/locations`, data);
}
