import { ambie_page, client_page, tools_page } from "../../../constants/routePath";
export const menuItems = [
  {
    title: "Ambie Hub",
    routePath: `${ambie_page}`,
  },
  {
    title: "Client Hub",
    routePath: `${client_page}`,
  },
  {
    title: "Tools",
    routePath: `${tools_page}`,
    // submenu: [
    //   {
    //     title: "Device Status ",
    //     routePath: `${devices_tools_page_navigate}`,
    //   },
    //   // {
    //   //   title: "Tracks ",
    //   //   routePath: `${schedules_page}`,
    //   // },
    //   // {
    //   //   title: "Track Uploader",
    //   //   routePath: `${schedules_page}`,
    //   // },
    //   // {
    //   //   title: "Tracks not in Use",
    //   //   routePath: `${schedules_page}`,
    //   // },
    //   // {
    //   //   title: "Artists",
    //   //   routePath: `${schedules_page}`,
    //   // },
    // ],
  },
  // {
  //   title: "Content Management",
  //   routePath: `${content_management_page}`,
  //   submenu: [
  //     // {
  //     //   title: "Olympus User Management",
  //     //   routePath: `${schedules_page}`,
  //     // },
  //     {
  //       title: "Device Status",
  //       routePath: `${devices_tools_page_navigate}`,
  //       // submenu: [
  //       //   {
  //       //     title: "Frontend",
  //       //     routePath: `${schedules_page}`,
  //       //   },
  //       //   {
  //       //     title: "Backend",
  //       //     routePath: `${schedules_page}`,
  //       //   },
  //       // ],
  //     },
  //     {
  //       title: "PPL Reporting",
  //       routePath: `${schedules_page}`,
  //     },
  //     {
  //       title: "Commercial Rules",
  //       routePath: `${schedules_page}`,
  //       submenu: [
  //         {
  //           title: "Commercial Rules",
  //           routePath: `${schedules_page}`,
  //         },
  //         {
  //           title: "Tracks Feedback",
  //           routePath: `${schedules_page}`,
  //         },
  //         {
  //           title: "Tracklist Analyser",
  //           routePath: `${schedules_page}`,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: "Components",
  //   routePath: `${reusable_components_page}`,
  // },
];
