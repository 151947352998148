import React, { useState, useEffect, Fragment } from "react";
import { Formik, Form } from "formik";
import { useLocation } from "react-router-dom";
import hexToRgba from "hex-to-rgba";
import Select from "react-select";
import { cloneDeep } from "lodash";

// import TimezoneHelper from "@ambie/ambie-npm-timezone-helper";

import TimezoneHelper from "../../components/timezoneHelper";
import moment from "moment-timezone";

// style components

import { GlobalButton } from "../../components/button";
import { Toggle } from "../../components/fields/toggle";
import { LoadingData } from "../../components/loading";
import {
  addSchedule,
  deleteSchedule,
  getSchedules,
  getSelectedSchedule,
  updateScheduleForAllAreas,
} from "../../services/schedulesServices";
import "./schedules.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux-toolkit/store";
import {
  changeExpandedViewSuccess,
  changeIsAddEditSchedValidSuccess,
  changeIsEditPlaylistSuccess,
  changeIsScheduleEditedSuccess,
  changeScheduleDiscardScheduleEditModePopupSuccess,
  changeScheduleEditModeSuccess,
  changeSchedulesSuccess,
  changeShowAddNewSchedulesPopupSuccess,
  changeShowDeleteSchedulePopupSuccess,
  changeShowScheduleDeleteConfirmationPopupSuccess,
  changeShowScheduleOverlapsConfirmationPopupSuccess,
  changeShowSchedulePopupSuccess,
  changeShowScheduleSavePopupSuccess,
  isErrorScheduleSuccess,
  showTimezoneModalSuccess,
} from "../../redux-toolkit/reducers/schedulesSlice";
import { ScheduleCurrentBar } from "./components/scheduleCurrentBar";
import { ChangeTimezone } from "./components/modal";
import { ScheduleAddEditChannelPopup } from "./components/modal/scheduleAddEditChannelPopup";
import { ScheduleSaveChannelPopup } from "./components/modal/scheduleSaveChannelPopup";
import { getClientLocationsWithAreas } from "../../services/locationsServices";
import { ScheduleOverlapsConfirmationPopup } from "./components/modal/scheduleOverlapsConfirmationPopup";
import { ScheduleDeleteConfirmationPopup } from "./components/modal/scheduleDeleteConfirmationPopup";
import { ScheduleDiscardScheduleEditModePopup } from "./components/modal/scheduleDiscardScheduleEditModePopup";
import { ScheduleAddSchedulePopup } from "./components/modal/scheduleAddSchedulePopup";
import { PlaylistSubmissionErrorModal } from "../playlists/components/playlist-modal/modal";
import { ScheduleDeleteSchedulePopup } from "./components/modal/scheduleDeleteSchedulePopup";
import { hideActionsSuccess, isLoadingSuccess } from "../../redux-toolkit/reducers/actionsSlice";
import { getActivePlaylistsByClientId } from "../../services/playlistsServices";

export const Schedules = () => {
  const location = useLocation();

  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const schedulesSlice = useSelector((state: RootState) => state.schedulesSlice);
  const [times] = useState<any>(TimezoneHelper.generateTimes("Europe/London"));

  // const locationsSlice = useSelector((state: RootState) => state.locationsSlice);
  const [locations, setLocations] = useState<[]>([]);

  const scheduleId = location.state?.schedule?.id || schedulesSlice.selectedScheduleId || "";
  const [currentTimeIndicator, setCurrentTimeIndicator] = useState<any>(null);

  const [selectedSchedule, setSelectedSchedule] = useState<any>({
    name: "",
    schedule: [[], [], [], [], [], [], []],
    cleanSchedule: [[], [], [], [], [], [], []],
    timezoneAdjustedSchedule: [[], [], [], [], [], [], []],
    _id: "",
  });
  const [editScheduleObject, setEditScheduleObject] = useState<any>({
    day: 0,
    days: [true, false, false, false, false, false, false],
    startValue: 0,
    endValue: 1,
    channel: {},
  });
  const dispatch = useDispatch();

  useEffect(() => {
    // moment(new Date(clientHubSlice.dateFilter.startDate)).startOf("day")
    let isSubscribed = true;
    const getSchedulesData = async () => {
      dispatch(isLoadingSuccess(true));
      await getSchedules(searchClient && searchClient.clientId, scheduleId)
        .then((response) => {
          if (isSubscribed) {
            dispatch(isLoadingSuccess(false));
            const timezoneAdjustedSchedule = TimezoneHelper.timezonifySchedule(
              response.selectedSchedule && response.selectedSchedule.schedule,
              TimezoneHelper.generateTimes("Europe/London"),
              "Europe/London"
            );
            const playload = {
              ...response.selectedSchedule,
              timezoneAdjustedSchedule: timezoneAdjustedSchedule,
            };
            setSelectedSchedule(playload);
          }
        })
        .catch((error) => {
          dispatch(isLoadingSuccess(false));
          throw new Error(error);
        });
    };

    const getPlaylistsByClientIdData = async () => {
      await getActivePlaylistsByClientId(searchClient.clientId);
    };

    const getClientLocationsWithAreasData = async () => {
      await getClientLocationsWithAreas(searchClient, true, true)
        .then((response) => {
          if (isSubscribed) {
            setLocations(response.response.data);
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    };

    if (currentTimeIndicator && currentTimeIndicator.children && currentTimeIndicator.children[0]) {
      window.scrollTo(0, currentTimeIndicator.children[0].offsetTop);
    }

    getSchedulesData();
    getPlaylistsByClientIdData();
    getClientLocationsWithAreasData();

    return () => {
      isSubscribed = false;
    };
  }, [searchClient, scheduleId, currentTimeIndicator, dispatch]);

  const getSelectedScheduleData = async (scheduleId: string) => {
    await getSelectedSchedule(scheduleId)
      .then((response) => {
        const timezoneAdjustedSchedule = TimezoneHelper.timezonifySchedule(
          response.schedule,
          TimezoneHelper.generateTimes("Europe/London"),
          "Europe/London"
        );
        const playload = {
          ...response,
          timezoneAdjustedSchedule: timezoneAdjustedSchedule,
        };

        setSelectedSchedule(playload);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const timeBar = () => {
    const elementHeight = schedulesSlice.isExpandedView ? 20 : 40;
    const fontSize = { small: "11px", large: "14px" };

    return !times
      ? null
      : times.map((time: any, i: number, self: any) => {
          const isMidnight: any = i === self.length - 1;
          if (!schedulesSlice.isExpandedView && i % 4 !== 0) {
            return null;
          }
          const prettyTime: any = isMidnight ? "24:00" : time.format("HH:mm");
          let elementStyle: any = {
            "--elementHeight": `${elementHeight}px`,
            "--fontSize": i % 4 === 0 ? fontSize.large : fontSize.small,
            "--textColor":
              !schedulesSlice.isExpandedView || (schedulesSlice.isExpandedView && i % 4 === 0) ? "#888" : "#a5a5a5",
          };
          if (isMidnight) elementStyle.height = 0;
          return (
            <span style={elementStyle} className="time-column-segment" key={time}>
              {prettyTime}
            </span>
          );
        });
  };

  const dayHeadings = () => {
    const dayIndex = moment().tz(schedulesSlice.timezoneName).isoWeekday() - 1;
    const todayStyle = {
      background: "#707d86",
      color: "#fff",
      border: "none",
    };

    return (
      <>
        {schedulesSlice.days.map((day, i) => (
          <span
            style={i === dayIndex ? todayStyle : {}}
            className="animated delay02s duration04 bounceInDown day"
            key={`heading-${day}`}
          >
            {day}
          </span>
        ))}
      </>
    );
  };

  const dayColumns = () => {
    let elementHeight = schedulesSlice.isExpandedView ? 20 : 10;
    let playlistStartIndex = Array.isArray(times) ? times.length - 1 : 0;
    let playlistEndIndex = playlistStartIndex;
    return schedulesSlice.days.map((day: any, dayIndex) => (
      <div
        className="day-column animated delay02s duration03 slideInDown"
        id={`column-${day.toLowerCase()}`}
        key={`column-${day}`}
      >
        {times.map((time: any, timeIndex: number) => {
          if (timeIndex === 0) {
            playlistStartIndex = times.length - 1;
            playlistEndIndex = playlistStartIndex;
          }
          const itemStyle = {
            height: `${elementHeight}px`,
            cursor: schedulesSlice.isScheduleEditMode ? "pointer" : "auto",
            borderTop:
              schedulesSlice.isExpandedView || (!schedulesSlice.isExpandedView && timeIndex % 4 === 0)
                ? "1px solid #e6e6e6"
                : "none",
          };

          let element: any = (
            <div
              key={`column-${day}-cell-${time}`}
              id={`column-${day.toLowerCase()}-cell-${time.format("HH-mm")}`}
              onClick={() => handleAddChannelPopup(time, dayIndex)}
              style={itemStyle}
              className="animated fadeInDown calendar-cell schedules-empty-block"
            >
              <div className="schedules-add-playlist">
                <i className="icon-plus"></i> Add Playlist
              </div>
            </div>
          );

          const selectedScheduleClean = [
            ...(selectedSchedule.timezoneAdjustedSchedule && selectedSchedule.timezoneAdjustedSchedule[dayIndex]),
          ];
          selectedScheduleClean
            .sort((a: any, b: any) => a.start_time.diff(b.start_time))
            .forEach((playlist: any, playlistIndex: any) => {
              if (playlist.playlist.energy_level === "happy") {
                playlist.playlist.mood.color = "#ebb750";
              }

              const dateTime = time.clone().add(dayIndex, "days");
              if (dateTime.isSame(playlist.start_time)) {
                const playlistColour =
                  (playlist && playlist.playlist && playlist.playlist.mood && playlist.playlist.mood.color) ||
                  "#cd618d";
                // check whether the start time of the scheduled playlist matches the time and create schedule block item if so
                const playlistLength = playlist.end_time.diff(playlist.start_time) / (60 * 1000) / 15; // playlist length in number of 15 min slots
                playlistStartIndex = timeIndex;
                playlistEndIndex = timeIndex + playlistLength;
                const durationHours = Math.floor(playlistLength / 4);
                const durationMins = ((playlistLength / 4) % 1) * 60;
                const playlistBlockStyle = {
                  height: elementHeight * playlistLength,
                  cursor: schedulesSlice.isScheduleEditMode ? "pointer" : "normal",

                  border: elementHeight * playlistLength > 0 ? `1px solid ${playlistColour}` : "none",
                  background: hexToRgba(playlistColour, 0.7),
                };
                const isSmallBlock = durationHours + durationMins / 60 <= 1 && !schedulesSlice.isExpandedView;
                const nameStyle: any = {
                  paddingTop: isSmallBlock ? "0" : "4px",
                  paddingLeft: isSmallBlock ? "3px" : "6px",
                  fontSize: durationHours === 0 && durationMins <= 15 ? "8px" : "14px",
                  width: durationHours + durationMins / 60 >= 0.5 ? "calc(100% - 25px)" : "calc(100% - 15px)",
                  "--webkit-line-clamp": isSmallBlock ? "1" : `${durationHours + 1}`,
                  WebkitBoxOrient: "vertical",
                };
                element = (
                  <Fragment key={`fragment-column-${day}-cell-${time}`}>
                    <div
                      key={`column-${day}-cell-${time}`}
                      id={`column-${day.toLowerCase()}-cell-${playlist.start_time.format(
                        "HH-mm"
                      )}-${playlist.end_time.format("HH-mm")}`}
                      onClick={() => {
                        handleEditChannelPopup(playlist, dayIndex);
                      }}
                      style={playlistBlockStyle}
                      className="schedule-block-item animated fadeInDown"
                    >
                      <span className="schedule-block-item-name" style={nameStyle}>
                        {playlist.playlist.name}
                      </span>
                      <div className="d-flex align-items-end justify-content-between">
                        {(schedulesSlice.isExpandedView && durationHours + durationMins / 60 >= 0.5) ||
                        durationHours + durationMins / 60 >= 1 ? (
                          <div className="schedule-block-item-start-finish">
                            {`${
                              playlist && playlist.start_time.minute() === 0
                                ? playlist && playlist.start_time.format("ha")
                                : playlist && playlist.start_time.format("h:mma")
                            } - ${
                              playlist && playlist.end_time.minute() === 0
                                ? playlist && playlist.end_time.format("ha")
                                : playlist && playlist.end_time.format("h:mma")
                            }`}
                          </div>
                        ) : null}

                        <div
                          style={{
                            fontSize:
                              durationHours === 0 && durationMins <= 15 && !schedulesSlice.isExpandedView
                                ? "6px"
                                : "11px",
                          }}
                          className="schedule-block-item-duration"
                        >
                          {durationHours === 0
                            ? durationMins + "mins"
                            : durationMins === 0 && durationHours === 1
                            ? durationHours + "hr"
                            : durationMins === 0 && durationHours > 1
                            ? durationHours + "hrs"
                            : `${durationHours}:${durationMins < 10 ? "0" + durationMins : durationMins}`}
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: elementHeight,
                          background: hexToRgba(playlistColour, 0.8),
                          boxShadow: `0 0 1px 1px ${hexToRgba(playlistColour)}`,
                        }}
                        className="edit-block"
                      >
                        Edit
                      </div>
                    </div>
                  </Fragment>
                );
              }
            });
          element =
            (timeIndex > playlistStartIndex && timeIndex < playlistEndIndex) || timeIndex === times.length - 1
              ? null
              : element;
          return element;
        })}
      </div>
    ));
  };

  const handleAddChannelPopup = (time: any, dayIndex: number) => {
    dispatch(changeIsAddEditSchedValidSuccess(true));
    if (schedulesSlice.isScheduleEditMode) {
      dispatch(changeIsEditPlaylistSuccess(false));
      const daysArray = [false, false, false, false, false, false, false];
      daysArray[dayIndex] = true;
      const startValue = time.clone().add(dayIndex, "days");
      const endValue =
        startValue.clone().add(1, "hours").date() !== startValue.clone().date()
          ? startValue.clone().add(1, "days").startOf("day")
          : startValue.clone().add(1, "hours");
      setEditScheduleObject({
        isEditChannel: false,
        day: dayIndex || 0,
        days: daysArray,
        startValue,
        endValue,
        channel: schedulesSlice.currentChannelsForClient[0] || {},
      });
      dispatch(changeShowSchedulePopupSuccess(true));
    }
  };

  const handleEditChannelPopup = (channel: any, dayIndex: number) => {
    if (!schedulesSlice.isScheduleEditMode) return;
    dispatch(changeScheduleEditModeSuccess(true));
    dispatch(changeIsAddEditSchedValidSuccess(true));
    if (schedulesSlice.isScheduleEditMode) {
      dispatch(changeIsEditPlaylistSuccess(true));
      const daysArray = [false, false, false, false, false, false, false];
      daysArray[dayIndex || 0] = true;
      setEditScheduleObject({
        isEditChannel: true,
        day: dayIndex || 0,
        days: daysArray,
        startValue: channel.start_time,
        endValue: channel.end_time,
        channel: channel.playlist,
        color: channel.playlist.color,
      });
      dispatch(changeShowSchedulePopupSuccess(true));
    }
  };

  const handleSaveChannel = () => {
    dispatch(changeShowSchedulePopupSuccess(false));
    dispatch(changeIsAddEditSchedValidSuccess(true));
    const newPlaylists = TimezoneHelper.createNewPlaylists(editScheduleObject, times);
    const schedule = [...selectedSchedule.timezoneAdjustedSchedule];
    if (TimezoneHelper.isOverlapInWeek(schedule, newPlaylists)) {
      dispatch(changeShowScheduleOverlapsConfirmationPopupSuccess(true));
    } else {
      continueScheduleUpdate(newPlaylists);
    }
  };

  const continueScheduleUpdate = (newPlaylists: any) => {
    const schedule = [...selectedSchedule.timezoneAdjustedSchedule];
    const timezoneAdjustedSchedule = TimezoneHelper.makeSpaceInSchedule(schedule, newPlaylists);
    finalizeSaveChannel(timezoneAdjustedSchedule);
  };

  const finalizeSaveChannel = (timezoneAdjustedSchedule: any) => {
    const cleanSchedule = selectedSchedule.cleanSchedule || cloneDeep(selectedSchedule.schedule);
    const ukSchedule = TimezoneHelper.scheduleToUKDateString([...timezoneAdjustedSchedule]);
    const selectedScheduleClean = {
      ...cloneDeep(selectedSchedule),
      cleanSchedule,
      schedule: ukSchedule,
      timezoneAdjustedSchedule,
    };

    setSelectedSchedule(selectedScheduleClean);
    dispatch(changeShowSchedulePopupSuccess(false));
    dispatch(changeIsScheduleEditedSuccess(true));
  };

  const handleDeleteChannel = () => {
    dispatch(changeShowScheduleDeleteConfirmationPopupSuccess(true));
    dispatch(changeIsAddEditSchedValidSuccess(true));
    dispatch(changeShowSchedulePopupSuccess(false));
  };

  const handleEditScheduleChannelChange = (modifiedObj: any) => {
    setEditScheduleObject({
      ...cloneDeep(editScheduleObject),
      ...cloneDeep(modifiedObj),
    });
  };

  const handleChangeIsAddEditSchedValid = (newValue: any) => {
    dispatch(changeIsAddEditSchedValidSuccess(newValue));
  };

  const handleCancelChannel = () => {
    dispatch(changeShowSchedulePopupSuccess(false));
    dispatch(changeIsAddEditSchedValidSuccess(true));
  };

  const handleUpdateSchedule = () => {
    dispatch(changeShowScheduleSavePopupSuccess(true));
    dispatch(changeIsAddEditSchedValidSuccess(true));
  };

  const affectedAreas = [].concat.apply(
    [],
    locations.map((location: any) =>
      location.areas
        .filter((area: any) => area.week_schedule_id === selectedSchedule.id)
        .map((area: any) => {
          return area;
        })
    )
  );

  const handleConfirmedUpdateSchedule = () => {
    const timezoneAdjustedSchedule = cloneDeep(selectedSchedule.timezoneAdjustedSchedule);
    const ukSchedule =
      schedulesSlice.timezoneName === TimezoneHelper.defaults.timezoneName
        ? cloneDeep(timezoneAdjustedSchedule)
        : TimezoneHelper.scheduleToUKMoment(cloneDeep(timezoneAdjustedSchedule));
    if (TimezoneHelper.isWeeklyScheduleValid(ukSchedule)) {
    } else {
      const schedule = {
        clientId: selectedSchedule.clientId,
        id: selectedSchedule.id,
        name: selectedSchedule.name,
        schedule: TimezoneHelper.scheduleToUKDateString(timezoneAdjustedSchedule),
      };
      updateScheduleForAllAreas(schedule, affectedAreas);
    }
  };

  const handleCancelUpdateSchedule = () => {
    dispatch(changeShowScheduleSavePopupSuccess(false));
  };

  const handleCancelOverlapsChannel = () => {
    dispatch(changeShowSchedulePopupSuccess(true));
    dispatch(changeShowScheduleOverlapsConfirmationPopupSuccess(false));
  };

  const handleConfirmedOverlapsChannel = () => {
    const newPlaylists = TimezoneHelper.createNewPlaylists(editScheduleObject, times);
    dispatch(changeShowScheduleOverlapsConfirmationPopupSuccess(false));
    continueScheduleUpdate(newPlaylists);
  };

  const handleCancelDeleteChannel = () => {
    dispatch(changeShowScheduleDeleteConfirmationPopupSuccess(false));
    dispatch(changeShowSchedulePopupSuccess(true));
  };

  const handleConfirmedDeleteChannel = () => {
    const schedule = [...selectedSchedule.timezoneAdjustedSchedule];
    const startTime = editScheduleObject.startValue;
    const endTime = editScheduleObject.endValue;
    const playlist = editScheduleObject.channel;
    const timezoneAdjustedSchedule = TimezoneHelper.removePlaylistFromSchedule(schedule, startTime, endTime, playlist);

    setSelectedSchedule({
      ...cloneDeep(selectedSchedule),
      timezoneAdjustedSchedule,
    });
    dispatch(changeSchedulesSuccess(schedulesSlice.schedules));
    dispatch(changeShowSchedulePopupSuccess(false));
    dispatch(changeShowScheduleDeleteConfirmationPopupSuccess(false));
    dispatch(changeIsScheduleEditedSuccess(true));
  };

  const handleContinueWithoutSavingEditSchedule = () => {
    dispatch(changeScheduleDiscardScheduleEditModePopupSuccess(false));
    dispatch(changeShowSchedulePopupSuccess(false));
    dispatch(changeScheduleEditModeSuccess(false));
    dispatch(changeIsScheduleEditedSuccess(false));
    const timezoneAdjustedSchedule = TimezoneHelper.emptySchedule;
    const selectedScheduleClean = {
      ...cloneDeep(selectedSchedule),
      schedule: selectedSchedule.cleanSchedule || selectedSchedule.schedule,
      timezoneAdjustedSchedule,
      cleanSchedule: null,
    };

    setSelectedSchedule(selectedScheduleClean);
  };

  const handleSaveEditSchedule = () => {
    dispatch(changeScheduleDiscardScheduleEditModePopupSuccess(false));
    dispatch(changeShowSchedulePopupSuccess(false));
    dispatch(changeShowScheduleSavePopupSuccess(true));
  };

  const handleReturnToEditSchedule = () => {
    dispatch(changeScheduleDiscardScheduleEditModePopupSuccess(false));
  };

  const handleCancelSchedule = () => {
    dispatch(changeIsAddEditSchedValidSuccess(true));
    if (schedulesSlice.isScheduleEdited) {
      dispatch(changeScheduleDiscardScheduleEditModePopupSuccess(true));
    } else {
      dispatch(changeScheduleDiscardScheduleEditModePopupSuccess(false));
      dispatch(changeShowSchedulePopupSuccess(false));
      dispatch(changeScheduleEditModeSuccess(false));
      //   getSchedules(searchClient && searchClient.clientId, scheduleId);
    }
  };

  const handleAddSchedule = (newName: string, cloneSource: any, cloneRef: any) => {
    if (!schedulesSlice.schedules?.some((sched: any) => sched.name === newName)) {
      if (cloneSource && cloneRef) {
        addSchedule({ name: newName, source: cloneSource, ref: cloneRef }, searchClient.clientId);
      } else {
        addSchedule({ name: newName }, searchClient.clientId);
      }
    } else {
      alert("A schedule with that name already exists for the current client");
    }
  };

  const handleCloseAddSchedule = () => {
    dispatch(changeShowAddNewSchedulesPopupSuccess(false));
  };

  const handleOpenAddSchedule = () => {
    dispatch(changeShowAddNewSchedulesPopupSuccess(true));
  };

  //   const handleOpenDeleteSchedule = () => {
  //     dispatch(changeShowDeleteSchedulePopupSuccess(true));
  //   };

  const handleCloseDeleteSchedule = () => {
    dispatch(changeShowDeleteSchedulePopupSuccess(false));
  };

  const handleDeleteSchedule = () => {
    deleteSchedule(searchClient.clientId, selectedSchedule.id);
  };

  //   let today = new Date();
  //   let dayIndex = (today.getDay() + 6) % 7;
  const schedulesList =
    schedulesSlice.schedules && schedulesSlice.schedules.length
      ? schedulesSlice.schedules
          //   .sort((a: any, b: any) => a.name.localeCompare(b.name))
          .map((schedule: any) => ({ value: schedule.id, label: schedule.name }))
      : [];

  return (
    <div className="content container py-3">
      <div className="row align-items-center" style={{ minHeight: "50px" }}>
        {actions.actionsIsLoading && <LoadingData />}
        {schedulesSlice.isScheduleEditMode ? (
          <>
            <div className="col-sm mb-2 mb-sm-0 col-sm-4">
              <span className="dropdown-header px-0 pt-0">Editing</span>
              <h2 className="page-header-title mb-0">
                {(location.state && location.state.schedule && location.state.schedule.name) || selectedSchedule.name}{" "}
                Schedule Name
              </h2>
            </div>
            <div className="col-sm-auto ms-auto">
              <div className="edit-schedule-buttons">
                <GlobalButton
                  format="secondary"
                  className="me-3"
                  onClick={() => {
                    handleCancelSchedule();
                  }}
                >
                  Close
                </GlobalButton>
                <GlobalButton
                  format="success"
                  onClick={() => {
                    handleUpdateSchedule();
                  }}
                >
                  Save Schedule
                </GlobalButton>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-sm mb-2 mb-sm-0 col-sm-4">
              <Select
                id="schedule-list"
                className="time-select channel-list"
                options={schedulesList}
                value={schedulesList.find((c: any) => c.value === selectedSchedule.id) || schedulesList[0]}
                onChange={(selected) => selected && selected.value && getSelectedScheduleData(selected.value)}
              />
            </div>

            <div className="col-sm-auto ms-auto d-flex align-items-center">
              <Formik initialValues={{ minuteIntervals: false }} onSubmit={(values) => {}}>
                {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                  <Form style={{ top: "4px", position: "relative" }}>
                    <Toggle
                      type="checkbox"
                      name="minuteIntervals"
                      id="minuteIntervals"
                      label="15 minute intervals"
                      className="me-4"
                      rightLabel={true}
                      checked={false}
                      onChange={(value: any) => {
                        const targetValue = value.target && value.target.value === "true" ? false : true;
                        setFieldValue(`minuteIntervals`, targetValue);
                      }}
                      onChangeCapture={() => dispatch(changeExpandedViewSuccess(values.minuteIntervals ? false : true))}
                    />
                  </Form>
                )}
              </Formik>

              <GlobalButton
                format="white"
                className="me-3"
                onClick={() => {
                  dispatch(showTimezoneModalSuccess(true));
                }}
              >
                {schedulesSlice.timezone || schedulesSlice.defaultTimezoneOffsetDisplay}
              </GlobalButton>

              <GlobalButton
                format="secondary"
                className="me-3"
                onClick={() => {
                  dispatch(changeScheduleEditModeSuccess(true));
                }}
              >
                Edit Schedule
              </GlobalButton>

              <GlobalButton
                format="danger"
                className="me-4"
                onClick={() => {
                  dispatch(changeShowDeleteSchedulePopupSuccess(true));
                }}
              >
                Delete Schedule
              </GlobalButton>

              <GlobalButton
                format="primary"
                onClick={() => {
                  handleOpenAddSchedule();
                }}
              >
                New Schedule
              </GlobalButton>
            </div>
          </>
        )}
      </div>

      {/* Calendar */}
      <div className={`schedules-inner-wrapper ${schedulesSlice.isScheduleEditMode ? "edit-mode" : ""}`}>
        {/* Days of the week */}
        <div className="day-header">{dayHeadings()}</div>

        <div id="schedules-body" className={`full-width ${schedulesSlice.isScheduleEditMode ? "edit-mode" : ""}`}>
          <div className="schedules-inner-wrapper">
            <div className="calendar-container">
              <div className="calendar">
                <div className="schedules-detail-hour-column-content">{timeBar()}</div>
                {dayColumns()}
                <div ref={(el) => setCurrentTimeIndicator(el)}>
                  <ScheduleCurrentBar
                    timeNow={moment().tz(schedulesSlice.timezoneName).parseZone()}
                    isScheduleEditMode={schedulesSlice.isScheduleEditMode}
                    hourHeight={schedulesSlice.isExpandedView ? 80 : 40}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        {/* list of modal */}
        <ChangeTimezone
          show={schedulesSlice.showTimezoneModal}
          handleClose={() => dispatch(showTimezoneModalSuccess(false))}
        />

        <ScheduleAddEditChannelPopup
          editScheduleObject={editScheduleObject}
          show={schedulesSlice.showScheduleEditPopup}
          handleClose={() => handleCancelChannel()}
          saveChannel={() => handleSaveChannel()}
          deleteChannel={() => handleDeleteChannel()}
          handleEditScheduleChannelChange={handleEditScheduleChannelChange}
          handleChangeIsAddEditSchedValid={handleChangeIsAddEditSchedValid}
        />

        <ScheduleSaveChannelPopup
          timezone={schedulesSlice.timezone || schedulesSlice.defaultTimezoneOffsetDisplay}
          timezoneName={schedulesSlice.timezoneName}
          show={schedulesSlice.showScheduleSavePopup}
          handleConfirm={() => handleConfirmedUpdateSchedule()}
          handleClose={() => handleCancelUpdateSchedule()}
          currentSchedule={selectedSchedule}
          affectedAreas={affectedAreas}
        />

        <ScheduleOverlapsConfirmationPopup
          show={schedulesSlice.showScheduleOverlapsConfirmationPopup}
          handleClose={() => handleCancelOverlapsChannel()}
          handleCancelOverlapsChannel={() => handleCancelOverlapsChannel()}
          handleConfirmedOverlapsChannel={handleConfirmedOverlapsChannel}
        />
        <ScheduleDeleteConfirmationPopup
          show={schedulesSlice.showScheduleDeleteConfirmationPopup}
          handleClose={() => handleCancelDeleteChannel()}
          handleCancelDeleteChannel={() => handleCancelDeleteChannel()}
          handleConfirmedDeleteChannel={handleConfirmedDeleteChannel}
        />

        <ScheduleDiscardScheduleEditModePopup
          show={schedulesSlice.showDiscardScheduleEditPopup}
          handleClose={() => handleReturnToEditSchedule()}
          handleContinueWithoutSavingEditSchedule={handleContinueWithoutSavingEditSchedule}
          handleSaveEditSchedule={handleSaveEditSchedule}
          handleReturnToEditSchedule={handleReturnToEditSchedule}
        />
        <ScheduleAddSchedulePopup
          show={schedulesSlice.showAddNewSchedulePopup}
          handleClose={() => handleCloseAddSchedule()}
          //   schedules={schedulesSlice.schedules}
          locations={locations}
          handleAddSchedule={handleAddSchedule}
          handleCloseAddSchedule={() => handleCloseAddSchedule()}
        />

        <PlaylistSubmissionErrorModal
          show={schedulesSlice.isErrorSchedule.status}
          handleClose={() => dispatch(isErrorScheduleSuccess({ satus: false, messages: [] }))}
          messages={schedulesSlice.isErrorSchedule.messages}
          footer={
            <GlobalButton
              format="info"
              size="sm"
              onClick={() => dispatch(isErrorScheduleSuccess({ satus: false, messages: [] }))}
            >
              Close
            </GlobalButton>
          }
        />

        <ScheduleDeleteSchedulePopup
          selectedSchedule={selectedSchedule}
          affectedAreas={affectedAreas}
          show={schedulesSlice.showDeleteSchedulePopup}
          handleClose={() => {
            handleCloseDeleteSchedule();
            dispatch(hideActionsSuccess());
          }}
          handleDeleteSchedule={handleDeleteSchedule}
          handleCloseDeleteSchedule={handleCloseDeleteSchedule}
        />
      </>
    </div>
  );
};
