import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

// style components
import { RootState } from "../../redux-toolkit/store";
import { MiniCard, KeyValueCard } from "../../components/cards";
import { SearchInput, SearchWithOptions } from "../../components/fields/search";
import { GlobalButton } from "../../components/button";
import { AgGridReactTable } from "../../components/table";
import { LoadingData } from "../../components/loading";
import { GlobalDropdown } from "../../components/dropdown";
import {
  BsPencil,
  BsFillCheckCircleFill,
  BsFillXCircleFill,
  BsSearch,
  BsXOctagon,
  BsKey,
  BsEnvelope,
  BsFillGridFill,
} from "react-icons/bs";
import {
  showEditUserActionsSuccess,
  showEditUserNameActionsSuccess,
  showChangeUserEmailActionsSuccess,
  showActivateUserActionsSuccess,
  showDeactivateUserActionsSuccess,
  showSendUserPasswordResetActionsSuccess,
  showGenerateSetUserPasswordActionsSuccess,
  showResetUserPasswordActionsSuccess,
  showSwitchClientActionsSuccess,
  clientIdActionsSuccess,
} from "../../redux-toolkit/reducers/actionsSlice";

import { getClientsUsers } from "../../services/usersServices";

import { WebSocketContext } from "../../components/websocket/websocket";
import { format } from "date-fns";
import { dashboard_page_navigate } from "../../constants/routePath";
import { useNavigate } from "react-router-dom";
import { searchClientSuccess } from "../../redux-toolkit/reducers/searchClientSlice";
import { UsersModal } from "./components/modal";

export const ClientsUsers = () => {
  const dispatch = useDispatch();
  const socket = useContext(WebSocketContext);

  const [userFliter, setFilter] = useState<{ userFilterName: string; userFilterId: number }>({
    userFilterName: "",
    userFilterId: 0,
  });

  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const actionsSlice = useSelector((state: RootState) => state.actionsSlice);
  const usersSlice = useSelector((state: RootState) => state.authSlice);
  const [permissionsFilter, setPermissionsFilter] = useState("all");
  const [areasFilter, setAreasFilter] = useState("all");
  const [selectedUsernameAndEmail, setSelectedUsernameAndEmail] = useState<string>("");
  const navigate = useNavigate();

  const usersFromClients = usersSlice.clientsUsers;

  useEffect(() => {
    socket.on("updateUser", () => {
      getUsersData();
    });

    socket.on("newUser", () => {
      getUsersData();
    });

    socket.on("updateClient", () => {
      getUsersData();
    });

    const getUsersData = async () => {
      await getClientsUsers();
    };

    getUsersData();

    return () => {
      socket.off("updateUser", () => {
        getUsersData();
      });
      socket.off("newUser", () => {
        getUsersData();
      });
      socket.off("updateClient", () => {
        getUsersData();
      });
    };
  }, [searchClient]);

  const filterPermissions = [
    {
      label: "All Permissions",
      value: "all",
    },
    {
      label: "Admin",
      value: "ADMIN",
    },
    {
      label: "Manager",
      value: "MANAGER",
    },
    {
      label: "Team",
      value: "TEAM",
    },
  ];

  const filterAreas = [
    {
      label: "All Users",
      value: "all",
    },
    {
      label: "Active Users",
      value: "active",
    },
    {
      label: "Inactive Users",
      value: "inactive",
    },
  ];

  const getSelectedUsernameAndEmail = (
    selectedUsernameAndEmail: any,
    filter: any,
    usernameEmailFilterPass: boolean
  ) => {
    const username = (filter.users_user && filter.users_user.username) || "";
    const email = (filter.users_user && filter.users_user.email) || "";
    return (usernameEmailFilterPass =
      username.toString()?.toLowerCase()?.includes(selectedUsernameAndEmail?.toString()?.toLowerCase()) ||
      email.toString()?.toLowerCase()?.includes(selectedUsernameAndEmail?.toString()?.toLowerCase()));
  };

  const usersFilter = usersFromClients
    .filter((filter: any) => filter.users_user.name)
    .map((data: any) => {
      return { name: data.users_user.name, id: data.users_user.id };
    });

  const usersTableRows = usersFromClients
    .filter((filter: any) => {
      let filterPass = true;
      let permissionsFilterPass = true;
      let areasStatusFilterPass = true;
      let usernameEmailFilterPass = true;

      if (userFliter.userFilterName && userFliter.userFilterId) {
        filterPass = filter.users_user.name === userFliter.userFilterName;
      }

      if (permissionsFilter === "all") {
        permissionsFilterPass = true;
      } else {
        permissionsFilterPass = filter.permission_level.permission_level === permissionsFilter;
      }

      if (areasFilter === "all") {
        areasStatusFilterPass = true;
      } else if (areasFilter === "active") {
        areasStatusFilterPass = filter.users_user.is_active;
      } else if (areasFilter === "inactive") {
        areasStatusFilterPass = !filter.users_user.is_active;
      }

      if (selectedUsernameAndEmail) {
        const selectedRecord = getSelectedUsernameAndEmail(selectedUsernameAndEmail, filter, usernameEmailFilterPass);
        usernameEmailFilterPass = selectedRecord;
      }

      return filterPass && permissionsFilterPass && areasStatusFilterPass && usernameEmailFilterPass;
    })
    .map((data: any) => {
      const rowData = {
        role: data.permission_level.name,
        permission: data.permission_level.permission_level,
        name: data.users_user.name,
        email: data.users_user.email,
        user_id: data.users_user.id,
        client: data.businesses_client.name,
        status: data.users_user.is_active,
        created: data.users_user.date_joined,
        clientId: data.businesses_client.id,
        clentEmail: data.businesses_client.contact_email,
        isFrozen: data.businesses_client.is_frozen,
        clentContactName: data.businesses_client.contact_name,
        actions: data,
      };
      return rowData;
    });

  const usersColumns = [
    {
      field: "role",
      headerName: "Role",
    },

    {
      field: "name",
      headerName: "Name",
      cellRenderer: (params: any) => {
        return (
          <>
            {params.data.name ? (
              params.data.name
            ) : (
              <GlobalButton
                size="xs"
                format="secondary"
                onClick={() => {
                  dispatch(
                    showEditUserNameActionsSuccess({
                      editUserNameDetails: true,
                      user: { id: params.data.user_id },
                    })
                  );
                }}
              >
                Add Name
              </GlobalButton>
            )}
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      cellRenderer: (params: any) => {
        return (
          <>
            {params.data.email ? (
              params.data.email
            ) : (
              <GlobalButton
                size="xs"
                format="secondary"
                onClick={() => {
                  dispatch(
                    showChangeUserEmailActionsSuccess({
                      changeUserEmail: true,
                      user: { id: params.data.user_id },
                    })
                  );
                }}
              >
                Add Email
              </GlobalButton>
            )}
          </>
        );
      },
    },
    {
      field: "client",
      headerName: "Client",
    },

    {
      field: "status",
      headerName: "Status",
      width: 120,
      maxWidth: 120,
      cellRenderer: (params: any) => {
        return (
          <>
            {params.data.status ? (
              <>
                <BsFillCheckCircleFill className="text-success" />
                <span style={{ marginLeft: 5 }}> Active</span>
              </>
            ) : (
              <>
                <BsFillXCircleFill className="text-danger" /> <span style={{ marginLeft: 5 }}> Inactive</span>
              </>
            )}
          </>
        );
      },
    },
    {
      field: "created",
      headerName: "Created",
      width: 180,
      maxWidth: 180,
      cellRenderer: (params: any) => {
        return <>{format(new Date(params.data.created), "dd/MM/yyyy H:mm")}</>;
      },
    },
    {
      field: "actions",
      headerName: "Action",
      width: 180,
      maxWidth: 180,
      cellClass: "actions-dropdown-cell",
      filter: false,
      sortingOrder: false,
      cellRenderer: (params: any) => {
        return (
          <GlobalDropdown name="Actions" format="white" size="xs" align="end">
            <div className="dropdown-item">
              <GlobalButton
                size="sm"
                format="none"
                onClick={() =>
                  dispatch(
                    showSwitchClientActionsSuccess({
                      switchClient: { status: true, clientId: params.data.clientId },
                      user: { name: params.data.name, id: params.data.user_id, email: params.data.email },
                    })
                  )
                }
              >
                <BsPencil className="dropdown-item-icon" /> Switch Client
              </GlobalButton>
            </div>

            <div className="dropdown-item">
              <GlobalButton
                size="sm"
                format="none"
                target="_blank"
                onClick={() => {
                  dispatch(
                    searchClientSuccess({
                      clientId: params.data.clientId,
                      clientName: params.data.client,
                      clentEmail: params.data.clentEmail,
                      clentContactName: params.data.client,
                      isFrozen: params.data.isFrozen,
                    })
                  );
                  goToDashboard();
                }}
              >
                <BsFillGridFill className="dropdown-item-icon" /> View Client
              </GlobalButton>
            </div>
            <div className="dropdown-item">
              <GlobalButton
                size="sm"
                format="none"
                onClick={() => {
                  dispatch(
                    showEditUserActionsSuccess({
                      editUserDetails: true,
                      user: { id: params.data.user_id },
                    })
                  );

                  dispatch(clientIdActionsSuccess(params.data.clientId));
                }}
              >
                <BsPencil className="dropdown-item-icon" /> Edit Details
              </GlobalButton>
            </div>

            {params.data.email && (
              <div className="dropdown-item">
                <GlobalButton
                  size="sm"
                  format="none"
                  onClick={() =>
                    dispatch(
                      showResetUserPasswordActionsSuccess({
                        resetUserPassword: true,
                        user: { name: params.data.name, id: params.data.user_id, email: params.data.email },
                      })
                    )
                  }
                >
                  <BsKey className="dropdown-item-icon" /> Reset User Password
                </GlobalButton>
              </div>
            )}

            {params.data.email && (
              <div className="dropdown-item">
                <GlobalButton
                  size="sm"
                  format="none"
                  onClick={() =>
                    dispatch(
                      showSendUserPasswordResetActionsSuccess({
                        sendUserPasswordReset: true,
                        user: { name: params.data.name, id: params.data.user_id, email: params.data.email },
                      })
                    )
                  }
                >
                  <BsKey className="dropdown-item-icon" /> Send Password Reset Email
                </GlobalButton>
              </div>
            )}

            <div className="dropdown-item">
              <GlobalButton
                size="sm"
                format="none"
                onClick={() =>
                  dispatch(
                    showGenerateSetUserPasswordActionsSuccess({
                      generateSetUserPassword: true,
                      user: { name: params.data.name, id: params.data.user_id, email: params.data.email },
                    })
                  )
                }
              >
                <BsEnvelope className="dropdown-item-icon" /> Generate Set Password Link
              </GlobalButton>
            </div>

            <div className="dropdown-divider"></div>
            <div className="dropdown-item text-danger">
              {params.data.status ? (
                <GlobalButton
                  size="sm"
                  format="none"
                  className="text-danger"
                  onClick={() =>
                    dispatch(
                      showDeactivateUserActionsSuccess({
                        deactivateUser: true,
                        user: { name: params.data.name, id: params.data.user_id },
                      })
                    )
                  }
                >
                  <BsXOctagon className="dropdown-item-icon text-danger" /> Deactivate User
                </GlobalButton>
              ) : (
                <GlobalButton
                  size="sm"
                  format="none"
                  className="text-danger"
                  onClick={() =>
                    dispatch(
                      showActivateUserActionsSuccess({
                        activateUser: true,
                        user: { name: params.data.name, id: params.data.user_id },
                      })
                    )
                  }
                >
                  <BsXOctagon className="dropdown-item-icon text-danger" /> Activate User
                </GlobalButton>
              )}
            </div>
          </GlobalDropdown>
        );
      },
    },
  ];

  let channelTableRows: any = [];
  const channelRowData = {
    channel: "Silver Lyan 5-7",
    tracks: 66,
    percentage: 50,
    actions: "",
  };
  channelTableRows.push(channelRowData);

  const activeUsers = usersFromClients?.filter((user: any) => user.users_user && user.users_user.is_active).length;
  const inactiveUsers = usersFromClients?.filter((user: any) => user.users_user && !user.users_user.is_active).length;

  const usersCount: any = [
    {
      message: "Number based on total users.",
      name: "Total users",
      info: true,
      value: usersFromClients.length || 0,
    },
    {
      message: "Number based on total active users.",
      name: "Active users",
      info: true,
      value: activeUsers || 0,
    },
    {
      message: "Number based on total inactive users.",
      name: "Inactive users",
      info: true,
      value: inactiveUsers || 0,
    },
  ];

  const goToDashboard = () => {
    navigate(dashboard_page_navigate);
  };

  const initialValues = {};
  return (
    <div className="content container py-3">
      <>
        {/* User action */}
        <UsersModal />
      </>

      <div>
        <div className="row align-items-center">
          <div className="col-sm-4" style={{ zoom: 0.95 }}>
            <MiniCard options={usersCount} columns="col-lg-4" />
          </div>
        </div>
        {actionsSlice.actionsIsLoading && <LoadingData />}
        <div style={{ marginTop: "1rem" }}>
          <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
            <Form>
              <KeyValueCard
                title={
                  <div style={{ width: "60%", marginRight: "10px" }}>
                    <SearchWithOptions
                      id="searchUser"
                      placeholder="Search name..."
                      options={usersFilter}
                      labelKey="name"
                      leftIcon={<BsSearch />}
                      clearButton
                      onChange={(selected: any) => {
                        setFilter({
                          userFilterId: (selected && selected[0] && selected[0].id) || 0,
                          userFilterName: (selected && selected[0] && selected[0].name) || "",
                        });
                      }}
                    />
                  </div>
                }
                filter={
                  <div className="d-flex align-items-center">
                    <div style={{ width: "220px", marginRight: "90px" }}>
                      <SearchInput
                        placeholder="Search email address..."
                        onSearch={(value: any) => setSelectedUsernameAndEmail(value)}
                        allowClear
                        size="large"
                        style={{ width: 300, fontSize: 10 }}
                      />
                    </div>

                    <div style={{ width: "200px", marginRight: "10px" }}>
                      <Select
                        id="permissions"
                        className="flex-grow-4 time-select channel-list"
                        options={filterPermissions}
                        value={filterPermissions.find((c: any) => c.value === permissionsFilter)}
                        onChange={(selected) => selected && selected.value && setPermissionsFilter(selected.value)}
                      />
                    </div>

                    <div style={{ width: "150px", marginRight: "10px" }}>
                      <Select
                        id="players"
                        className="flex-grow-4 time-select channel-list"
                        options={filterAreas}
                        value={filterAreas.find((c: any) => c.value === areasFilter)}
                        onChange={(selected) => selected && selected.value && setAreasFilter(selected.value)}
                      />
                    </div>
                  </div>
                }
              >
                <div className="row">
                  <div className="col-sm-12">
                    <AgGridReactTable
                      className="ag-theme-alpine"
                      rowData={usersTableRows}
                      columnDefs={usersColumns}
                      filter={true}
                      sortable={true}
                      animateRows={true}
                      pagination={true}
                      paginationPageSize={25}
                      domLayout="autoHeight"
                    />
                  </div>
                </div>
              </KeyValueCard>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
