import React from "react";
import { AgGridReact } from "ag-grid-react";

export const KeyValueTable = ({ columns, rows }: any) => {
  return (
    <div>
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              {columns.map((headerTitle: any, index: number) => (
                <th className={headerTitle.style} key={index}>
                  {headerTitle.title} {headerTitle.infoIcon} {headerTitle.filterIcon}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};

interface AgGridReactTableProps {
  rowData: any;
  columnDefs: any;
  paginationPageSize?: number;
  pagination?: boolean;
  animateRows?: boolean;
  enableRangeSelection?: boolean;
  resizable?: boolean;
  sortable?: boolean;
  enableBrowserTooltips?: boolean;
  filter?: boolean;
  height?: string;
  width?: string;
  className?: any;
  domLayout?: any;
}
export const AgGridReactTable = ({
  rowData,
  columnDefs,
  paginationPageSize,
  pagination,
  animateRows,
  enableRangeSelection,
  resizable,
  sortable,
  enableBrowserTooltips,
  filter,
  domLayout,
  height = "420px",
  width = "100%",
}: AgGridReactTableProps) => {
  return (
    <div className="ag-theme-alpine" style={{ height: height, width: width }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={{
          resizable: resizable,
          sortable: sortable,
          filter: filter,
          flex: 1,
        }}
        enableRangeSelection={enableRangeSelection}
        animateRows={animateRows}
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        enableBrowserTooltips={enableBrowserTooltips}
        domLayout={domLayout}
      />
    </div>
  );
};
