import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// style components

export interface clientHubState {
  feedback: [];
  dateFilter: { startDate: string; endDate: string };
  adminApproval: boolean;
  adminApprovalIsLoading: boolean;
  adminApprovalIsError: string;
  clientDetails: any;
  clientOwners: [];
  clientBriefs: [];
  brief: {};
  briefs: [];
}
const initialState: clientHubState = {
  feedback: [],
  dateFilter: { startDate: "", endDate: "" },
  adminApproval: false,
  adminApprovalIsLoading: false,
  adminApprovalIsError: "",
  clientOwners: [],
  clientDetails: {},
  clientBriefs: [],
  brief: {},
  briefs: [],
};

const BriefsSlice = createSlice({
  name: "briefs",
  initialState,
  reducers: {
    feedbackSuccess: (state, action: PayloadAction<any>) => {
      state.feedback = action.payload.feedback;
    },

    dateFilterSuccess: (state, action: PayloadAction<any>) => {
      state.dateFilter = action.payload.dateFilter;
    },
    showAdminApprovalActionsSuccess: (state, action: PayloadAction<any>) => {
      state.adminApproval = action.payload.adminApproval;
      state.adminApprovalIsLoading = action.payload.adminApprovalIsLoading;
      state.adminApprovalIsError = action.payload.adminApprovalIsError;
    },

    clientOwnersSuccess: (state, action: PayloadAction<any>) => {
      state.clientOwners = action.payload.clientOwners;
    },

    clientDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.clientDetails = action.payload.clientDetails;
    },

    clientBriefsSuccess: (state, action: PayloadAction<any>) => {
      state.clientBriefs = action.payload.clientBriefs;
    },
    briefSuccess: (state, action: PayloadAction<any>) => {
      state.brief = action.payload.brief;
    },

    briefsSuccess: (state, action: PayloadAction<any>) => {
      state.briefs = action.payload.briefs;
    },
  },
});

export const {
  feedbackSuccess,
  dateFilterSuccess,
  showAdminApprovalActionsSuccess,
  clientDetailsSuccess,
  clientOwnersSuccess,
  clientBriefsSuccess,
  briefSuccess,
  briefsSuccess,
} = BriefsSlice.actions;
export default BriefsSlice.reducer;
