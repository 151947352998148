import axiosInterceptors from "../lib/axiosInterceptors";
import { getEnvironment } from "../config/config";
import { store } from "../redux-toolkit/store";
import {
  changeIsScheduleEditedSuccess,
  changeScheduleEditModeSuccess,
  changeSchedulesSuccess,
  changeShowAddNewSchedulesPopupSuccess,
  changeShowDeleteSchedulePopupSuccess,
  changeShowSchedulePopupSuccess,
  changeShowScheduleSavePopupSuccess,
  selectedScheduleIdSuccess,
} from "../redux-toolkit/reducers/schedulesSlice";
import { isLoadingSuccess, showErrorMessageSuccess } from "../redux-toolkit/reducers/actionsSlice";
import { ACTIONS_ERROR_MESSAGE } from "../constants/globalText";

/**
 * Get schedules
 */
export async function getSchedules(clientId: number, scheduleId: string = "") {
  const response = await axiosInterceptors.get(
    `${getEnvironment()?.HOST_ROCK_API}/schedules/client/${clientId}/weekly-schedules`,
    {}
  );
  let selectedSchedule: any = [[], [], [], [], [], [], []];
  if (response.data) {
    store.dispatch(changeSchedulesSuccess(response.data));
    if (response.data?.length && scheduleId) {
      selectedSchedule = await getSelectedSchedule(scheduleId);
    } else if (response.data?.length) {
      selectedSchedule = await getSelectedSchedule(response.data[0].id);
    }
  }

  return { response, selectedSchedule };
}

/**
 * Get selected schedule
 */
export async function getSelectedSchedule(scheduleId: string) {
  const response = await axiosInterceptors.get(
    `${getEnvironment()?.HOST_ROCK_API}/schedules/client/${scheduleId}/weekly-schedule`,
    {}
  );

  let selectedSchedule = {
    name: "",
    schedule: [[], [], [], [], [], [], []],
    cleanSchedule: [[], [], [], [], [], [], []],
    timezoneAdjustedSchedule: [[], [], [], [], [], [], []],
    id: "",
  };

  if (response.data?.schedule) {
    selectedSchedule = response.data;
    store.dispatch(selectedScheduleIdSuccess(response.data.id));
  }

  return selectedSchedule;
}

/**
 * Resend schedule
 */
export async function resendSchedule(areaId: number) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/schedules/resend/${areaId}`, {});
}

/**
 * Update schedule for area
 */
export async function updateScheduleForArea(
  areaId: number,
  locationId: number,
  weekScheduleId: string,
  clientId: number
) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/schedules/area-week-schedule`, {
    areaId,
    locationId,
    weekScheduleId,
    clientId,
  });
}

/**
 * Update schedule for all areas
 */
export async function updateScheduleForAllAreas(schedule: any, affectedAreas: any) {
  store.dispatch(isLoadingSuccess(true));
  try {
    const updateSchedule = await axiosInterceptors.patch(
      `${getEnvironment()?.HOST_ROCK_API}/schedules/client/${schedule.id}/weekly-schedule`,
      {
        schedule: schedule.schedule,
        affectedAreas,
      }
    );

    store.dispatch(isLoadingSuccess(false));
    store.dispatch(changeShowScheduleSavePopupSuccess(false));
    store.dispatch(changeShowSchedulePopupSuccess(false));
    store.dispatch(changeScheduleEditModeSuccess(false));
    store.dispatch(changeIsScheduleEditedSuccess(false));

    return updateSchedule;
  } catch (error: any) {
    store.dispatch(isLoadingSuccess(false));
    throw new Error(error);
  }
}

/**
 * Delete schedule
 */
export async function deleteSchedule(clientId: number, scheduleId: string) {
  store.dispatch(isLoadingSuccess(true));
  await axiosInterceptors
    .remove(`${getEnvironment()?.HOST_ROCK_API}/schedules/client/${scheduleId}/weekly-schedule`, {})
    .then((response) => {
      const data = response.data;
      if (!data.error) {
        getSchedules(clientId);
        store.dispatch(changeShowDeleteSchedulePopupSuccess(false));
      } else {
        if (data.error === "Schedule is in use") {
          store.dispatch(showErrorMessageSuccess(data.message));
        } else {
          store.dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        }
      }

      store.dispatch(isLoadingSuccess(false));
    })
    .catch((error) => {
      store.dispatch(isLoadingSuccess(false));
      throw new Error(error);
    });
}

/**
 * Add a schedule
 */
export async function addSchedule(scheduleData: any, clientId: number) {
  store.dispatch(isLoadingSuccess(true));
  return await axiosInterceptors
    .post(`${getEnvironment()?.HOST_ROCK_API}/schedules/client/${clientId}/weekly-schedule`, scheduleData)
    .then((response) => {
      store.dispatch(changeShowAddNewSchedulesPopupSuccess(false));
      store.dispatch(isLoadingSuccess(false));
      getSchedules(clientId);
    })
    .catch((error) => {
      store.dispatch(isLoadingSuccess(false));
      throw new Error(error);
    });
}

/**
 * Migrate all schedules
 */
export async function migrateAllSchedules(clientId: number) {
  return await axiosInterceptors.get(
    `${getEnvironment()?.HOST_ROCK_API}/schedules/client/${clientId}/migrate-all-schedules`,
    {}
  );
}

/**
 * Migrate all schedules
 */
export async function migrateActiveSchedules(clientId: number) {
  return await axiosInterceptors.get(
    `${getEnvironment()?.HOST_ROCK_API}/schedules/client/${clientId}/migrate-active-schedules`,
    {}
  );
}

/**
 * Migrate schedule
 */
export async function migrateSchedule(clientId: number, scheduleId: string) {
  return await axiosInterceptors.get(
    `${getEnvironment()?.HOST_ROCK_API}/schedules/client/${clientId}/${scheduleId}/migrate-schedule`,
    {}
  );
}

/**
 * Assign SYB schedule
 */
export async function assignSYBSchedule(clientId: number, scheduleId: string) {
  return await axiosInterceptors.get(
    `${getEnvironment()?.HOST_ROCK_API}/schedules/client/${clientId}/${scheduleId}/assign-schedule`,
    {}
  );
}
/**
 * Assign SYB schedule
 */
export async function assignAllSYBSchedules(clientId: number) {
  return await axiosInterceptors.get(
    `${getEnvironment()?.HOST_ROCK_API}/schedules/client/${clientId}/assign-all-schedules`,
    {}
  );
}
