import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";

// style components
import { RootState } from "../../redux-toolkit/store";
import { MiniCard, KeyValueCard } from "../../components/cards";
import { SearchWithOptions } from "../../components/fields/search";
import { getExcludedTracks, getTotalPlaylistsAndTracks, updatePlaylist } from "../../services/playlistsServices";
import { GlobalButton } from "../../components/button";
import { AgGridReactTable } from "../../components/table";
import { format } from "date-fns";
import { LoadingData } from "../../components/loading";
import { GlobalDropdown } from "../../components/dropdown";
import {
  BsPencil,
  BsXCircle,
  BsCheckCircle,
  BsFillCheckCircleFill,
  BsFillXCircleFill,
  BsFront,
  BsSearch,
  BsDownload,
} from "react-icons/bs";
import {
  hideActionsSuccess,
  isLoadingSuccess,
  showAddPlaylistSuccess,
  showClonePlaylistSuccess,
  showEditPlaylistSuccess,
  showErrorMessageSuccess,
  showPublishPlaylistSuccess,
  showUnpublishPlaylistSuccess,
} from "../../redux-toolkit/reducers/actionsSlice";
import {
  PlaylistModal,
  PublishPlaylist,
  UnpublishPlaylist,
  ClonePlaylist,
  PlaylistTracksDuration,
  NoExcludedTracks,
} from "./components/playlist-modal/modal";
import locationsStyles from "../locations/locations.module.scss";
import { ACTIONS_ERROR_MESSAGE } from "../../constants/globalText";
import { MOODS } from "./components/mood/mood";
import { getClient, getClientForAmbieUser } from "../../services/clientsServices";
import { searchClientSuccess } from "../../redux-toolkit/reducers/searchClientSlice";
import { useSearchParams } from "react-router-dom";
import { WebSocketContext } from "../../components/websocket/websocket";
import { HandleExport } from "../../components/csv/export";

export const Playlists = () => {
  const dispatch = useDispatch();

  const [playlistFliter, setFilter] = useState<{ playlistName: string; playlistId: number }>({
    playlistName: "",
    playlistId: 0,
  });
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const playlistsSlice = useSelector((state: RootState) => state.playlistsSlice);
  const socket = useContext(WebSocketContext);
  const [getParams] = useSearchParams();
  const clientParams = getParams.get("client") || "";
  const playlistParams = getParams.get("playlist") || "";
  const [noExcludedTracks, setNoExcludedTracks] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    socket.on("updatePlaylist", (payload: { clientId: number }) => {
      if (payload.clientId === searchClient.clientId) getAllPlaylistsAndTracksData();
    });

    socket.on("newPlaylist", () => {
      getAllPlaylistsAndTracksData();
    });

    const getClientData = async () => {
      const clientId = parseInt(clientParams, 10);
      const playlistId = parseInt(playlistParams, 10);
      dispatch(
        showEditPlaylistSuccess({
          editPlaylist: {
            status: true,
            playlistId: playlistId,
          },
          playlistTitle: "Edit Playlist",
          editFromOlympus: true,
        })
      );
      dispatch(isLoadingSuccess(true));

      getClientForAmbieUser(clientId)
        .then((response) => {
          if (isSubscribed) {
            const data = response.data;
            dispatch(
              searchClientSuccess({
                clientId: data.id,
                clientName: data.name,
                clentEmail: data.contact_email,
                clentContactName: data.contact_name,
                isFrozen: data.is_frozen,
              })
            );
          }
        })
        .catch((error) => {});
    };

    const getAllPlaylistsAndTracksData = async () => {
      await getTotalPlaylistsAndTracks(searchClient.clientId);
    };

    getAllPlaylistsAndTracksData();
    if (clientParams && playlistParams) {
      getClientData();
    }

    return () => {
      socket.off("updatePlaylist", (payload: any) => {
        if (payload.clientId === searchClient.clientId) getAllPlaylistsAndTracksData();
      });
      socket.off("newPlaylist", () => {
        getAllPlaylistsAndTracksData();
      });
      isSubscribed = false;
    };
  }, [searchClient, dispatch]);

  /**
   * Publish or disable unpublish playlist
   */
  const updatePlaylistStatusData = async () => {
    const playlist = actions.editPlaylist && actions.editPlaylist;

    if (playlist.inUse && playlist.isPublished) {
      dispatch(showErrorMessageSuccess("You cannot unpublish In Use Playlist"));
      return;
    }

    dispatch(isLoadingSuccess(true));
    if (actions.unpublishPlaylist) {
      await updatePlaylist(playlist.playlistId, { is_published: false })
        .then((response) => {
          dispatch(hideActionsSuccess());
        })
        .catch((error) => {
          dispatch(isLoadingSuccess(false));
          dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
          throw new Error(error);
        });
    } else if (actions.publishPlaylist) {
      await updatePlaylist(playlist.playlistId, { is_published: true })
        .then((response) => {
          dispatch(hideActionsSuccess());
        })
        .catch((error) => {
          dispatch(isLoadingSuccess(false));
          dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
          throw new Error(error);
        });
    }
  };

  let playlistsTableRows: any = [];

  // eslint-disable-next-line array-callback-return
  playlistsSlice.playlists
    .filter((filter: { name: string; playlist_id: number }) => {
      let filterPass = true;
      if (playlistFliter.playlistName && playlistFliter.playlistId) {
        filterPass = filter.name === playlistFliter.playlistName;
      }
      return filterPass;
    })
    .sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name))
    // eslint-disable-next-line array-callback-return
    .map((data: any) => {
      const playlistLastAddedDate =
        data.composition && data.composition.length && data.composition[data.composition.length - 1].added_at
          ? data.composition[data.composition.length - 1].added_at
          : null;

      const rowData = {
        playlist: data.name,
        inUse: data.inUse,
        tracks: data.totalTracks,
        hours: data.duration,
        update: playlistLastAddedDate && format(new Date(playlistLastAddedDate), "dd/MM/yyyy H:mm"),
        created: format(new Date(data.created), "dd/MM/yyyy"),
        published: data.is_published,
        actions: data,
      };
      playlistsTableRows.push(rowData);
    });

  const playlistColumns = [
    {
      field: "playlist",
      headerName: "Playlist",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        const mood = MOODS[(params.data.actions && params.data.actions.energy_level) || "happy"];
        return (
          <>
            <div className="d-flex align-items-center">
              <img className="avatar avatar-sm me-3" src={mood.image} alt={params.data.name} loading="lazy" />
              <div>
                <span className="d-block h5 text-inherit mb-0">{params.data.playlist}</span>
                <span className="d-block fs-5 text-body">{mood.displayName}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: "inUse",
      headerName: "In use",
      width: 90,
      maxWidth: 90,
      cellRenderer: (params: any) => {
        return <>{params.data.inUse ? <span className="badge bg-soft-dark text-dark">In Use</span> : <></>}</>;
      },
    },
    {
      field: "tracks",
      headerName: "Tracks",
      width: 100,
      maxWidth: 100,
    },
    {
      field: "hours",
      headerName: "Hours",
      width: 100,
      maxWidth: 100,
      cellRenderer: (params: any) => {
        return <span>{PlaylistTracksDuration(params.data.hours || 0)}</span>;
      },
    },
    // {
    //   field: "update",
    //   headerName: "Last update",
    //   //type: "rightAligned",
    //   width: 150,
    //   maxWidth: 150,
    // },
    {
      field: "created",
      headerName: "Created",
      //type: "rightAligned",
      width: 150,
      maxWidth: 150,
    },
    {
      field: "published",
      headerName: "Published",
      // type: "rightAligned",
      width: 120,
      maxWidth: 120,
      cellRenderer: (params: any) => {
        return (
          <>
            {params.data.published ? (
              <BsFillCheckCircleFill className="text-success" />
            ) : (
              <BsFillXCircleFill className="text-danger" />
            )}
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      width: 180,
      maxWidth: 180,
      cellClass: "actions-dropdown-cell",
      filter: false,
      sortingOrder: false,
      cellRenderer: (params: any) => {
        return (
          <GlobalDropdown name="Actions" format="white" size="sm" align="end">
            <div className="dropdown-item">
              <GlobalButton
                size="sm"
                format="none"
                onClick={() => {
                  dispatch(
                    showEditPlaylistSuccess({
                      editPlaylist: {
                        status: true,
                        playlistId: params.data && params.data.actions && params.data.actions.playlist_id,
                      },
                      playlistTitle: "Edit Playlist",
                    })
                  );
                  dispatch(isLoadingSuccess(true));
                }}
              >
                <BsPencil className="dropdown-item-icon" /> Edit Playlist
              </GlobalButton>
            </div>

            <div className="dropdown-item">
              <GlobalButton
                size="sm"
                format="none"
                onClick={() => {
                  dispatch(
                    showClonePlaylistSuccess({
                      clonePlaylist: {
                        status: true,
                        playlistId: params.data && params.data.actions && params.data.actions.playlist_id,
                      },
                    })
                  );
                }}
              >
                <BsFront className="dropdown-item-icon" /> Clone
              </GlobalButton>
            </div>

            <div className="dropdown-item">
              <GlobalButton
                size="sm"
                format="none"
                onClick={() => {
                  handleExportData(params.data && params.data.actions && params.data.actions.playlist_id);
                }}
              >
                <BsDownload className="dropdown-item-icon" /> Export Excluded Tracks
              </GlobalButton>
            </div>

            <div className="dropdown-item">
              {params.data.published ? (
                <GlobalButton
                  size="sm"
                  format="none"
                  className="text-danger"
                  onClick={() => {
                    dispatch(
                      showUnpublishPlaylistSuccess({
                        unpublishPlaylist: true,
                        editPlaylist: {
                          status: false,
                          inUse: params.data.inUse,
                          playlistId: params.data && params.data.actions && params.data.actions.playlist_id,
                          isPublished: true,
                        },
                      })
                    );
                  }}
                >
                  <BsXCircle className="dropdown-item-icon text-danger" /> Unplublish
                </GlobalButton>
              ) : (
                <GlobalButton
                  size="sm"
                  format="none"
                  className="text-danger"
                  onClick={() => {
                    dispatch(
                      showPublishPlaylistSuccess({
                        publishPlaylist: true,
                        editPlaylist: {
                          status: false,
                          inUse: params.data.inUse,
                          playlistId: params.data && params.data.actions && params.data.actions.playlist_id,
                          isPublished: false,
                        },
                      })
                    );
                  }}
                >
                  <BsCheckCircle className="dropdown-item-icon text-danger" /> Publish
                </GlobalButton>
              )}
            </div>
          </GlobalDropdown>
        );
      },
    },
  ];

  // Playlist Modal - Channel Table
  let channelTableRows: any = [];
  const channelRowData = {
    channel: "Silver Lyan 5-7",
    tracks: 66,
    percentage: 50,
    actions: "",
  };
  channelTableRows.push(channelRowData);

  // export to csv
  const handleExportData = async (playlistId: number) => {
    const excludedTracks = await getExcludedTracks(playlistId);

    const excludedTracksData = excludedTracks.data;

    if (excludedTracksData.length) {
      const headings = [["Artist", "Track"]];
      return HandleExport(excludedTracksData, headings, "client-excluded-tracks", "csv");
    } else {
      setNoExcludedTracks(true);
      return true;
    }
  };

  const initialValues = {
    action: "",
  };

  return (
    <div className="content container py-3">
      <>
        {noExcludedTracks && (
          <NoExcludedTracks
            show={noExcludedTracks}
            handleClose={() => setNoExcludedTracks(false)}
            footer={
              <>
                <div className={locationsStyles.flexRowWrapModalFooter}>
                  <div className={locationsStyles.footerLeft}></div>
                  <div>
                    <GlobalButton
                      format="success"
                      disabled={actions.actionsIsLoading ? true : false}
                      onClick={() => setNoExcludedTracks(false)}
                      size="sm"
                    >
                      Ok
                    </GlobalButton>
                  </div>
                </div>
              </>
            }
          />
        )}

        {((actions.editPlaylist && actions.editPlaylist.status) || actions.addPlaylist) && <PlaylistModal />}

        {actions.unpublishPlaylist && (
          <UnpublishPlaylist
            show={actions.unpublishPlaylist}
            handleClose={() => dispatch(hideActionsSuccess())}
            footer={
              <>
                <div className={locationsStyles.flexRowWrapModalFooter}>
                  <div className={locationsStyles.footerLeft}>
                    {/* <GlobalButton
                      format="white"
                      disabled={actions.actionsIsLoading ? true : false}
                      size="sm"
                      onClick={() => dispatch(hideActionsSuccess())}
                    >
                      No
                    </GlobalButton> */}
                  </div>
                  <div>
                    <GlobalButton
                      format="success"
                      disabled={actions.actionsIsLoading ? true : false}
                      onClick={() => updatePlaylistStatusData()}
                      size="sm"
                    >
                      Yes
                    </GlobalButton>
                  </div>
                </div>
              </>
            }
          />
        )}

        {actions.publishPlaylist && (
          <PublishPlaylist
            show={actions.publishPlaylist}
            handleClose={() => dispatch(hideActionsSuccess())}
            footer={
              <>
                <div className={locationsStyles.flexRowWrapModalFooter}>
                  <div className={locationsStyles.footerLeft}>
                    <GlobalButton
                      format="white"
                      disabled={actions.actionsIsLoading ? true : false}
                      size="sm"
                      onClick={() => dispatch(hideActionsSuccess())}
                    >
                      No
                    </GlobalButton>
                  </div>
                  <div>
                    <GlobalButton
                      format="success"
                      disabled={actions.actionsIsLoading ? true : false}
                      onClick={() => updatePlaylistStatusData()}
                      size="sm"
                    >
                      Yes
                    </GlobalButton>
                  </div>
                </div>
              </>
            }
          />
        )}

        {actions.clonePlaylist && (
          <ClonePlaylist
            show={actions.clonePlaylist.status}
            handleClose={() => dispatch(hideActionsSuccess())}
            footer={
              <>
                <div className={locationsStyles.flexRowWrapModalFooter}>
                  <div className={locationsStyles.footerLeft}>
                    <GlobalButton
                      format="white"
                      disabled={actions.actionsIsLoading ? true : false}
                      size="sm"
                      onClick={() => dispatch(hideActionsSuccess())}
                    >
                      No
                    </GlobalButton>
                  </div>
                  <div>
                    <GlobalButton
                      format="success"
                      disabled={actions.actionsIsLoading ? true : false}
                      onClick={() => {
                        showClonePlaylistSuccess({
                          clonePlaylist: {
                            status: false,
                            playlistId: 0,
                          },
                        });

                        dispatch(
                          showEditPlaylistSuccess({
                            editPlaylist: {
                              status: true,
                              playlistId: actions.clonePlaylist.playlistId,
                              clone: true,
                            },
                            playlistTitle: "Clone Playlist",
                          })
                        );
                      }}
                      size="sm"
                    >
                      Yes
                    </GlobalButton>
                  </div>
                </div>
              </>
            }
          />
        )}
      </>

      <div>
        <div className="row align-items-center">
          <div className="col-sm-4" style={{ zoom: 0.95 }}>
            <MiniCard options={playlistsSlice.playlistsTracksCount} columns="col-lg-6" />
          </div>
          <div className="col-sm-auto ms-auto">
            <GlobalButton
              onClick={() => {
                dispatch(showAddPlaylistSuccess({ addPlaylist: true, playlistTitle: "Add New Playlist" }));
              }}
            >
              New Playlist
            </GlobalButton>
          </div>
        </div>
        {actions.actionsIsLoading && <LoadingData />}
        <div style={{ marginTop: "1rem" }}>
          <Formik initialValues={initialValues} onSubmit={() => {}}>
            <Form>
              <KeyValueCard
                title={
                  <SearchWithOptions
                    id="searchPlaylist"
                    placeholder="Search playlists..."
                    options={playlistsSlice.playlists}
                    labelKey="name"
                    leftIcon={<BsSearch />}
                    clearButton
                    onChange={(selected: any) => {
                      setFilter({
                        playlistId: (selected && selected[0] && selected[0].id) || 0,
                        playlistName: (selected && selected[0] && selected[0].name) || "",
                      });
                    }}
                  />
                }
              >
                <div className="row">
                  <div className="col-sm-12">
                    <AgGridReactTable
                      className="ag-theme-alpine"
                      rowData={playlistsTableRows}
                      columnDefs={playlistColumns}
                      filter={true}
                      sortable={true}
                      animateRows={true}
                      pagination={true}
                      paginationPageSize={25}
                      domLayout="autoHeight"
                    />
                  </div>
                </div>
              </KeyValueCard>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
