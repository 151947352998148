import blackLogo from "./blackLogo.svg";
import cyanLogo from "./cyanLogo.svg";
import greyLogo from "./greyLogo.svg";
import whiteLogo from "./whiteLogo.svg";
import locationIcon from "./location.svg";
import deviceIcon from "./device.svg";
import frozenImage from "./frozen.svg";

export const BlackLogoSvg = blackLogo;
export const CyanLogoSvg = cyanLogo;
export const GreyLogoSvg = greyLogo;
export const WhiteLogoSvg = whiteLogo;
export const LocationIconSvg = locationIcon;
export const DeviceIconSvg = deviceIcon;
export const frozenImageSvg = frozenImage;
