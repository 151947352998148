import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

// style components
import { Input } from "../../components/fields/input";
import { GlobalButton } from "../../components/button";
import { Toggle } from "../../components/fields/toggle";
import { login_page } from "../../constants/routePath";
import { validatePasswordResetToken, resetPassword } from "../../services/usersServices";
import { resetPasswordform } from "../../components/formValidation/formValidation";
import { useSearchParams } from "react-router-dom";
import {
  hideActionsSuccess,
  isLoadingSuccess,
  showErrorMessageSuccess,
} from "../../redux-toolkit/reducers/actionsSlice";
import { RootState } from "../../redux-toolkit/store";
import { LoadingData } from "../../components/loading";
import { GlobalErrorMessage } from "./components/errorMessage";
import { ACTIONS_ERROR_MESSAGE } from "../../constants/globalText";

export const ResetPassword: React.FC = () => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const dispatch = useDispatch();

  const [tokenValue, setTokenValue] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<string>("");
  const [getToken] = useSearchParams();
  const [passwordChange, setPasswordChange] = useState<{ success: boolean; error: boolean }>({
    success: false,
    error: false,
  });
  const token: any = getToken.get("token");
  useEffect(() => {
    (async () => {
      if (!passwordChange.success && !passwordChange.error) {
        const valid = await validatePasswordResetToken(token);
        if (valid.email) {
          setTokenValue(true);
          setValidEmail(valid.email);
        }
      }
    })();
  }, [tokenValue, passwordChange, token, validEmail]);

  const resetPasswordData = async (values: { password: string; password_confirm: string }) => {
    dispatch(isLoadingSuccess(true));
    const isChanged = await resetPassword(token.toString(), values.password, values.password_confirm, validEmail);
    if (isChanged) {
      dispatch(hideActionsSuccess());
      setPasswordChange({ error: false, success: true });
    } else {
      dispatch(isLoadingSuccess(false));
      dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      setPasswordChange({ error: true, success: false });
    }
  };

  const initialValues = { password: "", password_confirm: "", isEnabled: false };

  // invalid link
  if (!tokenValue) {
    return (
      <div className="card card-lg mb-5">
        <div className="card-body">
          <div className="text-center">
            <div className="mb-5">
              <h1 className="display-5">Invalid Link</h1>
              <p>If you know your password, please log in. Otherwise, create a new password reset request</p>
            </div>

            <div className="d-grid">
              <GlobalButton size="sm" format="primary" to={login_page}>
                Go to login
              </GlobalButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
  // password change success
  if (tokenValue && passwordChange.success) {
    return (
      <div className="card card-lg mb-5">
        <div className="card-body">
          <div className="text-center">
            <div className="mb-5">
              <h1 className="display-5">Password successfully changed</h1>
              <p>You can now log in with your new password</p>
            </div>

            <div className="d-grid">
              <GlobalButton size="sm" format="primary" to={login_page}>
                Go to login
              </GlobalButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // password change fail
  if (tokenValue && passwordChange.error) {
    return (
      <div className="card card-lg mb-5">
        <div className="card-body">
          <div className="text-center">
            <div className="mb-5">
              <h1 className="display-5">Error trying to set new password</h1>
              <p>Please create a new password reset request and try again</p>
            </div>

            <div className="d-grid">
              <GlobalButton size="sm" format="primary" to={login_page}>
                Go to login
              </GlobalButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // change password
  return (
    <div className="card card-lg mb-5">
      <div className="card-body">
        <div className="text-center">
          <div className="mb-5">
            <h1 className="display-5">Choose a new password</h1>
          </div>
        </div>

        <Formik form initialValues={initialValues} validationSchema={resetPasswordform} onSubmit={resetPasswordData}>
          {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors }) => (
            <div>
              <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
              {actions.actionsIsLoading && <LoadingData />}

              <Input
                placeholder="New password"
                name="password"
                id="password"
                label="New password"
                type={values.isEnabled ? "text" : "password"}
                onBlur={handleBlur("password")}
                onChange={handleChange("password")}
                error={errors.password}
                //testID="username"
                onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
              />
              <Input
                placeholder="Confirm new password"
                id="password_confirm"
                name="password_confirm"
                label="Confirm new password"
                type={values.isEnabled ? "text" : "password"}
                onBlur={handleBlur("password_confirm")}
                autoCapitalize="none"
                onChange={handleChange("password_confirm")}
                error={errors.password_confirm}
                //testID="password_confirm"
                onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
              />

              <div className="row">
                <div className="col-sm-6">
                  <Toggle
                    type="checkbox"
                    name="isEnabled"
                    id="isEnabled"
                    label={values.isEnabled ? "HIDE PASSWORD" : "SHOW PASSWORD"}
                    rightLabel
                    onChange={(value: any) => {
                      const targetValue = value.target && value.target.value === "true" ? false : true;
                      setFieldValue(`isEnabled`, targetValue);
                    }}
                  />
                </div>
                <div className="col-sm-6 text-right">
                  <GlobalButton size="sm" format="primary" onClick={() => handleSubmit()}>
                    Login
                  </GlobalButton>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
