import { GlobalButton } from "../../../../components/button";

// style components
import { GlobalModal } from "../../../../components/modal";
import modalStyles from "../../../locations/components/location-actions/location-actions.module.scss";
import locationStyles from "../../../locations/locations.module.scss";

interface ScheduleProps {
  size?: "sm" | "lg" | "xl";
  backdrop?: "static" | true | false;
  centered?: true | false;
  scrollable?: true | false;
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  footer?: any;
  title?: string;
  show: boolean;
  location?: { name: string; email: string; id: number };
  handleClose: any; //function
  handleCancelDeleteChannel: any; //function
  handleConfirmedDeleteChannel: any; //function
}

/**
 * Generate reset password link
 */
export const ScheduleDeleteConfirmationPopup = ({
  footer,
  show,
  handleClose,
  handleCancelDeleteChannel,
  handleConfirmedDeleteChannel,
}: ScheduleProps) => {
  return (
    <GlobalModal title="This will delete the scheduled playlist" show={show} handleClose={handleClose} footer={footer}>
      <div className={modalStyles.modal}>
        <p>Are you sure you want to do this?</p>
        <div className={locationStyles.flexRowWrapModalFooter}>
          <div className={locationStyles.footerLeft}>
            <GlobalButton format="white" size="sm" onClick={() => handleCancelDeleteChannel()}>
              Cancel
            </GlobalButton>
          </div>
          <div>
            <GlobalButton format="success" type="submit" size="sm" onClick={() => handleConfirmedDeleteChannel()}>
              Yes
            </GlobalButton>
          </div>
        </div>
      </div>
    </GlobalModal>
  );
};
