import { differenceInMilliseconds } from "date-fns";

export const DevicesStatus = (item: any) => {
  if (item.player_id && differenceInMilliseconds(Date.now(), item.last_heart_beat) / 60000 < 10) {
    return <span className="badge bg-soft-success text-success p-2">Online</span>;
  } else if (item.player_id && differenceInMilliseconds(Date.now(), item.last_heart_beat) / 60000 > 10) {
    return <span className="badge bg-soft-danger text-danger p-2">Offline</span>;
  } else if (item.player_id === "No Player") {
    return <span className="badge bg-soft-dark text-dark p-2">No Player</span>;
  }
};

export const AreaStatus = (status: any) => {
  if (status) {
    return <span className="badge bg-soft-success text-success p-2">Active</span>;
  } else {
    return <span className="badge bg-soft-danger text-danger p-2">Inactive</span>;
  }
};

export const DevicePlayingStatus = (item: any) => {
  const deviceHasFields = item && item.is_active && item.last_played && item.duration;
  const onlinePlayers = item.player_id && differenceInMilliseconds(Date.now(), item.last_heart_beat) / 60000 < 10;

  if (
    deviceHasFields &&
    onlinePlayers &&
    item.player_status === "play" &&
    differenceInMilliseconds(Date.now() - 1000 * 60 * 10, item.last_played) / 1000 < item.duration
  ) {
    return <span className="badge bg-soft-success text-success p-2">Playing</span>;
  } else if (deviceHasFields && onlinePlayers && item.player_status === "pause") {
    return <span className="badge bg-soft-info text-info p-2">Paused</span>;
  } else {
    return <span className="badge bg-soft-dark text-dark p-2">Not Playing</span>;
  }
};

export const DevicesStatusCSV = (item: any) => {
  if (item.player_id && differenceInMilliseconds(Date.now(), item.last_heart_beat) / 60000 < 10) {
    return "Online";
  } else if (item.player_id && differenceInMilliseconds(Date.now(), item.last_heart_beat) / 60000 > 10) {
    return "Offline";
  } else if (item.player_id === "No Player") {
    return "No Player";
  }
};

export const AreaStatusCSV = (status: any) => {
  if (status) {
    return "Active";
  } else {
    return "Inactive";
  }
};

export const DevicePlayingStatusCSV = (item: any) => {
  const deviceHasFields = item && item.is_active && item.last_played && item.duration;
  const onlinePlayers = item.player_id && differenceInMilliseconds(Date.now(), item.last_heart_beat) / 60000 < 10;

  if (
    deviceHasFields &&
    onlinePlayers &&
    item.player_status === "play" &&
    differenceInMilliseconds(Date.now() - 1000 * 60 * 10, item.last_played) / 1000 < item.duration
  ) {
    return "Playing";
  } else if (deviceHasFields && onlinePlayers && item.player_status === "pause") {
    return "Paused";
  } else {
    return "Not Playing";
  }
};
