import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// style components
import { getUser } from "../../utils/localStorage";
import { LOCAL_STORAGE_USER_TOKEN } from "../../constants/defaultValues";

const userRecord = getUser();
export interface UserState {
  user: {
    status?: number;
    isGod?: boolean;
    permissionGroup?: string;
    username?: string;
  };
  token: string;
  clientsList: [];
  clients: [];
  clientBusinessesList: [];
  users: [];
  ambieUsers: [];
  userDetails: any;
  clientsUsers: [];
}
const initialState: any = {
  user: userRecord || {},
  token: Cookies.get(LOCAL_STORAGE_USER_TOKEN) || "",
  clientsList: [],
  clients: [],
  clientBusinessesList: [],
  users: [],
  ambieUsers: [],
  userDetails: {},
  clientsUsers: [],
};

const authSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<any>) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    logoutSuccess: (state) => {
      state.user = {};
    },
    clientsListSuccess: (state, action: PayloadAction<any>) => {
      state.clientsList = action.payload.clientsList;
    },
    clientsSuccess: (state, action: PayloadAction<any>) => {
      state.clients = action.payload.clients;
    },
    clientBusinessesListSuccess: (state, action: PayloadAction<any>) => {
      state.clientBusinessesList = action.payload.clientBusinessesList;
    },
    usersSuccess: (state, action: PayloadAction<any>) => {
      state.users = action.payload.users;
    },
    ambieUsersSuccess: (state, action: PayloadAction<any>) => {
      state.ambieUsers = action.payload.ambieUsers;
    },
    userDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.userDetails = action.payload.userDetails;
    },
    clientsUsersSucess: (state, action: PayloadAction<any>) => {
      state.clientsUsers = action.payload.clientsUsers;
    },
  },
});

export const {
  loginSuccess,
  logoutSuccess,
  clientsListSuccess,
  clientsSuccess,
  clientBusinessesListSuccess,
  usersSuccess,
  ambieUsersSuccess,
  userDetailsSuccess,
  clientsUsersSucess,
} = authSlice.actions;
export default authSlice.reducer;
