import React, { useState } from "react";
import { Formik } from "formik";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

// style components
import { Input } from "../../components/fields/input";
import { GlobalButton } from "../../components/button";
import { login_page } from "../../constants/routePath";
import styles from "./users.module.scss";
import { forgotPasswordForm } from "../../components/formValidation/formValidation";
import { requestPasswordReset } from "../../services/usersServices";
import { GlobalErrorMessage } from "./components/errorMessage";
import {
  hideActionsSuccess,
  isLoadingSuccess,
  showErrorMessageSuccess,
} from "../../redux-toolkit/reducers/actionsSlice";
import { RootState } from "../../redux-toolkit/store";
import { LoadingData } from "../../components/loading";

export const ForgotPassword: React.FC = () => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const [state, setState] = useState<boolean>(false);
  const dispatch = useDispatch();

  type emailParams = { email: string };
  async function forgotPasswordData(values: emailParams) {
    dispatch(isLoadingSuccess(true));
    const referrer = window.location.hostname === "localhost" ? "localhost:3000" : window.location.hostname;

    // .replace(/ /g, "") regx is used to remove empty space
    requestPasswordReset(values.email.replace(/ /g, ""), referrer)
      .then((response) => {
        setState(true);
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        setState(true);
        dispatch(hideActionsSuccess());
      });
  }

  const initialValues = { email: "" };
  if (state) {
    return (
      <div className="card card-lg mb-5">
        <div className="card-body">
          <div className="text-center">
            <div className="mb-5">
              <h1 className="display-5">Reset Email Sent</h1>
              <p>
                Please check your inbox for a link to reset your password. If it doesn't appear within a few minutes,
                check your spam folder and contact{" "}
                <GlobalButton size="none" format="none" href="mailto:support@ambie.fm">
                  support@ambie.fm
                </GlobalButton>
              </p>
            </div>

            <GlobalButton size="lg" to={login_page} className={styles.backToLogin}>
              Back
            </GlobalButton>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="card card-lg mb-5">
      <div className="card-body">
        <div className="text-center">
          <div className="mb-5">
            <h1 className="display-5">Reset Password</h1>
            <p>Please enter your email address and we'll send you a link to reset your password.</p>
          </div>
        </div>

        <Formik form initialValues={initialValues} validationSchema={forgotPasswordForm} onSubmit={forgotPasswordData}>
          {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
            <div>
              <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
              {actions.actionsIsLoading && <LoadingData />}
              <Input
                placeholder="Email address"
                name="email"
                id="email"
                onBlur={handleBlur("email")}
                autoCapitalize="none"
                onChange={handleChange("email")}
                error={errors.email}
                //testID="username"
                onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
              />

              <div className={classNames("d-flex justify-content-between")}>
                <div className="me-3">
                  <GlobalButton
                    size="md"
                    format="secondary"
                    to={login_page}
                    className="block"
                    onClick={() => {
                      dispatch(showErrorMessageSuccess(""));
                    }}
                  >
                    Cancel
                  </GlobalButton>
                </div>
                <div>
                  <GlobalButton size="md" format="primary" onClick={() => handleSubmit()} className="block">
                    Submit
                  </GlobalButton>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
