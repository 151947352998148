import React, { useState, useEffect, useContext } from "react";
import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// style components
import { Input } from "../../../../components/fields/input";
import { forgotPasswordForm, renameClientForm } from "../../../../components/formValidation/formValidation";
import { GlobalModal } from "../../../../components/modal";
import {
  hideActionsSuccess,
  showErrorMessageSuccess,
  isLoadingSuccess,
} from "../../../../redux-toolkit/reducers/actionsSlice";
import styles from "./location-actions.module.scss";
import { RootState } from "../../../../redux-toolkit/store";
import { GlobalErrorMessage, SuccessMessage } from "../../../users/components/errorMessage";
import { LoadingData } from "../../../../components/loading";
import { ACTIONS_ERROR_MESSAGE } from "../../../../constants/globalText";
import { getClient, updateClientRecord } from "../../../../services/clientsServices";
import { WebSocketContext } from "../../../../components/websocket/websocket";

interface LocationActionsProps {
  size?: "sm" | "lg" | "xl";
  backdrop?: "static" | true | false;
  centered?: true | false;
  scrollable?: true | false;
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  footer?: any;
  title?: string;
  show: boolean;
  link?: string;
  location?: { name: string; email: string; id: number };
  handleClose: any;
  children?: any;
}

/**
 * Rename client name
 */
export const RenameClient = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const [clientName, setClientName] = useState<string>("");
  const socket = useContext(WebSocketContext);

  const dispatch = useDispatch();
  const clientId =
    actions.clientDetails && actions.clientDetails.status ? actions.clientDetails.id : searchClient.clientId;

  useEffect(() => {
    socket.on("updateClient", (payload: { id: number }) => {
      if (payload.id === clientId) getClientData();
    });

    const getClientData = async () => {
      await getClient(clientId)
        .then((response) => {
          setClientName(response.data && response.data.name);
        })
        .catch((error) => {});
    };

    getClientData();

    return () => {
      socket.off("updateClient", (payload: { id: number }) => {
        if (payload.id === clientId) getClientData();
      });
    };
  }, [clientName, searchClient, clientId]);

  const renameClientNameData = async (values: any) => {
    dispatch(isLoadingSuccess(true));
    await updateClientRecord(clientId, { name: values.clientName })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  return (
    <GlobalModal title="Rename Client" show={show} handleClose={handleClose}>
      <div className={styles.modal}>
        <Formik
          form
          initialValues={{ clientName: clientName }}
          validationSchema={renameClientForm}
          onSubmit={renameClientNameData}
          enableReinitialize
        >
          {({ handleChange, handleBlur, values, errors }) => (
            <Form>
              <div>
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <div>
                  <Input
                    id="clientName"
                    name="clientName"
                    type="text"
                    placeholder="Client name"
                    onChange={handleChange("clientName")}
                    value={values.clientName}
                    onBlur={handleBlur("clientName")}
                    autoCapitalize="none"
                    error={errors.clientName}
                    size="form-control-sm"
                    onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                  />
                </div>

                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GlobalModal>
  );
};

/**
 * Change client email address
 */
export const ChangeClientEmailAddress = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const [clientEmail, setClientEmail] = useState<string>("");

  const dispatch = useDispatch();

  useEffect(() => {
    const getClientData = async () => {
      await getClient(searchClient.clientId)
        .then((response) => {
          setClientEmail(response.data && response.data.contact_email);
        })
        .catch((error) => {});
    };

    getClientData();
  }, [clientEmail, searchClient]);

  const updateEmailAddressData = async (values: any) => {
    dispatch(isLoadingSuccess(true));
    await updateClientRecord(searchClient.clientId, { contact_email: values.email })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  return (
    <GlobalModal title="Change Client Email Address" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <Formik
          form
          initialValues={{ email: clientEmail }}
          validationSchema={forgotPasswordForm}
          onSubmit={updateEmailAddressData}
          enableReinitialize
        >
          {({ handleChange, handleBlur, values, errors }) => (
            <Form>
              <div>
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <div>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={handleChange("email")}
                    value={values.email}
                    onBlur={handleBlur("email")}
                    autoCapitalize="none"
                    error={errors.email}
                    size="form-control-sm"
                    onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                  />
                </div>

                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GlobalModal>
  );
};

/**
 * Send password reset link to the client email address
 */
export const SendClientPasswordResetEmail = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);

  return (
    <GlobalModal title="Send Client Password Reset Email" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Do you want to send a password reset link to <b>{searchClient && searchClient.clentEmail}</b> email address?
        </p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Generate reset password link
 */
export const GenerateClientSetPasswordLink = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);

  return (
    <GlobalModal title="Generate Client Set Password Link" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Do you want to generate a password reset link for <b>{searchClient && searchClient.clientName}</b>?
        </p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Copy a generated reset password link
 */
export const CopyGenerateClientSetPasswordLink = ({ footer, link, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Generate client Set Password Link" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <div>{actions.actionsSuccessMessage && <SuccessMessage message={actions.actionsSuccessMessage} />}</div>
        <p>{link}</p>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Freeze account
 */
export const ToggleFreezeClient = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);

  return (
    <GlobalModal
      title={searchClient.isFrozen ? "Unfreeze Client" : "Freeze Client"}
      show={show}
      handleClose={handleClose}
      footer={footer}
    >
      <div className={styles.modal}>
        <p>
          Are you sure you want to {searchClient.isFrozen ? "unfreeze" : "freeze"}{" "}
          <b>{searchClient && searchClient.clientName}</b> client?
        </p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};
