import { createSlice } from "@reduxjs/toolkit";

export interface ShakeAnimationState {
  shakeAnimation: boolean;
}

let initialState: ShakeAnimationState = {
  shakeAnimation: false,
};

const shakeAnimationSlice = createSlice({
  name: "shakeAnimation",
  initialState,
  reducers: {
    shakeAnimationSuccess: (state) => {
      state.shakeAnimation = state.shakeAnimation ? false : true;
    },
    logoutSuccess: (state) => {
      state.shakeAnimation = false;
    },
  },
});

export const { shakeAnimationSuccess, logoutSuccess } = shakeAnimationSlice.actions;
export default shakeAnimationSlice.reducer;
