// main route
export const login_page = "/login";
export const forgot_password_page = "/forgot-password";
export const reset_password_page = "/reset-password";
export const reusable_components_page = "/reusable-components";
export const clients_page = "/clients";
export const client_page = "/client";
export const content_management_page = "/content";
export const tools_page = "/tools";
export const ambie_page = "/ambie";

// navigate
export const dashboard_page_navigate = "/client/dashboard";
export const schedules_page_navigate = "/client/schedules";
export const locations_page_navigate = "/client/locations";
export const playlists_page_navigate = "/client/playlists";
export const users_page_navigate = "/client/users";
export const schedules_migration_page_navigate = "/client/schedules-migration";

// sub ambie
export const briefs_page = "briefs";

// sub client
export const dashboard_page = "dashboard";
export const locations_page = "locations";
export const playlists_page = "playlists";
export const schedules_page = "schedules";
export const schedules_migration_page = "schedules-migration";
export const users_page = "users";

//  navigation tools
export const devices_tools_page_navigate = "/tools/devices";
export const clients_tools_page_navigate = "/tools/clients";
export const ambie_users_tools_page_navigate = "/tools/ambie/users";
export const clienst_users_tools_page_navigate = "/tools/client/users";

export const locations_tools_page_navigate = "/tools/locations";
export const areas_tools_page_navigate = "/tools/areas";
export const briefs_ambie_page_navigate = "/ambie/briefs";

// sub tools
export const devices_tools_page = "devices";
export const clients_tools_page = "clients";
export const ambie_users_tools_page = "ambie/users";
export const clients_users_tools_page = "client/users";
export const locations_tools_page = "locations";
export const areas_tools_page = "areas";
