export const FeedbackSample = [
  {
    token: "75d61d2eec99cd11e5561bd4311d72c1",
    result: "success",
    results: [
      {
        type: "Too slow",
        track: "Goody Two Shoes",
        artist: "Adam Ant",
        track_id: 141,
        preview: "p/574/SpFDPwchBKkyP0TtC3JS/PlhgjQmoi7tbqmR6.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1665591487711,
        _id: "6346e8bf172e4b00092959f5",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "positive",
        track: "Goody Two Shoes",
        artist: "Adam Ant",
        track_id: 141,
        preview: "p/574/SpFDPwchBKkyP0TtC3JS/PlhgjQmoi7tbqmR6.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "working as expected",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1665591474448,
        _id: "6346e8b2172e4b00092959f4",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "positive",
        track: "Endless Love (feat. Betsy)",
        artist: "Joe Goddard",
        track_id: 19,
        preview: "previews/066/6415a9b0ca3b03bc0354/7b4971z8cmft4lrl.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1665590901347,
        _id: "6346e67584cccd0009c5ddf3",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "positive",
        track: "I've Been Waiting",
        artist: "Dear David",
        track_id: 30,
        preview: "previews/069/ebd80adf262e1b2f4955/yqdbopw9li3wcdur.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "Nice track, i really enjoy the song",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1665320864390,
        _id: "6342c7a04d97c60009cdd214",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Poor quality",
        track: "Celestial (Reprise)",
        artist: "VIMES",
        track_id: 11,
        preview: "previews/064/410d1a8f4bd422ae3efc/w70qec1x6kzs3jj7.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "it is no working as expected",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1661769755481,
        _id: "630c981bb7dfad0009130035",
        approval: {
          required: false,
          approved: true,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "positive",
        track: "I've Been Waiting",
        artist: "Dear David",
        track_id: 30,
        preview: "previews/069/ebd80adf262e1b2f4955/yqdbopw9li3wcdur.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "working fine",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1661766486866,
        _id: "630c8b56b527ae00099486d2",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Too fast",
        track: "Love Alight (Jean Tonique Remix)",
        artist: "Crystal Fighters",
        track_id: 39,
        preview: "previews/071/e07840705f3be998719c/xvbf0k8yb5dcpi36.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "To slow",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1661380865904,
        _id: "6306a901e0a52e00097ae370",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Chased",
        artist: "Raleigh Ritchie & The Internet",
        track_id: 12,
        preview: "previews/064/36de8d90016081da73da/wnxfz2egjs5rjx5i.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1660228015307,
        _id: "62f511af9759d5000a354d60",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Poor quality",
        track: "In Your Soul",
        artist: "The Checkup",
        track_id: 33,
        preview: "previews/070/e6e8aa21568ff4c0e80a/tyw0cf2yl1ikd1tz.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659972798563,
        _id: "62f12cbe858fc10009e5c436",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Cliché",
        artist: "Hey Champ",
        track_id: 13,
        preview: "previews/064/b3813fc17bf7c6c36a97/bk8rswb1qkqmpsko.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659972793049,
        _id: "62f12cb9858fc10009e5c435",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Love Alight (Jean Tonique Remix)",
        artist: "Crystal Fighters",
        track_id: 39,
        preview: "previews/071/e07840705f3be998719c/xvbf0k8yb5dcpi36.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659972616127,
        _id: "62f12c08858fc10009e5c434",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "In Your Soul",
        artist: "The Checkup",
        track_id: 33,
        preview: "previews/070/e6e8aa21568ff4c0e80a/tyw0cf2yl1ikd1tz.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659972611462,
        _id: "62f12c03858fc10009e5c433",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "In Your Soul",
        artist: "The Checkup",
        track_id: 33,
        preview: "previews/070/e6e8aa21568ff4c0e80a/tyw0cf2yl1ikd1tz.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659972604644,
        _id: "62f12bfc858fc10009e5c432",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Poor quality",
        track: "Love Alight (Jean Tonique Remix)",
        artist: "Crystal Fighters",
        track_id: 39,
        preview: "previews/071/e07840705f3be998719c/xvbf0k8yb5dcpi36.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659972539602,
        _id: "62f12bbb858fc10009e5c431",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Bad language",
        track: "In Your Soul",
        artist: "The Checkup",
        track_id: 33,
        preview: "previews/070/e6e8aa21568ff4c0e80a/tyw0cf2yl1ikd1tz.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659972530520,
        _id: "62f12bb2858fc10009e5c430",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Love Alight (Jean Tonique Remix)",
        artist: "Crystal Fighters",
        track_id: 39,
        preview: "previews/071/e07840705f3be998719c/xvbf0k8yb5dcpi36.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659972521902,
        _id: "62f12ba9858fc10009e5c42f",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Love Alight (Jean Tonique Remix)",
        artist: "Crystal Fighters",
        track_id: 39,
        preview: "previews/071/e07840705f3be998719c/xvbf0k8yb5dcpi36.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659972365621,
        _id: "62f12b0d858fc10009e5c42e",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Love Alight (Jean Tonique Remix)",
        artist: "Crystal Fighters",
        track_id: 39,
        preview: "previews/071/e07840705f3be998719c/xvbf0k8yb5dcpi36.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659972325964,
        _id: "62f12ae544d125000a9e4425",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971942026,
        _id: "62f12966582fa30009dd0056",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971935293,
        _id: "62f1295f582fa30009dd0055",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971929037,
        _id: "62f12959582fa30009dd0054",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971899114,
        _id: "62f1293b582fa30009dd0053",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971891428,
        _id: "62f12933582fa30009dd0052",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Lips",
        artist: "Marian Hill",
        track_id: 37,
        preview: "previews/071/e4b35954044a33a47f83/td1z0dweuyua2vrr.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971885284,
        _id: "62f1292d582fa30009dd0051",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Lips",
        artist: "Marian Hill",
        track_id: 37,
        preview: "previews/071/e4b35954044a33a47f83/td1z0dweuyua2vrr.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971737897,
        _id: "62f12899582fa30009dd0050",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Lips",
        artist: "Marian Hill",
        track_id: 37,
        preview: "previews/071/e4b35954044a33a47f83/td1z0dweuyua2vrr.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971712240,
        _id: "62f12880582fa30009dd004f",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Lips",
        artist: "Marian Hill",
        track_id: 37,
        preview: "previews/071/e4b35954044a33a47f83/td1z0dweuyua2vrr.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971705258,
        _id: "62f12879582fa30009dd004e",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Lips",
        artist: "Marian Hill",
        track_id: 37,
        preview: "previews/071/e4b35954044a33a47f83/td1z0dweuyua2vrr.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971697233,
        _id: "62f12871582fa30009dd004d",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971689064,
        _id: "62f12869582fa30009dd004c",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971679810,
        _id: "62f1285f582fa30009dd004b",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971667650,
        _id: "62f12853582fa30009dd004a",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Lips",
        artist: "Marian Hill",
        track_id: 37,
        preview: "previews/071/e4b35954044a33a47f83/td1z0dweuyua2vrr.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659971654290,
        _id: "62f12846582fa30009dd0049",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "positive",
        track: "Lifespan",
        artist: "Vaults",
        track_id: 35,
        preview: "previews/070/ceca3760dc35751d8461/mda633gncqp4wu2r.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "working fine",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659970261889,
        _id: "62f122d562a4f800090d5554",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Ich See",
        artist: "Patrick Zigon",
        track_id: 31,
        preview: "previews/069/461811aaa896d8ad1be3/hoysqldfr9z0oamv.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659968634825,
        _id: "62f11c7a45e5ba000947f031",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Ich See",
        artist: "Patrick Zigon",
        track_id: 31,
        preview: "previews/069/461811aaa896d8ad1be3/hoysqldfr9z0oamv.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659968614883,
        _id: "62f11c6645e5ba000947f030",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Annoying",
        track: "Expectations",
        artist: "Brika",
        track_id: 21,
        preview: "previews/067/80729371b0fafe5e577e/n3mbloheelr0saah.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659456143940,
        _id: "62e94a8f726b76000ad9cf1c",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Expectations",
        artist: "Brika",
        track_id: 21,
        preview: "previews/067/80729371b0fafe5e577e/n3mbloheelr0saah.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1659456132880,
        _id: "62e94a84726b76000ad9cf1b",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Too fast",
        track: "Deep End (feat. Eastside and Mayer Hawthorne) [Matoma Remix]",
        artist: "Coucheron",
        track_id: 15,
        preview: "previews/065/f7cdc6d09e0f84603961/5k4l4kv2ujl9g8gt.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658488211994,
        _id: "62da8594b2361c000965a65a",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "C O O L",
        artist: "Le Youth",
        track_id: 10,
        preview: "previews/063/21375c53f0b52d5c7951/4bq5gjgv9u5pgw9h.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "Great track",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658467742151,
        _id: "62da359eb675f800094a001e",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Ich See",
        artist: "Patrick Zigon",
        track_id: 31,
        preview: "previews/069/461811aaa896d8ad1be3/hoysqldfr9z0oamv.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658402365431,
        _id: "62d9363d565bee0009eb1576",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658402344337,
        _id: "62d93628565bee0009eb1575",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658402332478,
        _id: "62d9361c565bee0009eb1574",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658402227796,
        _id: "62d935b3565bee0009eb1573",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658402215416,
        _id: "62d935a7565bee0009eb1572",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Bad language",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658402036902,
        _id: "62d934f4565bee0009eb1571",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658402029017,
        _id: "62d934ed565bee0009eb1570",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Bones Without You",
        artist: "Panes",
        track_id: 9,
        preview: "previews/063/c6ddfc6fc11686f6539a/lrgmmkrhbu951m5b.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658400565677,
        _id: "62d92f354d7ece00098b1202",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Bad language",
        track: "Bones Without You",
        artist: "Panes",
        track_id: 9,
        preview: "previews/063/c6ddfc6fc11686f6539a/lrgmmkrhbu951m5b.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658400247921,
        _id: "62d92df764ed3300093801d1",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Bones Without You",
        artist: "Panes",
        track_id: 9,
        preview: "previews/063/c6ddfc6fc11686f6539a/lrgmmkrhbu951m5b.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658400241260,
        _id: "62d92df164ed3300093801d0",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Bones Without You",
        artist: "Panes",
        track_id: 9,
        preview: "previews/063/c6ddfc6fc11686f6539a/lrgmmkrhbu951m5b.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658400225661,
        _id: "62d92de164ed3300093801cf",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Poor quality",
        track: "C O O L",
        artist: "Le Youth",
        track_id: 10,
        preview: "previews/063/21375c53f0b52d5c7951/4bq5gjgv9u5pgw9h.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658397895893,
        _id: "62d924c76fcd750009823040",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Lifespan",
        artist: "Vaults",
        track_id: 35,
        preview: "previews/070/ceca3760dc35751d8461/mda633gncqp4wu2r.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658397887252,
        _id: "62d924bf6fcd75000982303f",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Too fast",
        track: "Cliché",
        artist: "Hey Champ",
        track_id: 13,
        preview: "previews/064/b3813fc17bf7c6c36a97/bk8rswb1qkqmpsko.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "hdujsje",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658393665532,
        _id: "62d91441f2b26d0009650506",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Celestial (Reprise)",
        artist: "VIMES",
        track_id: 11,
        preview: "previews/064/410d1a8f4bd422ae3efc/w70qec1x6kzs3jj7.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658385995297,
        _id: "62d8f64b46364100094249f9",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "In Your Soul",
        artist: "The Checkup",
        track_id: 33,
        preview: "previews/070/e6e8aa21568ff4c0e80a/tyw0cf2yl1ikd1tz.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658362335784,
        _id: "62d899df0bdec60009912f29",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Love Alight (Jean Tonique Remix)",
        artist: "Crystal Fighters",
        track_id: 39,
        preview: "previews/071/e07840705f3be998719c/xvbf0k8yb5dcpi36.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658362103582,
        _id: "62d898f70bdec60009912f28",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Bad language",
        track: "Love Alight (Jean Tonique Remix)",
        artist: "Crystal Fighters",
        track_id: 39,
        preview: "previews/071/e07840705f3be998719c/xvbf0k8yb5dcpi36.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658362096922,
        _id: "62d898f00bdec60009912f27",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "In Your Soul",
        artist: "The Checkup",
        track_id: 33,
        preview: "previews/070/e6e8aa21568ff4c0e80a/tyw0cf2yl1ikd1tz.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658362081862,
        _id: "62d898e10bdec60009912f26",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Ich See",
        artist: "Patrick Zigon",
        track_id: 31,
        preview: "previews/069/461811aaa896d8ad1be3/hoysqldfr9z0oamv.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658361296869,
        _id: "62d895d0ed2e3f000a8ae4f1",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Overplayed",
        track: "Ich See",
        artist: "Patrick Zigon",
        track_id: 31,
        preview: "previews/069/461811aaa896d8ad1be3/hoysqldfr9z0oamv.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658361290606,
        _id: "62d895caed2e3f000a8ae4f0",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Ich See",
        artist: "Patrick Zigon",
        track_id: 31,
        preview: "previews/069/461811aaa896d8ad1be3/hoysqldfr9z0oamv.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658361281965,
        _id: "62d895c2ed2e3f000a8ae4ef",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Listen to Soul, Listen to Blues",
        artist: "SAFIA",
        track_id: 38,
        preview: "previews/071/e68fa3e90ac3ba0efd48/vgrqnc7mznin5axy.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658360829500,
        _id: "62d893fdd8585000092c87f6",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658360786427,
        _id: "62d893d2d8585000092c87f5",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658360571225,
        _id: "62d892fbd8585000092c87f4",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658360519265,
        _id: "62d892c7d8585000092c87f3",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658360459847,
        _id: "62d8928bd8585000092c87f2",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658360357684,
        _id: "62d89225d8585000092c87f1",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658360169030,
        _id: "62d89169d8585000092c87f0",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Too fast",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658360113451,
        _id: "62d89131d8585000092c87ef",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658360104276,
        _id: "62d89128d8585000092c87ee",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658360072314,
        _id: "62d89108d8585000092c87ed",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Poor quality",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658360064542,
        _id: "62d89100d8585000092c87ec",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Bones Without You",
        artist: "Panes",
        track_id: 9,
        preview: "previews/063/c6ddfc6fc11686f6539a/lrgmmkrhbu951m5b.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658359958754,
        _id: "62d89096d8585000092c87eb",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Bones Without You",
        artist: "Panes",
        track_id: 9,
        preview: "previews/063/c6ddfc6fc11686f6539a/lrgmmkrhbu951m5b.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658359941821,
        _id: "62d89085d8585000092c87ea",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658359921106,
        _id: "62d89071d8585000092c87e9",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658359890701,
        _id: "62d89052d8585000092c87e8",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658359494848,
        _id: "62d88ec68a7a1600095d054c",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658359487965,
        _id: "62d88ebf8a7a1600095d054b",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658359452836,
        _id: "62d88e9c8a7a1600095d054a",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658359397803,
        _id: "62d88e658a7a1600095d0549",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658359343788,
        _id: "62d88e2f8a7a1600095d0548",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658359315362,
        _id: "62d88e138a7a1600095d0547",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658359090579,
        _id: "62d88d328a7a1600095d0546",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658359032382,
        _id: "62d88cf88a7a1600095d0545",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658358855825,
        _id: "62d88c478a7a1600095d0544",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658358818967,
        _id: "62d88c228a7a1600095d0543",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658358775306,
        _id: "62d88bf78a7a1600095d0542",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658358663581,
        _id: "62d88b878a7a1600095d0541",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658358593317,
        _id: "62d88b418a7a1600095d0540",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658358584826,
        _id: "62d88b388a7a1600095d053f",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658358572358,
        _id: "62d88b2c8a7a1600095d053e",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658358316984,
        _id: "62d88a2c8a7a1600095d053d",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658358231294,
        _id: "62d889d78a7a1600095d053c",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658358162461,
        _id: "62d889928a7a1600095d053b",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658358139054,
        _id: "62d8897b8a7a1600095d053a",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658357807143,
        _id: "62d8882f8a7a1600095d0539",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Annoying",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658357795293,
        _id: "62d888238a7a1600095d0538",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Poor quality",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "not working properly ",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658357783471,
        _id: "62d888178a7a1600095d0537",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658357760711,
        _id: "62d888008a7a1600095d0536",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Poor quality",
        track: "Bones Without You",
        artist: "Panes",
        track_id: 9,
        preview: "previews/063/c6ddfc6fc11686f6539a/lrgmmkrhbu951m5b.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658357367284,
        _id: "62d88677d852cb00092e9456",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Limitless",
        artist: "One Bit",
        track_id: 36,
        preview: "previews/071/0645258e7eab1a75f369/0nwke2y17wkg60rf.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658357325587,
        _id: "62d8864dd852cb00092e9455",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Music from beautiful Benin",
        playlist_id: 113,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658337523057,
        _id: "62d838f39b53040009a57cc9",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Music from beautiful Benin",
        playlist_id: 113,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658337491975,
        _id: "62d838d39b53040009a57cc8",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Music from beautiful Benin",
        playlist_id: 113,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658337486797,
        _id: "62d838ce9b53040009a57cc7",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Music from beautiful Benin",
        playlist_id: 113,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658337259072,
        _id: "62d837eb9b53040009a57cc6",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Goody Two Shoes",
        artist: "Adam Ant",
        track_id: 141,
        preview: "p/574/SpFDPwchBKkyP0TtC3JS/PlhgjQmoi7tbqmR6.mp3",
        playlist: "Music from beautiful Benin",
        playlist_id: 113,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658337233439,
        _id: "62d837d19b53040009a57cc5",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Music from beautiful Benin",
        playlist_id: 113,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658337149155,
        _id: "62d8377d9b53040009a57cc4",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Bad language",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Music from beautiful Benin",
        playlist_id: 113,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658336993984,
        _id: "62d836e19b53040009a57cc3",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Music from beautiful Benin",
        playlist_id: 113,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658336974198,
        _id: "62d836ce9b53040009a57cc2",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Music from beautiful Benin",
        playlist_id: 113,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658336964436,
        _id: "62d836c49b53040009a57cc1",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Music from beautiful Benin",
        playlist_id: 113,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658336908732,
        _id: "62d8368c9b53040009a57cc0",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Music from beautiful Benin",
        playlist_id: 113,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658336902035,
        _id: "62d836869b53040009a57cbf",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Music from beautiful Benin",
        playlist_id: 113,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658336834295,
        _id: "62d836429b53040009a57cbe",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Overplayed",
        track: "Every Little Word",
        artist: "MNEK",
        track_id: 20,
        preview: "previews/066/200af7a6fbb21c598155/z7ty09fpdig44yhg.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "ighhhh jhuj",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658279737831,
        _id: "62d7573936076a0009c81072",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Every Little Word",
        artist: "MNEK",
        track_id: 20,
        preview: "previews/066/200af7a6fbb21c598155/z7ty09fpdig44yhg.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658279722902,
        _id: "62d7572a36076a0009c81071",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Overplayed",
        track: "Every Little Word",
        artist: "MNEK",
        track_id: 20,
        preview: "previews/066/200af7a6fbb21c598155/z7ty09fpdig44yhg.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658279498451,
        _id: "62d7564a36076a0009c81070",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Every Little Word",
        artist: "MNEK",
        track_id: 20,
        preview: "previews/066/200af7a6fbb21c598155/z7ty09fpdig44yhg.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658279473303,
        _id: "62d7563136076a0009c8106f",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Annoying",
        track: "Every Little Word",
        artist: "MNEK",
        track_id: 20,
        preview: "previews/066/200af7a6fbb21c598155/z7ty09fpdig44yhg.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658279190436,
        _id: "62d7551636076a0009c8106e",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Poor quality",
        track: "Mac N' Cheese",
        artist: "Mileena",
        track_id: 185,
        preview: "/p/592/496ORHHAxs73jaxdGQca/8dmQDjhgHeoskuy3.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658279067462,
        _id: "62d7549b36076a0009c8106d",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Poor quality",
        track: "Mac N' Cheese",
        artist: "Mileena",
        track_id: 185,
        preview: "/p/592/496ORHHAxs73jaxdGQca/8dmQDjhgHeoskuy3.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658278976695,
        _id: "62d7544036076a0009c8106c",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Poor quality",
        track: "Mac N' Cheese",
        artist: "Mileena",
        track_id: 185,
        preview: "/p/592/496ORHHAxs73jaxdGQca/8dmQDjhgHeoskuy3.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658278939454,
        _id: "62d7541b36076a0009c8106b",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Poor quality",
        track: "Every Little Word",
        artist: "MNEK",
        track_id: 20,
        preview: "previews/066/200af7a6fbb21c598155/z7ty09fpdig44yhg.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658278763834,
        _id: "62d7536b36076a0009c8106a",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Too slow",
        track: "Mac N' Cheese",
        artist: "Mileena",
        track_id: 185,
        preview: "/p/592/496ORHHAxs73jaxdGQca/8dmQDjhgHeoskuy3.mp3",
        playlist: "Some music [Used By Cubik for Provisioning]",
        playlist_id: 2,
        message: "",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658277968011,
        _id: "62d75050a27fda0009bb5ded",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Poor quality",
        track: "Love Alight (Jean Tonique Remix)",
        artist: "Crystal Fighters",
        track_id: 39,
        preview: "previews/071/e07840705f3be998719c/xvbf0k8yb5dcpi36.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "no",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658155407154,
        _id: "62d5718f4346b7000a58e4e1",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Love Alight (Jean Tonique Remix)",
        artist: "Crystal Fighters",
        track_id: 39,
        preview: "previews/071/e07840705f3be998719c/xvbf0k8yb5dcpi36.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "working ",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658155334033,
        _id: "62d571464346b7000a58e4e0",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Imperfection",
        artist: "Bodhi",
        track_id: 32,
        preview: "previews/070/64c4dcf52167d54d9a7a/w0frezdkrvd9pwil.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "works ",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658147677604,
        _id: "62d5535d6393de00098829c2",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Deep End (feat. Eastside and Mayer Hawthorne) [Matoma Remix]",
        artist: "Coucheron",
        track_id: 15,
        preview: "previews/065/f7cdc6d09e0f84603961/5k4l4kv2ujl9g8gt.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "welldone ",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658144584157,
        _id: "62d54748012db3000975bc4a",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Lounge",
                area_id: 18,
              },
              {
                name: "Bar",
                area_id: 19,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Imperfection",
        artist: "Bodhi",
        track_id: 32,
        preview: "previews/070/64c4dcf52167d54d9a7a/w0frezdkrvd9pwil.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "welldone ",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658143242368,
        _id: "62d5420a0a7c280009f0f101",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Imperfection",
        artist: "Bodhi",
        track_id: 32,
        preview: "previews/070/64c4dcf52167d54d9a7a/w0frezdkrvd9pwil.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "I really like the app",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658143223290,
        _id: "62d541f70a7c280009f0f100",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Celestial (Reprise)",
        artist: "VIMES",
        track_id: 11,
        preview: "previews/064/410d1a8f4bd422ae3efc/w70qec1x6kzs3jj7.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "shdhd",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658142542470,
        _id: "62d53f4ea8202800093c3173",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Positive",
        track: "Celestial (Reprise)",
        artist: "VIMES",
        track_id: 11,
        preview: "previews/064/410d1a8f4bd422ae3efc/w70qec1x6kzs3jj7.mp3",
        playlist: "Liquid Metal",
        playlist_id: 107,
        message: "shdhd",
        location: "Benin Hotels - Adjarra - Bar",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          created: "2017-07-03T14:47:45.026Z",
          business_name: "Benin Hotels",
          business_admin_data_id: 15,
          user_name: "Cyrille",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1658142540494,
        _id: "62d53f4c2be2d700094ee7c7",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        feedbackOrigin: {
          location: "Adjarra",
          areaName: "Bar",
          deviceId: "c-32034",
        },
        locations: [
          {
            name: "Porto-Novo Hotel",
            id: 153,
            areas: [
              {
                name: "Restaurant",
                area_id: 62,
              },
            ],
          },
          {
            name: "Adjarra",
            id: 18,
            areas: [
              {
                name: "Bar",
                area_id: 19,
              },
              {
                name: "Lounge",
                area_id: 18,
              },
            ],
          },
          {
            name: "Cotonou Hotel",
            id: 6,
            areas: [
              {
                name: "Cafe Deluxe",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "positive",
        track: null,
        artist: null,
        playlist: null,
        playlist_id: "",
        message: "lul",
        location: "Marco [Client] 2 - Another marco business - Marco - Business 3 - Area 1",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client] 2",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        rule_created: false,
        date: 1573574765127,
        _id: "5dcad86d0ad5d7000776caa6",
        approval: {
          required: true,
          approved: true,
          rejected: false,
        },
        locations: [
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "marco2",
                area_id: 19,
              },
              {
                name: "Default",
                area_id: 18,
              },
            ],
          },
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
          {
            name: "Another marco business",
            id: 153,
            areas: [
              {
                name: "Marco - Business 3 - Area 1",
                area_id: 62,
              },
            ],
          },
        ],
      },
      {
        type: "Too fast",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Monday Funday - Clone 2",
        playlist_id: 94,
        message: "",
        location: "Another marco business",
        user_type: "staff",
        user: {
          id: 154,
          user_type: "staff",
          is_god: false,
          client_id: 15,
          business_id: 153,
          area_id: 62,
          created: "2018-05-02T12:17:16.510Z",
        },
        ack: false,
        date: 1556723808813,
        _id: "5cc9b860809dda283e4edf3b",
        approval: {
          required: true,
          approved: false,
          rejected: false,
        },
      },
      {
        type: "Bad language",
        track: "Git Witt U",
        artist: "FS Green",
        playlist: "Monday Funday - Clone 2",
        playlist_id: 94,
        message: "",
        location: "Another marco business",
        user_type: "staff",
        user: {
          id: 154,
          user_type: "staff",
          is_god: false,
          client_id: 15,
          business_id: 153,
          area_id: 62,
          created: "2018-05-02T12:17:16.510Z",
        },
        ack: false,
        date: 1556720594520,
        _id: "5cc9abd2809dda283e4edf3a",
        approval: {
          required: true,
          approved: false,
          rejected: false,
        },
      },
      {
        type: "positive",
        track: "Hold Me",
        artist: "Gold Fields",
        track_id: 29,
        preview: "previews/069/972f280b5d157f34d8a0/omj8514f1q603sis.mp3",
        playlist: "Monday Funday - Clone 2",
        playlist_id: 94,
        message: "Yaaaaas",
        location: "Marco [Client] 2 - Another marco business - Marco - Business 3 - Area 1",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client] 2",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1556718662620,
        _id: "5cc9a446d64c4f00016d9fc8",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "marco2",
                area_id: 19,
              },
              {
                name: "Default",
                area_id: 18,
              },
            ],
          },
          {
            name: "Another marco business",
            id: 153,
            areas: [
              {
                name: "Marco - Business 3 - Area 1",
                area_id: 62,
              },
            ],
          },
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "language",
        track: "Hold Me",
        artist: "Gold Fields",
        track_id: 29,
        preview: "previews/069/972f280b5d157f34d8a0/omj8514f1q603sis.mp3",
        playlist: "Monday Funday - Clone 2",
        playlist_id: 94,
        message: "The track contains inappropriate language or themes",
        location: "Marco [Client] 2 - Another marco business - Marco - Business 3 - Area 1",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client] 2",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1556718522080,
        _id: "5cc9a3bad64c4f00016d9fc7",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "marco2",
                area_id: 19,
              },
              {
                name: "Default",
                area_id: 18,
              },
            ],
          },
          {
            name: "Another marco business",
            id: 153,
            areas: [
              {
                name: "Marco - Business 3 - Area 1",
                area_id: 62,
              },
            ],
          },
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "positive",
        track: "Hold Me",
        artist: "Gold Fields",
        track_id: 29,
        preview: "previews/069/972f280b5d157f34d8a0/omj8514f1q603sis.mp3",
        playlist: "Monday Funday - Clone 2",
        playlist_id: 94,
        message: "",
        location: "Marco [Client] 2 - Another marco business - Marco - Business 3 - Area 1",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client] 2",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1556718495459,
        _id: "5cc9a39fd64c4f00016d9fc6",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "marco2",
                area_id: 19,
              },
              {
                name: "Default",
                area_id: 18,
              },
            ],
          },
          {
            name: "Another marco business",
            id: 153,
            areas: [
              {
                name: "Marco - Business 3 - Area 1",
                area_id: 62,
              },
            ],
          },
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Too fast",
        track: "Giving It All",
        artist: "Bondax",
        track_id: 27,
        preview: "previews/068/49704cd2bd562af1a9ff/572hsdy7yohdia09.mp3",
        playlist: "Monday Funday - Clone 2",
        playlist_id: 94,
        message: "The track is too fast for this trading period",
        location: "Marco [Client] 2 - Another marco business - Marco - Business 3 - Area 1",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client] 2",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        rule_created: false,
        date: 1556718462119,
        _id: "5cc9a37ed64c4f00016d9fc5",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "marco2",
                area_id: 19,
              },
              {
                name: "Default",
                area_id: 18,
              },
            ],
          },
          {
            name: "Another marco business",
            id: 153,
            areas: [
              {
                name: "Marco - Business 3 - Area 1",
                area_id: 62,
              },
            ],
          },
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "Too slow",
        track: "Giving It All",
        artist: "Bondax",
        track_id: 27,
        preview: "previews/068/49704cd2bd562af1a9ff/572hsdy7yohdia09.mp3",
        playlist: "Monday Funday - Clone 2",
        playlist_id: 94,
        message: "The track is too slow for this trading period",
        location: "Marco [Client] 2 - Another marco business - Marco - Business 3 - Area 1",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client] 2",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1556718326160,
        _id: "5cc9a2f6d64c4f00016d9fc4",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "marco2",
                area_id: 19,
              },
              {
                name: "Default",
                area_id: 18,
              },
            ],
          },
          {
            name: "Another marco business",
            id: 153,
            areas: [
              {
                name: "Marco - Business 3 - Area 1",
                area_id: 62,
              },
            ],
          },
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "speed-fast",
        track: "Fade Out Lines (In the Style of the Avener) [Karaoke Version]",
        artist: "Starstruck Backing Tracks",
        track_id: 23,
        preview: "previews/067/3bf77b331cc397676d52/317hrk0ts9xjxf76.mp3",
        playlist: "Monday Funday - Clone 2",
        playlist_id: 94,
        message: "The track is too fast for this trading period",
        location: "Marco [Client] 2 - Another marco business - Marco - Business 3 - Area 1",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client] 2",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        rule_created: true,
        date: 1556716519581,
        _id: "5cc99be7b7fd1b00011481ce",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "marco2",
                area_id: 19,
              },
              {
                name: "Default",
                area_id: 18,
              },
            ],
          },
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
          {
            name: "Another marco business",
            id: 153,
            areas: [
              {
                name: "Marco - Business 3 - Area 1",
                area_id: 62,
              },
            ],
          },
        ],
      },
      {
        type: "speed-slow",
        track: "Giving It All",
        artist: "Bondax",
        track_id: 27,
        preview: "previews/068/49704cd2bd562af1a9ff/572hsdy7yohdia09.mp3",
        playlist: "Monday Funday - Clone 2",
        playlist_id: 94,
        message: "The track is too slow for this trading period",
        location: "Marco [Client] 2 - Another marco business - Marco - Business 3 - Area 1",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client] 2",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        rule_created: false,
        date: 1556715067863,
        _id: "5cc9963bb7fd1b00011481cd",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "marco2",
                area_id: 19,
              },
              {
                name: "Default",
                area_id: 18,
              },
            ],
          },
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
          {
            name: "Another marco business",
            id: 153,
            areas: [
              {
                name: "Marco - Business 3 - Area 1",
                area_id: 62,
              },
            ],
          },
        ],
      },
      {
        type: "speed-slow",
        track: "Fade Out Lines (In the Style of the Avener) [Karaoke Version]",
        artist: "Starstruck Backing Tracks",
        track_id: 23,
        preview: "previews/067/3bf77b331cc397676d52/317hrk0ts9xjxf76.mp3",
        playlist: "Monday Funday - Clone 2",
        playlist_id: 94,
        message: "The track is too slow for this trading period",
        location: "Marco [Client] 2 - Another marco business - Marco - Business 3 - Area 1",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client] 2",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        rule_created: true,
        date: 1556714912681,
        _id: "5cc995a0b7fd1b00011481cc",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "marco2",
                area_id: 19,
              },
              {
                name: "Default",
                area_id: 18,
              },
            ],
          },
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
          {
            name: "Another marco business",
            id: 153,
            areas: [
              {
                name: "Marco - Business 3 - Area 1",
                area_id: 62,
              },
            ],
          },
        ],
      },
      {
        type: "Too slow",
        track: "Flashlight",
        artist: "Bonobo",
        track_id: 24,
        preview: "previews/068/7cbfff8bf6a624f41c5b/p2ay40g3uisl31ei.mp3",
        playlist: "Monday Funday - Clone 2",
        playlist_id: 94,
        message: "This is my comment. There are many like it but this one is mine.",
        location: "Another marco business",
        user_type: "staff",
        user: {
          id: 154,
          user_type: "staff",
          is_god: false,
          client_id: 15,
          business_id: 153,
          area_id: 62,
          created: "2018-05-02T12:17:16.510Z",
        },
        ack: false,
        date: 1556643245968,
        _id: "5cc87dae809dda283e4edf39",
        approval: {
          required: true,
          approved: false,
          rejected: false,
        },
      },
      {
        type: "Annoying",
        track: "Garden (Calibre Remix)",
        artist: "Totally Enormous Extinct Dinosaurs",
        track_id: 25,
        preview: "previews/068/58eb8c1a9f50f5b0e0d2/2juo7t48mrjmtdbp.mp3",
        playlist_id: 62,
        message: "Test ",
        location: "Marco",
        user_type: "staff",
        user: {
          id: 56,
          user_type: "staff",
          is_god: false,
          client_id: 15,
          business_id: 6,
          area_id: 5,
        },
        ack: true,
        rule_created: false,
        date: 1547651177989,
        _id: "5c3f486a7e0a4114ef9746d1",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
      },
      {
        type: "Too quiet",
        track: "Garden (Calibre Remix)",
        artist: "Totally Enormous Extinct Dinosaurs",
        track_id: 25,
        preview: "previews/068/58eb8c1a9f50f5b0e0d2/2juo7t48mrjmtdbp.mp3",
        playlist_id: 62,
        message: "From streaming",
        location: "Marco",
        user_type: "staff",
        user: {
          id: 56,
          user_type: "staff",
          is_god: false,
          client_id: 15,
          business_id: 6,
          area_id: 5,
        },
        ack: true,
        rule_created: true,
        date: 1544527624068,
        _id: "5c0f9f08c9eef52955abf48e",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
      },
      {
        type: "speed",
        track: "Name of track",
        artist: "Name of artist",
        playlist: "Name of Playlist",
        playlist_id: "",
        message: "The track is too slow / fast for this trading period",
        location: "Marco [Client] - Marco3 - Default",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client]",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        date: 1506358987269,
        _id: "59c936cba9fdf200011b4161",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "Default",
                area_id: 18,
              },
              {
                name: "marco2",
                area_id: 19,
              },
            ],
          },
        ],
      },
      {
        type: "speed",
        track: "Mas Pan (DJ Day Remix)",
        artist: "Quantic and His Combo Bárbaro",
        playlist: "Indie-Pop and Soul",
        playlist_id: "",
        message: "The track is too slow / fast for this trading period",
        location: "Marco3",
        user_type: "staff",
        user: {
          id: 76,
          user_type: "staff",
          is_god: false,
          client_id: 15,
          business_id: 18,
          area_id: 19,
        },
        ack: true,
        date: 1506358904324,
        _id: "59c93678038a1f33029947ee",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
      },
      {
        type: "language",
        track: null,
        artist: null,
        playlist: null,
        playlist_id: "",
        message: "The track contains inappropriate language or themes",
        location: "Marco [Client] - Marco - Area: marco_stafff",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client]",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        date: 1506356417591,
        _id: "59c92cc23492b60001abb4e1",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "Default",
                area_id: 18,
              },
              {
                name: "marco2",
                area_id: 19,
              },
            ],
          },
        ],
      },
      {
        type: "speed",
        track: "Name of track",
        artist: "Name of artist",
        playlist: "Name of Playlist",
        playlist_id: "",
        message: "The track is too slow / fast for this trading period",
        location: "Marco [Client] - Marco3 - Default",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client]",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        date: 1506355399928,
        _id: "59c928c8cb8f5e0001272cf8",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "Default",
                area_id: 18,
              },
              {
                name: "marco2",
                area_id: 19,
              },
            ],
          },
        ],
      },
      {
        type: "speed",
        track: "Name of track",
        artist: "Name of artist",
        playlist: "Name of Playlist",
        playlist_id: "",
        message: "The track is too slow / fast for this trading period",
        location: "Marco [Client] - Marco3 - Default",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client]",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        date: 1506355328829,
        _id: "59c92881d741970001b4d8af",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "Default",
                area_id: 18,
              },
              {
                name: "marco2",
                area_id: 19,
              },
            ],
          },
        ],
      },
      {
        type: "language",
        track: null,
        artist: null,
        playlist: null,
        playlist_id: "",
        message: "The track contains inappropriate language or themes",
        location: "undefined - undefined - marco2",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client]",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        date: 1506351905520,
        _id: "59c91b21f6149300018078bf",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "Default",
                area_id: 18,
              },
              {
                name: "marco2",
                area_id: 19,
              },
            ],
          },
        ],
      },
      {
        type: "suggestion",
        track: null,
        artist: null,
        playlist: null,
        playlist_id: "",
        message: "",
        location: "undefined - undefined - Area: marco_stafff",
        user_type: "admin",
        user: {
          id: 45,
          user_type: "admin",
          is_god: false,
          business_name: "Marco [Client]",
          business_admin_data_id: 15,
          user_name: "Marco",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        date: 1506351819880,
        _id: "59c91accf6149300018078be",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
          {
            name: "Marco3",
            id: 18,
            areas: [
              {
                name: "Default",
                area_id: 18,
              },
              {
                name: "marco2",
                area_id: 19,
              },
            ],
          },
        ],
      },
      {
        type: "language",
        track: "Name of track",
        artist: "Name of artist",
        playlist: "Name of Playlist",
        playlist_id: "",
        message: "The track contains inappropriate language or themes",
        location: "undefined - undefined - Area: marco_stafff",
        user_type: "manager",
        user: {
          id: 6,
          user_type: "manager",
          is_god: true,
          client_id: 15,
          business_name: "Marco [Client]",
          user_name: "MarcoMAnager",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        date: 1505320892901,
        _id: "59b95fbd893cf70001b33fc3",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "speed",
        track: "Name of track",
        artist: "Name of artist",
        playlist: "Name of Playlist",
        playlist_id: "",
        message: "The track is too slow / fast for this trading period",
        location: "Marco",
        user_type: "staff",
        user: {
          id: 56,
          user_type: "staff",
          is_god: false,
          client_id: 15,
          business_id: 6,
          area_id: 5,
        },
        ack: true,
        date: 1502449371638,
        _id: "598d8edb07177640af4ad802",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
      },
      {
        type: "language",
        track: "Name of track",
        artist: "Name of artist",
        playlist: "Name of Playlist",
        playlist_id: "",
        message: "The track contains inappropriate language or themes",
        location: "Marco",
        user_type: "staff",
        user: {
          id: 56,
          user_type: "staff",
          is_god: false,
          client_id: 15,
          business_id: 6,
          area_id: 5,
        },
        ack: true,
        rule_created: false,
        date: 1502449342640,
        _id: "598d8ebe07177640af4ad801",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
      },
      {
        type: "speed",
        track: "Name of track",
        artist: "Name of artist",
        playlist: "Name of Playlist",
        playlist_id: "",
        message: "The track is too slow / fast for this trading period",
        location: "undefined - undefined - Area: marco_stafff",
        user_type: "manager",
        user: {
          id: 6,
          user_type: "manager",
          is_god: true,
          client_id: 15,
          business_name: "Marco [Client]",
          user_name: "MarcoMAnager",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        rule_created: false,
        date: 1502373690141,
        _id: "598c673ae70a3100014cc004",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "speed",
        track: "Name of track",
        artist: "Name of artist",
        playlist: "Name of Playlist",
        playlist_id: "",
        message: "The track is too slow / fast for this trading period",
        location: "undefined - undefined - Area: marco_stafff",
        user_type: "manager",
        user: {
          id: 6,
          user_type: "manager",
          is_god: true,
          client_id: 15,
          business_name: "Marco [Client]",
          user_name: "MarcoMAnager",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: false,
        date: 1502368164880,
        _id: "598c51a5c1ac61000188d6b0",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "speed",
        track: "Name of track",
        artist: "Name of artist",
        playlist: "Name of Playlist",
        playlist_id: "",
        message: "The track is too slow / fast for this trading period",
        location: " - ",
        user_type: "manager",
        user: {
          id: 6,
          user_type: "manager",
          is_god: true,
          client_id: 15,
          business_name: "Marco [Client]",
          user_name: "MarcoMAnager",
          business_image_url: "https://dashboard.ambie.fm/images/logo-transparent.png",
        },
        ack: true,
        rule_created: false,
        date: 1502367724539,
        _id: "598c4fec0796b900018fe35e",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
        locations: [
          {
            name: "Marco",
            id: 6,
            areas: [
              {
                name: "Area: marco_stafff",
                area_id: 5,
              },
            ],
          },
        ],
      },
      {
        type: "language",
        track: "Name of track",
        artist: "Name of artist",
        playlist: "Name of Playlist",
        playlist_id: "",
        message: "The track contains inappropriate language or themes",
        location: "Marco",
        user_type: "staff",
        user: {
          id: 56,
          user_type: "staff",
          is_god: false,
          client_id: 15,
          business_id: 6,
          area_id: 5,
        },
        ack: true,
        rule_created: false,
        date: 1502295980859,
        _id: "598b37adc3483b01bfa714d0",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
      },
      {
        type: "language",
        track: "Name of track",
        artist: "Name of artist",
        playlist: "Name of Playlist",
        playlist_id: "",
        message: "The track contains inappropriate language or themes",
        location: "Marco",
        user_type: "staff",
        user: {
          id: 56,
          user_type: "staff",
          is_god: false,
          client_id: 15,
          business_id: 6,
          area_id: 5,
        },
        ack: true,
        rule_created: false,
        date: 1499253303363,
        _id: "595cca37a64e746ef251404a",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
      },
      {
        type: "speed",
        track: "Name of track",
        artist: "Name of artist",
        playlist: "Name of Playlist",
        playlist_id: "",
        message: "The track is too slow / fast for this trading period",
        location: "Marco",
        user_type: "staff",
        user: {
          id: 56,
          user_type: "staff",
          is_god: false,
          client_id: 15,
          business_id: 6,
          area_id: 5,
        },
        ack: true,
        rule_created: false,
        date: 1499253297459,
        _id: "595cca31a64e746ef2514049",
        approval: {
          required: false,
          approved: false,
          rejected: false,
        },
      },
    ],
  },
];
