export const STAGING = {
  NODE_ENV: "staging",
  AMBIE_ENV: "staging",
  USE_REDUX_LOGGER: "false",
  WP_HOST: "https://kruo3njzbf.execute-api.eu-west-1.amazonaws.com/staging",
  WP_HOST_CF: "https://ayrtrxdcnc.execute-api.eu-west-1.amazonaws.com/v1",
  WP_HOST_CF_FRONTEND: "https://wlefab6kl8.execute-api.eu-west-1.amazonaws.com/v1",
  WP_PORT: 443,
  WP_PREFIX: "/staging",
  WP_OLD_ADMIN_ROOT: "https://stage.ambie.fm/",
  WP_OLD_ADMIN_URL: "https://stage.ambie.fm/staff/",
  WP_OLD_ADMIN_URL_TRACKS: "https://stage.ambie.fm/tracks/track/?q=",
  WP_AUDIO_PREFIX: "http://d2t4zkoyu407b2.cloudfront.net/media/",
  WP_MEDIA_CF_URL: "http://d2t4zkoyu407b2.cloudfront.net/",
  WP_WEBSOCKET_URL: "https://staging.trackuploader.ambie.fm:20000",
  WP_PLAYLIST_COVER: "https://stage.ambie.fm/media/",
  WP_TRACK_NOT_IN_USE_ENDPOINT: "https://kruo3njzbf.execute-api.eu-west-1.amazonaws.com/staging/tracks/not-in-use",
  WP_TRACKS_ENDPOINT: "https://ayrtrxdcnc.execute-api.eu-west-1.amazonaws.com/v1/tracks",
  WP_COGNITO_IDENTITY_POOL: "eu-west-1:5b3a067d-f261-4cb8-9e7c-00fa2dc14530",
  WP_IOT_POLICY: "stagingOlympusBrowserPolicy",
  WP_IOT_HOST: "a117f4qbnqd0g2-ats.iot.eu-west-1.amazonaws.com",
  WP_AWS_REGION: "eu-west-1",
  WP_SF_DOMAIN: "https://cs84.lightning.force.com",
  WP_STREAMING_WEBSOCKET_URL: "https://stage.remote.ambie.fm:20011",
  LOGIN_ENDPOINT: "https://73fqpwyvzi.execute-api.eu-west-1.amazonaws.com/v1",
  HOST_ROCK_API: "https://rock-stage.ambie.fm",
  WEB_SOCKET_URL: "wss://rock-stage.ambie.fm/events",
};
