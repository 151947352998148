/* TODO 
  We need to refactor this to build the proper objects that return from the API calls, and are put into state
  so that we don't have to do data integrity checks everywhere to make sure the app does not throw errors
  For example, an area should always have the device field so we don't need to do area && area.device && etc
  This applies to all keys that are used in the code.

  In this case it could avoid checks in this module, in te MiniPlayer module and on the SwitchPlaylist module
*/
import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";

// style components
import { RootState } from "../../redux-toolkit/store";
import { MiniCard, KeyValueCard, RowCard } from "../../components/cards";
import styles from "./locations.module.scss";
import {
  enableStreaming,
  disableStreaming,
  removeDeviceFromArea,
  getClientLocationsWithAreas,
  // updateLocationRecord,
  updateAreaRecord,
  changeDevice,
  deactivateLocation,
  activateLocation,
  freezeLocation,
} from "../../services/locationsServices";
import {
  AmbieMusicPlaybackStatus,
  AmbiePlayerStatus,
  WebPlayerStatus,
  WebMusicPlaybackStatus,
} from "./components/deviceStatus";
import { Miniplayer } from "./components/miniplayer";
import { SearchWithOptions } from "../../components/fields/search";
import { GlobalButton } from "../../components/button";
import { GlobalDropdown } from "../../components/dropdown";
import { LoadingData } from "../../components/loading";
import { getSchedules, resendSchedule, updateScheduleForArea } from "../../services/schedulesServices";
import {
  RenameLocation,
  SendPasswordResetEmail,
  GenerateSetPasswordLink,
  CopyGenerateSetPasswordLink,
  DeactivateLocation,
  ActivateLocation,
  AddLocation,
  FreezeLocation,
} from "./components/location-actions/LocationActions";
import {
  ViewCommercialInjectionRules,
  ViewSchedule,
  ChangeSchedule,
  ResendSchedule,
  UpdateSoftware,
  AssignDevice,
  RenameArea,
  ChangeAreaAudioSetting,
  AddArea,
  StreamingStatus,
  DeactivateArea,
  RemoveDevice,
  ActivateArea,
  AssignDeviceActionRequired,
  UnsaveDeviceStatus,
} from "./components/location-actions/LocationAreaActions";
import {
  BsMusicNoteBeamed,
  BsPencil,
  BsMusicNoteList,
  // BsEnvelope,
  // BsKey,
  BsXOctagon,
  BsTable,
  BsCalendarWeek,
  BsCloud,
  BsHdd,
  BsFileEarmarkArrowUp,
  BsArrowClockwise,
  BsXCircleFill,
  BsFileEarmarkCode,
  BsSearch,
  BsGeoFill,
} from "react-icons/bs";
import { schedules_page_navigate } from "../../constants/routePath";
import {
  showRenameLocationActionsSuccess,
  // showChangeEmailActionsSuccess,
  hideActionsSuccess,
  // showSendPasswordResetActionsSuccess,
  // showGenerateSetPasswordActionsSuccess,
  showCopyGenerateSetPasswordActionsSuccess,
  showDeactivateLocationActionsSuccess,
  showRenameAreaActionsSuccess,
  showAreaStreamingActionsSuccess,
  isLoadingSuccess,
  showErrorMessageSuccess,
  showSuccessMessageSuccess,
  showDeactivateAreaActionsSuccess,
  showActivateAreaActionsSuccess,
  showRemoveDeviceFromAreaActionsSuccess,
  showResendScheduleAreaActionsSuccess,
  showAssignDeviceAreaActionsSuccess,
  showGenerateWebPlayerAreaActionsSuccess,
  showUpdateSoftwareAreaActionsSuccess,
  showViewCommercialInjectionRulesAreaActionsSuccess,
  // showViewScheduleAreaActionsSuccess,
  showChangeScheduleAreaActionsSuccess,
  showTimezoneActionsSuccess,
  showActivateLocationActionsSuccess,
  showAddAreaActionsSuccess,
  showAddLocationActionsSuccess,
  showChangeAudioSettingSuccess,
  showDeviceStatusFailActionsSuccess,
  showFreezeLocationActionsSuccess,
  // showAddAreaActionsSuccess,
  // showAddLocationActionsSuccess,
} from "../../redux-toolkit/reducers/actionsSlice";
import { requestPasswordReset } from "../../services/usersServices";
import CopyToClipboard from "react-copy-to-clipboard";
import { ACTIONS_ERROR_MESSAGE } from "../../constants/globalText";
import {
  generateWebPlayerThing,
  updateDeviceSoftwareVersion,
  updatePlayersWithoutStatus,
} from "../../services/devceStatusServices";
import TimezoneHelper from "../../components/timezoneHelper";
import { changeTimezoneSuccess } from "../../redux-toolkit/reducers/schedulesSlice";
import { differenceInMilliseconds } from "date-fns";
import { ChangeTimezone } from "../schedules/components/modal";
import { WebSocketContext } from "../../components/websocket/websocket";
import { DEFAULT_TIMEOUT } from "../../constants/defaultValues";
import { getActivePlaylistsByClientId } from "../../services/playlistsServices";
import { getClientForAmbieUser } from "../../services/clientsServices";
import { searchClientSuccess } from "../../redux-toolkit/reducers/searchClientSlice";
import { storeClient } from "../../utils/localStorage";
// import { frozenImageSvg } from "../../assets/images/logo/logo";

export default function Locations() {
  const [playerStatus, setPlayerStatus] = useState<string>("all");
  const [locationStatus, setLocationStatus] = useState<string>("active");
  const [locationFilter, setFilter] = useState<{ locationName: string; locationId: number }>({
    locationName: "",
    locationId: 0,
  });
  const [displayMiniplayer, setDisplayMiniplayer] = useState<string>("");

  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const actions = useSelector((state: RootState) => state.actionsSlice);
  // const userInfo = useSelector((state: RootState) => state.authSlice);
  const schedulesSlice = useSelector((state: RootState) => state.schedulesSlice);
  const locationsSlice = useSelector((state: RootState) => state.locationsSlice);

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const socket = useContext(WebSocketContext);

  let referrer: string = "";
  if (process.env.REACT_APP_AMBIE_ENV === "development") {
    referrer = "localhost:7777";
  } else if (process.env.REACT_APP_AMBIE_ENV === "staging") {
    referrer = "dashboard-stage.ambie.fm";
  } else if (process.env.REACT_APP_AMBIE_ENV === "production") {
    referrer = "dashboard.ambie.fm";
  }

  useEffect(() => {
    let clientLocationsIds: any = [];
    const getClientLocationsWithAreasData = async (loading: boolean) => {
      await getClientLocationsWithAreas(searchClient, loading)
        .then((response) => {
          clientLocationsIds = response.clientLocationsIds;
        })
        .catch((error) => {});
    };

    const getPlaylistsByClientIdData = async () => {
      await getActivePlaylistsByClientId(searchClient.clientId);
    };

    socket.on("updateLocation", (payload: any) => {
      if (searchClient.clientId === payload.parent_id) getClientLocationsWithAreasData(false);
    });

    socket.on("updateArea", (payload: { business_id: number }) => {
      if (clientLocationsIds.includes(payload.business_id as never)) {
        getClientLocationsWithAreasData(false);
      }
    });

    const getSchedulesData = async () => {
      await getSchedules(searchClient && searchClient.clientId);
    };

    getClientLocationsWithAreasData(true);
    getPlaylistsByClientIdData();
    getSchedulesData();

    let interval = setInterval(() => {
      !actions.viewCommercialInjectionRules && getClientLocationsWithAreasData(false);
    }, DEFAULT_TIMEOUT);

    return () => {
      clearInterval(interval);
      socket.off("updateLocation", (payload: any) => {
        if (searchClient.clientId === payload.parent_id) getClientLocationsWithAreasData(false);
      });

      socket.off("updateArea", (payload: { business_id: number }) => {
        if (clientLocationsIds.includes(payload.business_id as never)) {
          getClientLocationsWithAreasData(false);
        }
      });
    };
  }, [searchClient, actions.viewCommercialInjectionRules]);

  /**
   * send or generate password reset email
   */
  const sendOrGeneratePasswordResetEmailData = async () => {
    dispatch(isLoadingSuccess(true));
    await requestPasswordReset(actions.location.email, referrer)
      .then((response) => {
        if (actions.generateSetPassword) {
          const token = response && response.data && response.data.accessToken;
          const link = `${referrer}/reset-password?token=${token}`;
          dispatch(isLoadingSuccess(false));
          dispatch(showErrorMessageSuccess(""));
          dispatch(showCopyGenerateSetPasswordActionsSuccess({ link: link }));
        } else {
          dispatch(hideActionsSuccess());
        }
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  /**
   * Deactivate location
   */
  const deactivateLocationData = async () => {
    dispatch(isLoadingSuccess(true));

    await deactivateLocation(actions.location.id, { is_active: false, devicesId: actions.devicesId })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  /**
   * Activate location
   */
  const activateLocationData = async () => {
    dispatch(isLoadingSuccess(true));
    await activateLocation(actions.location.id, { is_active: true, devicesId: actions.devicesId })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  /**
   * Resend schedule
   */
  const resendScheduleData = async () => {
    dispatch(isLoadingSuccess(true));
    await resendSchedule(actions.area.id)
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  /**
   * Update schedule area
   */
  const updateScheduleAreaData = async () => {
    dispatch(isLoadingSuccess(true));
    await updateScheduleForArea(
      actions.area && actions.area.id,
      actions.location && actions.location.id,
      actions.area.schedule && actions.area.schedule.id,
      searchClient.clientId
    )
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  /**
   * Enable or disable streaming status
   */
  const streamingStatusData = async () => {
    dispatch(isLoadingSuccess(true));
    if (actions.area.streaming && actions.area.streaming.enabled) {
      await disableStreaming(actions.area.id)
        .then((response) => {
          dispatch(hideActionsSuccess());
        })
        .catch((error) => {
          dispatch(isLoadingSuccess(false));
          dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
          throw new Error(error);
        });
    } else {
      await enableStreaming(actions.area.id)
        .then((response) => {
          dispatch(hideActionsSuccess());
        })
        .catch((error) => {
          dispatch(isLoadingSuccess(false));
          dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
          throw new Error(error);
        });
    }
  };

  /**
   * Activate location area
   */
  const activateAreaData = async () => {
    dispatch(isLoadingSuccess(true));
    await updateAreaRecord(actions.area.id, { is_active: true })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  /**
   * Deactivate location area
   */
  const deactivateAreaData = async () => {
    dispatch(isLoadingSuccess(true));
    await updateAreaRecord(actions.area.id, { is_active: false })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  /**
   * Remove device from location area
   */
  const removeDeviceFromAreaData = async () => {
    dispatch(isLoadingSuccess(true));
    await removeDeviceFromArea(actions.area.serial)
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  /**
   * generate web Ppayer
   */
  const generateWebPlayerThingData = async () => {
    dispatch(isLoadingSuccess(true));
    await generateWebPlayerThing()
      .then((response) => {
        dispatch(isLoadingSuccess(false));
        dispatch(
          showGenerateWebPlayerAreaActionsSuccess({
            generateWebPlayer: { deviceCode: response.data.deviceCode, thingName: response.data.thingName },
          })
        );
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  /**
   * Update software
   */
  const updateSoftwareData = async () => {
    dispatch(isLoadingSuccess(true));
    await updateDeviceSoftwareVersion(actions.area.serial)
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  /**
   * reset client info
   */
  const resetClientInfoData = async () => {
    await getClientForAmbieUser(searchClient.clientId)
      .then((response) => {
        const data = response.data;
        const clientInfo = {
          clientId: data.id,
          clientName: data.name,
          clentEmail: data.contact_email,
          clentContactName: data.contact_name,
          isFrozen: data.is_frozen,
        };
        dispatch(searchClientSuccess(clientInfo));

        storeClient(clientInfo);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  /**
   * freeze location
   */
  const freezeLocationData = async () => {
    dispatch(isLoadingSuccess(true));

    await freezeLocation(actions.location.id, { is_frozen: actions.freezeLocation.isFrozen })
      .then((response) => {
        resetClientInfoData();
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  const AssignDeviceActionRequiredData = async () => {
    const changeDeviceRecord = {
      areaId: actions.actionRequiredInfo.areaId,
      deviceId: actions.actionRequiredInfo.deviceId,
      deviceType: actions.actionRequiredInfo.deviceType,
      businessId: actions.actionRequiredInfo.businessId,
      scheduleDeviceId: actions.actionRequiredInfo.scheduleDeviceId,
      actionRequired: true,
    };
    await changeDevice(changeDeviceRecord);
  };

  const audioSettingsForDisplay = (audioSetting: string): string => {
    const UiNames: any = {
      standard_mono: "Standard Mono",
      standard_stereo: "Standard Stereo",
      hifi_mono: "HIFI Mono",
      hifi_stereo: "HIFI Stereo",
    };
    return UiNames[audioSetting];
  };

  const updatePlayersWithoutStatusData = async (devices: []) => {
    dispatch(isLoadingSuccess(true));
    await updatePlayersWithoutStatus(devices)
      .then((response) => {
        dispatch(isLoadingSuccess(false));

        if (response.data?.error === "fail") {
          dispatch(
            showDeviceStatusFailActionsSuccess({ deviceStatusFail: { status: true, devices: response.data.devices } })
          );
        }
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(true));
      });
  };

  const initialValues = {
    showMiniplayer: displayMiniplayer,
  };

  const filterPlayerStatus = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Online",
      value: "online",
    },
    {
      label: "Offline",
      value: "offline",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
    {
      label: "No Player",
      value: "no-player",
    },
  ];

  const filterLocations = [
    {
      label: "All Locations",
      value: "all",
    },
    {
      label: "Active Locations",
      value: "active",
    },
    {
      label: "Inactive Locations",
      value: "inactive",
    },
  ];

  const tooltipMessage =
    "If this option is enabled, we will not make any changes to your music based on any feedback that has been left until you or a manager has approved it. Once enabled, you can approve or reject feedback by searching for a date range from the date picker above and then using the approve/reject buttons on the table below.";

  return (
    <div className="content container py-3">
      <div className="page-header" style={{ borderBottom: "none", paddingBottom: 0, marginBottom: "1rem" }}>
        <div className="row align-items-center">
          <div className="col-md-3">
            <MiniCard options={locationsSlice.locationsAreasStatus} columns="col-lg-6" />
          </div>
          <div className="col-md-6">
            <MiniCard options={locationsSlice.playerStatusWithLocations} columns="col-lg-3" />
          </div>
          <div className="col-sm-auto"></div>
        </div>
        <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
          <Formik enableReinitialize initialValues={{ is_frozen: searchClient.isFrozen }} onSubmit={(values) => {}}>
            {() => {
              return (
                <div className="d-flex align-items-center row">
                  <div className="col-md-5 mb-2 mb-md-0 d-flex flex-column">
                    <SearchWithOptions
                      id="searchLocation"
                      placeholder="Search locations..."
                      options={locationsSlice.locations}
                      labelKey="name"
                      leftIcon={<BsSearch />}
                      clearButton
                      onChange={(selected: any) => {
                        setFilter({
                          locationId: (selected && selected[0] && selected[0].id) || 0,
                          locationName: (selected && selected[0] && selected[0].name) || "",
                        });
                      }}
                    />
                  </div>

                  <div className="col-md-7 d-grid d-sm-flex justify-content-md-end align-items-sm-center gap-4">
                    <div style={{ width: "180px" }}>
                      <Select
                        id="player-status"
                        className="flex-grow-4 time-select channel-list"
                        options={filterLocations}
                        value={filterLocations.find((c: any) => c.value === locationStatus)}
                        onChange={(selected) => selected && selected.value && setLocationStatus(selected.value)}
                      />
                    </div>

                    <div style={{ width: "180px" }}>
                      <Select
                        id="player-status"
                        className="flex-grow-4 time-select channel-list"
                        options={filterPlayerStatus}
                        value={filterPlayerStatus.find((c: any) => c.value === playerStatus)}
                        onChange={(selected) => selected && selected.value && setPlayerStatus(selected.value)}
                      />
                    </div>

                    <div>
                      <GlobalButton
                        size="sm"
                        onClick={() => {
                          dispatch(
                            showAddLocationActionsSuccess({
                              addLocation: true,
                            })
                          );
                        }}
                      >
                        Add Location
                      </GlobalButton>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
        {actions.actionsIsLoading && <LoadingData />}

        <>
          {/* Location action */}

          {actions.deviceStatusFail.status && (
            <UnsaveDeviceStatus
              devices={actions.deviceStatusFail.devices || []}
              show={actions.deviceStatusFail.status}
              handleClose={() => dispatch(hideActionsSuccess())}
            />
          )}

          {actions.renameLocation && (
            <RenameLocation
              show={actions.renameLocation}
              location={actions.location}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <div className={styles.flexRowWrapModalFooter}>
                  <div className={styles.footerLeft}>
                    <GlobalButton
                      format="white"
                      size="sm"
                      disabled={actions.actionsIsLoading ? true : false}
                      onClick={() => dispatch(hideActionsSuccess())}
                    >
                      Cancel
                    </GlobalButton>
                  </div>
                  <div>
                    <GlobalButton
                      disabled={actions.actionsIsLoading ? true : false}
                      format="success"
                      type="submit"
                      size="sm"
                    >
                      Submit
                    </GlobalButton>
                  </div>
                </div>
              }
            />
          )}

          {actions.sendPasswordReset && (
            <SendPasswordResetEmail
              show={actions.sendPasswordReset}
              location={actions.location}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        size="sm"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => sendOrGeneratePasswordResetEmailData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.generateSetPassword && (
            <GenerateSetPasswordLink
              show={actions.generateSetPassword}
              location={actions.location}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => sendOrGeneratePasswordResetEmailData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.copyGenerateSetPassword && (
            <CopyGenerateSetPasswordLink
              show={actions.copyGenerateSetPassword}
              link={actions.link}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        Close
                      </GlobalButton>
                    </div>
                    <div>
                      <CopyToClipboard text={`${actions.link}`}>
                        <GlobalButton
                          format="success"
                          disabled={actions.actionsIsLoading ? true : false}
                          onClick={() => dispatch(showSuccessMessageSuccess("Copied, you can now close the mobal"))}
                          size="sm"
                        >
                          Copy
                        </GlobalButton>
                      </CopyToClipboard>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.deactivateLocation && (
            <DeactivateLocation
              show={actions.deactivateLocation}
              location={actions.location}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => deactivateLocationData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.freezeLocation.status && (
            <FreezeLocation
              show={actions.freezeLocation.status}
              location={actions.location}
              frozenLocationMessage={actions?.freezeLocation?.message}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => freezeLocationData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.activateLocation && (
            <ActivateLocation
              show={actions.activateLocation}
              location={actions.location}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => activateLocationData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.timezone && (
            <ChangeTimezone show={actions.timezone} handleClose={() => dispatch(hideActionsSuccess())} />
          )}

          {/* location area action */}
          {actions.viewCommercialInjectionRules && (
            <ViewCommercialInjectionRules
              show={actions.viewCommercialInjectionRules}
              handleClose={() => dispatch(hideActionsSuccess())}
              type="area"
              clientIdOrAreaIdOrPlaylistId={actions.area.id}
              nameDisplay={actions.area.name}
              footer={
                <div className={styles.rightAligned}>
                  <GlobalButton
                    disabled={actions.actionsIsLoading ? true : false}
                    format="success"
                    type="submit"
                    size="sm"
                  >
                    Submit
                  </GlobalButton>
                </div>
              }
            />
          )}

          {actions.resendSchedule && (
            <ResendSchedule
              show={actions.resendSchedule}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => resendScheduleData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.viewSchedule && (
            <ViewSchedule
              show={actions.viewSchedule.status}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => {
                          dispatch(
                            changeTimezoneSuccess({
                              timezoneName: actions.viewSchedule.timezoneName,
                            })
                          );
                          dispatch(hideActionsSuccess());
                          navigation(schedules_page_navigate, {
                            state: { schedule: actions.area && actions.area.schedule },
                          });
                        }}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.changeSchedule && (
            <ChangeSchedule
              show={actions.changeSchedule}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => updateScheduleAreaData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.updateSoftware && (
            <UpdateSoftware
              show={actions.updateSoftware}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => updateSoftwareData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.assignDevice && (
            <AssignDevice
              show={actions.assignDevice}
              area={actions.area}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        size="sm"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        Dismiss
                      </GlobalButton>
                    </div>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        disabled={actions.actionsIsLoading ? true : false}
                        format="success"
                        type="submit"
                        size="sm"
                      >
                        Assign
                      </GlobalButton>
                    </div>
                    <div className={styles.footerLeft}>
                      <p className={styles.footerAssignDevice}>Or</p>
                    </div>
                    <div>
                      <GlobalButton size="sm" onClick={() => generateWebPlayerThingData()}>
                        Generate Web Player
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.actionRequired && (
            <AssignDeviceActionRequired
              show={actions.actionRequired}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => AssignDeviceActionRequiredData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.renameArea && (
            <RenameArea
              show={actions.renameArea}
              area={actions.area}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        size="sm"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        Cancel
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        disabled={actions.actionsIsLoading ? true : false}
                        format="success"
                        type="submit"
                        size="sm"
                      >
                        Submit
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.changeAudioSetting && (
            <ChangeAreaAudioSetting
              show={actions.changeAudioSetting}
              area={actions.area}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        size="sm"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        Cancel
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        disabled={actions.actionsIsLoading ? true : false}
                        format="success"
                        type="submit"
                        size="sm"
                      >
                        Submit
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          <CSSTransition
            in={actions.addArea}
            timeout={100}
            classNames="panel-animate"
            onEnter={() => document.body.classList.add("css-transition-modal-open")}
            onExited={() => document.body.classList.remove("css-transition-modal-open")}
            unmountOnExit={true}
            mountOnEnter={true}
          >
            <AddArea show={actions.addArea} />
          </CSSTransition>

          <CSSTransition
            in={actions.addLocation}
            timeout={100}
            classNames="panel-animate"
            onEnter={() => document.body.classList.add("css-transition-modal-open")}
            onExited={() => document.body.classList.remove("css-transition-modal-open")}
            unmountOnExit={true}
            mountOnEnter={true}
          >
            <AddLocation show={actions.addLocation} />
          </CSSTransition>

          {actions.streamingStatus && (
            <StreamingStatus
              show={actions.streamingStatus}
              area={actions.area}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => streamingStatusData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.deactivateArea && (
            <DeactivateArea
              show={actions.deactivateArea}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => deactivateAreaData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.activateArea && (
            <ActivateArea
              show={actions.activateArea}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => activateAreaData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}

          {actions.removeDeviceFromArea && (
            <RemoveDevice
              show={actions.removeDeviceFromArea}
              handleClose={() => dispatch(hideActionsSuccess())}
              footer={
                <>
                  <div className={styles.flexRowWrapModalFooter}>
                    <div className={styles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() => dispatch(hideActionsSuccess())}
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => removeDeviceFromAreaData()}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}
        </>

        <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
          {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
            <Form>
              {locationsSlice.locations && locationsSlice.locations.length ? (
                locationsSlice.locations
                  .filter((location: { name: string; id: number; is_active: boolean }) => {
                    let filterPass = true;
                    let locationFilterPass = true;

                    if (locationStatus === "all") {
                      locationFilterPass = true;
                    } else if (locationStatus === "active") {
                      locationFilterPass = location.is_active;
                    } else if (locationStatus === "inactive") {
                      locationFilterPass = !location.is_active;
                    }
                    if (locationFilter.locationId && locationFilter.locationId) {
                      filterPass =
                        location.name === locationFilter.locationName && location.id === locationFilter.locationId;
                    }
                    return locationFilterPass && filterPass;
                  })
                  .sort((a: { name: string; is_active: boolean }, b: { name: string; is_active: boolean }) => {
                    if (a.is_active && !b.is_active) return -1;
                    if (!a.is_active && b.is_active) return 1;
                    return a.name.localeCompare(b.name);
                  })
                  .map((data: any, locationIndex: number) => {
                    const playersWithoutStatus =
                      (data?.areas?.length &&
                        data.areas
                          .filter((player: any) => player.device_id && !player.status)
                          .map((deviceId: any) => deviceId.device_id)) ||
                      [];

                    return (
                      <div style={{ marginBottom: 20 }} key={locationIndex}>
                        <KeyValueCard
                          title={
                            <span className="flex">
                              {data.name}{" "}
                              {data.is_frozen ? (
                                <span style={{ paddingLeft: 20 }}>
                                  <small className="badge bg-soft-danger text-danger p-1">Frozen</small>
                                  {/* <img
                                    src={frozenImageSvg}
                                    alt="frozen"
                                    style={{
                                      height: 25,
                                    }}
                                  /> */}
                                </span>
                              ) : null}
                            </span>
                          }
                          subTitle={
                            data.timezone && data.timezone.name
                              ? `${data.timezone.name.replace("_", " ")} (${
                                  TimezoneHelper.generateTimezoneObj(data.timezone.name.replace(" ", "_")).offsetDisplay
                                })`
                              : `${schedulesSlice.defaultTimezoneName} (${schedulesSlice.defaultTimezoneOffsetDisplay})`
                          }
                          filter={
                            <div className="row">
                              {playersWithoutStatus.length ? (
                                <div style={{ width: "190px" }}>
                                  <GlobalButton
                                    size="sm"
                                    onClick={() => updatePlayersWithoutStatusData(playersWithoutStatus)}
                                  >
                                    Activate player status
                                  </GlobalButton>
                                </div>
                              ) : null}

                              <div style={{ width: "180px" }}>
                                <GlobalDropdown name="Location Actions" format="white" size="sm" align="end">
                                  <div className="dropdown-item">
                                    <GlobalButton
                                      size="sm"
                                      format="none"
                                      onClick={() =>
                                        dispatch(
                                          showRenameLocationActionsSuccess({
                                            renameLocation: true,
                                            location: { name: data.name, email: data.email, id: data.id },
                                          })
                                        )
                                      }
                                    >
                                      <BsPencil className="dropdown-item-icon" /> Rename Location
                                    </GlobalButton>
                                  </div>

                                  <div className="dropdown-item">
                                    <GlobalButton
                                      size="sm"
                                      format="none"
                                      onClick={() =>
                                        dispatch(
                                          showTimezoneActionsSuccess({
                                            timezone: true,
                                            location: { name: data.name, email: data.email, id: data.id },
                                          })
                                        )
                                      }
                                    >
                                      <BsGeoFill className="dropdown-item-icon" /> Update Timezone
                                    </GlobalButton>
                                  </div>

                                  <div className="dropdown-item">
                                    <GlobalButton
                                      size="sm"
                                      format="none"
                                      onClick={() =>
                                        dispatch(
                                          showAddAreaActionsSuccess({
                                            addArea: true,
                                            location: { name: data.name, email: data.email, id: data.id },
                                          })
                                        )
                                      }
                                    >
                                      <BsPencil className="dropdown-item-icon" /> Add Area
                                    </GlobalButton>
                                  </div>

                                  <div className="dropdown-divider"></div>
                                  <div className="dropdown-item text-danger">
                                    {data.is_active ? (
                                      <GlobalButton
                                        size="sm"
                                        format="none"
                                        className="text-danger"
                                        onClick={() => {
                                          const devicesId =
                                            (data &&
                                              data.areas &&
                                              data.areas.length &&
                                              data.areas
                                                .filter((player: any) => player.device_id)
                                                .map((deviceId: any) => deviceId.device_id)) ||
                                            [];
                                          dispatch(
                                            showDeactivateLocationActionsSuccess({
                                              deactivateLocation: true,
                                              location: { name: data.name, email: data.email, id: data.id },
                                              devicesId: devicesId,
                                            })
                                          );
                                        }}
                                      >
                                        <BsXOctagon className="dropdown-item-icon text-danger" /> Deactivate Location
                                      </GlobalButton>
                                    ) : (
                                      <GlobalButton
                                        size="sm"
                                        format="none"
                                        className="text-danger"
                                        onClick={() => {
                                          const devicesId =
                                            (data &&
                                              data.areas &&
                                              data.areas.length &&
                                              data.areas
                                                .filter((player: any) => player.device_id)
                                                .map((deviceId: any) => deviceId.device_id)) ||
                                            [];

                                          dispatch(
                                            showActivateLocationActionsSuccess({
                                              activateLocation: true,
                                              location: { name: data.name, email: data.email, id: data.id },
                                              devicesId: devicesId,
                                            })
                                          );
                                        }}
                                      >
                                        <BsXOctagon className="dropdown-item-icon text-danger" /> Activate Location
                                      </GlobalButton>
                                    )}
                                  </div>
                                  <div className="dropdown-item text-danger">
                                    <GlobalButton
                                      size="sm"
                                      format="none"
                                      className="text-danger"
                                      onClick={() => {
                                        dispatch(
                                          showFreezeLocationActionsSuccess({
                                            freezeLocation: {
                                              status: true,
                                              message: data.is_frozen ? "Unfreeze" : "Freeze",
                                              isFrozen: !data.is_frozen,
                                            },
                                            location: { name: data.name, email: data.email, id: data.id },
                                          })
                                        );
                                      }}
                                    >
                                      <BsXOctagon className="dropdown-item-icon text-danger" />{" "}
                                      {data.is_frozen ? "Unfreeze Location" : "Freeze Location"}
                                    </GlobalButton>
                                  </div>
                                </GlobalDropdown>
                              </div>
                            </div>
                          }
                        >
                          {data && data.areas && data.areas.length ? (
                            data.areas
                              .filter((area: { is_active: boolean; device: { serial: string; last_seen: number } }) => {
                                const deviceHasFields = area.is_active && area.device.serial && area.device.last_seen;
                                let filterPass = true;

                                if (playerStatus === "all") {
                                  filterPass = true;
                                } else if (
                                  deviceHasFields &&
                                  differenceInMilliseconds(Date.now(), area.device.last_seen) / 60000 < 10 &&
                                  playerStatus === "online"
                                ) {
                                  filterPass = true;
                                } else if (
                                  deviceHasFields &&
                                  differenceInMilliseconds(Date.now(), area.device.last_seen) / 60000 > 10 &&
                                  playerStatus === "offline"
                                ) {
                                  filterPass = true;
                                } else if (
                                  !area.is_active &&
                                  area.device &&
                                  area.device.serial &&
                                  playerStatus === "inactive"
                                ) {
                                  filterPass = true;
                                } else if (
                                  ((area.device && !area.device.serial) || !area.device) &&
                                  playerStatus === "no-player"
                                ) {
                                  filterPass = true;
                                } else {
                                  filterPass = false;
                                }
                                return filterPass;
                              })
                              .sort((a: { name: string }, b: { name: string }) => {
                                return a.name.localeCompare(b.name);
                              })
                              .map((area: any, areaIndex: number) => {
                                const deviceStatus = area.is_active && area.device.serial && area.device.last_seen;
                                const scheduleDefaultValue: any =
                                  schedulesSlice.schedules.length &&
                                  schedulesSlice.schedules.find(
                                    (schedule: any) => schedule.id === area.week_schedule_id
                                  );
                                const fakeDeviceReg = /^f-[0-9]{5}$/i;
                                const realDevice = !fakeDeviceReg.test(area?.device?.serial);

                                return (
                                  <div key={area.area_id || areaIndex}>
                                    <RowCard>
                                      <div className="row align-items-center">
                                        <div className="col-sm-3">
                                          <h4 className="font-weight-bold mb-0">{area.name}</h4>
                                          <small>{area.device.serial}</small>
                                        </div>
                                        <div className="col-sm mb-2 mb-sm-0 d-flex align-items-center">
                                          {/* Schedule Dropdown */}
                                          {schedulesSlice.schedules && schedulesSlice.schedules.length ? (
                                            <GlobalDropdown
                                              name={
                                                (scheduleDefaultValue && scheduleDefaultValue.name) || "Schedule Lists"
                                              }
                                              icon={<BsCalendarWeek />}
                                              format="white"
                                              size="sm"
                                              width="16rem"
                                              height="225px"
                                              align="end"
                                              className="me-4 font-weight-bold"
                                            >
                                              {schedulesSlice.schedules.map(
                                                (schedule: { name: string }, scheduleIndex: number) => {
                                                  return (
                                                    <div key={scheduleIndex} className="dropdown-item">
                                                      <GlobalButton
                                                        size="sm"
                                                        format="none"
                                                        onClick={() =>
                                                          dispatch(
                                                            showChangeScheduleAreaActionsSuccess({
                                                              changeSchedule: true,
                                                              area: {
                                                                name: area.name,
                                                                id: area.area_id,
                                                                serial: (area.device && area.device.serial) || "",
                                                                schedule: schedule,
                                                              },
                                                              location: {
                                                                name: data.name,
                                                                email: data.email,
                                                                id: data.id,
                                                              },
                                                            })
                                                          )
                                                        }
                                                      >
                                                        {schedule.name}
                                                      </GlobalButton>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </GlobalDropdown>
                                          ) : null}

                                          <div>
                                            <span>
                                              <small className="text-body">Ambie Player - </small>
                                              <small className="badge bg-soft-dark text-dark p-1">
                                                {audioSettingsForDisplay(area.audio_setting)}
                                              </small>
                                            </span>
                                            <ul className="list-inline d-flex align-items-center my-2">
                                              <li className="list-inline-item me-3">
                                                {AmbiePlayerStatus({ ...area, locationStatus: data.is_active })}
                                              </li>
                                              <li className="list-inline-item">
                                                {AmbieMusicPlaybackStatus({ ...area, locationStatus: data.is_active })}
                                              </li>
                                              <li className="list-inline-item ms-3">
                                                {/* TODO: Hide if player inactive */}
                                                {deviceStatus && (
                                                  <GlobalButton
                                                    size="xs"
                                                    format="white"
                                                    leftIcon={<BsMusicNoteBeamed />}
                                                    onClick={() => {
                                                      if (values.showMiniplayer === data.name + area.name) {
                                                        setDisplayMiniplayer("");
                                                      } else {
                                                        setDisplayMiniplayer(data.name + area.name);
                                                      }
                                                    }}
                                                  ></GlobalButton>
                                                )}
                                              </li>
                                            </ul>
                                          </div>
                                          {area.is_active &&
                                            area.streaming &&
                                            area.streaming.streaming &&
                                            area.streaming.streaming.enabled && (
                                              <div className="ms-4">
                                                <small className="text-body">Web Player</small>
                                                <ul className="list-inline d-flex align-items-center my-2">
                                                  <li className="list-inline-item me-3">{WebPlayerStatus(area)}</li>
                                                  <li className="list-inline-item">{WebMusicPlaybackStatus(area)}</li>
                                                  <li className="list-inline-item ms-3">
                                                    {/* TODO: Hide if player inactive */}
                                                    <GlobalButton
                                                      size="xs"
                                                      format="white"
                                                      leftIcon={<BsMusicNoteBeamed />}
                                                      onClick={() => {}}
                                                    ></GlobalButton>
                                                  </li>
                                                </ul>
                                              </div>
                                            )}
                                        </div>
                                        <div className="col-auto ms-auto">
                                          {/* Actions Dropdown */}
                                          <GlobalDropdown name="Actions" format="white" size="sm" align="end">
                                            <div className="dropdown-item">
                                              <GlobalButton
                                                size="sm"
                                                format="none"
                                                onClick={() =>
                                                  dispatch(
                                                    showViewCommercialInjectionRulesAreaActionsSuccess({
                                                      viewCommercialInjectionRules: true,
                                                      area: {
                                                        name: area.name,
                                                        id: area.area_id,
                                                        serial: (area.device && area.device.serial) || "",
                                                      },
                                                    })
                                                  )
                                                }
                                              >
                                                <BsTable className="dropdown-item-icon" /> View Commercial Rules
                                              </GlobalButton>
                                            </div>

                                            {scheduleDefaultValue && scheduleDefaultValue.id && (
                                              <div className="dropdown-item">
                                                <GlobalButton
                                                  size="sm"
                                                  format="none"
                                                  onClick={() => {
                                                    const timezoneName =
                                                      (data.timezone && data.timezone.name) ||
                                                      schedulesSlice.defaultTimezoneName;

                                                    // dispatch(
                                                    //   showViewScheduleAreaActionsSuccess({
                                                    //     viewSchedule: { status: true, timezoneName },
                                                    //     area: {
                                                    //       name: area.name,
                                                    //       id: area.area_id,
                                                    //       serial: (area.device && area.device.serial) || "",
                                                    //       schedule: scheduleDefaultValue,
                                                    //     },
                                                    //   })
                                                    // );

                                                    dispatch(
                                                      changeTimezoneSuccess({
                                                        timezoneName: timezoneName,
                                                      })
                                                    );
                                                    navigation(schedules_page_navigate, {
                                                      state: { schedule: scheduleDefaultValue },
                                                    });
                                                  }}
                                                >
                                                  <BsCalendarWeek className="dropdown-item-icon" /> View Schedule
                                                </GlobalButton>
                                              </div>
                                            )}

                                            {realDevice && (
                                              <div className="dropdown-item">
                                                <GlobalButton
                                                  size="sm"
                                                  format="none"
                                                  onClick={() => {
                                                    window.open(
                                                      `https://papertrailapp.com/systems/${area.device.serial}/events`,
                                                      "_blank"
                                                    );
                                                  }}
                                                >
                                                  <BsFileEarmarkCode className="dropdown-item-icon" /> View Papertrail
                                                </GlobalButton>
                                              </div>
                                            )}

                                            <div className="dropdown-item">
                                              <GlobalButton
                                                size="sm"
                                                format="none"
                                                onClick={() =>
                                                  dispatch(
                                                    showResendScheduleAreaActionsSuccess({
                                                      resendSchedule: true,
                                                      area: {
                                                        name: area.name,
                                                        id: area.area_id,
                                                      },
                                                    })
                                                  )
                                                }
                                              >
                                                <BsArrowClockwise className="dropdown-item-icon" /> Resend Schedule
                                              </GlobalButton>
                                            </div>

                                            {realDevice && (
                                              <div className="dropdown-item">
                                                <GlobalButton
                                                  size="sm"
                                                  format="none"
                                                  onClick={() =>
                                                    dispatch(
                                                      showUpdateSoftwareAreaActionsSuccess({
                                                        updateSoftware: true,
                                                        area: {
                                                          name: area.name,
                                                          id: area.area_id,
                                                          serial: (area.device && area.device.serial) || "",
                                                        },
                                                      })
                                                    )
                                                  }
                                                >
                                                  <BsFileEarmarkArrowUp className="dropdown-item-icon" /> Update
                                                  Software
                                                </GlobalButton>
                                              </div>
                                            )}
                                            <div className="dropdown-item">
                                              <GlobalButton
                                                size="sm"
                                                format="none"
                                                onClick={() =>
                                                  dispatch(
                                                    showAssignDeviceAreaActionsSuccess({
                                                      assignDevice: true,
                                                      area: {
                                                        name: area.name,
                                                        id: area.area_id,
                                                      },
                                                      location: { name: data.name, email: data.email, id: data.id },
                                                    })
                                                  )
                                                }
                                              >
                                                <BsHdd className="dropdown-item-icon" /> Assign Device
                                              </GlobalButton>
                                            </div>

                                            <div className="dropdown-item">
                                              <GlobalButton
                                                size="sm"
                                                format="none"
                                                onClick={() =>
                                                  dispatch(
                                                    showChangeAudioSettingSuccess({
                                                      changeAudioSetting: true,
                                                      area: {
                                                        name: area.name,
                                                        id: area.area_id,
                                                        audioSetting: area.audio_setting,
                                                      },
                                                    })
                                                  )
                                                }
                                              >
                                                <BsMusicNoteList className="dropdown-item-icon" />
                                                Change Audio Setting
                                              </GlobalButton>
                                            </div>

                                            <div className="dropdown-item">
                                              <GlobalButton
                                                size="sm"
                                                format="none"
                                                onClick={() =>
                                                  dispatch(
                                                    showRenameAreaActionsSuccess({
                                                      renameArea: true,
                                                      area: {
                                                        name: area.name,
                                                        id: area.area_id,
                                                      },
                                                    })
                                                  )
                                                }
                                              >
                                                <BsPencil className="dropdown-item-icon" /> Rename Area
                                              </GlobalButton>
                                            </div>

                                            <div className="dropdown-divider"></div>
                                            {area.device && area.device.serial && (
                                              <div className="dropdown-item text-danger">
                                                <GlobalButton
                                                  size="sm"
                                                  format="none"
                                                  className="text-danger"
                                                  onClick={() =>
                                                    dispatch(
                                                      showAreaStreamingActionsSuccess({
                                                        streamingStatus: true,
                                                        area: {
                                                          name: area.name,
                                                          id: area.area_id,
                                                          streaming: area.streaming && area.streaming.streaming,
                                                        },
                                                      })
                                                    )
                                                  }
                                                >
                                                  <BsCloud className="dropdown-item-icon text-danger" /> Enable/disable
                                                  Streaming
                                                </GlobalButton>
                                              </div>
                                            )}
                                            {area.device && area.device.serial && (
                                              <>
                                                {area.is_active ? (
                                                  <div className="dropdown-item text-danger">
                                                    <GlobalButton
                                                      size="sm"
                                                      format="none"
                                                      className="text-danger"
                                                      onClick={() =>
                                                        dispatch(
                                                          showDeactivateAreaActionsSuccess({
                                                            deactivateArea: true,
                                                            area: {
                                                              name: area.name,
                                                              id: area.area_id,
                                                            },
                                                          })
                                                        )
                                                      }
                                                    >
                                                      <BsXOctagon className="dropdown-item-icon text-danger" />{" "}
                                                      Deactivate Area
                                                    </GlobalButton>
                                                  </div>
                                                ) : (
                                                  <div className="dropdown-item text-danger">
                                                    <GlobalButton
                                                      size="sm"
                                                      format="none"
                                                      className="text-danger"
                                                      onClick={() =>
                                                        dispatch(
                                                          showActivateAreaActionsSuccess({
                                                            activateArea: true,
                                                            area: {
                                                              name: area.name,
                                                              id: area.area_id,
                                                              serial: (area.device && area.device.serial) || "",
                                                            },
                                                          })
                                                        )
                                                      }
                                                    >
                                                      <BsXOctagon className="dropdown-item-icon text-danger" /> Activate
                                                      Area
                                                    </GlobalButton>
                                                  </div>
                                                )}
                                                {area.device && area.device.serial && (
                                                  <div className="dropdown-item text-danger">
                                                    <GlobalButton
                                                      size="sm"
                                                      format="none"
                                                      className="text-danger"
                                                      onClick={() =>
                                                        dispatch(
                                                          showRemoveDeviceFromAreaActionsSuccess({
                                                            removeDeviceFromArea: true,
                                                            area: {
                                                              name: area.name,
                                                              id: area.area_id,
                                                              serial: (area.device && area.device.serial) || "",
                                                            },
                                                          })
                                                        )
                                                      }
                                                    >
                                                      <BsXCircleFill className="dropdown-item-icon text-danger" />{" "}
                                                      Remove device from area
                                                    </GlobalButton>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </GlobalDropdown>
                                        </div>
                                      </div>
                                    </RowCard>
                                    {values.showMiniplayer === data.name + area.name && <Miniplayer area={area} />}
                                  </div>
                                );
                              })
                          ) : (
                            <div>
                              <RowCard>
                                <div style={{ textAlign: "center" }}>
                                  <h3>No area found</h3>
                                </div>
                              </RowCard>
                            </div>
                          )}
                        </KeyValueCard>
                      </div>
                    );
                  })
              ) : (
                <div style={{ textAlign: "center", marginTop: 100 }}>
                  <h3>No location found</h3>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
