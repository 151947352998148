import { cloneDeep } from "lodash";
import moment from "moment-timezone";

const defaultTimezoneName = "Europe/London";

class TimezoneHelper {
  static get defaults() {
    return {
      days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      times: TimezoneHelper.generateTimes(defaultTimezoneName),
      timezone: TimezoneHelper.generateTimezoneObj(defaultTimezoneName),
      timezoneName: defaultTimezoneName,
      startOfUKWeek: moment().tz(defaultTimezoneName).startOf("isoWeek"),
    };
  }

  static get emptySchedule() {
    return [...Array(TimezoneHelper.defaults.days.length)].map(() => []);
  }

  static timezoneOffset = (timezoneName = TimezoneHelper.defaults.timezoneName) => {
    const timezoneObj = TimezoneHelper.generateTimezoneObj(timezoneName);
    return { hours: timezoneObj.offsetHours, minutes: timezoneObj.offsetMins };
  };

  static generateTimes = (timezoneName = TimezoneHelper.defaults.timezoneName) => {
    let date = moment().tz(timezoneName).startOf("isoWeek").parseZone();
    let times = [date];
    const dayOfYear = date.dayOfYear();
    while (date.dayOfYear() === dayOfYear) {
      date = date.clone().add(15, "minutes");
      times.push(date);
    }
    return times;
  };

  static generateTimezoneObj = (timezoneName = TimezoneHelper.defaults.timezoneName) => {
    const zone = moment.tz.zone(timezoneName);
    const offsetHoursAndMins = (zone.utcOffset(moment().utc()) / 60) * -1;
    const offsetHours = Math.floor(offsetHoursAndMins);
    const offsetMins = (Math.abs(offsetHoursAndMins) - Math.abs(offsetHours)) * 60;
    const offsetSymbol = offsetHours >= 0 ? "+" : "";
    const offsetDisplay = `GMT ${offsetSymbol + offsetHours}:${
      Math.abs(offsetMins) < 10 ? `0${Math.abs(offsetMins)}` : Math.abs(offsetMins)
    }`;
    return {
      name: timezoneName,
      offsetHours,
      offsetMins: offsetHours >= 0 ? offsetMins : -offsetMins,
      offsetHoursAndMins,
      offsetDisplay,
    };
  };

  static generateTimezones = () =>
    moment.tz
      .names()
      .map((tz) => TimezoneHelper.generateTimezoneObj(tz))
      .sort((a, b) => a.offsetHoursAndMins - b.offsetHoursAndMins);

  static toUKDate = (dateTime) => {
    const ukOffset = moment.tz(TimezoneHelper.defaults.timezoneName).utcOffset();
    const ukOffsetHours = Math.floor(ukOffset / 60).toString();
    const ukOffsetMins = (ukOffset % 60).toString();
    const ukGmtOffsetString = ` GMT+${ukOffsetHours.padStart(2, "0")}${ukOffsetMins.padEnd(2, "0")}`;
    if (typeof dateTime === "number") {
      return new Date(dateTime);
    }
    if (typeof dateTime === "string" && !dateTime.includes("GMT")) {
      return Date.parse(new Date(dateTime.replace(/-/g, "/") + ukGmtOffsetString));
    }
    if (dateTime instanceof moment) {
      return dateTime.clone().tz(TimezoneHelper.defaults.timezoneName).parseZone();
    }
    return dateTime;
  };

  static extractTimeFromDateString = (dateString) => {
    const re = new RegExp(/!*\d\d:\d\d:\d\d!*/);
    const matches = dateString.match(re);
    if (matches && matches.length > 0) {
      const [hours, minutes, seconds] = matches[0].split(":");
      return [hours, minutes, seconds];
    } else {
      return null;
    }
  };

  static dayIndex = (momentTime) => momentTime.isoWeekday() - 1;

  static sortWeeklySchedule = (schedule) => schedule.map((day) => TimezoneHelper.sortDailySchedule(day));

  static sortDailySchedule = (dailySchedule) => {
    const sortedSchedule = [];
    dailySchedule
      .sort((a, b) => a.start_time.diff(b.start_time))
      .forEach((playlist) => {
        if (sortedSchedule.length > 0) {
          const prevPlaylist = sortedSchedule[sortedSchedule.length - 1];
          const prevStart = prevPlaylist.start_time.clone();
          const prevEnd = prevPlaylist.end_time.clone();
          const currendStart = playlist.start_time.clone();
          if (
            currendStart.isBetween(prevStart, prevEnd, null, "[]") &&
            prevPlaylist.playlist.id === playlist.playlist.id
          ) {
            sortedSchedule[sortedSchedule.length - 1].end_time = playlist.end_time.clone();
          } else {
            sortedSchedule.push(playlist);
          }
        } else {
          sortedSchedule.push(playlist);
        }
      });
    return sortedSchedule;
  };

  static timezonifySchedule = (schedule, times, timezoneName) => {
    const timezoneOffset = TimezoneHelper.timezoneOffset(timezoneName);
    let timezoneAdjustedSchedule = [];
    const offsetHours = TimezoneHelper.defaults.timezone.offsetHours;
    const offsetMins = TimezoneHelper.defaults.timezone.offsetMins;
    const defaultOffset = offsetHours + offsetMins / 60;
    if (timezoneOffset.hours + timezoneOffset.minutes / 60 === defaultOffset) {
      timezoneAdjustedSchedule = TimezoneHelper.sortNoOffset(schedule);
    } else if (timezoneOffset.hours + timezoneOffset.minutes / 60 > defaultOffset) {
      timezoneAdjustedSchedule = TimezoneHelper.sortOffset(schedule, times, timezoneName, true);
    } else {
      timezoneAdjustedSchedule = TimezoneHelper.sortOffset(schedule, times, timezoneName, false);
    }
    return TimezoneHelper.sortWeeklySchedule(timezoneAdjustedSchedule);
  };

  static getStartEndTime = (playlist, dayIndex, isOffset = false) => {
    let daysToAdd = dayIndex;
    const startOfUKWeek = TimezoneHelper.defaults.startOfUKWeek.clone();
    let [hours, minutes, seconds] = TimezoneHelper.extractTimeFromDateString(playlist.start_time);
    const start = startOfUKWeek.clone().set({ hours, minutes, seconds }).add(daysToAdd, "days");
    [hours, minutes, seconds] = TimezoneHelper.extractTimeFromDateString(playlist.end_time);
    daysToAdd = isOffset && [hours, minutes, seconds].every((n) => parseInt(n, 10) === 0) ? daysToAdd + 1 : daysToAdd;
    const end = startOfUKWeek.clone().set({ hours, minutes, seconds }).add(daysToAdd, "days");
    return [start, end];
  };

  static sortNoOffset = (schedule) => {
    const timezoneAdjustedSchedule = TimezoneHelper.emptySchedule;
    TimezoneHelper.defaults.days.forEach((_, dayIndex) => {
      schedule[dayIndex]
        .sort((a, b) => TimezoneHelper.toUKDate(a.start_time) - TimezoneHelper.toUKDate(b.start_time))
        .forEach((pl) => {
          const playlist = JSON.parse(JSON.stringify(pl));
          const [start, end] = TimezoneHelper.getStartEndTime(playlist, dayIndex);
          playlist.start_time = start.clone();
          playlist.end_time = end.clone();
          if (playlist.end_time.hour() === 0 && playlist.end_time.minute() === 0) {
            playlist.end_time = playlist.end_time.clone().add(1, "days");
          }
          timezoneAdjustedSchedule[dayIndex].push(playlist);
        });
    });
    return timezoneAdjustedSchedule;
  };

  static sortOffset = (schedule, times, timezoneName, isPositiveOffset) => {
    timezoneName = timezoneName.replace(" ", "_");
    const timezoneAdjustedSchedule = TimezoneHelper.emptySchedule;
    TimezoneHelper.defaults.days.forEach((_, dayIndex) => {
      schedule[dayIndex]
        .sort((a, b) => TimezoneHelper.toUKDate(a.start_time) - TimezoneHelper.toUKDate(b.start_time))
        .forEach((pl) => {
          let playlist = JSON.parse(JSON.stringify(pl));
          let overflowPlaylist = null;
          const [start, end] = TimezoneHelper.getStartEndTime(playlist, dayIndex, true);
          playlist.start_time = start.clone().tz(timezoneName).parseZone();
          playlist.end_time = end.clone().tz(timezoneName).parseZone();
          [playlist, overflowPlaylist] = isPositiveOffset
            ? TimezoneHelper.getPositiveOverflow(playlist, times)
            : TimezoneHelper.getNegativeOverflow(playlist, times);
          timezoneAdjustedSchedule[playlist.dayIndex].push(playlist);
          if (overflowPlaylist) timezoneAdjustedSchedule[overflowPlaylist.dayIndex].push(overflowPlaylist);
        });
    });
    return timezoneAdjustedSchedule;
  };

  static getPositiveOverflow = (playlist, times) => {
    let overflowPlaylist = null;
    if (
      playlist.start_time.date() !== playlist.end_time.date() &&
      playlist.end_time.hour() + playlist.end_time.minutes() !== 0
    ) {
      overflowPlaylist = cloneDeep(playlist);
      overflowPlaylist.start_time = overflowPlaylist.end_time.clone().startOf("day");
      overflowPlaylist.dayIndex = TimezoneHelper.dayIndex(overflowPlaylist.start_time);
      overflowPlaylist.start_time = TimezoneHelper.setCorrectStartDate(overflowPlaylist.start_time, times);
      overflowPlaylist.end_time = TimezoneHelper.setCorrectEndDate(overflowPlaylist.end_time, times);
      playlist.end_time = playlist.start_time.clone().add(1, "days").startOf("day");
    }
    playlist.dayIndex = TimezoneHelper.dayIndex(playlist.start_time);
    playlist.start_time = TimezoneHelper.setCorrectStartDate(playlist.start_time, times);
    playlist.end_time = TimezoneHelper.setCorrectEndDate(playlist.end_time, times);
    return [playlist, overflowPlaylist];
  };

  static getNegativeOverflow = (playlist, times) => {
    let overflowPlaylist = null;
    if (
      playlist.start_time.date() !== playlist.end_time.date() &&
      playlist.end_time.hour() + playlist.end_time.minutes() !== 0
    ) {
      overflowPlaylist = cloneDeep(playlist);
      overflowPlaylist.end_time = overflowPlaylist.start_time.clone().add(1, "days").startOf("day");
      overflowPlaylist.dayIndex = TimezoneHelper.dayIndex(overflowPlaylist.start_time);
      overflowPlaylist.start_time = TimezoneHelper.setCorrectStartDate(overflowPlaylist.start_time, times);
      overflowPlaylist.end_time = TimezoneHelper.setCorrectEndDate(overflowPlaylist.end_time, times);
      playlist.start_time = playlist.end_time.clone().startOf("day");
    }
    playlist.dayIndex = TimezoneHelper.dayIndex(playlist.start_time);
    playlist.start_time = TimezoneHelper.setCorrectStartDate(playlist.start_time, times);
    playlist.end_time = TimezoneHelper.setCorrectEndDate(playlist.end_time, times);
    return [playlist, overflowPlaylist];
  };

  static setCorrectStartDate = (date, times) => {
    const startOfWeek = times[0].clone();
    const endOfWeek = times[0].clone().endOf("isoWeek");
    if (date.isAfter(endOfWeek)) {
      return date.clone().subtract(1, "weeks");
    } else if (date.isBefore(startOfWeek)) {
      return date.clone().add(1, "weeks");
    }
    return date.clone();
  };

  static setCorrectEndDate = (date, times) => {
    const startOfWeek = times[0].clone();
    const endOfWeek = times[0].clone().add(1, "weeks");
    if (date.isAfter(endOfWeek)) {
      return date.clone().subtract(1, "weeks");
    } else if (date.isSameOrBefore(startOfWeek)) {
      return date.clone().add(1, "weeks");
    }
    return date.clone();
  };

  static isOverlapInWeek = (schedule, newPlaylists) => {
    const playlists = Array.isArray(newPlaylists) ? [...newPlaylists] : [newPlaylists];
    return playlists.some((playlist) =>
      schedule.some((dailySchedule) => TimezoneHelper.isOverlapInDay(dailySchedule, playlist))
    );
  };

  static isOverlapInDay = (dailySchedule, newPlaylist) =>
    dailySchedule.some(
      (playlist) =>
        newPlaylist.start_time.isBetween(playlist.start_time, playlist.end_time, null, "()") ||
        newPlaylist.end_time.isBetween(playlist.start_time, playlist.end_time, null, "()") ||
        playlist.start_time.isBetween(newPlaylist.start_time, newPlaylist.end_time, null, "()") ||
        playlist.end_time.isBetween(newPlaylist.start_time, newPlaylist.end_time, null, "()") ||
        (newPlaylist.start_time.isSame(playlist.start_time) && newPlaylist.end_time.isSame(playlist.end_time))
    );

  static isWeeklyScheduleValid = (schedule) =>
    schedule.some((dailySchedule) => TimezoneHelper.isDailyScheduleValid(dailySchedule));

  static isDailyScheduleValid = (dailySchedule) =>
    dailySchedule.some((playlist, i, self) => {
      const copy = [...self];
      copy.splice(i, 1);
      return TimezoneHelper.isOverlapInDay(copy, playlist);
    });

  static isGapsInSchedule = (schedule) => {
    const scheduleList = [...schedule];
    return scheduleList.some((daySchedule) =>
      daySchedule
        // .sort((a, b) => a.start_time.diff(b.start_time))
        .some(
          (schedule, i, self) =>
            i !== self.length - 1 && self[i + 1] && !schedule.end_time.isSame(self[i + 1].start_time)
        )
    );
  };

  static isEmptySchedule = (schedule) => schedule.every((day) => day.length === 0);

  static makeSpaceInSchedule = (schedule, newPlaylists) => {
    const timezoneAdjustedSchedule = cloneDeep(schedule);
    const tempTimezoneAdjustedSchedule = cloneDeep(schedule);
    newPlaylists.forEach((newPlaylist) => {
      const dayIndex = TimezoneHelper.dayIndex(newPlaylist.start_time);
      tempTimezoneAdjustedSchedule[dayIndex].forEach((playlist, i) => {
        if (
          TimezoneHelper.playlistShouldBeDeleted(playlist, newPlaylist) ||
          TimezoneHelper.isEditPlaylist(playlist, newPlaylist)
        ) {
          timezoneAdjustedSchedule[dayIndex][i].shouldDelete = true;
        } else if (TimezoneHelper.newPlaylistIsSandwiched(playlist, newPlaylist)) {
          const playlistBefore = cloneDeep(playlist);
          playlistBefore.end_time = newPlaylist.start_time.clone();
          timezoneAdjustedSchedule[dayIndex][i] = playlistBefore;
          const playlistAfter = cloneDeep(playlist);
          playlistAfter.start_time = newPlaylist.end_time.clone();
          timezoneAdjustedSchedule[dayIndex].push(playlistAfter);
        } else if (TimezoneHelper.newPlaylistOverlapsEndTime(playlist, newPlaylist)) {
          const updatedPlaylist = cloneDeep(playlist);
          updatedPlaylist.end_time = newPlaylist.start_time.clone();
          timezoneAdjustedSchedule[dayIndex][i] = updatedPlaylist;
        } else if (TimezoneHelper.newPlaylistOverlapsStartTime(playlist, newPlaylist)) {
          const updatedPlaylist = cloneDeep(playlist);
          updatedPlaylist.start_time = newPlaylist.end_time.clone();
          timezoneAdjustedSchedule[dayIndex][i] = updatedPlaylist;
        }
      });
      timezoneAdjustedSchedule[dayIndex].push(newPlaylist);
    });
    return timezoneAdjustedSchedule.map((day) =>
      day.filter((item) => !item.shouldDelete).sort((a, b) => a.start_time.diff(b.start_time))
    );
  };

  static playlistShouldBeDeleted = (playlist, newPlaylist) =>
    (playlist.start_time.isSame(newPlaylist.start_time) && playlist.end_time.isSame(newPlaylist.end_time)) ||
    (playlist.start_time.isBetween(newPlaylist.start_time, newPlaylist.end_time, null, "[]") &&
      playlist.end_time.isBetween(newPlaylist.start_time, newPlaylist.end_time, null, "[]"));

  static newPlaylistIsSandwiched = (playlist, newPlaylist) =>
    newPlaylist.start_time.isBetween(playlist.start_time, playlist.end_time, null, "()") &&
    newPlaylist.end_time.isBetween(playlist.start_time, playlist.end_time, null, "()");

  static isEditPlaylist = (playlist, newPlaylist) =>
    newPlaylist.start_time.isBetween(playlist.start_time, playlist.end_time, null, "[]") &&
    newPlaylist.end_time.isBetween(playlist.start_time, playlist.end_time, null, "[]") &&
    newPlaylist.isEditChannel;

  static newPlaylistOverlapsEndTime = (playlist, newPlaylist) =>
    newPlaylist.start_time.isBetween(playlist.start_time, playlist.end_time, null, "()") &&
    newPlaylist.start_time.isAfter(playlist.start_time);

  static newPlaylistOverlapsStartTime = (playlist, newPlaylist) =>
    newPlaylist.end_time.isBetween(playlist.start_time, playlist.end_time, null, "()") &&
    newPlaylist.end_time.isBefore(playlist.end_time);

  static removePlaylistFromSchedule(schedule, startTime, endTime, playlist) {
    const playlistToDelete = {
      start_time: startTime.clone(),
      end_time: endTime.clone(),
      dayIndex: TimezoneHelper.dayIndex(startTime),
      playlist,
    };
    schedule[playlistToDelete.dayIndex] = schedule[playlistToDelete.dayIndex].filter(
      (playlist) =>
        !(
          playlist.start_time.isSame(playlistToDelete.start_time) &&
          playlist.end_time.isSame(playlistToDelete.end_time) &&
          playlist.playlist.id === playlistToDelete.playlist.id
        )
    );
    return schedule;
  }

  static scheduleToUKDateString = (schedule) => {
    const ukSchedule = TimezoneHelper.emptySchedule;
    schedule.forEach((day) =>
      day.forEach((playlist) => {
        if (playlist) {
          let [ukPlaylist, overflowPlaylist] = TimezoneHelper.playlistToUKMoment(playlist);
          ukPlaylist = TimezoneHelper.playlistToUKDateString(ukPlaylist);
          overflowPlaylist = overflowPlaylist ? TimezoneHelper.playlistToUKDateString(overflowPlaylist) : null;
          ukSchedule[ukPlaylist.dayIndex].push(ukPlaylist);
          if (overflowPlaylist) {
            ukSchedule[overflowPlaylist.dayIndex].push(overflowPlaylist);
          }
        }
      })
    );
    return ukSchedule;
  };

  static scheduleToUKMoment = (schedule) => {
    const ukSchedule = TimezoneHelper.emptySchedule;
    schedule.forEach((day) =>
      day.forEach((playlist) => {
        if (playlist) {
          const [ukPlaylist, overflowPlaylist] = TimezoneHelper.playlistToUKMoment(playlist);
          ukSchedule[ukPlaylist.dayIndex].push(ukPlaylist);
          if (overflowPlaylist) {
            ukSchedule[overflowPlaylist.dayIndex].push(overflowPlaylist);
          }
        }
      })
    );
    return ukSchedule;
  };

  static playlistToUKMoment = (newPlaylist) => {
    const times = cloneDeep(TimezoneHelper.defaults.times);
    const timezone = TimezoneHelper.generateTimezoneObj();
    const newUKPlaylist = cloneDeep(newPlaylist);
    newUKPlaylist.start_time = newUKPlaylist.start_time.clone().tz(TimezoneHelper.defaults.timezoneName).parseZone();
    newUKPlaylist.end_time = newUKPlaylist.end_time.clone().tz(TimezoneHelper.defaults.timezoneName).parseZone();
    const offset = timezone.offsetHours + timezone.offsetMins;
    let [playlist, overflowPlaylist] = [newUKPlaylist, null];
    if (newPlaylist.start_time.zone() > 0) {
      [playlist, overflowPlaylist] = TimezoneHelper.getPositiveOverflow(newUKPlaylist, times);
    } else if (newPlaylist.start_time.zone() < 0) {
      [playlist, overflowPlaylist] = TimezoneHelper.getNegativeOverflow(newUKPlaylist, times);
    }
    return [playlist, overflowPlaylist];
  };

  static playlistToUKDateString = (playlist, format = "YYYY-MM-DD HH:mm:ss") => {
    const newUKPlaylist = cloneDeep(playlist);
    newUKPlaylist.start_time = TimezoneHelper.dateToUKMoment(playlist.start_time);
    newUKPlaylist.end_time = TimezoneHelper.dateToUKMoment(playlist.end_time);
    newUKPlaylist.dayIndex = TimezoneHelper.dayIndex(newUKPlaylist.start_time);
    newUKPlaylist.start_time = newUKPlaylist.start_time
      .clone()
      .tz(TimezoneHelper.defaults.timezoneName)
      .parseZone()
      .format(format);
    newUKPlaylist.end_time = newUKPlaylist.end_time
      .clone()
      .tz(TimezoneHelper.defaults.timezoneName)
      .parseZone()
      .format(format);
    return newUKPlaylist;
  };

  static dateToUKMoment = (date) => {
    const times = cloneDeep(TimezoneHelper.defaults.times);
    const ukDate = TimezoneHelper.toUKDate(date);
    if (!(ukDate instanceof moment)) {
      const startOfUKWeek = TimezoneHelper.defaults.startOfUKWeek.clone();
      const ukMoment = moment(ukDate)
        .tz(TimezoneHelper.defaults.timezoneName)
        .set({
          year: startOfUKWeek.year(),
          month: startOfUKWeek.month(),
        })
        .parseZone();
      const dayIndex = TimezoneHelper.dayIndex(ukMoment);
      const timeIndex = dayIndex === 0 ? 0 : dayIndex * 4 - 1;
      const dateNumber = times[timeIndex];
      ukMoment.date(dateNumber);
      return ukMoment;
    }
    return ukDate;
  };

  static createNewPlaylists = (scheduleObj, times) =>
    scheduleObj.days
      .map((day, dayIndex) => {
        if (!day) {
          return null;
        }
        const startTime = times[0].clone().add(dayIndex, "days").set({
          hour: scheduleObj.startValue.clone().hour(),
          minute: scheduleObj.startValue.clone().minute(),
        });
        const endTimeDayIndex = scheduleObj.endValue.clone().startOf("day").isSame(scheduleObj.endValue.clone())
          ? dayIndex + 1
          : dayIndex;
        const endTime = times[0].clone().add(endTimeDayIndex, "days").set({
          hour: scheduleObj.endValue.clone().hour(),
          minute: scheduleObj.endValue.clone().minute(),
        });
        return {
          start_time: TimezoneHelper.setCorrectStartDate(startTime, times),
          end_time: TimezoneHelper.setCorrectEndDate(endTime, times),
          playlist: {
            ...scheduleObj.channel,
            colour: scheduleObj.channel.colour || scheduleObj.channel.color || "#cd618d",
          },
          isEditChannel: scheduleObj.isEditChannel,
        };
      })
      .filter((el) => el !== null);
}
export default TimezoneHelper;
