import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import counterSlice from "./counterSlice";
import searchClientSlice from "./searchClientSlice";
import shakeAnimationSlice from "./shakeAnimationSlice";
import actionsSlice from "./actionsSlice";
import playlistsSlice from "./playlistsSlice";
import schedulesSlice from "./schedulesSlice";
import clientHubSlice from "./clientHubSlice";
import locationsSlice from "./locationsSlice";
import briefsSlice from "./briefsSlice";
export const rootReducers = combineReducers({
  authSlice,
  counterSlice,
  searchClientSlice,
  shakeAnimationSlice,
  actionsSlice,
  playlistsSlice,
  schedulesSlice,
  clientHubSlice,
  locationsSlice,
  briefsSlice,
});
