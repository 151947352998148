import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import ClientHub from "../pages/client-hub";
import Locations from "../pages/locations";
import { Schedules } from "../pages/schedules";
import { Playlists } from "../pages/playlists";
import { Users } from "../pages/users";
import { Devices } from "../pages/devices";
import { SecondLevelNavBar } from "./navbar/secondLevel";
import { ContentManagementBar } from "./navbar/secondLevel/contentManagement";
import ReusableComponents from "../pages/dashboard/reusableComponents";
import { Layout } from "./index";
import {
  dashboard_page,
  locations_page,
  users_page,
  playlists_page,
  schedules_page,
  reusable_components_page,
  client_page,
  devices_tools_page,
  tools_page,
  ambie_users_tools_page,
  clients_tools_page,
  ambie_page,
  briefs_page,
  clients_users_tools_page,
  schedules_migration_page,
} from "../constants/routePath";
import { AmbieUsers } from "../pages/users/ambieUsers";
import { AmbieClients } from "../pages/clients";
import { RootState } from "../redux-toolkit/store";
import { useSelector } from "react-redux";
import { SuperuserPermission } from "../components/permissions";
import { Briefs } from "../pages/briefs";
import { AmbieHubBar } from "./navbar/secondLevel/ambieHub";
import { ClientsUsers } from "../pages/users/clientsUsers";
import { NotFound } from "../pages/notFound";
import SchedulesMigration from "../pages/schedules-migration";
export const Authenticated = () => {
  const authSlice = useSelector((state: RootState) => state.authSlice);
  const user =
    authSlice.user &&
    authSlice.user.user &&
    authSlice.user.user.permission_level &&
    authSlice.user.user.permission_level.permission_level;
  return (
    <Layout>
      <Routes>
        <Route path={reusable_components_page} element={<ReusableComponents />} />

        {/* nested client page */}
        <Route path={client_page} element={<SecondLevelNavBar />}>
          <Route index element={<ClientHub />} />

          {/* dashboard */}
          <Route path={dashboard_page} element={<Dashboard />} />

          {/* locations */}
          <Route path={locations_page} element={<Locations />} />

          {/* schedules */}
          <Route path={schedules_page} element={<Schedules />} />

          {/* schedules migration*/}
          <Route path={schedules_migration_page} element={<SchedulesMigration />} />

          {/* playlists */}
          <Route path={playlists_page} element={<Playlists />} />

          {/* users */}
          <Route path={users_page} element={<Users />} />
        </Route>

        {/* nested tools page */}
        <Route path={tools_page} element={<ContentManagementBar />}>
          <Route index element={<Devices />} />

          {user === SuperuserPermission() && (
            <>
              <Route path={clients_tools_page} element={<AmbieClients />} />
              <Route path={ambie_users_tools_page} element={<AmbieUsers />} />
            </>
          )}

          <Route path={devices_tools_page} element={<Devices />} />
          <Route path={clients_users_tools_page} element={<ClientsUsers />} />
        </Route>

        {/* nested ambie page */}
        <Route path={ambie_page} element={<AmbieHubBar />}>
          <Route index element={<Briefs />} />

          <Route path={briefs_page} element={<Briefs />} />
        </Route>

        {/* not found page */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
};
