// import { BsFillXCircleFill } from "react-icons/bs";
import { GlobalButton } from "../../../../components/button";
import { showEditPlaylistSuccess } from "../../../../redux-toolkit/reducers/actionsSlice";
import { store } from "../../../../redux-toolkit/store";
import { MOODS } from "../../../playlists/components/mood/mood";
import { PlaylistTracksDuration } from "../../../playlists/components/playlist-modal/modal";

export const PlaylistMood = (playlists: any) => {
  return (
    <div className="row">
      {playlists.map((playlist: any) => {
        const mood = MOODS[(playlist && playlist.energyLevel) || "happy"];
        return (
          <div className="col-lg-6" key={playlist.value}>
            <div className="card card-body mb-3 bg-soft-secondary">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img className="avatar avatar-lg" src={mood.image} alt={`Mood - ${playlist.energyLevel}`} />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h5 className="text-inherit mb-0">{playlist.label}</h5>
                  <small className="d-block text-muted">{PlaylistTracksDuration(playlist.duration || 0)}</small>
                </div>
                <div className="ms-2">
                  {/* <BsFillXCircleFill size="20" className="text-danger" /> */}
                  <GlobalButton
                    size="xs"
                    format="primary"
                    onClick={() => {
                      store.dispatch(
                        showEditPlaylistSuccess({
                          editPlaylist: {
                            status: true,
                            playlistId: playlist.value,
                          },
                          playlistTitle: "Edit Playlist",
                          briefStatus: true,
                        })
                      );
                    }}
                  >
                    View
                  </GlobalButton>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
