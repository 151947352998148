import { AMBIE_ENV } from "../environment";

//Get environement variables
export const getEnvironment = () => {
  if (process.env.REACT_APP_AMBIE_ENV === "dev") {
    return AMBIE_ENV.DEVELOPMENT;
  } else if (process.env.REACT_APP_AMBIE_ENV === "staging") {
    return AMBIE_ENV.STAGING;
  } else if (process.env.REACT_APP_AMBIE_ENV === "production") {
    return AMBIE_ENV.PRODUCTION;
  } else if (process.env.REACT_APP_AMBIE_ENV === "preproduction") {
    return AMBIE_ENV.PREPRODUCTION;
  } else {
    return AMBIE_ENV.STAGING;
  }
};
