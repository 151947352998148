import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

// style components
import { RootState } from "../../redux-toolkit/store";
import { MiniCard, KeyValueCard } from "../../components/cards";
import { SearchWithOptions } from "../../components/fields/search";
import { GlobalButton } from "../../components/button";
import { AgGridReactTable } from "../../components/table";
import { LoadingData } from "../../components/loading";
import { GlobalDropdown } from "../../components/dropdown";
import { BsPencil, BsFillCheckCircleFill, BsFillXCircleFill, BsSearch, BsXOctagon } from "react-icons/bs";
import {
  hideActionsSuccess,
  isLoadingSuccess,
  showErrorMessageSuccess,
  showActivateClientActionsSuccess,
  showDeactivateClientActionsSuccess,
  showAddClientActionsSuccess,
  showEditClientActionsSuccess,
} from "../../redux-toolkit/reducers/actionsSlice";

import { ACTIONS_ERROR_MESSAGE } from "../../constants/globalText";
import { ActivateClient, AddNewClient, DeactivateClient, EditClientDetails } from "./components/modal";
import styles from "./components/modal/clients-modal.module.scss";
import { WebSocketContext } from "../../components/websocket/websocket";
import { CSSTransition } from "react-transition-group";
import { getClients, updateClientRecord } from "../../services/clientsServices";
import { useLocation, useNavigate } from "react-router-dom";
import { clients_tools_page_navigate } from "../../constants/routePath";
import format from "date-fns/format";

export const AmbieClients = () => {
  const dispatch = useDispatch();
  const socket = useContext(WebSocketContext);

  const [clientFilter, setFilter] = useState<{ clientFilterName: string; clientFilterId: number }>({
    clientFilterName: "",
    clientFilterId: 0,
  });

  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const clientsSlice = useSelector((state: RootState) => state.authSlice.clients);
  const [clientsFilter, setClientsFilter] = useState("all");

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const pathWithoutSlash = path.replace(/^\//, "");

  useEffect(() => {
    socket.on("updateClient", () => {
      getClientsData();
    });

    socket.on("newClient", () => {
      getClientsData();
    });

    const getClientsData = async () => {
      await getClients();
    };

    getClientsData();

    if (pathWithoutSlash === "tools") {
      navigate(clients_tools_page_navigate);
    }

    return () => {
      socket.off("updateClient", () => {
        getClientsData();
      });
      socket.off("newClient", () => {
        getClientsData();
      });
    };
  }, [searchClient, pathWithoutSlash, navigate]);

  /**
   * Deactivate client
   */
  const deactivateClientData = async () => {
    dispatch(isLoadingSuccess(true));
    await updateClientRecord(actions.clientDetails.id, { is_active: false })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  /**
   * Activate client
   */
  const activateClientData = async () => {
    dispatch(isLoadingSuccess(true));
    await updateClientRecord(actions.clientDetails.id, { is_active: true })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  let clientsTableRows: any = [];

  const filterClients = [
    {
      label: "All Clients",
      value: "all",
    },
    {
      label: "Active Clients",
      value: "active",
    },
    {
      label: "Inactive Clients",
      value: "inactive",
    },
    {
      label: "SYB Clients",
      value: "has",
    },
  ];

  const usersFilter = clientsSlice
    .filter((filter: any) => filter.name)
    .map((data: any) => {
      return { name: data.name, id: data.id };
    });

  clientsSlice
    .filter((filter: any) => {
      let filterPass = true;
      let areasStatusFilterPass: any = true;
      let usernameEmailFilterPass = true;

      if (clientFilter.clientFilterName && clientFilter.clientFilterId) {
        filterPass = filter.name === clientFilter.clientFilterName;
      }

      if (clientsFilter === "all") {
        areasStatusFilterPass = true;
      } else if (clientsFilter === "active") {
        areasStatusFilterPass = filter.is_active;
      } else if (clientsFilter === "inactive") {
        areasStatusFilterPass = !filter.is_active;
      } else if (clientsFilter === "has") {
        areasStatusFilterPass = filter.syb_id;
      }

      return filterPass && areasStatusFilterPass && usernameEmailFilterPass;
    })
    .sort(
      (
        a: { account_owner_id: number; curator_owner_id: number; name: string },
        b: { account_owner_id: number; curator_owner_id: number; name: string }
      ) => {
        if (a.account_owner_id && a.curator_owner_id && !b.account_owner_id && !b.curator_owner_id) return 1;
        if (!a.account_owner_id && !a.curator_owner_id && b.account_owner_id && b.curator_owner_id) return -1;

        return a.name.localeCompare(b.name);
      }
    )
    .map((data: any) => {
      const rowData = {
        name: data.name,
        id: data.id,
        status: data.is_active,
        created: data.created,
        customerOwner: (data.account_owner && data.account_owner.name) || "",
        curationOwner: (data.curator_owner && data.curator_owner.name) || "",
        sybId: data.syb_id,
        actions: data,
      };
      return clientsTableRows.push(rowData);
    });

  const clientsColumns = [
    {
      field: "name",
      headerName: "Name",
      cellRenderer: (params: any) => {
        return <>{params.data.name}</>;
      },
    },
    {
      field: "customerOwner",
      headerName: "Customer Owner",
      cellRenderer: (params: any) => {
        return <>{params.data.customerOwner}</>;
      },
    },
    {
      field: "curationOwner",
      headerName: "Curation Owner",
      cellRenderer: (params: any) => {
        return <>{params.data.curationOwner}</>;
      },
    },

    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params: any) => {
        return (
          <>
            {params.data.status ? (
              <>
                <BsFillCheckCircleFill className="text-success" />
                <span style={{ marginLeft: 5 }}> Active</span>
              </>
            ) : (
              <>
                <BsFillXCircleFill className="text-danger" /> <span style={{ marginLeft: 5 }}> Inactive</span>
              </>
            )}
          </>
        );
      },
    },
    {
      field: "created",
      headerName: "Created",
      cellRenderer: (params: any) => {
        return <>{format(new Date(params.data.created), "dd/MM/yyyy H:mm")}</>;
      },
    },
    {
      field: "sybId",
      headerName: "SYB ID",
      cellRenderer: (params: any) => {
        return <>{params.data.sybId}</>;
      },
    },
    {
      field: "actions",
      headerName: "Action",
      width: 180,
      maxWidth: 180,
      cellClass: "actions-dropdown-cell",
      filter: false,
      sortingOrder: false,
      cellRenderer: (params: any) => {
        return (
          <GlobalDropdown name="Actions" format="white" size="xs" align="end">
            <div className="dropdown-item">
              <GlobalButton
                size="sm"
                format="none"
                onClick={() =>
                  dispatch(
                    showEditClientActionsSuccess({
                      editClient: true,
                      clientDetails: { status: true, id: params.data.id, name: params.data.name },
                    })
                  )
                }
              >
                <BsPencil className="dropdown-item-icon" /> Edit Details
              </GlobalButton>
            </div>

            <div className="dropdown-divider"></div>
            <div className="dropdown-item text-danger">
              {params.data.status ? (
                <GlobalButton
                  size="sm"
                  format="none"
                  className="text-danger"
                  onClick={() =>
                    dispatch(
                      showDeactivateClientActionsSuccess({
                        deactivateClient: true,
                        clientDetails: { id: params.data.id, name: params.data.name },
                      })
                    )
                  }
                >
                  <BsXOctagon className="dropdown-item-icon text-danger" /> Deactivate Client
                </GlobalButton>
              ) : (
                <GlobalButton
                  size="sm"
                  format="none"
                  className="text-danger"
                  onClick={() =>
                    dispatch(
                      showActivateClientActionsSuccess({
                        activateClient: true,
                        clientDetails: { id: params.data.id, name: params.data.name },
                      })
                    )
                  }
                >
                  <BsXOctagon className="dropdown-item-icon text-danger" /> Activate Client
                </GlobalButton>
              )}
            </div>
          </GlobalDropdown>
        );
      },
    },
  ];

  let channelTableRows: any = [];
  const channelRowData = {
    channel: "Silver Lyan 5-7",
    tracks: 66,
    percentage: 50,
    actions: "",
  };
  channelTableRows.push(channelRowData);

  const activeUsers = clientsSlice.length && clientsSlice.filter((client: any) => client.is_active).length;
  const inactiveUsers = clientsSlice.length && clientsSlice.filter((client: any) => !client.is_active).length;
  const sybClients = clientsSlice.length && clientsSlice.filter((client: any) => client.syb_id).length;

  const usersCount: any = [
    {
      message: "Number based on total clients.",
      name: "Total clients",
      info: true,
      value: clientsSlice.length || 0,
    },
    {
      message: "Number based on total active clients.",
      name: "Active clients",
      info: true,
      value: activeUsers || 0,
    },
    {
      message: "Number based on total inactive clients.",
      name: "Inactive clients",
      info: true,
      value: inactiveUsers || 0,
    },
    {
      message: "Number based on total of clients with SYB id.",
      name: "Clients with SYB id",
      info: true,
      value: sybClients || 0,
    },
  ];

  const initialValues = {};
  return (
    <div className="content container py-3">
      <>
        {/* Client action */}
        <CSSTransition
          in={actions.editClient}
          timeout={100}
          classNames="panel-animate"
          onEnter={() => document.body.classList.add("css-transition-modal-open")}
          onExited={() => document.body.classList.remove("css-transition-modal-open")}
          unmountOnExit={true}
          mountOnEnter={true}
        >
          <EditClientDetails show={actions.editClient} />
        </CSSTransition>

        <CSSTransition
          in={actions.addClient}
          timeout={100}
          classNames="panel-animate"
          onEnter={() => document.body.classList.add("css-transition-modal-open")}
          onExited={() => document.body.classList.remove("css-transition-modal-open")}
          unmountOnExit={true}
          mountOnEnter={true}
        >
          <AddNewClient show={actions.addClient} />
        </CSSTransition>

        {actions.deactivateClient && (
          <DeactivateClient
            show={actions.deactivateClient}
            client={actions.clientDetails}
            handleClose={() => dispatch(hideActionsSuccess())}
            footer={
              <>
                <div className={styles.flexRowWrapModalFooter}>
                  <div className={styles.footerLeft}>
                    <GlobalButton
                      format="white"
                      disabled={actions.actionsIsLoading ? true : false}
                      size="sm"
                      onClick={() => dispatch(hideActionsSuccess())}
                    >
                      No
                    </GlobalButton>
                  </div>
                  <div>
                    <GlobalButton
                      format="success"
                      disabled={actions.actionsIsLoading ? true : false}
                      onClick={() => deactivateClientData()}
                      size="sm"
                    >
                      Yes
                    </GlobalButton>
                  </div>
                </div>
              </>
            }
          />
        )}

        {actions.activateClient && (
          <ActivateClient
            show={actions.activateClient}
            client={actions.clientDetails}
            handleClose={() => dispatch(hideActionsSuccess())}
            footer={
              <>
                <div className={styles.flexRowWrapModalFooter}>
                  <div className={styles.footerLeft}>
                    <GlobalButton
                      format="white"
                      disabled={actions.actionsIsLoading ? true : false}
                      size="sm"
                      onClick={() => dispatch(hideActionsSuccess())}
                    >
                      No
                    </GlobalButton>
                  </div>
                  <div>
                    <GlobalButton
                      format="success"
                      disabled={actions.actionsIsLoading ? true : false}
                      onClick={() => activateClientData()}
                      size="sm"
                    >
                      Yes
                    </GlobalButton>
                  </div>
                </div>
              </>
            }
          />
        )}
      </>

      <div>
        <div className="row align-items-center">
          <div className="col-sm-6" style={{ zoom: 0.95 }}>
            <MiniCard options={usersCount} columns="col-lg-3" />
          </div>
          <div className="col-sm-auto ms-auto">
            <GlobalButton
              onClick={() => {
                dispatch(
                  showAddClientActionsSuccess({
                    addClient: true,
                  })
                );
              }}
            >
              Add client
            </GlobalButton>
          </div>
        </div>
        {actions.actionsIsLoading && <LoadingData />}
        <div style={{ marginTop: "1rem" }}>
          <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
            <Form>
              <KeyValueCard
                title={
                  <div style={{ width: "60%", marginRight: "10px" }}>
                    <SearchWithOptions
                      id="searchClient"
                      placeholder="Search client..."
                      options={usersFilter}
                      labelKey="name"
                      leftIcon={<BsSearch />}
                      clearButton
                      onChange={(selected: any) => {
                        setFilter({
                          clientFilterId: (selected && selected[0] && selected[0].id) || 0,
                          clientFilterName: (selected && selected[0] && selected[0].name) || "",
                        });
                      }}
                    />
                  </div>
                }
                filter={
                  <div className="d-flex align-items-center">
                    <div style={{ width: "200px", marginRight: "10px" }}>
                      <Select
                        id="players"
                        className="flex-grow-4 time-select channel-list"
                        options={filterClients}
                        value={filterClients.find((c: any) => c.value === clientsFilter)}
                        onChange={(selected) => selected && selected.value && setClientsFilter(selected.value)}
                      />
                    </div>
                  </div>
                }
              >
                <div className="row">
                  <div className="col-sm-12">
                    <AgGridReactTable
                      className="ag-theme-alpine"
                      rowData={clientsTableRows}
                      columnDefs={clientsColumns}
                      filter={true}
                      sortable={true}
                      animateRows={true}
                      pagination={true}
                      paginationPageSize={25}
                      domLayout="autoHeight"
                    />
                  </div>
                </div>
              </KeyValueCard>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
