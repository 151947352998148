import axiosInterceptors from "../lib/axiosInterceptors";
import { storeClient, storeUser } from "../utils/localStorage";
import { getEnvironment } from "../config/config";
import { store } from "../redux-toolkit/store";
import { searchClientSuccess } from "../redux-toolkit/reducers/searchClientSlice";
import Cookies from "js-cookie";
import { LOCAL_STORAGE_USER_REFRESH_TOKEN, LOCAL_STORAGE_USER_TOKEN } from "../constants/defaultValues";
import { clientsUsersSucess, ambieUsersSuccess, loginSuccess, usersSuccess } from "../redux-toolkit/reducers/authSlice";
import browserHistory from "../lib/history";
import { isLoadingSuccess } from "../redux-toolkit/reducers/actionsSlice";

/**
 * Login service
 */
export async function login(username: string, password: string) {
  const response = await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/auth/login`, {
    username,
    password,
    application: "admin-portal",
  });

  let userInfo: any = {};

  if (response.status === 200) {
    userInfo = {
      status: response.status,
      ...response.data,
    };
    const clientRecord = userInfo.user && userInfo.user.businesses_client;

    const clientInfo = {
      clientId: clientRecord.id,
      clientName: clientRecord.name,
      clentEmail: clientRecord.email || "",
      clentContactName: clientRecord.contact_name,
      isFrozen: clientRecord.is_frozen,
    };

    store.dispatch(searchClientSuccess(clientInfo));

    storeClient(clientInfo);
    storeUser(userInfo);
  }

  return { userInfo, token: response.data.accessToken, refreshToken: response.data.refreshToken };
}

/**
 * Get user profile
 */
export async function userProfile() {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/users/me`, {});
}

/**
 * Get all users
 */
export async function getUsers(clientId: number) {
  return await axiosInterceptors
    .get(`${getEnvironment()?.HOST_ROCK_API}/users/users-with-client/${clientId}`, {})
    .then((response) => {
      if (response.data) {
        store.dispatch(usersSuccess({ users: response.data }));
        store.dispatch(isLoadingSuccess(false));
      }
    })
    .catch((error) => {});
}

/**
 * Get all ambie users
 */
export async function getAmbieUsers() {
  return await axiosInterceptors
    .get(`${getEnvironment()?.HOST_ROCK_API}/users/ambie-users-with-client`, {})
    .then((response) => {
      if (response.data) {
        store.dispatch(ambieUsersSuccess({ ambieUsers: response.data }));
        store.dispatch(isLoadingSuccess(false));
      }
    })
    .catch((error) => {});
}

/**
 * Get clients users
 */
export async function getClientsUsers() {
  return await axiosInterceptors
    .get(`${getEnvironment()?.HOST_ROCK_API}/users/clients`, {})
    .then((response) => {
      if (response.data) {
        store.dispatch(clientsUsersSucess({ clientsUsers: response.data }));
        store.dispatch(isLoadingSuccess(false));
      }
    })
    .catch((error) => {});
}

/**
 * Get all client users
 */
export async function getClientUsers(clientId: number) {
  return await axiosInterceptors
    .get(`${getEnvironment()?.HOST_ROCK_API}/users/client-users/${clientId}`, {})
    .then((response) => {
      if (response.data) {
        store.dispatch(usersSuccess({ users: response.data }));
        store.dispatch(isLoadingSuccess(false));
      }
    })
    .catch((error) => {});
}

/**
 * Get user
 */
export async function getUser(userId: number) {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/users/user-with-client/${userId}`, {});
}

/**
 * Update user
 */
export async function updateUserRecord(userId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/users/${userId}`, data);
}

/**
 * Add new user
 */
export async function addNewUser(data: any) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/users`, data);
}

/**
 * Request password reset
 */
export async function requestPasswordReset(email: string, referrer: string) {
  const body = { email, referrer, isWeb: true };
  const responseData = await axiosInterceptors.post(
    `${getEnvironment().HOST_ROCK_API}/auth/reset-password-request`,
    body
  );

  if (responseData.data && responseData.data.accessToken) {
    return responseData;
  } else {
    throw Error("error");
  }
}

/**
 * Reset password
 */
export const resetPassword = async (token: string, password: string, passwordConfirm: string, email: string) => {
  try {
    const body = { accessToken: token, password, confirmPassword: passwordConfirm, email };
    await axiosInterceptors.post(`${getEnvironment().HOST_ROCK_API}/auth/reset-password`, body);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Validate password reset  token
 */
export const validatePasswordResetToken = async (token: string) => {
  try {
    const response = await axiosInterceptors.get(
      `${getEnvironment()?.HOST_ROCK_API}/auth/validate-reset-password/${token}`,
      {}
    );
    return response.data;
  } catch (error) {
    return false;
  }
};

/**
 * Logout user
 */
export async function logoutUser() {
  Cookies.remove(LOCAL_STORAGE_USER_TOKEN);
  Cookies.remove(LOCAL_STORAGE_USER_REFRESH_TOKEN);
  localStorage.clear();
  store.dispatch(loginSuccess({ user: {}, token: "" }));
  browserHistory.push("/");
  window.location.reload();
}

/**
 * Get user profile
 */
export async function getUserPermissions() {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/permission`, {});
}

export async function getUserPermissionsSearch(ambieUser: boolean) {
  if (ambieUser) return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/permission/internal/users`, {});
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/permission/clients`, {});
}

/**
 * Get user token from mongoDB
 */
export const getUserTokenFromMongo = async (userId: number, accessToken: string | undefined) => {
  return await axiosInterceptors
    .get(`${getEnvironment().HOST_ROCK_API}/users/user-token/${userId}/${accessToken}`, {})
    .then(() => {})
    .catch((error) => {
      logoutUser();
    });
};

/**
 * Switch user client
 */
export async function switchUserClient(userId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/users/swicth-user-client/${userId}`, data);
}
