import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import _ from "lodash";
import TimezoneHelper from "../../components/timezoneHelper";

// style components

const _generateTimezones = () => {
  return moment.tz
    .names()
    .map((tz) => {
      const zone: any = moment.tz.zone(tz);
      const offsetHoursAndMins = (zone.utcOffset(moment().utc()) / 60) * -1;
      const offsetHours = Math.floor(offsetHoursAndMins);
      const offsetMins = (Math.abs(offsetHoursAndMins) - Math.abs(offsetHours)) * 60;
      const offsetSymbol = offsetHours >= 0 ? "+" : "";
      const offsetDisplay = `GMT ${offsetSymbol + offsetHours}:${
        Math.abs(offsetMins) < 10 ? `0${Math.abs(offsetMins)}` : Math.abs(offsetMins)
      }`;
      return {
        name: tz,
        offsetHours,
        offsetMins: offsetHours > 0 ? offsetMins : -offsetMins,
        offsetHoursAndMins,
        offsetDisplay,
      };
    })
    .sort((a, b) => a.offsetHoursAndMins - b.offsetHoursAndMins);
};

const _getTimezoneByName = (timezoneName: any) => {
  return _generateTimezones().filter(
    (tz) =>
      tz.name.toLowerCase() === timezoneName.toLowerCase() ||
      tz.name.toLowerCase() === timezoneName.toLowerCase().replace(" ", "_")
  )[0];
};

export interface SchedulesState {
  isScheduleEditMode: boolean;
  isErrorSchedule: { status: boolean; messages: [] };
  isEditPlaylist: boolean;
  isExpandedView: boolean;
  showScheduleEditPopup: boolean;
  showScheduleSavePopup: boolean;
  showScheduleLocationsPopup: boolean;
  showScheduleDeleteConfirmationPopup: boolean;
  showScheduleOverlapsConfirmationPopup: boolean;
  showDiscardScheduleEditPopup: boolean;
  showAddNewSchedulePopup: boolean;
  showDeleteSchedulePopup: boolean;
  isScheduleEdited: boolean;
  isAddEditSchedValid: boolean;
  editScheduleObject: {
    day: number;
    days: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
    startValue: any;
    endValue: any;
    channel: any;
  };
  schedules: [
    {
      id: number;
      name: string;
    }
  ];
  selectedSchedule: {
    clientId: number;
    name: string;
    schedule: [[], [], [], [], [], [], []];
    cleanSchedule: [[], [], [], [], [], [], []];
    timezoneAdjustedSchedule: [[], [], [], [], [], [], []];
    _id: string;
  };
  currentChannelsForClient: any;
  days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  timezone: any;
  timezoneName: string;
  timezoneOffset: {
    hours: number;
    minutes: number;
  };
  timezones: any;
  defaultTimezoneName: string;
  defaultTimezone: any;
  defaultTimezoneOffsetDisplay: any;
  showTimezoneModal: false;
  times: any;
  selectedScheduleId: string;
}
const initialState: SchedulesState = {
  isErrorSchedule: { status: false, messages: [] },
  isScheduleEditMode: false,
  isEditPlaylist: false,
  isExpandedView: false,
  showScheduleEditPopup: false,
  showScheduleSavePopup: false,
  showScheduleLocationsPopup: false,
  showScheduleDeleteConfirmationPopup: false,
  showScheduleOverlapsConfirmationPopup: false,
  showDiscardScheduleEditPopup: false,
  showAddNewSchedulePopup: false,
  showDeleteSchedulePopup: false,
  isScheduleEdited: false,
  isAddEditSchedValid: true,
  editScheduleObject: {
    day: 0,
    days: [true, false, false, false, false, false, false],
    startValue: 0,
    endValue: 1,
    channel: {},
  },
  schedules: [
    {
      id: 0,
      name: "",
    },
  ],
  selectedSchedule: {
    clientId: 0,
    name: "",
    schedule: [[], [], [], [], [], [], []],
    cleanSchedule: [[], [], [], [], [], [], []],
    timezoneAdjustedSchedule: [[], [], [], [], [], [], []],
    _id: "",
  },
  currentChannelsForClient: [],
  days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
  timezone: null,
  timezoneName: "Europe/London",
  timezoneOffset: {
    hours: 0,
    minutes: 0,
  },
  timezones: _generateTimezones(),
  defaultTimezoneName: "Europe/London",
  defaultTimezone: _getTimezoneByName("Europe/London"),
  defaultTimezoneOffsetDisplay: _getTimezoneByName("Europe/London").offsetDisplay,
  showTimezoneModal: false,
  times: JSON.stringify(TimezoneHelper.generateTimes("Europe/London")),
  selectedScheduleId: "",
};

const SchedulesSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {
    // used
    changeSelectedScheduleSuccess: (state, action: PayloadAction<any>) => {
      let selectedSchedule = action.payload;
      selectedSchedule.timezoneAdjustedSchedule = action.payload.timezoneAdjustedSchedule ||
        state.selectedSchedule.timezoneAdjustedSchedule || [[], [], [], [], [], [], []];
      return _.cloneDeep({ ...state, selectedSchedule });
    },
    changeSchedulesSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({ ...state, schedules: action.payload });
    },
    changeScheduleEditModeSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({
        ...state,
        isScheduleEditMode: action.payload,
        isExpandedView: action.payload,
      });
    },
    isErrorScheduleSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({ ...state, isErrorSchedule: action.payload });
    },
    changeShowSchedulePopupSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({ ...state, showScheduleEditPopup: action.payload });
    },
    changeEditScheduleObjSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({ ...state, editScheduleObject: action.payload });
    },
    changeShowScheduleSavePopupSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({ ...state, showScheduleSavePopup: action.payload });
    },
    changeShowScheduleLocationsPopupSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({
        ...state,
        showScheduleLocationsPopup: action.payload,
      });
    },
    changeShowScheduleDeleteConfirmationPopupSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({
        ...state,
        showScheduleDeleteConfirmationPopup: action.payload,
      });
    },
    changeScheduleDiscardScheduleEditModePopupSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({
        ...state,
        showDiscardScheduleEditPopup: action.payload,
      });
    },
    changeIsScheduleEditedSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({ ...state, isScheduleEdited: action.payload });
    },
    changeShowScheduleOverlapsConfirmationPopupSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({
        ...state,
        showScheduleOverlapsConfirmationPopup: action.payload,
      });
    },
    changeIsEditPlaylistSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({ ...state, isEditPlaylist: action.payload });
    },
    changeIsAddEditSchedValidSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({ ...state, isAddEditSchedValid: action.payload });
    },
    changeChannelsForClientSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({
        ...state,
        currentChannelsForClient: action.payload,
      });
    },
    changeShowAddNewSchedulesPopupSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({ ...state, showAddNewSchedulePopup: action.payload });
    },
    changeShowDeleteSchedulePopupSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({ ...state, showDeleteSchedulePopup: action.payload });
    },
    // used
    showTimezoneModalSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({ ...state, showTimezoneModal: action.payload });
    },

    // TIMEZONES
    changeTimezonesSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({
        ...state,
        timezones: action.payload,
      });
    },
    changeTimezoneSuccess: (state, action: PayloadAction<any>) => {
      let payload = _.cloneDeep(action.payload);
      if (action.payload.timezoneName) {
        const tz = _getTimezoneByName(action.payload.timezoneName);
        if (tz) {
          payload.timezone = tz.offsetDisplay;
          payload.timezoneName = tz.name;
          payload.timezoneOffset = {
            hours: tz.offsetHours,
            minutes: tz.offsetMins,
          };
        }
      }
      return _.cloneDeep({
        ...state,
        timezone: payload.timezone || state.timezone,
        timezones: payload.timezones || state.timezones,
        timezoneName: payload.timezoneName || state.timezoneName,
        timezoneOffset: payload.timezoneOffset || state.timezoneOffset,
      });
    },
    changeTimezoneOffsetSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({
        ...state,
        timezoneOffset: action.payload,
      });
    },
    changeTimesSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({
        ...state,
        times: action.payload,
      });
    },
    changeExpandedViewSuccess: (state, action: PayloadAction<any>) => {
      return _.cloneDeep({
        ...state,
        isExpandedView: action.payload,
      });
    },

    selectedScheduleIdSuccess: (state, action: PayloadAction<any>) => {
      state.selectedScheduleId = action.payload;
    },
  },
});

export const {
  isErrorScheduleSuccess,
  changeSelectedScheduleSuccess,
  changeSchedulesSuccess,
  changeScheduleEditModeSuccess,
  changeShowSchedulePopupSuccess,
  changeEditScheduleObjSuccess,
  changeShowScheduleSavePopupSuccess,
  changeShowScheduleLocationsPopupSuccess,
  changeShowScheduleDeleteConfirmationPopupSuccess,
  changeScheduleDiscardScheduleEditModePopupSuccess,
  changeIsScheduleEditedSuccess,
  changeShowScheduleOverlapsConfirmationPopupSuccess,
  changeIsEditPlaylistSuccess,
  changeIsAddEditSchedValidSuccess,
  changeChannelsForClientSuccess,
  changeShowAddNewSchedulesPopupSuccess,
  changeShowDeleteSchedulePopupSuccess,

  // TIMEZONES
  changeTimezonesSuccess,
  changeTimezoneSuccess,
  changeTimesSuccess,
  changeTimezoneOffsetSuccess,
  changeExpandedViewSuccess,
  showTimezoneModalSuccess,
  selectedScheduleIdSuccess,
} = SchedulesSlice.actions;
export default SchedulesSlice.reducer;
