import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// style components
import { SearchWithOptions } from "../../../components/fields/search";
import { searchClientSuccess } from "../../../redux-toolkit/reducers/searchClientSlice";
import { RootState } from "../../../redux-toolkit/store";
import { BsSearch } from "react-icons/bs";
import { getActiveClients } from "../../../services/clientsServices";
import { WebSocketContext } from "../../../components/websocket/websocket";

export const SearchClientBar = () => {
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const [clearSelected, setClearSelected] = useState<boolean>(true);

  const [clients, setClients] = useState<any>([]);
  const dispatch = useDispatch();
  const socket = useContext(WebSocketContext);

  useEffect(() => {
    let isSubscribed = true;

    const getClientsListData = async () => {
      await getActiveClients()
        .then((response) => {
          if (isSubscribed) {
            setClients(response.data);
          }
        })
        .catch((error) => {});
    };

    socket.on("createClient", () => {
      getClientsListData();
    });

    socket.on("updateClient", (payload: { id: number }) => {
      if (searchClient.clientId === payload.id) getClientsListData();
    });

    getClientsListData();

    return () => {
      isSubscribed = false;
      socket.off("createClient", () => {
        getClientsListData();
      });

      socket.off("updateClient", (payload: { id: number }) => {
        if (searchClient.clientId === payload.id) getClientsListData();
      });
    };
  }, [searchClient]);

  return (
    <>
      <Formik initialValues={{}} onSubmit={() => {}}>
        <SearchWithOptions
          id="searchClient"
          placeholder="Search clients..."
          selected={(clearSelected && clients.filter((c: any) => c.id === searchClient.clientId)) || null}
          options={clients}
          maxResults={50}
          labelKey="name"
          leftIcon={<BsSearch />}
          clearButton
          onFocus={() => setClearSelected(false)}
          onChange={(selected: any) => {
            if (selected.length) {
              dispatch(
                searchClientSuccess({
                  clientId: selected[0].id,
                  clientName: selected[0].name,
                  clentEmail: selected[0].contact_email,
                  clentContactName: selected[0].contact_name,
                  isFrozen: selected[0].is_frozen,
                })
              );
            }
          }}
        />
      </Formik>
    </>
  );
};
