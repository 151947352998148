import React from "react";
import { Slider } from "antd";
import { Badge } from "react-bootstrap";
// styles
import classnames from "classnames";
import { GlobalButton } from "../../../../components/button";
import styles from "./miniplayer.module.scss";
import modalStyles from "./../../locations.module.scss";

// icons
import { BsPlayFill, BsPauseFill, BsFlag, BsSkipForward, BsArrowLeftRight } from "react-icons/bs";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import {
  SwitchPlaylistModal,
  FeedbackAction,
  SendFeedback,
  NegativeFeedbackAction,
  SkipTrack,
  DeviceStatus,
} from "./SwitchPlaylistModal";
import {
  hideActionsSuccess,
  isLoadingSuccess,
  showDeviceStatusSuccess,
  showErrorMessageSuccess,
  showFeedbackActionSuccess,
  showSkipTrackSuccess,
  showSwitchPlaylistSuccess,
} from "../../../../redux-toolkit/reducers/actionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux-toolkit/store";
import { DEFAULT_REFRESH_INTERVAL_TIME, MAX_SKIPS } from "../../../../constants/defaultValues";
import { getSkipTrack, getSwitchPlaylist, storeSkipTrack, storeSwitchPlaylist } from "../../../../utils/localStorage";
import { differenceInMilliseconds } from "date-fns";
import { updatePlayerSkip, updatePlayerStatus } from "../../../../services/locationsServices";
import { ACTIONS_ERROR_MESSAGE } from "../../../../constants/globalText";

interface MiniPlayerProps {
  area: any;
}

export const Miniplayer = ({ area }: MiniPlayerProps) => {
  const currentTrackInfo = area && area.device && area.device.current_track;
  const deviceStatus = area && area.device;
  const actions = useSelector((state: RootState) => state.actionsSlice);

  const dispatch = useDispatch();

  const tracks = getSkipTrack();
  const switchPlaylist = getSwitchPlaylist();

  // Reset the number of skiped value to 0 after 1 hour
  const interval = setInterval(() => {
    tracks
      .filter((track: any) => differenceInMilliseconds(new Date().getTime(), track.date) / 60000 > 60)
      .forEach((track: any) => {
        tracks.splice(tracks.indexOf(track), 1);
        storeSkipTrack(tracks);
      });

    clearInterval(interval);
  }, DEFAULT_REFRESH_INTERVAL_TIME);

  // Reset the number of switch playlist
  const switchPlaylistInterval = setInterval(() => {
    switchPlaylist
      .filter((playlist: any) => differenceInMilliseconds(new Date().getTime(), playlist.duration) / 60000 > 0)
      .forEach((playlist: any) => {
        switchPlaylist.splice(switchPlaylist.indexOf(playlist), 1);
        storeSwitchPlaylist(switchPlaylist);
      });

    clearInterval(switchPlaylistInterval);
  }, DEFAULT_REFRESH_INTERVAL_TIME);

  const updatePlayerSkipData = async (area: any) => {
    dispatch(isLoadingSuccess(true));
    await updatePlayerSkip(area.device.serial)
      .then((response) => {
        const findAreaIndex = tracks.findIndex((track: any) => track.areaId === area.area_id);
        if (findAreaIndex >= 0) {
          tracks[findAreaIndex].skip++;
          storeSkipTrack(tracks);
        } else {
          let track = {
            areaId: area.area_id,
            skip: 1,
            date: Date.now(),
            timeout: 9,
          };
          tracks.push(track);
          storeSkipTrack(tracks);
        }
        getSkipNumber(area);
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  const deviceHasFields = area?.device;

  const updatePlayerStatusData = async (status: string, volume: number) => {
    // return;
    dispatch(isLoadingSuccess(true));

    const newStatus = {
      status,
      volume,
    };

    updatePlayerStatus(deviceHasFields?.name, newStatus)
      .then(() => {
        dispatch(hideActionsSuccess());
        dispatch(isLoadingSuccess(false));
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  const getSkipNumber = (area: any) => {
    let skip: number = 0;
    const getSkip = tracks.find((data: any) => data.areaId === area.area_id);
    if (getSkip) {
      skip = getSkip.skip;
    }
    return skip;
  };

  const getSwitchPlaylistStatus = (area: any) => {
    let switchStatus = false;
    const scheduleOverrideEndtime = area?.schedule_override?.end_time || Date.now();

    const timeDifferent = differenceInMilliseconds(Date.now(), scheduleOverrideEndtime) / 60000 < 0;

    if (timeDifferent) {
      switchStatus = true;
    }
    return switchStatus;
  };

  function playIcon() {
    if (deviceHasFields && deviceHasFields.status === "play") {
      return <BsPauseFill size={34} />;
    } else if (deviceHasFields && deviceHasFields.status === "pause") {
      return <BsPlayFill size={34} />;
    }
  }

  return (
    <div className={styles.miniplayer}>
      <>
        {actions.switchPlaylist && (
          <SwitchPlaylistModal
            show={actions.switchPlaylist}
            handleClose={() => dispatch(hideActionsSuccess())}
            area={area}
            footer={
              <>
                <div className={modalStyles.flexRowWrapModalFooter}>
                  <div className={modalStyles.footerLeft}>
                    <GlobalButton
                      format="white"
                      size="sm"
                      disabled={actions.actionsIsLoading ? true : false}
                      onClick={() => dispatch(hideActionsSuccess())}
                    >
                      Cancel
                    </GlobalButton>
                  </div>
                  <div>
                    <GlobalButton
                      disabled={actions.actionsIsLoading ? true : false}
                      format="success"
                      type="submit"
                      size="sm"
                    >
                      Submit
                    </GlobalButton>
                  </div>
                </div>
              </>
            }
          />
        )}

        {actions.feedbackAction && (
          <FeedbackAction show={actions.feedbackAction} handleClose={() => dispatch(hideActionsSuccess())} />
        )}

        {actions.sendFeedback && (
          <SendFeedback
            show={actions.sendFeedback}
            playlistSelectedTrack={actions.playlistSelectedTrack}
            handleClose={() => dispatch(hideActionsSuccess())}
            footer={
              <>
                <div className={modalStyles.flexRowWrapModalFooter}>
                  <div className={modalStyles.footerLeft}>
                    <GlobalButton
                      format="white"
                      size="sm"
                      disabled={actions.actionsIsLoading ? true : false}
                      onClick={() => dispatch(hideActionsSuccess())}
                    >
                      Cancel
                    </GlobalButton>
                  </div>
                  <div>
                    <GlobalButton
                      disabled={actions.actionsIsLoading ? true : false}
                      format="success"
                      type="submit"
                      size="sm"
                    >
                      Submit
                    </GlobalButton>
                  </div>
                </div>
              </>
            }
          />
        )}

        {actions.negativeFeedbackAction && (
          <NegativeFeedbackAction
            show={actions.negativeFeedbackAction}
            playlistSelectedTrack={actions.playlistSelectedTrack}
            handleClose={() => dispatch(hideActionsSuccess())}
          />
        )}

        {actions.skipTrack && (
          <SkipTrack
            show={actions.skipTrack}
            handleClose={() => dispatch(hideActionsSuccess())}
            footer={
              <>
                <div className={modalStyles.flexRowWrapModalFooter}>
                  <div className={modalStyles.footerLeft}>
                    <GlobalButton
                      format="white"
                      size="sm"
                      disabled={actions.actionsIsLoading ? true : false}
                      onClick={() => dispatch(hideActionsSuccess())}
                    >
                      No
                    </GlobalButton>
                  </div>
                  <div>
                    <GlobalButton
                      disabled={actions.actionsIsLoading ? true : false}
                      format="success"
                      size="sm"
                      onClick={() => updatePlayerSkipData(actions.area)}
                    >
                      Yes
                    </GlobalButton>
                  </div>
                </div>
              </>
            }
          />
        )}

        {actions.deviceStatusShow && (
          <DeviceStatus
            show={actions.deviceStatusShow}
            handleClose={() => dispatch(hideActionsSuccess())}
            footer={
              <>
                <div className={modalStyles.flexRowWrapModalFooter}>
                  <div className={modalStyles.footerLeft}>
                    <GlobalButton
                      format="white"
                      size="sm"
                      disabled={actions.actionsIsLoading ? true : false}
                      onClick={() => dispatch(hideActionsSuccess())}
                    >
                      No
                    </GlobalButton>
                  </div>
                  <div>
                    <GlobalButton
                      disabled={actions.actionsIsLoading ? true : false}
                      format="success"
                      size="sm"
                      onClick={() =>
                        updatePlayerStatusData(
                          deviceHasFields && deviceHasFields.status === "play" ? "pause" : "play",
                          deviceHasFields && deviceHasFields.volume
                        )
                      }
                    >
                      Yes
                    </GlobalButton>
                  </div>
                </div>
              </>
            }
          />
        )}
      </>

      <div className={classnames("col-md-5", styles.trackDetails)}>
        {/* Play / Pause */}
        <button
          className={styles.play}
          type="button"
          onClick={() => {
            dispatch(
              showDeviceStatusSuccess({
                deviceStatusShow: true,
                deviceStatus: deviceHasFields && deviceHasFields.status === "play" ? "pause" : "play",
              })
            );
          }}
        >
          {playIcon()}
        </button>

        {/* Track Information */}
        <div className={styles.track}>
          <strong>{(currentTrackInfo && currentTrackInfo.title) || ""}</strong>
          <br />
          {(currentTrackInfo && currentTrackInfo.artist) || ""}
          <br />
          {`${currentTrackInfo && currentTrackInfo.playlist}`}
        </div>
      </div>

      <div className={classnames("col-md-7 d-flex justify-content-end", styles.actions)}>
        {/* Volume and Volume Percentage */}
        <div className={styles.volume}>
          {deviceStatus.volume ? (
            <FaVolumeUp size={24} className="text-white me-2" />
          ) : (
            <FaVolumeMute size={24} className="text-white me-2" />
          )}

          <Slider
            min={0}
            max={100}
            defaultValue={deviceStatus.volume}
            className={classnames(styles.slider)}
            onAfterChange={(value) => {
              updatePlayerStatusData(deviceStatus.status, value);
            }}
          />
          <span className="badge bg-white text-dark ms-2">{`${deviceStatus.volume}%`}</span>
        </div>

        {/* Feedback */}
        <GlobalButton
          format="secondary"
          size="sm"
          className="me-3 ms-4"
          leftIcon={<BsFlag size={18} className="me-2" />}
          onClick={() => {
            dispatch(
              showFeedbackActionSuccess({
                feedbackAction: true,
                playlistTrack: area,
              })
            );
          }}
        >
          Feedback
        </GlobalButton>

        {/* Skips */}
        <GlobalButton
          format="secondary"
          size="sm"
          // className="me-3"
          className="me-3 ms-4"
          leftIcon={<BsSkipForward size={20} className="me-2" />}
          disabled={getSkipNumber(area) > 2}
          onClick={() => {
            dispatch(showSkipTrackSuccess({ skipTrack: true, area: area }));
          }}
        >
          {getSkipNumber(area)} / {MAX_SKIPS} Skips
        </GlobalButton>

        {/* Switch Playlist */}
        <GlobalButton
          format="secondary"
          size="sm"
          className="me-1"
          leftIcon={<BsArrowLeftRight size={16} className="me-2" />}
          onClick={() => {
            dispatch(
              showSwitchPlaylistSuccess({
                switchPlaylist: true,
                switchPlaylistStatus: getSwitchPlaylistStatus(area),
                area: {
                  name: area.name,
                  id: area.area_id,
                  tlgVersion: area.tlg_version,
                  serial: (area.device && area.device.serial) || "",
                },
              })
            );
          }}
        >
          Switch Playlist{" "}
          <span>
            {getSwitchPlaylistStatus(area) && (
              <Badge pill bg="primary">
                Active
              </Badge>
            )}
          </span>
        </GlobalButton>
      </div>
    </div>
  );
};
