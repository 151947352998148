import React, { useContext, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { BsDownload, BsPencil } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { CSSTransition } from "react-transition-group";
import Select from "react-select";

// styles components
import { DateRangePicker } from "../../components/fields/datePicker";
import { MiniCard, KeyValueCard } from "../../components/cards";
import { LoadingData } from "../../components/loading";
import { AgGridReactTable } from "../../components/table";
import { PieChart } from "./components/chart";
import { getFeedback } from "../../services/feedbackServices";
import { getClientLocationsWithAreas } from "../../services/locationsServices";
import { getTotalPlaylistsAndTracks } from "../../services/playlistsServices";
import { RootState } from "../../redux-toolkit/store";
import { GlobalButton } from "../../components/button";
import { BulletType } from "./components/bulletType";
import { dateFilterSuccess, showAdminApprovalActionsSuccess } from "../../redux-toolkit/reducers/clientHubSlice";
import { Toggle } from "../../components/fields/toggle";
import { ToggleAdminApproval } from "./components/modal/modal";
import locationsStyles from "../locations/locations.module.scss";
import { dashboard_page_navigate } from "../../constants/routePath";
import { ACTIONS_ERROR_MESSAGE } from "../../constants/globalText";
import { HandleExport } from "../../components/csv/export";
import { updateClientRecord, getClient } from "../../services/clientsServices";
import { WebSocketContext } from "../../components/websocket/websocket";
import { GlobalDropdown } from "../../components/dropdown";
import { showAddBriefActionsSuccess, showEditBriefActionsSuccess } from "../../redux-toolkit/reducers/actionsSlice";
import { getClientBriefs } from "../../services/briefsServices";
import { BriefCompletionStatus, BriefDueStatus, BriefStatus, BriefType } from "../briefs/components/status";
import { AddBrief, EditBrief } from "../briefs/components/modal";

export default function ClientHub() {
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const clientHubSlice = useSelector((state: RootState) => state.clientHubSlice);
  const playlistsSlice = useSelector((state: RootState) => state.playlistsSlice);
  const locationsSlice = useSelector((state: RootState) => state.locationsSlice);
  const actions = useSelector((state: RootState) => state.actionsSlice);

  const [briefStatus, setBriefStatus] = useState<string>("all");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const path = location.pathname;
  const pathWithoutSlash = path.replace(/^\//, "");
  const socket = useContext(WebSocketContext);
  const clientId = searchClient && searchClient.clientId;

  useEffect(() => {
    const getClientLocationsWithAreasData = async () => {
      await getClientLocationsWithAreas(searchClient);
    };

    const getAllPlaylistsAndTracksData = async () => {
      await getTotalPlaylistsAndTracks(clientId);
    };

    const getFeedbackData = async () => {
      await getFeedback(clientId);
    };

    const getClientFeedbackApprovalData = async () => {
      await getClient(clientId);
    };

    const getClientBriefsData = async () => {
      await getClientBriefs(clientId);
    };

    socket.on("newBrief", (payload: any) => {
      if (clientId === payload.client_id) getClientBriefsData();
    });

    socket.on("updateBrief", (payload: any) => {
      if (clientId === payload.client_id) getClientBriefsData();
    });

    socket.on("newClient", () => {
      getClientFeedbackApprovalData();
    });

    socket.on("updateClient", (payload: any) => {
      if (clientId === payload.id) getClientFeedbackApprovalData();
    });

    getClientFeedbackApprovalData();
    getFeedbackData();
    getClientLocationsWithAreasData();
    getAllPlaylistsAndTracksData();
    getClientBriefsData();

    if (pathWithoutSlash === "client") {
      navigate(dashboard_page_navigate);
    }

    return () => {
      socket.off("newClient", () => {
        getClientFeedbackApprovalData();
      });
      socket.off("updateClient", (payload: any) => {
        if (clientId === payload.id) getClientFeedbackApprovalData();
      });
      socket.off("newBrief", (payload: any) => {
        if (clientId === payload.client_id) getClientBriefsData();
      });
      socket.off("updateBrief", (payload: any) => {
        if (clientId === payload.client_id) getClientBriefsData();
      });
    };
  }, [searchClient, navigate, pathWithoutSlash, clientId, socket]);

  let feedbackTableRows: any = [];
  let briefTableRows: any = [];

  clientHubSlice.clientBriefs
    .filter((row: any) => {
      let filterPass = true;

      if (briefStatus === "all") {
        filterPass = true;
      } else if (briefStatus === "pending" && row.status === "pending") {
        filterPass = true;
      } else if (briefStatus === "progress" && row.status === "progress") {
        filterPass = true;
      } else if (briefStatus === "completed" && row.status === "completed") {
        filterPass = true;
      } else {
        filterPass = false;
      }

      return filterPass;
    })
    .sort((a: any, b: any) => {
      const dateA: any = new Date(a.created);
      const dateB: any = new Date(b.created);
      return dateB - dateA;
    })
    .map((data: any) => {
      const rowData = {
        briefId: data.id,
        status: data.status,
        title: data.title,
        type: data.type,
        createdAt: data.created && format(new Date(data.created), "dd/MM/yyy HH:mm"),
        submissionDate: data.created,
        dueDate: data.due_date,
        completionDate: data.completion_date,
        curationOwner: data.curation_owner && data.curation_owner.name,
        submittedBy: data.submitted_by_user && data.submitted_by_user.name,
      };

      briefTableRows.push(rowData);
      return rowData;
    });

  clientHubSlice.feedback
    .filter((row: any) => {
      let filterPass = true;
      if (clientHubSlice.dateFilter.startDate && clientHubSlice.dateFilter.endDate) {
        filterPass =
          format(new Date(row.date), "yyyy-MM-dd") > clientHubSlice.dateFilter.startDate &&
          format(new Date(row.date), "yyyy-MM-dd") < clientHubSlice.dateFilter.endDate;
      }

      return filterPass;
    })
    .sort((a: any, b: any) => b.date - a.date)
    .map((data: any) => {
      const report = data && data.report;
      const rowData = {
        type: report && report && report.type[0].toUpperCase() + report.type.substring(1),
        location: (report && report.location) || "-",
        area: (report && report.area) || "-",
        track: `${report && report.track} - ${report && report.artist}`,
        playlist: (report && report.playlist) || "-",
        message: (report && report.message) || "-",
        date: format(new Date(data.date), "dd/MM/yyyy H:mm"),
        actions: data,
      };
      feedbackTableRows.push(rowData);

      return rowData;
    });

  const columns = [
    {
      field: "type",
      headerName: "Type",
      wrapText: true,
      width: 130,
      maxWidth: 130,
      cellRenderer: (params: any) => {
        return (
          <div>
            <BulletType type={params.data.type} /> {params.data.type}
          </div>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      wrapText: true,
      width: 150,
      maxWidth: 150,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.location}</span>;
      },
    },
    {
      field: "area",
      headerName: "Area",
      wrapText: true,
      width: 150,
      maxWidth: 150,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.area}</span>;
      },
    },
    {
      field: "track",
      headerName: "Track & Artist",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.track}</span>;
      },
    },
    {
      field: "playlist",
      headerName: "Playlist",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.playlist}</span>;
      },
    },
    {
      field: "message",
      headerName: "Message",
      wrapText: true,
      autoHeight: true,
      tooltipField: "message",
      cellRenderer: (params: any) => {
        return <span>{params.data.message}</span>;
      },
    },
    {
      field: "date",
      headerName: "Date",
      type: "rightAligned",
      width: 150,
      maxWidth: 150,
    },
  ];
  const briefStatusList = (status: any, submissionDate: any, completionDate: any, dueDate: any) => {
    if (dueDate && status !== "completed") {
      return BriefDueStatus(dueDate);
    } else if (completionDate && status === "completed") {
      return BriefCompletionStatus(submissionDate, completionDate);
    } else {
      return "-";
    }
  };

  const briefColumns = [
    {
      field: "status",
      headerName: "Status",
      wrapText: true,
      width: 130,
      maxWidth: 130,
      cellRenderer: (params: any) => {
        return <span>{BriefStatus(params.data.status)}</span>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      wrapText: true,
      width: 150,
      maxWidth: 150,
      cellRenderer: (params: any) => {
        return <span className="text">{BriefType(params.data.type)}</span>;
      },
    },
    {
      field: "title",
      headerName: "Title",
      wrapText: true,
      width: 300,
      maxWidth: 300,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.title}</span>;
      },
    },
    {
      field: "createdAt",
      headerName: "Submission date",
      wrapText: true,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.createdAt}</span>;
      },
    },
    {
      field: "createdAt",
      headerName: "",
      wrapText: true,
      cellRenderer: (params: any) => {
        return (
          <span className="text">
            {briefStatusList(
              params.data.status,
              params.data.submissionDate,
              params.data.completionDate,
              params.data.dueDate
            )}
          </span>
        );
        // return (
        //   <span className="textk">
        //     <>
        //       <span>
        //         {params.data.createdAt}{" "}
        //         <>
        //           <span style={{ marginLeft: 15 }}>
        //             {briefStatusList(params.data.status, params.data.completionDate, params.data.dueDate)}
        //           </span>
        //         </>
        //       </span>
        //     </>
        //   </span>
        // );
      },
    },

    // {
    //   field: "owner",
    //   headerName: "Owner",
    //   wrapText: true,
    //   autoHeight: true,
    //   cellRenderer: (params: any) => {
    //     return <span className="text">{params.data.curationOwner}</span>;
    //   },
    // },

    {
      field: "submittedBy",
      headerName: "Submitted By",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return <span className="text">{params.data.submittedBy}</span>;
      },
    },

    {
      field: "actions",
      headerName: "Action",
      width: 180,
      maxWidth: 180,
      cellClass: "actions-dropdown-cell",
      filter: false,
      sortingOrder: false,
      cellRenderer: (params: any) => {
        return (
          <GlobalDropdown name="Actions" format="white" size="xs" align="end">
            <div className="dropdown-item">
              <GlobalButton
                size="sm"
                format="none"
                onClick={() =>
                  dispatch(
                    showEditBriefActionsSuccess({
                      editBrief: { status: true, id: params.data.briefId },
                    })
                  )
                }
              >
                <BsPencil className="dropdown-item-icon" /> Edit Brief
              </GlobalButton>
            </div>
          </GlobalDropdown>
        );
      },
    },
  ];

  /**
   * Update software
   */
  const handleToggleAdminApproval = async () => {
    dispatch(
      showAdminApprovalActionsSuccess({ adminApproval: true, adminApprovalIsLoading: true, adminApprovalIsError: "" })
    );
    const isApprovalNeeded =
      clientHubSlice.clientFeedbackApproval && clientHubSlice.clientFeedbackApproval.feedback_approval_required;
    await updateClientRecord(clientId, { feedback_approval_required: !isApprovalNeeded })
      .then((response) => {
        dispatch(
          showAdminApprovalActionsSuccess({
            adminApproval: false,
            adminApprovalIsLoading: false,
            adminApprovalIsError: "",
          })
        );
      })
      .catch((error) => {
        dispatch(
          showAdminApprovalActionsSuccess({
            adminApproval: true,
            adminApprovalIsLoading: false,
            adminApprovalIsError: ACTIONS_ERROR_MESSAGE,
          })
        );
      });
  };

  // export to csv
  const handleExportData = () => {
    const headings = [["Type", "Location", "Track & Artist", "Playlist", "Message", "Date"]];
    HandleExport(feedbackTableRows, headings, "client-feedback");
  };

  const filterLocations = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "In Progress",
      value: "progress",
    },
    {
      label: "Completed",
      value: "completed",
    },
  ];

  const tooltipMessage =
    "If this option is enabled, we will not make any changes to your music based on any feedback that has been left until you or a manager has approved it. Once enabled, you can approve or reject feedback by searching for a date range from the date picker above and then using the approve/reject buttons on the table below.";

  return (
    <div>
      <>
        {clientHubSlice.adminApproval && (
          <ToggleAdminApproval
            status={
              clientHubSlice.clientFeedbackApproval && clientHubSlice.clientFeedbackApproval.feedback_approval_required
                ? "disable"
                : "enable"
            }
            show={clientHubSlice.adminApproval}
            handleClose={() =>
              dispatch(
                showAdminApprovalActionsSuccess({
                  adminApproval: false,
                  adminApprovalIsLoading: false,
                  adminApprovalIsError: "",
                })
              )
            }
            footer={
              <>
                <div className={locationsStyles.flexRowWrapModalFooter}>
                  <div className={locationsStyles.footerLeft}>
                    <GlobalButton
                      format="white"
                      disabled={clientHubSlice.adminApprovalIsLoading ? true : false}
                      size="sm"
                      onClick={() =>
                        dispatch(
                          showAdminApprovalActionsSuccess({
                            adminApproval: false,
                            adminApprovalIsLoading: false,
                            adminApprovalIsError: "",
                          })
                        )
                      }
                    >
                      No
                    </GlobalButton>
                  </div>
                  <div>
                    <GlobalButton
                      format="success"
                      disabled={clientHubSlice.adminApprovalIsLoading ? true : false}
                      onClick={() => handleToggleAdminApproval()}
                      size="sm"
                    >
                      Yes
                    </GlobalButton>
                  </div>
                </div>
              </>
            }
          />
        )}

        <CSSTransition
          in={actions.addBrief}
          timeout={100}
          classNames="panel-animate"
          onEnter={() => document.body.classList.add("css-transition-modal-open")}
          onExited={() => document.body.classList.remove("css-transition-modal-open")}
          unmountOnExit={true}
          mountOnEnter={true}
        >
          <AddBrief show={actions.addBrief} />
        </CSSTransition>

        <CSSTransition
          in={actions.editBrief && actions.editBrief.status}
          timeout={100}
          classNames="panel-animate"
          onEnter={() => document.body.classList.add("css-transition-modal-open")}
          onExited={() => document.body.classList.remove("css-transition-modal-open")}
          unmountOnExit={true}
          mountOnEnter={true}
        >
          <EditBrief show={actions.editBrief && actions.editBrief.status} />
        </CSSTransition>
      </>
      {/* page content */}
      <div className="content container py-3">
        <div className="row mb-3">
          <div className="col-xl-5">
            <MiniCard options={playlistsSlice.playlistsTracksCount} columns="col-lg-6" />
          </div>
          <div className="col-lg-6 col-xl-4">
            <MiniCard options={locationsSlice.playerStatus} columns="col-lg-3" />
          </div>
          <div className="col-xl-3">
            <MiniCard options={clientHubSlice.clientOwners} columns="col-lg-6" />
          </div>
        </div>
        {actions.actionsIsLoading && <LoadingData />}

        <div style={{ marginBottom: 50 }}>
          <Formik
            initialValues={{
              feedback_approval_required:
                (clientHubSlice.clientFeedbackApproval &&
                  clientHubSlice.clientFeedbackApproval.feedback_approval_required) ||
                false,
            }}
            onSubmit={() => {}}
            enableReinitialize
          >
            {({ handleChange, handleBlur, setFieldValue, values, errors, touched }) => {
              return (
                <Form>
                  <KeyValueCard
                    title="Briefs"
                    vButton={
                      <div className="col-sm-auto ms-auto">
                        <GlobalButton
                          className="flex-grow-6 time-select channel-list"
                          size="sm"
                          onClick={() => {
                            dispatch(
                              showAddBriefActionsSuccess({
                                addBrief: true,
                              })
                            );
                          }}
                        >
                          Add Brief
                        </GlobalButton>
                      </div>
                    }
                    filter={
                      <div className="row">
                        <div style={{ width: "180px" }}>
                          <Select
                            id="brief-status"
                            className="flex-grow-6 time-select channel-list"
                            options={filterLocations}
                            value={filterLocations.find((c: any) => c.value === briefStatus)}
                            onChange={(selected) => selected && selected.value && setBriefStatus(selected.value)}
                          />
                        </div>
                      </div>
                    }
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <AgGridReactTable
                          className="ag-theme-alpine"
                          rowData={briefTableRows}
                          columnDefs={briefColumns}
                          filter={true}
                          sortable={true}
                          animateRows={true}
                          pagination={true}
                          paginationPageSize={5}
                          enableBrowserTooltips={true}
                          height="300px"
                        />
                      </div>
                    </div>
                  </KeyValueCard>
                </Form>
              );
            }}
          </Formik>
        </div>

        <Formik
          initialValues={{
            feedback_approval_required:
              (clientHubSlice.clientFeedbackApproval &&
                clientHubSlice.clientFeedbackApproval.feedback_approval_required) ||
              false,
          }}
          onSubmit={() => {}}
          enableReinitialize
        >
          {({ handleChange, handleBlur, setFieldValue, values, errors, touched }) => {
            return (
              <Form>
                <KeyValueCard
                  title="Feedback"
                  vButton={
                    <GlobalButton
                      size="sm"
                      format="white"
                      leftIcon={<BsDownload className="me-2" />}
                      onClick={handleExportData}
                    >
                      Export CSV
                    </GlobalButton>
                  }
                  filter={
                    <div className="row">
                      <div className="col-md-6" style={{ marginTop: "5px" }}>
                        <Toggle
                          type="checkbox"
                          name="feedback_approval_required"
                          id="feedback_approval_required"
                          label="Admin approval needed"
                          rightLabel={true}
                          checked={true}
                          style={{ cursor: "pointer" }}
                          tooltipMessage={tooltipMessage}
                          onChange={() => {
                            dispatch(
                              showAdminApprovalActionsSuccess({
                                adminApproval: true,
                                adminApprovalIsLoading: false,
                                adminApprovalIsError: "",
                              })
                            );
                          }}
                        />
                      </div>

                      <div
                        className={
                          clientHubSlice.clientFeedbackApproval && clientHubSlice.clientFeedbackApproval.client_id
                            ? "col-md-6"
                            : "col-md-6"
                        }
                      >
                        <DateRangePicker
                          defaultPickerValue={[
                            clientHubSlice.dateFilter.startDate
                              ? moment(new Date(clientHubSlice.dateFilter.startDate)).startOf("day")
                              : null,
                            clientHubSlice.dateFilter.endDate
                              ? moment(new Date(clientHubSlice.dateFilter.endDate)).startOf("day")
                              : null,
                          ]}
                          onChange={(values: any) => {
                            dispatch(
                              dateFilterSuccess({
                                dateFilter: {
                                  startDate: (values && values[0] && format(new Date(values[0]), "yyyy-MM-dd")) || "",
                                  endDate: (values && values[1] && format(new Date(values[1]), "yyyy-MM-dd")) || "",
                                },
                              })
                            );
                          }}
                        />
                      </div>
                    </div>

                    //   <DateRangePicker
                    //   id={`rules.${index}.startDate`}
                    //   error={
                    //     <ErrorMessage
                    //       component="span"
                    //       className="field_error"
                    //       name={`rules.${index}.startDate`}
                    //     />
                    //   }
                    //   defaultPickerValue={[
                    //     rule.startDate ? moment(rule.startDate).startOf("day") : null,
                    //     rule.endDate ? moment(rule.endDate).startOf("day") : null,
                    //   ]}
                    //   onChange={(values: any) => {
                    //     setFieldValue(
                    //       `rules.${index}.startDate`,
                    //       (values && values[0] && new Date(values[0]).getTime()) || null
                    //     );
                    //     setFieldValue(
                    //       `rules.${index}.endDate`,
                    //       (values && values[1] && new Date(values[1]).getTime()) || null
                    //     );
                    //   }}
                    // />
                  }
                >
                  <div className="row">
                    <div className="col-sm-3 my-3">
                      <PieChart />
                    </div>
                    <div className="col-sm-9">
                      <AgGridReactTable
                        className="ag-theme-alpine"
                        rowData={feedbackTableRows}
                        columnDefs={columns}
                        filter={true}
                        sortable={true}
                        animateRows={true}
                        pagination={true}
                        paginationPageSize={25}
                        enableBrowserTooltips={true}
                        // domLayout="autoHeight"
                        height="400px"
                      />
                    </div>
                  </div>
                </KeyValueCard>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
