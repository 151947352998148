import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

// style components
import { FeedbackSample } from "../../../../data/feedbackSample";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart = () => {
  // variable for label and value for the chart
  let chartRecord = [
    {
      label: "Positive",
      value: 0,
    },
    {
      label: "Too fast",
      value: 0,
    },
    {
      label: "Too slow",
      value: 0,
    },
    {
      label: "Bad language",
      value: 0,
    },
    {
      label: "Poor quality",
      value: 0,
    },
    {
      label: "Other",
      value: 0,
    },
  ];

  // count the total value for each type
  chartRecord.forEach(async (ele) => {
    const result = FeedbackSample[0].results.filter(
      (element) => element.type.toLowerCase() === ele.label.toLowerCase()
    );
    ele.value = result.length;
  });

  // sum all the current value
  const currentValue = chartRecord.reduce((pre, cur) => pre + cur.value, 0);

  // set value for other
  chartRecord[chartRecord.length - 1].value =
    FeedbackSample[0].results && FeedbackSample[0].results.length - currentValue;

  const pieData = {
    labels: chartRecord.map((data) => {
      return data.label;
    }),
    datasets: [
      {
        label: "# of Votes",
        data: chartRecord.map((data) => {
          return data.value;
        }),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return <Pie data={pieData} height={50} width={100} options={{ responsive: true }} />;
};
