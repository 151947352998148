import React from "react";

// styles components
import ClientHub from "../client-hub";

export default function Dashboard() {
  return (
    <div>
      <ClientHub />
    </div>
  );
}
