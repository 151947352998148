import axiosInterceptors from "../lib/axiosInterceptors";
import { getEnvironment } from "../config/config";
import { store } from "../redux-toolkit/store";
import { playlistsSuccess, playlistsTracksCountSuccess } from "../redux-toolkit/reducers/playlistsSlice";
import { isLoadingSuccess } from "../redux-toolkit/reducers/actionsSlice";
import { changeChannelsForClientSuccess } from "../redux-toolkit/reducers/schedulesSlice";

/**
 * Get playlist tracks
 */
export async function getPlaylistTracks(playlistId: number) {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/playlists/client/${playlistId}/tracks`, {});
}

/**
 * Get playlist tracks
 */
export async function getExcludedTracks(playlistId: number) {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/tracks/excluded/${playlistId}`, {});
}

/**
 * Get playlist details
 */
export async function getPlaylist(clientId: number, playlistId: number) {
  return await axiosInterceptors.get(
    `${getEnvironment()?.HOST_ROCK_API}/playlists/client/${clientId}/${playlistId}`,
    {}
  );
}

/**
 * Get client playlists
 */
export async function getActivePlaylistsByClientId(clientId: number) {
  return await axiosInterceptors
    .get(`${getEnvironment()?.HOST_ROCK_API}/playlists/client/${clientId}/active`, {})
    .then((response) => {
      store.dispatch(changeChannelsForClientSuccess(response.data.results));
      return response.data.results;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

/**
 * Get rotation details
 */
export async function getPlaylistRotationDetails(playlistId: number) {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/playlists/client/${playlistId}/rotation`, {});
}

/**
 * Get total playlists and total tracks
 */
export async function getTotalPlaylistsAndTracks(clientId: number) {
  store.dispatch(isLoadingSuccess(true));
  const response = await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/playlists/client/${clientId}`, {});

  // get the total publised playlsist
  const totalPublisedPlaylists =
    response.data &&
    response.data.playlists &&
    response.data.playlists.filter((published: any) => published.is_published);

  let tracks: any = [];
  let playlists: any = [];

  // get the list of the composition array into tracks array variable
  for (let { composition } of totalPublisedPlaylists) {
    if (composition.length) {
      tracks.push(composition);
    }
  }

  for (let playlist of response.data && response.data.playlists) {
    let totalTracks = 0;

    if (playlist.composition && playlist.composition.length) {
      totalTracks = playlist.composition.reduce(
        (previous: any, current: any) => parseInt(previous) + parseInt(current.number_of_tracks),
        0
      );
    }

    playlist.totalTracks = totalTracks;
    playlists.push(playlist);
  }

  // combine all sub arrays into one array and then return the total number of tracks
  const record = await tracks.flat();
  const uniqueTrack = [...new Map(record.map((item: any) => [item["corechannel_id"], item])).values()];

  const totalTracks = uniqueTrack.reduce((previous: any, current: any) => {
    return parseInt(previous) + parseInt(current.number_of_tracks);
  }, 0);

  // add both total playlists and total tracks
  const playlistsTracksCount: any = [
    {
      message: "Number based on total published playlists.",
      name: "Total playlists",
      info: true,
      value: (totalPublisedPlaylists && totalPublisedPlaylists.length) || 0,
    },
    {
      message: "Number based on total amount of unique tracks in published playlists.",
      name: "Total tracks",
      info: true,
      value: totalTracks || 0,
    },
  ];

  if (response.data && response.data.playlists) {
    store.dispatch(playlistsSuccess({ playlists: playlists }));
    store.dispatch(playlistsTracksCountSuccess({ playlistsTracksCount: playlistsTracksCount }));
  }

  store.dispatch(isLoadingSuccess(false));

  return response.data;
}

/**
 * Update playlist
 */
export async function updatePlaylist(playlistId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/playlists/${playlistId}`, data);
}

/**
 * Update pg and mongo playlist
 */
export async function updatePgAndMongoPlaylist(playlistId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/playlists/playlist/${playlistId}`, data);
}

/**
 * Create playlist
 */
export async function createPlaylist(data: any) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/playlists`, {
    ...data,
  });
}
