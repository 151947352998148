import axiosInterceptors from "../lib/axiosInterceptors";
import { getEnvironment } from "../config/config";
import { store } from "../redux-toolkit/store";
import { clientBusinessesListSuccess, clientsListSuccess, clientsSuccess } from "../redux-toolkit/reducers/authSlice";
import { clientFeedbackApprovalSuccess, clientOwnersSuccess } from "../redux-toolkit/reducers/clientHubSlice";

/**
 * Get a client
 */
export async function getClient(clientId: number) {
  const response = await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/clients/${clientId}`, {});

  if (response.data && response.data) {
    const data = response.data;
    const clientOwners: any = [
      {
        name: "Roles",
        info: false,
        value: (data && data.account_owner && data.account_owner.name) || "-",
        status: "Customer Owner",
      },
      {
        info: false,
        value: (data && data.curator_owner && data.curator_owner.name) || "-",
        status: "Curation Owner",
      },
    ];

    const reusult =
      {
        feedback_approval_required: data && data.feedback_approval_required,
        client_id: data && data.id,
      } || "";
    store.dispatch(clientFeedbackApprovalSuccess({ clientFeedbackApproval: reusult }));
    store.dispatch(clientOwnersSuccess({ clientOwners: clientOwners }));
  }

  return response;
}

/**
 * Get a client
 */
export async function getClientForAmbieUser(clientId: number) {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/clients/${clientId}`, {});
}

/**
 * update client
 */
export async function updateClientRecord(clientId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/clients/${clientId}`, data);
}

/**
 * freeze client
 */
export async function freezeClient(clientId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/clients/freeze/${clientId}`, data);
}

/**
 * Get clients list
 */
export async function getClients() {
  const response = await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/clients`, {});

  store.dispatch(clientsSuccess({ clients: response.data }));
  return response;
}

/**
 * Get clients list
 */
export async function getActiveClients() {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/clients/active`, {});
}

/**
 * Get clients list
 */
export const getClientsList = async () => {
  const response = await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/clients/locations/areas`, {});
  let clientBusinesses: any = [];
  if (response.data && response.data) {
    if (response.data.length) {
      response.data.forEach((element: any) =>
        element.businesses_business.map((business: any) => {
          business.client_id = element.id;
          business.client_name = element.name;
          business.business_id = business.id;
          business.business_name = business.name;
          clientBusinesses.push(business);

          return business;
        })
      );
    }

    store.dispatch(clientBusinessesListSuccess({ clientBusinessesList: clientBusinesses }));
    store.dispatch(clientsListSuccess({ clientsList: response.data }));
  }
  return response;
};

/**
 * Add new client
 */
export async function addNewClient(data: any) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/clients`, data);
}
