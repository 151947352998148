import axiosInterceptors from "../lib/axiosInterceptors";
import { getEnvironment } from "../config/config";
import { store } from "../redux-toolkit/store";
import { clientBusinessesListSuccess, clientsListSuccess, clientsSuccess } from "../redux-toolkit/reducers/authSlice";
import { clientBriefsSuccess, briefSuccess } from "../redux-toolkit/reducers/clientHubSlice";
import { briefsSuccess } from "../redux-toolkit/reducers/briefsSlice";

/**
 * Get a client
 */
export async function getClientForAmbieUser(clientId: number) {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/clients/${clientId}`, {});
}

/**
 * update client
 */
export async function updateClientRecord(clientId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/clients/${clientId}`, data);
}

/**
 * Get clients list
 */
export async function getClients() {
  const response = await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/clients`, {});

  store.dispatch(clientsSuccess({ clients: response.data }));
  return response;
}

/**
 * Get clients list
 */
export async function getActiveClients() {
  return await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/clients/active`, {});
}

/**
 * Get clients list
 */
export const getClientsList = async () => {
  const response = await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/clients/locations/areas`, {});
  let clientBusinesses: any = [];
  if (response.data && response.data) {
    if (response.data.length) {
      response.data.forEach((element: any) =>
        element.businesses_business.map((business: any) => {
          business.client_id = element.id;
          business.client_name = element.name;
          clientBusinesses.push(business);

          return business;
        })
      );
    }

    store.dispatch(clientBusinessesListSuccess({ clientBusinessesList: clientBusinesses }));
    store.dispatch(clientsListSuccess({ clientsList: response.data }));
  }
  return response;
};

/**
 * Get briefs
 */
export async function getBriefs() {
  const response = await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/briefs`, {});

  if (response.data) {
    store.dispatch(briefsSuccess({ briefs: response.data }));
  } else {
    store.dispatch(briefsSuccess({ briefs: [] }));
  }
}

/**
 * Add new brief
 */
export async function addNewBrief(data: any) {
  return await axiosInterceptors.post(`${getEnvironment()?.HOST_ROCK_API}/briefs`, data);
}

/**
 * Get client briefs
 */
export async function getClientBriefs(clientId: number) {
  const response = await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/briefs/client/${clientId}`, {});

  if (response.data) {
    store.dispatch(clientBriefsSuccess({ clientBriefs: response.data }));
  } else {
    store.dispatch(clientBriefsSuccess({ clientBriefs: [] }));
  }
}

/**
 * Get brief
 */
export async function getBrief(briefId: number) {
  const response = await axiosInterceptors.get(`${getEnvironment()?.HOST_ROCK_API}/briefs/${briefId}`, {});

  if (response.data) {
    store.dispatch(briefSuccess({ brief: response.data }));
  } else {
    store.dispatch(briefSuccess({ brief: {} }));
  }
}

/**
 * Edit brief
 */
export async function editBrief(briefId: number, data: any) {
  return await axiosInterceptors.patch(`${getEnvironment()?.HOST_ROCK_API}/briefs/${briefId}`, data);
}
