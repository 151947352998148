import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { BsFillTvFill, BsPeopleFill, BsPersonFill } from "react-icons/bs";

// style components
import {
  clients_tools_page,
  devices_tools_page,
  ambie_users_tools_page,
  clients_users_tools_page,
} from "../../../constants/routePath";
import { requestPasswordReset } from "../../../services/usersServices";
import { RootState } from "../../../redux-toolkit/store";
import styles from "../../../pages/locations/locations.module.scss";

import {
  ChangeClientEmailAddress,
  CopyGenerateClientSetPasswordLink,
  GenerateClientSetPasswordLink,
  RenameClient,
  SendClientPasswordResetEmail,
} from "../../../pages/locations/components/location-actions/ClientActions";
import {
  hideActionsSuccess,
  isLoadingSuccess,
  showCopyGenerateClientSetPasswordActionsSuccess,
  showErrorMessageSuccess,
  showSuccessMessageSuccess,
} from "../../../redux-toolkit/reducers/actionsSlice";
import { GlobalButton } from "../../../components/button";
import { ACTIONS_ERROR_MESSAGE } from "../../../constants/globalText";
import { ViewCommercialInjectionRules } from "../../../pages/locations/components/location-actions/LocationAreaActions";
import { SuperuserPermission } from "../../../components/permissions";

export const ContentManagementBar = () => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const authSlice = useSelector((state: RootState) => state.authSlice);
  const user =
    authSlice.user &&
    authSlice.user.user &&
    authSlice.user.user.permission_level &&
    authSlice.user.user.permission_level.permission_level;

  const dispatch = useDispatch();

  let referrer: string = "";
  if (process.env.REACT_APP_AMBIE_ENV === "development") {
    referrer = "localhost:7777";
  } else if (process.env.REACT_APP_AMBIE_ENV === "staging") {
    referrer = "dashboard-stage.ambie.fm";
  } else if (process.env.REACT_APP_AMBIE_ENV === "production") {
    referrer = "dashboard.ambie.fm";
  }

  const sendOrGeneratePasswordResetEmailData = async () => {
    dispatch(isLoadingSuccess(true));
    await requestPasswordReset(searchClient.clentEmail, referrer)
      .then((response) => {
        if (actions.generateClientSetPassword) {
          const token = response && response.data && response.data.accessToken;
          const link = `${referrer}/reset-password?token=${token}`;
          dispatch(isLoadingSuccess(false));
          dispatch(showErrorMessageSuccess(""));
          dispatch(showCopyGenerateClientSetPasswordActionsSuccess({ linkClient: link }));
        } else {
          dispatch(hideActionsSuccess());
        }
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  return (
    <>
      <div className="bg-white navbar navbar-expand-lg navbar-bordered">
        <div className="container">
          {/* <SearchClientBar /> */}
          <div></div>

          {/* Client actions */}

          <>
            {actions.renameClient && (
              <RenameClient
                show={actions.renameClient}
                handleClose={() => dispatch(hideActionsSuccess())}
                footer={
                  <>
                    <div className={styles.flexRowWrapModalFooter}>
                      <div className={styles.footerLeft}>
                        <GlobalButton
                          format="white"
                          size="sm"
                          disabled={actions.actionsIsLoading ? true : false}
                          onClick={() => dispatch(hideActionsSuccess())}
                        >
                          Cancel
                        </GlobalButton>
                      </div>
                      <div>
                        <GlobalButton
                          disabled={actions.actionsIsLoading ? true : false}
                          format="success"
                          type="submit"
                          size="sm"
                        >
                          Submit
                        </GlobalButton>
                      </div>
                    </div>
                  </>
                }
              />
            )}

            {actions.changeClientEmail && (
              <ChangeClientEmailAddress
                show={actions.changeClientEmail}
                handleClose={() => dispatch(hideActionsSuccess())}
                footer={
                  <>
                    <div className={styles.flexRowWrapModalFooter}>
                      <div className={styles.footerLeft}>
                        <GlobalButton
                          format="white"
                          size="sm"
                          disabled={actions.actionsIsLoading ? true : false}
                          onClick={() => dispatch(hideActionsSuccess())}
                        >
                          Cancel
                        </GlobalButton>
                      </div>
                      <div>
                        <GlobalButton
                          disabled={actions.actionsIsLoading ? true : false}
                          format="success"
                          type="submit"
                          size="sm"
                        >
                          Submit
                        </GlobalButton>
                      </div>
                    </div>
                  </>
                }
              />
            )}

            {actions.sendClientPasswordReset && (
              <SendClientPasswordResetEmail
                show={actions.sendClientPasswordReset}
                handleClose={() => dispatch(hideActionsSuccess())}
                footer={
                  <>
                    <div className={styles.flexRowWrapModalFooter}>
                      <div className={styles.footerLeft}>
                        <GlobalButton
                          format="white"
                          size="sm"
                          disabled={actions.actionsIsLoading ? true : false}
                          onClick={() => dispatch(hideActionsSuccess())}
                        >
                          No
                        </GlobalButton>
                      </div>
                      <div>
                        <GlobalButton
                          format="success"
                          disabled={actions.actionsIsLoading ? true : false}
                          onClick={() => sendOrGeneratePasswordResetEmailData()}
                          size="sm"
                        >
                          Yes
                        </GlobalButton>
                      </div>
                    </div>
                  </>
                }
              />
            )}

            {actions.generateClientSetPassword && (
              <GenerateClientSetPasswordLink
                show={actions.generateClientSetPassword}
                handleClose={() => dispatch(hideActionsSuccess())}
                footer={
                  <>
                    <div className={styles.flexRowWrapModalFooter}>
                      <div className={styles.footerLeft}>
                        <GlobalButton
                          format="white"
                          disabled={actions.actionsIsLoading ? true : false}
                          size="sm"
                          onClick={() => dispatch(hideActionsSuccess())}
                        >
                          No
                        </GlobalButton>
                      </div>
                      <div>
                        <GlobalButton
                          format="success"
                          disabled={actions.actionsIsLoading ? true : false}
                          onClick={() => sendOrGeneratePasswordResetEmailData()}
                          size="sm"
                        >
                          Yes
                        </GlobalButton>
                      </div>
                    </div>
                  </>
                }
              />
            )}
            {actions.copyGenerateClientSetPassword && (
              <CopyGenerateClientSetPasswordLink
                show={actions.copyGenerateClientSetPassword}
                link={actions.linkClient}
                handleClose={() => dispatch(hideActionsSuccess())}
                footer={
                  <>
                    <div className={styles.flexRowWrapModalFooter}>
                      <div className={styles.footerLeft}>
                        <GlobalButton
                          format="white"
                          disabled={actions.actionsIsLoading ? true : false}
                          size="sm"
                          onClick={() => dispatch(hideActionsSuccess())}
                        >
                          Close
                        </GlobalButton>
                      </div>
                      <div>
                        <CopyToClipboard text={`${actions.linkClient}`}>
                          <GlobalButton
                            format="success"
                            disabled={actions.actionsIsLoading ? true : false}
                            onClick={() => dispatch(showSuccessMessageSuccess("Copied, you can now close the mobal"))}
                            size="sm"
                          >
                            Copy
                          </GlobalButton>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </>
                }
              />
            )}

            {actions.viewClientCommercialInjectionRules && (
              <ViewCommercialInjectionRules
                show={actions.viewClientCommercialInjectionRules}
                handleClose={() => dispatch(hideActionsSuccess())}
                type="client"
                clientIdOrAreaIdOrPlaylistId={searchClient.clientId}
                nameDisplay={searchClient.clientName}
                footer={
                  <div className={styles.rightAligned}>
                    <GlobalButton
                      disabled={actions.actionsIsLoading ? true : false}
                      format="success"
                      type="submit"
                      size="sm"
                    >
                      Submit
                    </GlobalButton>
                  </div>
                }
              />
            )}
          </>

          <nav className="js-mega-menu hs-menu-initialized hs-menu-horizontal">
            <ul className="navbar-nav">
              {user === SuperuserPermission() && (
                <li className="nav-item">
                  <NavLink
                    to={clients_tools_page}
                    className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}
                  >
                    <BsPersonFill className="dropdown-item-icon" />
                    Clients
                  </NavLink>
                </li>
              )}

              <li className="nav-item">
                <NavLink
                  to={devices_tools_page}
                  className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}
                >
                  <BsFillTvFill className="dropdown-item-icon" />
                  Devices
                </NavLink>
              </li>

              {user === SuperuserPermission() && (
                <li className="nav-item">
                  <NavLink
                    to={ambie_users_tools_page}
                    className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}
                  >
                    <BsPeopleFill className="dropdown-item-icon" />
                    Team Users
                  </NavLink>
                </li>
              )}

              <li className="nav-item">
                <NavLink
                  to={clients_users_tools_page}
                  className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}
                >
                  <BsPeopleFill className="dropdown-item-icon" />
                  Client Users
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Outlet />
    </>
  );
};
