import React, { useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import _ from "lodash";

// style components
import { GlobalModal } from "../../../../components/modal";
import { RootState } from "../../../../redux-toolkit/store";
import modalStyles from "../../../locations/components/location-actions/location-actions.module.scss";
import { GlobalButton } from "../../../../components/button";
import locationStyles from "../../../locations/locations.module.scss";
import classNames from "classnames";
import { isErrorScheduleSuccess } from "../../../../redux-toolkit/reducers/schedulesSlice";

// import TimezoneHelper from "@ambie/ambie-npm-timezone-helper";
import TimezoneHelper from "../../../../components/timezoneHelper";

interface ScheduleProps {
  size?: "sm" | "lg" | "xl";
  backdrop?: "static" | true | false;
  centered?: true | false;
  scrollable?: true | false;
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  footer?: any;
  title?: string;
  show: boolean;
  location?: { name: string; email: string; id: number };
  handleClose: any;
  saveChannel: any; // function
  deleteChannel: any; //function
  handleEditScheduleChannelChange: any; //function
  handleChangeIsAddEditSchedValid: any; //function
  editScheduleObject: any;
}

/**
 * Generate reset password link
 */
export const ScheduleAddEditChannelPopup = ({
  footer,
  show,
  handleClose,
  saveChannel,
  deleteChannel,
  handleEditScheduleChannelChange,
  handleChangeIsAddEditSchedValid,
  editScheduleObject,
}: ScheduleProps) => {
  const schedulesSlice = useSelector((state: RootState) => state.schedulesSlice);
  const [timess] = useState<any>(TimezoneHelper.generateTimes("Europe/London"));
  const [state, setState] = useState<{ times: []; channelId: number }>({
    times: [],
    channelId: editScheduleObject.channel.id,
  });
  const dispatch = useDispatch();

  const changeChannelHelper = (selected: any) => {
    const channel =
      schedulesSlice.currentChannelsForClient.filter(
        (channel: any) => (channel.playlist_id || channel.id) === selected.value
      )[0] || editScheduleObject.channel;

    handleEditScheduleChannelChange({ channel });
    setState({
      ...state,
      channelId: channel.id,
    });
  };

  const changeStartHelper = (selected: any) => {
    handleEditScheduleChannelChange({ startValue: selected.value });
  };

  const changeEndHelper = (selected: any) => {
    handleEditScheduleChannelChange({ endValue: selected.value });
  };

  const changeDaysHelper = (day: any, e: any) => {
    const days = _.cloneDeep(editScheduleObject.days);
    days[day] = !days[day];
    if (days.filter((weekDay: any) => weekDay).length < 1) return;
    let startValue = editScheduleObject.startValue.clone();
    let endValue = editScheduleObject.endValue.clone();
    if (days.filter((weekDay: any) => weekDay).length === 1) {
      const startOfWeek = editScheduleObject.startValue.clone().startOf("isoWeek");
      const startDayIndex = days.indexOf(true);
      const endDayIndex = endValue.clone().startOf("day").isSame(endValue) ? startDayIndex + 1 : startDayIndex;
      startValue = startOfWeek
        .clone()
        .add(startDayIndex, "days")
        .set({ hour: startValue.hour(), minute: startValue.minute() });
      endValue = startOfWeek.clone().add(endDayIndex, "days").set({ hour: endValue.hour(), minute: endValue.minute() });
    }
    handleEditScheduleChannelChange({ days, startValue, endValue });
  };

  const _saveChannel = () => {
    let playlistErrorMessages: any = [];
    const channel = { ...editScheduleObject.channel };
    const days = _.cloneDeep(editScheduleObject.days);
    const day = days.indexOf(true) || 0;
    const startValue = editScheduleObject.startValue.clone();
    const endValue = editScheduleObject.endValue.clone();
    if (channel.name && startValue.isBefore(endValue)) {
      handleEditScheduleChannelChange({
        day,
        channel,
        startValue,
        endValue,
        days,
        isEditChannel: schedulesSlice.isEditPlaylist,
      });
      saveChannel();
    } else {
      if (!channel.name) {
        playlistErrorMessages.push({ message: "Playlist name is required" });
      }
      if (!startValue.isBefore(endValue)) {
        playlistErrorMessages.push({ message: "The start time connot be greater than the end time" });
      }
      dispatch(isErrorScheduleSuccess({ status: true, messages: playlistErrorMessages }));
      handleChangeIsAddEditSchedValid(false);
    }
  };

  const dayIndex = editScheduleObject.days.indexOf(true);

  const startDate =
    editScheduleObject.startValue && editScheduleObject.startValue.clone().startOf("isoWeek").date() + dayIndex;
  const times = timess.map((time: any, i: number, self: []) => ({
    value: time.clone().set({
      date: i === self.length - 1 ? startDate + 1 : startDate,
    }),
    label: i === self.length - 1 ? "24:00" : time.clone().format("HH:mm"),
  }));

  const channelsList =
    schedulesSlice.currentChannelsForClient && schedulesSlice.currentChannelsForClient.length
      ? schedulesSlice.currentChannelsForClient
          // .sort((a: any, b: any) => a.name.localeCompare(b.name))
          .map((channel: any) => ({ value: channel.playlist_id || channel.id, label: channel.name }))
      : [];

  return (
    <GlobalModal
      title="Choose a playlist and set the time"
      show={show}
      handleClose={handleClose}
      size="lg"
      footer={footer}
    >
      <div className={modalStyles.modal}>
        <div>
          <Formik initialValues={{}} onSubmit={(values) => {}}>
            <>
              <div className={classNames(`flex full-width full-width-channel`)}>
                <div>
                  <Select
                    id="channel-list"
                    className="flex-grow-4 time-select channel-list channel-width"
                    options={channelsList}
                    defaultValue={
                      channelsList.find((c: any) => c.value === editScheduleObject.channel.id) || channelsList[0]
                    }
                    onChange={changeChannelHelper}
                  />
                </div>
                <div>
                  <Select
                    id="start-time"
                    className="flex-grow-1 time-select start-time"
                    options={times}
                    defaultValue={
                      times.filter((time: any) => time.value.isSame(editScheduleObject.startValue))[0] || times[0]
                    }
                    onChange={changeStartHelper}
                  />
                </div>
                <div>
                  <Select
                    id="end-time"
                    className="flex-grow-1 time-select end-time"
                    options={times}
                    defaultValue={
                      times.filter((time: any) => time.value.isSame(editScheduleObject.endValue))[0] || times[3]
                    }
                    onChange={changeEndHelper}
                  />
                </div>
              </div>
              <div className="flex-wrap schedule-days">
                {schedulesSlice.days.map((day: any, i: number) => {
                  return (
                    <div key={day} className="checkbox pill">
                      <input
                        onChange={(e: any) => changeDaysHelper(i, e)}
                        checked={editScheduleObject.days[i]}
                        type="checkbox"
                        name={`check-${day.toLowerCase()}`}
                        id={`check-${day.toLowerCase()}`}
                      />
                      <label htmlFor={`check-${day.toLowerCase()}`}>{day}</label>
                    </div>
                  );
                })}
              </div>
              <div className={locationStyles.flexRowWrapModalFooter}>
                <div>
                  <GlobalButton format="white" className="me-3" onClick={() => handleClose()}>
                    Cancel
                  </GlobalButton>
                  {schedulesSlice.isEditPlaylist && (
                    <GlobalButton format="danger" onClick={() => deleteChannel()}>
                      Delete
                    </GlobalButton>
                  )}
                </div>
                <div>
                  <GlobalButton format="success" type="submit" onClick={_saveChannel}>
                    {schedulesSlice.isEditPlaylist ? "Save" : "Add"} Playlist
                  </GlobalButton>
                </div>
              </div>
            </>
          </Formik>
        </div>

        {footer && <div className={modalStyles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};
