import React from "react";
import { BsFillTvFill } from "react-icons/bs";
import { NavLink, Outlet } from "react-router-dom";

// style components
import { briefs_page } from "../../../constants/routePath";

export const AmbieHubBar = () => {
  return (
    <>
      <div className="bg-white navbar navbar-expand-lg navbar-bordered">
        <div className="container">
          {/* <SearchClientBar /> */}
          <div></div>

          <nav className="js-mega-menu hs-menu-initialized hs-menu-horizontal">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  to={briefs_page}
                  className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}
                >
                  <BsFillTvFill className="dropdown-item-icon" />
                  Briefs
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Outlet />
    </>
  );
};
