import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

import { Authenticated } from "./layouts/authenticated";
import { Unauthenticated } from "./layouts/unauthenticated";
import { LOCAL_STORAGE_USER_TOKEN } from "./constants/defaultValues";
import { RootState } from "./redux-toolkit/store";
import { socket, WebSocketProvider } from "./components/websocket/websocket";

function App() {
  const tokenReducer = useSelector((state: RootState) => state.authSlice.token);
  const [token, setToken] = useState(tokenReducer || Cookies.get(LOCAL_STORAGE_USER_TOKEN));

  useEffect(() => {
    setToken(Cookies.get(LOCAL_STORAGE_USER_TOKEN));
  }, [tokenReducer, token]);

  return (
    <>
      <WebSocketProvider value={socket}>
        {token && <Authenticated />}
        {!token && <Unauthenticated />}
      </WebSocketProvider>
    </>
  );
}

export default App;
