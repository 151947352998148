import { DEVELOPMENT } from "./env/dev";
import { STAGING } from "./env/staging";
import { PREPRODUCTION } from "./env/preprod";
import { PRODUCTION } from "./env/prod";
export const AMBIE_ENV = {
  DEVELOPMENT,
  STAGING,
  PREPRODUCTION,
  PRODUCTION,
};
