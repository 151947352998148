import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// style components

export interface clientHubState {
  feedback: [];
  dateFilter: { startDate: string; endDate: string };
  adminApproval: boolean;
  adminApprovalIsLoading: boolean;
  adminApprovalIsError: string;
  clientFeedbackApproval: { client_id: number; feedback_approval_required: boolean };
  clientDetails: any;
  clientOwners: [];
  clientBriefs: [];
  brief: {};
}
const initialState: clientHubState = {
  feedback: [],
  dateFilter: { startDate: "", endDate: "" },
  adminApproval: false,
  adminApprovalIsLoading: false,
  adminApprovalIsError: "",
  clientFeedbackApproval: { client_id: 0, feedback_approval_required: false },
  clientOwners: [],
  clientDetails: {},
  clientBriefs: [],
  brief: {},
};

const ClientHubSlice = createSlice({
  name: "clientHub",
  initialState,
  reducers: {
    feedbackSuccess: (state, action: PayloadAction<any>) => {
      state.feedback = action.payload.feedback;
    },

    dateFilterSuccess: (state, action: PayloadAction<any>) => {
      state.dateFilter = action.payload.dateFilter;
    },
    showAdminApprovalActionsSuccess: (state, action: PayloadAction<any>) => {
      state.adminApproval = action.payload.adminApproval;
      state.adminApprovalIsLoading = action.payload.adminApprovalIsLoading;
      state.adminApprovalIsError = action.payload.adminApprovalIsError;
    },

    clientFeedbackApprovalSuccess: (state, action: PayloadAction<any>) => {
      state.clientFeedbackApproval = action.payload.clientFeedbackApproval;
    },

    clientOwnersSuccess: (state, action: PayloadAction<any>) => {
      state.clientOwners = action.payload.clientOwners;
    },

    clientDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.clientDetails = action.payload.clientDetails;
    },

    clientBriefsSuccess: (state, action: PayloadAction<any>) => {
      state.clientBriefs = action.payload.clientBriefs;
    },
    briefSuccess: (state, action: PayloadAction<any>) => {
      state.brief = action.payload.brief;
    },
  },
});

export const {
  feedbackSuccess,
  dateFilterSuccess,
  showAdminApprovalActionsSuccess,
  clientFeedbackApprovalSuccess,
  clientDetailsSuccess,
  clientOwnersSuccess,
  clientBriefsSuccess,
  briefSuccess,
} = ClientHubSlice.actions;
export default ClientHubSlice.reducer;
