import { BsQuestionCircle } from "react-icons/bs";
import classnames from "classnames";

// style components
import { Tooltip } from "../tooltip";
import styles from "./cards.module.scss";

interface KeyValueCardProps {
  title: any;
  subTitle?: any;
  filter?: any;
  vButton?: any;
  pButton?: any;
  cButton?: any;
  children?: any;
}

export const KeyValueCard = ({ title, subTitle, filter, vButton, pButton, cButton, children }: KeyValueCardProps) => {
  return (
    <div className="card">
      <div className={classnames("card-header")}>
        <div className="row">
          <div className="col-md-5 mb-2 mb-md-0 d-flex flex-column">
            <h3 className="mb-0">{title}</h3>
            {subTitle && <p className="card-subtitle mt-1">{subTitle}</p>}
          </div>

          <div className="col-md-7 d-grid d-sm-flex justify-content-md-end align-items-sm-center gap-4">
            {filter && <div className={styles.filter}>{filter}</div>}
            {vButton && (
              <a href="##" className={classnames("text-muted", filter && styles.vButton)}>
                {vButton}
              </a>
            )}
            {pButton && <div className={styles.pButton}>{pButton}</div>}
            {cButton && <div className={styles.cButton}>{cButton}</div>}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export const RowCard = ({ children, className = "" }: any) => {
  return (
    <div className={`${styles.row} ${className}`}>
      <div className={styles.value}>{children}</div>
    </div>
  );
};

interface MiniCardProps {
  options?: any;
  columns?: any;
}
export const MiniCard = ({ options, columns }: MiniCardProps) => {
  return (
    <div className="card card-body h-100">
      <div className="row col-lg-divider gx-lg-6 align-items-end">
        {options.map(
          (
            option: { name?: string; info?: any; value: any; status?: string; colour?: string; message: string },
            index: number
          ) => {
            return (
              <div className={columns} key={index}>
                <h6 className={styles.cardSubtitle}>
                  {option.name}
                  {option.info && (
                    <span className="position-relative">
                      <span className={styles.tooltipIcon}>
                        <BsQuestionCircle
                          className="lead bi-question-circle ms-1"
                          style={{ fontSize: "1rem", position: "relative", top: "-1px" }}
                        />
                      </span>
                      <span className={styles.tooltipIconHide}>
                        <Tooltip message={option.message || "message here..."} />
                      </span>
                    </span>
                  )}
                </h6>
                <div className="position-relative">
                  <span
                    className={classnames("js-counter display-4", option.colour ? option.colour : "text-dark")}
                    data-value={option.value}
                  >
                    {typeof option.value === "string" ? (
                      <span style={{ fontSize: 13 }}>
                        {option.value.length > 15 ? `${option.value.substring(0, 15)}...` : option.value}
                      </span>
                    ) : (
                      option.value
                    )}
                  </span>
                  {option.status && <h6 className={styles.cardSubtitle + " mt-1 mb-0"}>{option.status}</h6>}
                  {options.length > index + 1 && <div className={styles.cardDivider}></div>}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};
