import React, { useContext, useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";

// style components
import { DeviceIconSvg, LocationIconSvg } from "../../../../assets/images/logo/logo";
import { Input } from "../../../../components/fields/input";
import {
  addOrEditUserForm,
  forgotPasswordForm,
  renameUserForm,
  resetPasswordform,
} from "../../../../components/formValidation/formValidation";
import { GlobalModal } from "../../../../components/modal";
import {
  hideActionsSuccess,
  showErrorMessageSuccess,
  isLoadingSuccess,
  showSuccessMessageSuccess,
} from "../../../../redux-toolkit/reducers/actionsSlice";
import styles from "./users-modal.module.scss";
import { RootState } from "../../../../redux-toolkit/store";
import {
  addNewUser,
  getUser,
  getUserPermissionsSearch,
  requestPasswordReset,
  resetPassword,
  switchUserClient,
  updateUserRecord,
} from "../../../../services/usersServices";
import { GlobalErrorMessage, SuccessMessage } from "../../../users/components/errorMessage";
import { LoadingData } from "../../../../components/loading";
import { ACTIONS_ERROR_MESSAGE } from "../../../../constants/globalText";
import { userDetailsSuccess } from "../../../../redux-toolkit/reducers/authSlice";
import { Toggle } from "../../../../components/fields/toggle";
import { SearchWithOptions } from "../../../../components/fields/search";
import { GlobalButton } from "../../../../components/button";
import { Panel } from "../../../../components/panel";
import panelStyles from "../../../../components/panel/panel.module.scss";
import { getClientLocations } from "../../../../services/locationsServices";
import { BsSearch } from "react-icons/bs";
import { CheckboxLocationsAreas } from "../../../../components/fields/checkbox";
import {
  ManagerAccess,
  ManagerAndTeamAccess,
  TeamAccess,
  AccessWithoutManagerAndTeam,
} from "../../../../components/permissions";
import { getClients } from "../../../../services/clientsServices";
import CopyToClipboard from "react-copy-to-clipboard";
import { activateUser, deactivateUser, sendOrGenerateUserPasswordResetEmail } from "../../actions/actions";
import { WebSocketContext } from "../../../../components/websocket/websocket";

interface UserActionsProps {
  size?: "sm" | "lg" | "xl";
  backdrop?: "static" | true | false;
  centered?: true | false;
  scrollable?: true | false;
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  footer?: any;
  title?: string;
  show: boolean;
  link?: string;
  location?: { name: string; email: string; id: number };
  user?: { name: string; email: string; id: number };
  handleClose?: any;
  children?: any;
}

/**
 * Edit user details
 */
export const EditUserDetails = ({ show }: UserActionsProps) => {
  const actionsSlice = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const userDetails = useSelector((state: RootState) => state.authSlice.userDetails);
  const [permissions, setPermissions] = useState<[]>([]);
  const [selectedPermission, setSelectedPermission] = useState<{ id: number; name: string }>({ id: 0, name: "" });
  const [clearSelected, setClearSelected] = useState<boolean>(true);
  const [locations, setLocations] = useState<[]>([]);
  const userInfo = userDetails && userDetails.users_user;
  const clientId = actionsSlice.clientId || searchClient.clientId;

  const dispatch = useDispatch();
  const socket = useContext(WebSocketContext);

  useEffect(() => {
    let isSubscribed = true;
    socket.on("updateUser", (payload: { id: number }) => {
      if (payload.id === actionsSlice.user.id) getUserData();
    });

    const getUserData = async () => {
      socket.on("updateUser", (payload: { id: number }) => {
        if (payload.id === actionsSlice.user.id) getUserData();
      });

      if (actionsSlice.user.id) {
        await getUser(actionsSlice.user.id)
          .then((response) => {
            if (isSubscribed) {
              dispatch(userDetailsSuccess({ userDetails: response.data }));
              setSelectedPermission(response.data.permission_level);
            }
          })
          .catch((error) => {});
      }

      return () => {
        isSubscribed = false;
        socket.off("updateUser", (payload: { id: number }) => {
          if (payload.id === actionsSlice.user.id) getUserData();
        });
      };
    };

    const getUserPermissionsData = async () => {
      await getUserPermissionsSearch(actionsSlice.ambieUser)
        .then((response) => {
          if (isSubscribed) {
            setPermissions(response.data);
          }
        })
        .catch((error) => {});
    };

    const getLocationsData = async () => {
      await getClientLocations(clientId)
        .then((response) => {
          setLocations(response.data);
        })
        .catch((error) => {});
    };

    getUserData();
    getUserPermissionsData();
    getLocationsData();
  }, [actionsSlice, clientId, dispatch]);

  const editUserDetailsData = async (values: any) => {
    const businessClientId = userDetails.businesses_client.id;
    const data = {
      name: values.name,
      email: values.email,
      permissionLevelId: values.permissionLevelId,
      clientId: businessClientId,
      userId: userInfo.id,
      locationIdsOrLocationAreaIds: ManagerAccess().includes(values.permissionLevel)
        ? values.managerLocationsIds
        : values.teamLocationsAndAreasIds,
      permissionLevel: values.permissionLevel,
      editUserDetails: true,
    };
    dispatch(isLoadingSuccess(true));
    await updateUserRecord(actionsSlice.user.id, data)
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        if (error.response?.data?.target?.includes("email")) {
          dispatch(showErrorMessageSuccess(`${values.email} email has already been used!`));
        } else {
          dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        }
      });
  };

  const permissionsLists = permissions.length
    ? permissions.map((permission: any) => ({
        value: permission.id,
        label: permission.name,
        permissionLevel: permission.permission_level,
      }))
    : [];

  const initialValues = {
    name: (userInfo && userInfo.name) || "",
    permissionLevelId: selectedPermission.id,
    email: (userInfo && userInfo.email) || "",
    permissionLevel: (userDetails.permission_level && userDetails.permission_level.permission_level) || "",
    permissionLevelName: (userDetails.permission_level && userDetails.permission_level.name) || "",
    managerLocationsIds:
      (ManagerAccess().includes(userDetails.permission_level && userDetails.permission_level.permission_level) &&
        userDetails.users_user &&
        userDetails.users_user.users_businesses_areas.map(
          (location: any) => location.businesses_business && location.businesses_business.id
        )) ||
      [],
    teamLocationsAndAreasIds:
      (TeamAccess().includes(userDetails.permission_level && userDetails.permission_level.permission_level) &&
        userDetails.users_user &&
        userDetails.users_user.users_businesses_areas.map((location: any) => {
          return { location_id: location.businesses_business.id, area_id: location.schedules_area.id };
        })) ||
      [],
    locations:
      (locations.length &&
        locations.map((details: any) => ({
          ...details,
          activeLocations:
            userDetails.users_user &&
            userDetails.users_user.users_businesses_areas
              .map((location: any) => location.businesses_business.id)
              .includes(details.id),
        }))) ||
      [],
    locationFilter: null,
  };

  const locationsFilter = locations.map((location: any) => ({
    location_name: location.name,
    location_id: location.id,
    areas: location.schedules_area.map((area: any) => ({
      location_id: location.id,
      location_name: location.name,
      area_id: area.id,
      area_name: area.name,
    })),
  }));

  return (
    <Formik
      form
      initialValues={initialValues}
      validationSchema={addOrEditUserForm}
      onSubmit={editUserDetailsData}
      enableReinitialize
    >
      {({ handleChange, handleBlur, setFieldValue, values, errors }) => (
        <Form>
          <Panel
            show={show}
            handleClose={() => {
              dispatch(hideActionsSuccess());
            }}
            title="Edit User"
            width="50%"
            maxWidth="500px"
            footer={
              <div className={panelStyles.footer}>
                <div className={panelStyles.footer_left}>
                  <GlobalButton
                    format="white"
                    size="sm"
                    onClick={() => {
                      dispatch(hideActionsSuccess());
                    }}
                  >
                    Close
                  </GlobalButton>
                </div>
                <div>
                  <GlobalButton type="submit" format="primary" size="sm">
                    Save
                  </GlobalButton>
                </div>
              </div>
            }
          >
            <div>
              <div>
                <div>
                  {actionsSlice.actionsErrorMessage && (
                    <GlobalErrorMessage message={actionsSlice.actionsErrorMessage} />
                  )}
                </div>
                {actionsSlice.actionsIsLoading && <LoadingData />}
                <div>
                  <div style={{ marginBottom: 20 }}>
                    <SearchWithOptions
                      label="Permission"
                      placeholder="Search Permission"
                      id="permissionLevelId"
                      selected={
                        (clearSelected && permissionsLists.filter((c: any) => c.value === selectedPermission.id)) ||
                        null
                      }
                      options={permissionsLists}
                      labelKey="label"
                      filterBy={["label"]}
                      rightIcon={true}
                      size="sm"
                      leftIcon={<BsSearch />}
                      clearButton
                      maxWidth="100%"
                      tooltipIcon={false}
                      tooltipMessage="Message about the permission to be display here.."
                      onFocus={() => setClearSelected(false)}
                      maxResults={30}
                      onChange={(selected: any) => {
                        if (selected && selected[0]) {
                          setFieldValue(`permissionLevelId`, selected[0].value);
                          setFieldValue("permissionLevel", selected[0].permissionLevel);
                          setFieldValue("permissionLevelName", selected[0].label);
                        }
                        if (!selected.length) {
                          setFieldValue(`permissionLevelId`, null);
                          setFieldValue("permissionLevel", null);
                          setFieldValue("permissionLevelName", null);
                        }
                      }}
                      renderMenuItemChildren={(option: any) => {
                        return (
                          <div style={{ whiteSpace: "pre-wrap" }}>
                            <span style={{ fontWeight: "bold" }}>{option.label}</span>
                            {getPermissionLevelContent(option.permissionLevel)}
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div style={{ marginBottom: 20 }}>
                    <Input
                      label="Name"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Name"
                      onChange={handleChange("name")}
                      value={values.name}
                      onBlur={handleBlur("name")}
                      autoCapitalize="none"
                      error={errors.name}
                      size="form-control-sm"
                      onChangeCapture={() => actionsSlice.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                    />
                  </div>

                  <div style={{ marginBottom: 20 }}>
                    <Input
                      label="Email"
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={handleChange("email")}
                      value={values.email}
                      onBlur={handleBlur("email")}
                      autoCapitalize="none"
                      error={errors.email}
                      size="form-control-sm"
                      onChangeCapture={() => actionsSlice.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                    />
                  </div>
                  {ManagerAndTeamAccess().includes(values.permissionLevel) && (
                    <div style={{ marginBottom: 20 }}>
                      <div>
                        <SearchWithOptions
                          label="Locations Access"
                          id="searchLocation"
                          placeholder="Search locations / areas"
                          options={locationsFilter}
                          size="sm"
                          labelKey="location_name"
                          filterBy={["location_name", "area_name"]}
                          leftIcon={<BsSearch />}
                          clearButton
                          maxWidth="100%"
                          onChange={(selected: any) => {
                            setFieldValue(
                              "locationFilter",
                              (selected && selected[0] && selected[0].location_id) || null
                            );
                          }}
                          renderMenuItemChildren={(option: any) => {
                            return (
                              <div>
                                {option.location_name}
                                {option.areas.map((area: any) => (
                                  <ul key={area.area_id}>
                                    <li>
                                      <small>{area.area_name}</small>
                                    </li>
                                  </ul>
                                ))}
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div style={{ marginTop: 10 }}>
                        <FieldArray
                          name="locations"
                          render={(arrayHelpers) => {
                            return (
                              <div>
                                {values.locations.length &&
                                  values.locations
                                    .filter((filter: any) => {
                                      let filterPass = true;
                                      if (values.locationFilter) {
                                        filterPass = values.locationFilter === filter.id;
                                      }
                                      return filterPass;
                                    })
                                    .map((location: any) => {
                                      const locationIndex = values.locations.findIndex(
                                        (obj: any) => obj.id === location.id
                                      );

                                      return (
                                        <div key={location.id}>
                                          {ManagerAccess().includes(values.permissionLevel) ? (
                                            <CheckboxLocationsAreas
                                              type="checkbox"
                                              name={`locations.${locationIndex}.activeLocations`}
                                              id={`locations.${locationIndex}.activeLocations`}
                                              value={location.name}
                                              onChangeCapture={(value: any) => {
                                                if (value.target.value === "true") {
                                                  const managerIds = values.managerLocationsIds.filter(
                                                    (id: number) => id !== location.id
                                                  );

                                                  values.managerLocationsIds = managerIds;
                                                } else {
                                                  values.managerLocationsIds.push(location.id);
                                                }
                                              }}
                                              select="location"
                                            />
                                          ) : (
                                            <p style={{ fontWeight: 500, fontSize: "110%", marginTop: 10 }}>
                                              <img
                                                className="text-secondary"
                                                src={LocationIconSvg}
                                                alt="Location"
                                                style={{
                                                  height: 20,
                                                  marginRight: "10px",
                                                  position: "relative",
                                                  top: "-2px",
                                                }}
                                              />
                                              {location.name}
                                            </p>
                                          )}

                                          {location.schedules_area.length &&
                                            location.schedules_area.map((area: any, areaIndex: number) => {
                                              const activeAreas =
                                                userDetails.users_user &&
                                                userDetails.users_user.users_businesses_areas
                                                  .map(
                                                    (location: any) =>
                                                      location.schedules_area && location.schedules_area.id
                                                  )
                                                  .includes(area.id);

                                              return (
                                                <div key={area.id} style={{ marginLeft: 20, padding: "3px 0" }}>
                                                  {TeamAccess().includes(values.permissionLevel) ? (
                                                    <CheckboxLocationsAreas
                                                      type="checkbox"
                                                      name={
                                                        activeAreas
                                                          ? `locations.${locationIndex}.schedules_area.${areaIndex}.is_active`
                                                          : `locations.${locationIndex}.schedules_area.${areaIndex}.activeArea`
                                                      }
                                                      id={
                                                        activeAreas
                                                          ? `locations.${locationIndex}.schedules_area.${areaIndex}.is_active`
                                                          : `locations.${locationIndex}.schedules_area.${areaIndex}.activeArea`
                                                      }
                                                      value={area.name}
                                                      onChangeCapture={(value: any) => {
                                                        if (value.target.value === "true") {
                                                          const teamIds = values.teamLocationsAndAreasIds.filter(
                                                            (filter: { area_id: number }) => filter.area_id !== area.id
                                                          );
                                                          values.teamLocationsAndAreasIds = teamIds;
                                                        } else {
                                                          values.teamLocationsAndAreasIds.push({
                                                            location_id: location.id,
                                                            area_id: area.id,
                                                          });
                                                        }
                                                      }}
                                                      select="area"
                                                    />
                                                  ) : (
                                                    <p style={{ fontWeight: 500, fontSize: "110%" }}>
                                                      <img
                                                        className="text-secondary"
                                                        src={LocationIconSvg}
                                                        alt="Location"
                                                        style={{
                                                          height: 20,
                                                          marginRight: "10px",
                                                          position: "relative",
                                                          top: "-2px",
                                                        }}
                                                      />
                                                      {area.name}
                                                    </p>
                                                  )}
                                                </div>
                                              );
                                            })}
                                        </div>
                                      );
                                    })}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {AccessWithoutManagerAndTeam().includes(values.permissionLevel) && (
                    <p>
                      {values.permissionLevelName} user can view a specific location, area(s) within that location and
                      playlists that are linked to those areas.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Panel>
        </Form>
      )}
    </Formik>
  );
};

/**
 * Add new user
 */
export const AddNewUser = ({ show }: UserActionsProps) => {
  const actionsSlice = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const [permissions, setPermissions] = useState<[]>([]);
  const [locations, setLocations] = useState<[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let isSubscribed = true;

    const getUserPermissionsData = async () => {
      await getUserPermissionsSearch(actionsSlice.ambieUser)
        .then((response) => {
          if (isSubscribed) {
            setPermissions(response.data);
          }
        })
        .catch((error) => {});
    };

    const getLocationsData = async () => {
      await getClientLocations(searchClient.clientId)
        .then((response) => {
          setLocations(response.data);
        })
        .catch((error) => {});
    };

    getUserPermissionsData();
    getLocationsData();
  }, [actionsSlice, searchClient, dispatch]);

  const addNewUserDetailsData = async (values: any) => {
    const data = {
      name: values.name,
      email: values.email,
      password: "Ambie123!",
      permissionLevelId: values.permissionLevelId,
      clientId: searchClient.clientId,
      locationIdsOrLocationAreaIds: ManagerAccess().includes(values.permissionLevel)
        ? values.managerLocationsIds
        : values.teamLocationsAndAreasIds,
      permissionLevel: values.permissionLevel,
    };

    dispatch(isLoadingSuccess(true));
    await addNewUser(data)
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  const permissionsLists = permissions.length
    ? permissions.map((permission: any) => ({
        value: permission.id,
        label: permission.name,
        permissionLevel: permission.permission_level,
      }))
    : [];

  const initialValues = {
    name: "",
    permissionLevelId: null,
    email: "",
    permissionLevel: "",
    permissionLevelName: "",
    managerLocationsIds: [] as any,
    teamLocationsAndAreasIds: [] as any,
    locations: locations,
    locationFilter: null,
  };

  const locationsFilter = locations.map((location: any) => ({
    location_name: location.name,
    location_id: location.id,
    areas: location.schedules_area.map((area: any) => ({
      location_id: location.id,
      location_name: location.name,
      area_id: area.id,
      area_name: area.name,
    })),
  }));

  return (
    <Formik
      form
      initialValues={initialValues}
      validationSchema={addOrEditUserForm}
      onSubmit={addNewUserDetailsData}
      enableReinitialize
    >
      {({ handleChange, handleBlur, setFieldValue, values, errors }) => (
        <Form>
          <Panel
            show={show}
            handleClose={() => {
              dispatch(hideActionsSuccess());
            }}
            title="Add New User"
            width="50%"
            maxWidth="500px"
            footer={
              <div className={panelStyles.footer}>
                <div className={panelStyles.footer_left}>
                  <GlobalButton
                    format="white"
                    size="sm"
                    onClick={() => {
                      dispatch(hideActionsSuccess());
                    }}
                  >
                    Close
                  </GlobalButton>
                </div>
                <div>
                  <GlobalButton type="submit" format="primary" size="sm">
                    Add User
                  </GlobalButton>
                </div>
              </div>
            }
          >
            <div>
              <div>
                <div>
                  {actionsSlice.actionsErrorMessage && (
                    <GlobalErrorMessage message={actionsSlice.actionsErrorMessage} />
                  )}
                </div>
                {actionsSlice.actionsIsLoading && <LoadingData />}
                <div>
                  <div style={{ marginBottom: 20 }}>
                    <SearchWithOptions
                      label="Permission"
                      placeholder="Search Permission"
                      id="permissionLevelId"
                      options={permissionsLists}
                      leftIcon={<BsSearch />}
                      labelKey="label"
                      filterBy={["label"]}
                      rightIcon={true}
                      size="sm"
                      clearButton
                      maxWidth="100%"
                      maxResults={30}
                      tooltipIcon={false}
                      tooltipMessage="Message about the permission to be display here.."
                      onChange={(selected: any) => {
                        if (selected && selected[0]) {
                          setFieldValue(`permissionLevelId`, selected[0].value);
                          setFieldValue("permissionLevel", selected[0].permissionLevel);
                          setFieldValue("permissionLevelName", selected[0].label);
                        }
                        if (!selected.length) {
                          setFieldValue(`permissionLevelId`, null);
                          setFieldValue("permissionLevel", null);
                          setFieldValue("permissionLevelName", null);
                        }
                      }}
                      renderMenuItemChildren={(option: any) => {
                        return (
                          <div style={{ whiteSpace: "pre-wrap" }}>
                            <span style={{ fontWeight: "bold" }}>{option.label}</span>
                            {getPermissionLevelContent(option.permissionLevel)}
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div style={{ marginBottom: 20 }}>
                    <Input
                      label="Name"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Name"
                      onChange={handleChange("name")}
                      value={values.name}
                      onBlur={handleBlur("name")}
                      autoCapitalize="none"
                      error={errors.name}
                      size="form-control-sm"
                      onChangeCapture={() => actionsSlice.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                    />
                  </div>

                  <div style={{ marginBottom: 20 }}>
                    <Input
                      label="Email"
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={handleChange("email")}
                      value={values.email}
                      onBlur={handleBlur("email")}
                      autoCapitalize="none"
                      error={errors.email}
                      size="form-control-sm"
                      onChangeCapture={() => actionsSlice.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                    />
                  </div>
                  {ManagerAndTeamAccess().includes(values.permissionLevel) && (
                    <div style={{ marginBottom: 20 }}>
                      <div>
                        <SearchWithOptions
                          label="Locations Access"
                          id="searchLocation"
                          placeholder="Search locations / areas"
                          options={locationsFilter}
                          size="sm"
                          labelKey="location_name"
                          filterBy={["location_name", "area_name"]}
                          leftIcon={<BsSearch />}
                          maxWidth="100%"
                          clearButton
                          onChange={(selected: any) => {
                            setFieldValue(
                              "locationFilter",
                              (selected && selected[0] && selected[0].location_id) || null
                            );
                          }}
                          renderMenuItemChildren={(option: any) => {
                            return (
                              <div>
                                {option.location_name}
                                {option.areas.map((area: any) => (
                                  <ul key={area.area_id}>
                                    <li>
                                      <small>{area.area_name}</small>
                                    </li>
                                  </ul>
                                ))}
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div style={{ marginTop: 10 }}>
                        <FieldArray
                          name="locations"
                          render={(arrayHelpers) => {
                            return (
                              <div>
                                {values.locations.length &&
                                  values.locations
                                    .filter((filter: any) => {
                                      let filterPass = true;
                                      if (values.locationFilter) {
                                        filterPass = values.locationFilter === filter.id;
                                      }
                                      return filterPass;
                                    })
                                    .map((location: any) => {
                                      const locationIndex = values.locations.findIndex(
                                        (obj: any) => obj.id === location.id
                                      );

                                      return (
                                        <div key={location.id}>
                                          {ManagerAccess().includes(values.permissionLevel) ? (
                                            <>
                                              <CheckboxLocationsAreas
                                                type="checkbox"
                                                name={`locations.${locationIndex}.activeLocations`}
                                                id={`locations.${locationIndex}.activeLocations`}
                                                value={location.name}
                                                onChangeCapture={(value: any) => {
                                                  if (value.target.value === "true") {
                                                    const managerIds = values.managerLocationsIds.filter(
                                                      (id: number) => id !== location.id
                                                    );

                                                    values.managerLocationsIds = managerIds;
                                                  } else {
                                                    values.managerLocationsIds.push(location.id);
                                                  }
                                                }}
                                                select="location"
                                              />
                                            </>
                                          ) : (
                                            <p
                                              style={{
                                                fontWeight: 500,
                                                fontSize: "110%",
                                                marginBottom: 5,
                                                marginTop: 10,
                                              }}
                                            >
                                              <img
                                                className="text-secondary"
                                                src={LocationIconSvg}
                                                alt="Location"
                                                style={{
                                                  height: 20,
                                                  marginRight: "10px",
                                                  position: "relative",
                                                  top: "-2px",
                                                }}
                                              />
                                              {location.name}
                                            </p>
                                          )}

                                          {location.schedules_area.length &&
                                            location.schedules_area.map((area: any, areaIndex: number) => {
                                              return (
                                                <div key={area.id} style={{ marginLeft: 28, padding: "2px 0" }}>
                                                  {TeamAccess().includes(values.permissionLevel) ? (
                                                    <>
                                                      <CheckboxLocationsAreas
                                                        type="checkbox"
                                                        name={`locations.${locationIndex}.schedules_area.${areaIndex}.status`}
                                                        id={`locations.${locationIndex}.schedules_area.${areaIndex}.status`}
                                                        value={area.name}
                                                        onChangeCapture={(value: any) => {
                                                          if (value.target.value === "true") {
                                                            const teamIds = values.teamLocationsAndAreasIds.filter(
                                                              (filter: { area_id: number }) =>
                                                                filter.area_id !== area.id
                                                            );
                                                            values.teamLocationsAndAreasIds = teamIds;
                                                          } else {
                                                            values.teamLocationsAndAreasIds.push({
                                                              location_id: location.id,
                                                              area_id: area.id,
                                                            });
                                                          }
                                                        }}
                                                        select="area"
                                                      />
                                                    </>
                                                  ) : (
                                                    <p style={{ fontWeight: 500, fontSize: "110%", marginBottom: 8 }}>
                                                      <img
                                                        className="text-secondary"
                                                        src={DeviceIconSvg}
                                                        alt="Device"
                                                        style={{
                                                          height: 20,
                                                          marginRight: "10px",
                                                          position: "relative",
                                                          top: "-2px",
                                                        }}
                                                      />
                                                      {area.name}
                                                    </p>
                                                  )}
                                                </div>
                                              );
                                            })}
                                        </div>
                                      );
                                    })}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {AccessWithoutManagerAndTeam().includes(values.permissionLevel) && (
                    <p>
                      {values.permissionLevelName} user can view a specific location, area(s) within that location and
                      playlists that are linked to those areas.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Panel>
        </Form>
      )}
    </Formik>
  );
};

/**
 * Reset user password
 */
export const ResetUserPassword = ({ footer, show, handleClose }: UserActionsProps) => {
  const actionsSlice = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const userDetails = useSelector((state: RootState) => state.authSlice.userDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    const getUserData = async () => {
      if (actionsSlice.user.id) {
        await getUser(actionsSlice.user.id)
          .then((response) => {
            dispatch(userDetailsSuccess({ userDetails: response.data }));
          })
          .catch((error) => {});
      }
    };

    getUserData();
  }, [actionsSlice, searchClient, dispatch]);

  const resetPasswordData = async (token: string, values: any) => {
    dispatch(isLoadingSuccess(true));
    await resetPassword(token, values.password, values.password_confirm, actionsSlice.user.email)
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  const generateToken = async (values: any) => {
    await requestPasswordReset(actionsSlice.user.email, "no-email")
      .then((response) => {
        const token = response && response.data && response.data.accessToken;
        resetPasswordData(token, values);
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  return (
    <GlobalModal
      title={`Reset ${(userDetails.users_user && userDetails.users_user.name) || "Unknown"} user password`}
      show={show}
      handleClose={handleClose}
    >
      <div className={styles.modal}>
        <Formik
          form
          initialValues={{ password: "", password_confirm: "", isEnabled: false }}
          validationSchema={resetPasswordform}
          onSubmit={generateToken}
          enableReinitialize
        >
          {({ handleChange, handleBlur, setFieldValue, values, errors }) => (
            <Form>
              <div>
                <div>
                  {actionsSlice.actionsErrorMessage && (
                    <GlobalErrorMessage message={actionsSlice.actionsErrorMessage} />
                  )}
                </div>
                {actionsSlice.actionsIsLoading && <LoadingData />}
                <div>
                  <Input
                    placeholder="New password"
                    name="password"
                    id="password"
                    type={values.isEnabled ? "text" : "password"}
                    onBlur={handleBlur("password")}
                    onChange={handleChange("password")}
                    error={errors.password}
                    //testID="username"
                    onChangeCapture={() => actionsSlice.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                  />
                  <Input
                    placeholder="Confirm new password"
                    id="password_confirm"
                    name="password_confirm"
                    type={values.isEnabled ? "text" : "password"}
                    onBlur={handleBlur("password_confirm")}
                    autoCapitalize="none"
                    onChange={handleChange("password_confirm")}
                    error={errors.password_confirm}
                    //testID="password_confirm"
                    onChangeCapture={() => actionsSlice.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                  />

                  <Toggle
                    type="checkbox"
                    name="isEnabled"
                    id="isEnabled"
                    label={values.isEnabled ? "HIDE PASSWORD" : "SHOW PASSWORD"}
                    rightLabel
                    onChange={(value: any) => {
                      const targetValue = value.target && value.target.value === "true" ? false : true;
                      setFieldValue(`isEnabled`, targetValue);
                    }}
                  />
                </div>

                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GlobalModal>
  );
};

/**
 * Edit user name
 */
export const EditUserName = ({ footer, show, handleClose }: UserActionsProps) => {
  const actionsSlice = useSelector((state: RootState) => state.actionsSlice);
  const userDetails = useSelector((state: RootState) => state.authSlice.userDetails);

  const dispatch = useDispatch();
  // const socket = useContext(WebSocketContext);

  useEffect(() => {
    const getUserData = async () => {
      if (actionsSlice.user.id) {
        await getUser(actionsSlice.user.id)
          .then((response) => {
            dispatch(userDetailsSuccess({ userDetails: response.data }));
          })
          .catch((error) => {});
      }
    };

    getUserData();

    return () => {
      // socket.off("updateUser", (payload: { id: number }) => {
      //   if (payload.id === actionsSlice.user.id) getUserData();
      // });
    };
  }, [actionsSlice.user, dispatch]);

  const EditUserNameData = async (values: any) => {
    dispatch(isLoadingSuccess(true));
    await updateUserRecord(actionsSlice.user.id, { name: values.name })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  return (
    <GlobalModal title={`Add User Name`} show={show} handleClose={handleClose}>
      <div className={styles.modal}>
        <Formik
          form
          initialValues={{ name: (userDetails.users_user && userDetails.users_user.name) || "" }}
          validationSchema={renameUserForm}
          onSubmit={EditUserNameData}
          enableReinitialize
        >
          {({ handleChange, handleBlur, values, errors }) => (
            <Form>
              <div>
                <div>
                  {actionsSlice.actionsErrorMessage && (
                    <GlobalErrorMessage message={actionsSlice.actionsErrorMessage} />
                  )}
                </div>
                {actionsSlice.actionsIsLoading && <LoadingData />}
                <div>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="User name"
                    onChange={handleChange("name")}
                    value={values.name}
                    onBlur={handleBlur("name")}
                    autoCapitalize="none"
                    error={errors.name}
                    size="form-control-sm"
                    onChangeCapture={() => actionsSlice.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                  />
                </div>

                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GlobalModal>
  );
};

/**
 * Change business email address
 */
export const ChangeUserEmailAddress = ({ footer, show, handleClose }: UserActionsProps) => {
  const actionsSlice = useSelector((state: RootState) => state.actionsSlice);
  const userDetails = useSelector((state: RootState) => state.authSlice.userDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    const getUserData = async () => {
      if (actionsSlice.user.id) {
        await getUser(actionsSlice.user.id)
          .then((response) => {
            dispatch(userDetailsSuccess({ userDetails: response.data }));
          })
          .catch((error) => {});
      }
    };

    getUserData();
  }, [actionsSlice.user, dispatch]);

  const updateEmailAddressData = async (values: any) => {
    dispatch(isLoadingSuccess(true));
    await updateUserRecord(actionsSlice.user.id, { email: values.email })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  return (
    <GlobalModal title={`Change User Email Address`} show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <Formik
          form
          initialValues={{ email: (userDetails.users_user && userDetails.users_user.email) || "" }}
          validationSchema={forgotPasswordForm}
          onSubmit={updateEmailAddressData}
        >
          {({ handleChange, handleBlur, values, errors }) => (
            <Form>
              <div>
                {/* <p>Change {location && location.name} email address</p> */}
                <div>
                  {actionsSlice.actionsErrorMessage && (
                    <GlobalErrorMessage message={actionsSlice.actionsErrorMessage} />
                  )}
                </div>
                {actionsSlice.actionsIsLoading && <LoadingData />}
                <div>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={handleChange("email")}
                    value={values.email}
                    onBlur={handleBlur("email")}
                    autoCapitalize="none"
                    error={errors.email}
                    size="form-control-sm"
                    onChangeCapture={() => actionsSlice.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                  />
                </div>

                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GlobalModal>
  );
};

/**
 * Send password reset link to the business email address
 */
export const SendUserPasswordResetEmail = ({ footer, show, user, handleClose }: UserActionsProps) => {
  const actionsSlice = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Send Password Reset Email" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Do you want to send a password reset link to <b>{user && user.email}</b> email address?
        </p>
        <div>
          {actionsSlice.actionsErrorMessage && <GlobalErrorMessage message={actionsSlice.actionsErrorMessage} />}
        </div>
        {actionsSlice.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Generate reset password link
 */
export const GenerateSetUserPasswordLink = ({ footer, show, user, handleClose }: UserActionsProps) => {
  const actionsSlice = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Generate Set Password Link" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Do you want to generate a password reset link for <b>{user && user.name}</b>?
        </p>
        <div>
          {actionsSlice.actionsErrorMessage && <GlobalErrorMessage message={actionsSlice.actionsErrorMessage} />}
        </div>
        {actionsSlice.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Copy a generated reset password link
 */
export const CopyGenerateSetUserPasswordLink = ({ footer, link, show, handleClose }: UserActionsProps) => {
  const actionsSlice = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Generate Set User Password Link" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <div>
          {actionsSlice.actionsSuccessMessage && <SuccessMessage message={actionsSlice.actionsSuccessMessage} />}
        </div>
        <p className={styles.generatedPasswordLink}>{link}</p>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Deactivate location
 */
export const DeactivateUser = ({ footer, show, user, handleClose }: UserActionsProps) => {
  return (
    <GlobalModal title="Deactivate User" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Are you sure you want to deactivate <span style={{ fontWeight: "bold" }}>{user && user.name}</span> user?
        </p>

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Activate location
 */
export const ActivateUser = ({ footer, show, user, handleClose }: UserActionsProps) => {
  return (
    <GlobalModal title="Deactivate User" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Are you sure you want to activate <span style={{ fontWeight: "bold" }}>{user && user.name}</span> user?
        </p>

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

const getPermissionLevelContent = (permissionLevel: string) => {
  if (permissionLevel === "MANAGER") {
    return (
      <div>
        <span>
          Can view a specific location, area(s) within that location and playlists that are linked to those areas.
        </span>
      </div>
    );
  } else if (permissionLevel === "TEAM") {
    return (
      <div>
        <span>Can view and control a specific area and can view playlists linked to that area.</span>
      </div>
    );
  } else {
    return (
      <div>
        <span>Can view, edit all locations, areas, playlists.</span>
      </div>
    );
  }
};

export const SwitchUserClient = ({ footer, show, user, handleClose }: UserActionsProps) => {
  const actionsSlice = useSelector((state: RootState) => state.actionsSlice);
  const clientsSlice = useSelector((state: RootState) => state.authSlice.clients);

  const dispatch = useDispatch();

  useEffect(() => {
    const getClientsData = async () => {
      await getClients();
    };

    getClientsData();
  }, []);

  const clientsOptions = clientsSlice.map((client: any) => ({
    label: client.name,
    value: client.id,
  }));

  const switchClientData = async (values: any) => {
    dispatch(isLoadingSuccess(true));
    await switchUserClient(actionsSlice.user.id, { client_id: values.clientId })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  return (
    <GlobalModal title={`Switch ${user?.name || "Unknown"} user`} show={show} handleClose={handleClose}>
      <div className={styles.modal}>
        <Formik
          form
          enableReinitialize
          initialValues={{ clientId: actionsSlice.switchClient.clientId, clientName: "" }}
          onSubmit={switchClientData}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div>
                <div>
                  {actionsSlice.actionsErrorMessage && (
                    <GlobalErrorMessage message={actionsSlice.actionsErrorMessage} />
                  )}
                </div>
                {actionsSlice.actionsIsLoading && <LoadingData />}
                {actionsSlice.switchClient.clientId !== values.clientId && (
                  <p style={{ color: "red" }}>
                    You are about to switch {user?.name || user?.email} to {values.clientName} client
                  </p>
                )}
                <div>
                  <Select
                    id="audioSetting"
                    className="flex-grow-4 time-select channel-list"
                    options={clientsOptions}
                    value={clientsOptions.find((c: any) => c.value === values.clientId)}
                    onChange={(selected) => {
                      if (selected?.value) {
                        setFieldValue("clientId", selected.value);
                        setFieldValue("clientName", selected.label);
                      }
                    }}
                  />
                </div>

                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GlobalModal>
  );
};

export const UsersModal = () => {
  const actionsSlice = useSelector((state: RootState) => state.actionsSlice);
  const dispatch = useDispatch();

  return (
    <>
      {/* User action */}
      <CSSTransition
        in={actionsSlice.editUserDetails}
        timeout={100}
        classNames="panel-animate"
        onEnter={() => document.body.classList.add("css-transition-modal-open")}
        onExited={() => document.body.classList.remove("css-transition-modal-open")}
        unmountOnExit={true}
        mountOnEnter={true}
        onExit={() => dispatch(hideActionsSuccess())}
      >
        <EditUserDetails show={actionsSlice.editUserDetails} />
      </CSSTransition>

      <CSSTransition
        in={actionsSlice.addNewUser}
        timeout={100}
        classNames="panel-animate"
        onEnter={() => document.body.classList.add("css-transition-modal-open")}
        onExited={() => document.body.classList.remove("css-transition-modal-open")}
        unmountOnExit={true}
        mountOnEnter={true}
        onExit={() => dispatch(hideActionsSuccess())}
      >
        <AddNewUser show={actionsSlice.addNewUser} />
      </CSSTransition>

      {actionsSlice.switchClient.status && (
        <SwitchUserClient
          show={actionsSlice.switchClient.status}
          handleClose={() => dispatch(hideActionsSuccess())}
          user={actionsSlice.user}
          footer={
            <>
              <div className={styles.flexRowWrapModalFooter}>
                <div className={styles.footerLeft}>
                  <GlobalButton
                    format="white"
                    size="sm"
                    disabled={actionsSlice.actionsIsLoading ? true : false}
                    onClick={() => dispatch(hideActionsSuccess())}
                  >
                    Cancel
                  </GlobalButton>
                </div>
                <div>
                  <GlobalButton
                    disabled={actionsSlice.actionsIsLoading ? true : false}
                    format="success"
                    type="submit"
                    size="sm"
                  >
                    Submit
                  </GlobalButton>
                </div>
              </div>
            </>
          }
        />
      )}

      {actionsSlice.resetUserPassword && (
        <ResetUserPassword
          show={actionsSlice.resetUserPassword}
          user={actionsSlice.user}
          handleClose={() => dispatch(hideActionsSuccess())}
          footer={
            <div className={styles.flexRowWrapModalFooter}>
              <div className={styles.footerLeft}>
                <GlobalButton
                  format="white"
                  size="sm"
                  disabled={actionsSlice.actionsIsLoading ? true : false}
                  onClick={() => dispatch(hideActionsSuccess())}
                >
                  Cancel
                </GlobalButton>
              </div>
              <div>
                <GlobalButton
                  disabled={actionsSlice.actionsIsLoading ? true : false}
                  format="success"
                  type="submit"
                  size="sm"
                >
                  Submit
                </GlobalButton>
              </div>
            </div>
          }
        />
      )}

      {actionsSlice.editUserNameDetails && (
        <EditUserName
          show={actionsSlice.editUserNameDetails}
          handleClose={() => dispatch(hideActionsSuccess())}
          footer={
            <div className={styles.flexRowWrapModalFooter}>
              <div className={styles.footerLeft}>
                <GlobalButton
                  format="white"
                  size="sm"
                  disabled={actionsSlice.actionsIsLoading ? true : false}
                  onClick={() => dispatch(hideActionsSuccess())}
                >
                  Cancel
                </GlobalButton>
              </div>
              <div>
                <GlobalButton
                  disabled={actionsSlice.actionsIsLoading ? true : false}
                  format="success"
                  type="submit"
                  size="sm"
                >
                  Submit
                </GlobalButton>
              </div>
            </div>
          }
        />
      )}

      {actionsSlice.changeUserEmail && (
        <ChangeUserEmailAddress
          show={actionsSlice.changeUserEmail}
          handleClose={() => dispatch(hideActionsSuccess())}
          footer={
            <div className={styles.flexRowWrapModalFooter}>
              <div className={styles.footerLeft}>
                <GlobalButton
                  format="white"
                  size="sm"
                  disabled={actionsSlice.actionsIsLoading ? true : false}
                  onClick={() => dispatch(hideActionsSuccess())}
                >
                  Cancel
                </GlobalButton>
              </div>
              <div>
                <GlobalButton
                  format="success"
                  disabled={actionsSlice.actionsIsLoading ? true : false}
                  type="submit"
                  size="sm"
                >
                  Submit
                </GlobalButton>
              </div>
            </div>
          }
        />
      )}

      {actionsSlice.sendUserPasswordReset && (
        <SendUserPasswordResetEmail
          show={actionsSlice.sendUserPasswordReset}
          user={actionsSlice.user}
          handleClose={() => dispatch(hideActionsSuccess())}
          footer={
            <>
              <div className={styles.flexRowWrapModalFooter}>
                <div className={styles.footerLeft}>
                  <GlobalButton
                    format="white"
                    size="sm"
                    disabled={actionsSlice.actionsIsLoading ? true : false}
                    onClick={() => dispatch(hideActionsSuccess())}
                  >
                    No
                  </GlobalButton>
                </div>
                <div>
                  <GlobalButton
                    format="success"
                    disabled={actionsSlice.actionsIsLoading ? true : false}
                    onClick={() =>
                      sendOrGenerateUserPasswordResetEmail(actionsSlice.user, actionsSlice.generateSetUserPassword)
                    }
                    size="sm"
                  >
                    Yes
                  </GlobalButton>
                </div>
              </div>
            </>
          }
        />
      )}
      {actionsSlice.generateSetUserPassword && (
        <GenerateSetUserPasswordLink
          show={actionsSlice.generateSetUserPassword}
          user={actionsSlice.user}
          handleClose={() => dispatch(hideActionsSuccess())}
          footer={
            <>
              <div className={styles.flexRowWrapModalFooter}>
                <div className={styles.footerLeft}>
                  <GlobalButton
                    format="white"
                    disabled={actionsSlice.actionsIsLoading ? true : false}
                    size="sm"
                    onClick={() => dispatch(hideActionsSuccess())}
                  >
                    No
                  </GlobalButton>
                </div>
                <div>
                  <GlobalButton
                    format="success"
                    disabled={actionsSlice.actionsIsLoading ? true : false}
                    onClick={() =>
                      sendOrGenerateUserPasswordResetEmail(actionsSlice.user, actionsSlice.generateSetUserPassword)
                    }
                    size="sm"
                  >
                    Yes
                  </GlobalButton>
                </div>
              </div>
            </>
          }
        />
      )}
      {actionsSlice.copyGenerateSetPassword && (
        <CopyGenerateSetUserPasswordLink
          show={actionsSlice.copyGenerateSetPassword}
          link={actionsSlice.link}
          handleClose={() => dispatch(hideActionsSuccess())}
          footer={
            <>
              <div className={styles.flexRowWrapModalFooter}>
                <div className={styles.footerLeft}>
                  <GlobalButton
                    format="white"
                    disabled={actionsSlice.actionsIsLoading ? true : false}
                    size="sm"
                    onClick={() => dispatch(hideActionsSuccess())}
                  >
                    Close
                  </GlobalButton>
                </div>
                <div>
                  <CopyToClipboard text={`${actionsSlice.link}`}>
                    <GlobalButton
                      format="success"
                      disabled={actionsSlice.actionsIsLoading ? true : false}
                      onClick={() => dispatch(showSuccessMessageSuccess("Copied, you can now close the mobal"))}
                      size="sm"
                    >
                      Copy
                    </GlobalButton>
                  </CopyToClipboard>
                </div>
              </div>
            </>
          }
        />
      )}
      {actionsSlice.deactivateUser && (
        <DeactivateUser
          show={actionsSlice.deactivateUser}
          user={actionsSlice.user}
          handleClose={() => dispatch(hideActionsSuccess())}
          footer={
            <>
              <div className={styles.flexRowWrapModalFooter}>
                <div className={styles.footerLeft}>
                  <GlobalButton
                    format="white"
                    disabled={actionsSlice.actionsIsLoading ? true : false}
                    size="sm"
                    onClick={() => dispatch(hideActionsSuccess())}
                  >
                    No
                  </GlobalButton>
                </div>
                <div>
                  <GlobalButton
                    format="success"
                    disabled={actionsSlice.actionsIsLoading ? true : false}
                    onClick={() => deactivateUser(actionsSlice.user)}
                    size="sm"
                  >
                    Yes
                  </GlobalButton>
                </div>
              </div>
            </>
          }
        />
      )}

      {actionsSlice.activateUser && (
        <ActivateUser
          show={actionsSlice.activateUser}
          user={actionsSlice.user}
          handleClose={() => dispatch(hideActionsSuccess())}
          footer={
            <>
              <div className={styles.flexRowWrapModalFooter}>
                <div className={styles.footerLeft}>
                  <GlobalButton
                    format="white"
                    disabled={actionsSlice.actionsIsLoading ? true : false}
                    size="sm"
                    onClick={() => dispatch(hideActionsSuccess())}
                  >
                    No
                  </GlobalButton>
                </div>
                <div>
                  <GlobalButton
                    format="success"
                    disabled={actionsSlice.actionsIsLoading ? true : false}
                    onClick={() => activateUser(actionsSlice.user)}
                    size="sm"
                  >
                    Yes
                  </GlobalButton>
                </div>
              </div>
            </>
          }
        />
      )}
    </>
  );
};
