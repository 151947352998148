export const ScheduleCurrentBar = (props: any) => {
  const hourHeight = props.hourHeight || 80;
  const hoursOffset = props.timeNow.hour();
  const minutesOffset = props.timeNow.minutes();
  const barTopOffset = hourHeight * hoursOffset + (minutesOffset / 60) * hourHeight;

  const barStyle = {
    top: barTopOffset,
  };

  const currentDayIndex = props.timeNow.isoWeekday();

  const barCurDayStyle: any = {
    "--dayNumber": currentDayIndex,
  };

  return (
    <div style={barStyle} className="schedule-current-bar">
      <span className="schedule-current-bar-marker" style={barCurDayStyle}></span>
    </div>
  );
};
