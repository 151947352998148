import React, { useState, useEffect, useRef } from "react";

import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Tab, Tabs } from "react-bootstrap";
import {
  BsDownload,
  BsPlayFill,
  BsPauseFill,
  BsPlusCircle,
  BsTrash,
  BsVolumeMuteFill,
  BsVolumeUpFill,
  BsXCircle,
  BsSearch,
} from "react-icons/bs";

// style components
import { GlobalButton } from "../../../../components/button";
import { KeyValueCard, MiniCard, RowCard } from "../../../../components/cards";
import { Checkbox } from "../../../../components/fields/checkbox";
import { DateRangePicker, SingleDatePicker } from "../../../../components/fields/datePicker";
import { Input } from "../../../../components/fields/input";
import { SearchWithOptions } from "../../../../components/fields/search";
import { Select } from "../../../../components/fields/select";
import { Toggle } from "../../../../components/fields/toggle";
import { GlobalModal } from "../../../../components/modal";
import { AgGridReactTable } from "../../../../components/table";
import { RootState } from "../../../../redux-toolkit/store";
import {
  hideActionsSuccess,
  isLoadingSuccess,
  showErrorMessageSuccess,
  showSubmissionErrorSuccess,
  showSavePlaylistSuccess,
  showEditPlaylistSuccess,
} from "../../../../redux-toolkit/reducers/actionsSlice";
import {
  createPlaylist,
  getPlaylistTracks,
  getPlaylistRotationDetails,
  getPlaylist,
  updatePgAndMongoPlaylist,
} from "../../../../services/playlistsServices";
import { LoadingData } from "../../../../components/loading";
import { GlobalErrorMessage } from "../../../users/components/errorMessage";
import { Textarea } from "../../../../components/fields/texarea";
import moment from "moment";
import { playlistSubmitionForm } from "../../../../components/formValidation/formValidation";
import {
  getCoreChannelsListForTackInjectionModal,
  getCoreChannelTracks,
} from "../../../../services/core-ChannelsServices";
import { Slider } from "antd";
import styles from "./modal.module.scss";
import locationsStyles from "../../../locations/locations.module.scss";
import {
  clearPlaylistSuccess,
  playlistTracksSuccess,
  playlistTrackSuccess,
} from "../../../../redux-toolkit/reducers/playlistsSlice";
import { intervalToDuration } from "date-fns";
import { ACTIONS_ERROR_MESSAGE } from "../../../../constants/globalText";
import { HandleExport } from "../../../../components/csv/export";
import { getClientsList } from "../../../../services/clientsServices";
import { playlists_page_navigate } from "../../../../constants/routePath";
import { useNavigate } from "react-router-dom";

interface PlaylistModalProps {
  footer?: any;
  title?: string;
  show: boolean;
  handleClose: any;
  messages?: [];
}

export const PlaylistModal = () => {
  const [coreChannels, setCoreChannels] = useState<[]>([]);
  const [playlistTracks, setPlaylistTracks] = useState<[]>([]);
  const [coreChannelSelected, setCoreChannelSelected] = useState<any>("");
  const [hasCompositionError, setHasCompositionError] = useState("");
  const [hasClientBusinessError, setHasClientBusinessError] = useState("");
  const [playlist, setPlaylist] = useState<any>("");
  const [filterTrack, setFilterTrack] = useState<any>("");
  const [refresh, setRefresh] = useState<boolean>(false);
  const [currentTime, setcurrentTime] = useState<number>(0);
  const [playlistDuration, setPlaylistDuration] = useState<number>(0);
  const [clearSelected, setClearSelected] = useState<boolean>(false);
  const [tracksInRotation, setTracksInRotation] = useState<{ total: number; lists: [] }>({ total: 0, lists: [] });
  const [createOrUpdate, setCreateOrUpdate] = useState<boolean>(false);

  const actions = useSelector((state: RootState) => state.actionsSlice);
  const playlists = useSelector((state: RootState) => state.playlistsSlice);
  const authSlice = useSelector((state: RootState) => state.authSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const audioElem: any = useRef();

  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  let totalPercentage = 0;
  let compositionLength = 0;
  let clientBusinessesLength = 0;
  let clientsLength = 0;
  let playlistErrorMessages: any = [];

  useEffect(() => {
    let isSubscribed = true;

    const getPlaylistDetailsData = async () => {
      await getPlaylist(searchClient.clientId, actions.editPlaylist && actions.editPlaylist.playlistId)
        .then((response) => {
          if (isSubscribed) {
            !createOrUpdate && dispatch(isLoadingSuccess(false));
            setPlaylist(response.data && response.data.playlist);
            setPlaylistDuration((response.data && response.data.playlist && response.data.playlist.duration) || 0);
          }
        })
        .catch((error) => {
          dispatch(isLoadingSuccess(false));
          throw new Error(error);
        });
    };

    const getPlaylistTracksData = async () => {
      await getPlaylistTracks(actions.editPlaylist && actions.editPlaylist.playlistId)
        .then((response) => {
          if (isSubscribed) {
            setPlaylistTracks(response.data && response.data.tracks);
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    };

    const getPlaylistRotationDetailsData = async () => {
      await getPlaylistRotationDetails(actions.editPlaylist && actions.editPlaylist.playlistId)
        .then((response) => {
          if (isSubscribed) {
            const tracksInRotation =
              (response.data && response.data.rotation && response.data.rotation.out_tracks) || [];

            const combinedArray = tracksInRotation.reduce((track: any, array: any) => track.concat(array.tracks), []);

            setTracksInRotation({ total: combinedArray.length, lists: combinedArray });
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    };

    const getCoreChannelsListData = async () => {
      await getCoreChannelsListForTackInjectionModal()
        .then((response) => {
          if (isSubscribed) {
            setCoreChannels(response.data);
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    };

    const getClientsListData = async () => {
      await getClientsList();
    };

    getCoreChannelsListData();
    getClientsListData();

    if (
      actions.editPlaylist &&
      actions.editPlaylist.playlistId &&
      actions.editPlaylist.status &&
      !actions.submissionError.status &&
      !refresh
    ) {
      getPlaylistDetailsData();
      getPlaylistTracksData();
      getPlaylistRotationDetailsData();
    } else if (actions.editPlaylist && actions.addPlaylist && !actions.submissionError.status && !refresh) {
      setPlaylistTracks([]);
      setPlaylist({
        name: "",
        description: "",
        ambience: "",
        colour_id: 1,
        cover: "",
        cover_thumb: "",
        // allTheRotations: [],
        composition: [],
        viewableBy: {
          businesses: [],
          clients: [],
        },
        rotationRules: {
          recurrence: "biweekly",
          value: 10,
          start: Date.now(),
          active: false,
        },
        energy_level: "",
        featured_artists: "",
        rules: {
          artist: [],
          category: [],
          tracks: [],
          commercial: [],
        },
        tracks: [],
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [searchClient, actions, filterTrack, refresh, playlists, playlistDuration, dispatch, createOrUpdate]);

  const goToPlaylists = () => {
    navigate(playlists_page_navigate);
  };

  const updateOrAddPlaylistData = async (values: any) => {
    const commercialRules = values.rules?.commercial;
    const errors: any = {};
    const emptyDateErrors: any = {};
    const emptyRepetitionErrors: any = {};
    const emptyCoreChannelErrors: any = {};
    if (commercialRules?.length) {
      for (let i = 0; i < commercialRules.length; i++) {
        for (let j = i + 1; j < commercialRules.length; j++) {
          if (
            commercialRules[i].startDate <= commercialRules[j].endDate &&
            commercialRules[i].endDate >= commercialRules[j].startDate
          ) {
            if (!errors.rules) {
              errors.rules = [];
            }
            if (!errors.rules[i]) {
              errors.rules[i] = {};
            }
            errors.rules[i].startDate = commercialRules[i];
            if (!errors.rules[j]) {
              errors.rules[j] = {};
            }
            errors.rules[j].startDate = commercialRules[j];
          }

          if (
            !commercialRules[i].startDate ||
            !commercialRules[j].endDate ||
            !commercialRules[i].endDate ||
            !commercialRules[j].startDate
          ) {
            if (!emptyDateErrors.rules) {
              emptyDateErrors.rules = [];
            }
            if (!emptyDateErrors.rules[i]) {
              emptyDateErrors.rules[i] = {};
            }
            emptyDateErrors.rules[i].startDate = commercialRules[i];
            if (!emptyDateErrors.rules[j]) {
              emptyDateErrors.rules[j] = {};
            }
            emptyDateErrors.rules[j].startDate = commercialRules[j];
          }

          if (!commercialRules[i].repetition || !commercialRules[j].repetition) {
            if (!emptyRepetitionErrors.rules) {
              emptyRepetitionErrors.rules = [];
            }
            if (!emptyRepetitionErrors.rules[i]) {
              emptyRepetitionErrors.rules[i] = {};
            }
            emptyRepetitionErrors.rules[i].startDate = commercialRules[i];
            if (!emptyRepetitionErrors.rules[j]) {
              emptyRepetitionErrors.rules[j] = {};
            }
            emptyRepetitionErrors.rules[j].startDate = commercialRules[j];
          }

          if (!commercialRules[i].core_channel_id || !commercialRules[j].core_channel_id) {
            if (!emptyCoreChannelErrors.rules) {
              emptyCoreChannelErrors.rules = [];
            }
            if (!emptyCoreChannelErrors.rules[i]) {
              emptyCoreChannelErrors.rules[i] = {};
            }
            emptyCoreChannelErrors.rules[i].startDate = commercialRules[i];
            if (!emptyCoreChannelErrors.rules[j]) {
              emptyCoreChannelErrors.rules[j] = {};
            }
            emptyCoreChannelErrors.rules[j].startDate = commercialRules[j];
          }
        }
      }
    }
    if (
      totalPercentage !== 100 ||
      !compositionLength ||
      !clientBusinessesLength ||
      !clientsLength ||
      errors?.rules?.length ||
      emptyDateErrors?.rules?.length ||
      emptyRepetitionErrors?.rules?.length ||
      (values.rotationRules &&
        (values.rotationRules.recurrence || values.rotationRules.start) &&
        !values.rotationRules.value) ||
      (values.rotationRules &&
        values.rotationRules.active &&
        (!values.rotationRules.recurrence || !values.rotationRules.start || !values.rotationRules.value))
    ) {
      // check if the total percentage is 100
      if (totalPercentage !== 100) {
        playlistErrorMessages.push({
          message: "The maximun and minimun percentage must be 100 in [CHANNEL SECTION]",
        });
      }
      if (errors?.rules?.length) {
        playlistErrorMessages.push({
          message: "Commercial rules have overlapping dates in [COMMERCIAL RULES SECTION]",
        });
      }

      if (emptyDateErrors?.rules?.length) {
        playlistErrorMessages.push({
          message: "Commercial rules start or end date cannot be empty [COMMERCIAL RULES SECTION]",
        });
      }

      if (emptyRepetitionErrors?.rules?.length) {
        playlistErrorMessages.push({
          message: "Commercial rules repetition cannot be empty [COMMERCIAL RULES SECTION]",
        });
      }

      if (emptyCoreChannelErrors?.rules?.length) {
        playlistErrorMessages.push({
          message: "Commercial rules core channel cannot be empty [COMMERCIAL RULES SECTION]",
        });
      }
      // check componsition length
      if (!compositionLength) {
        playlistErrorMessages.push({ message: "At least one channel is required in [CHANNEL SECTION]" });
      }
      // check componsition length
      if (!clientBusinessesLength || !clientsLength) {
        playlistErrorMessages.push({
          message: "At least one location is required in [AVAILABLE FOR SECTION]",
        });
      }
      // check rotation rules
      if (
        values.rotationRules &&
        values.rotationRules.active &&
        (!values.rotationRules.recurrence || !values.rotationRules.start || !values.rotationRules.value)
      ) {
        playlistErrorMessages.push({
          message: "Make sure you complete all rotation fields in [AVAILABLE FOR SECTION]",
        });
      }
      if (
        values.rotationRules &&
        (values.rotationRules.recurrence || values.rotationRules.start) &&
        !values.rotationRules.value
      ) {
        playlistErrorMessages.push({
          message: "Either all rotation rule field must be completed or all empty in [AVAILABLE FOR SECTION]",
        });
      }
      dispatch(
        showSubmissionErrorSuccess({
          submissionError: { status: true, messages: playlistErrorMessages },
        })
      );
      return;
    }
    const data = {
      composition: values.composition,
      name: values.name,
      playlistMongoId: values.id,
      rotation_rules: {
        active: values.rotationRules.active,
        recurrence: values.rotationRules.recurrence,
        start: values.rotationRules.start,
        value: values.rotationRules.value,
      },
      rules: {
        artist: values.rules?.artist || [],
        category: values.rules?.category || [],
        tracks: values.rules?.tracks || [],
        commercial: values.rules?.commercial || [],
      },
      viewableBy: {
        businesses: values.viewableBy?.businesses || [],
        clients:
          (values.viewableBy?.businesses?.length &&
            values.viewableBy.businesses.filter(
              (obj: any, index: any, self: any) =>
                self.map((item: any) => item.client_id).indexOf(obj.client_id) === index
            )) ||
          [],
      },
    };

    if (actions.editPlaylist && actions.editPlaylist.status && !actions.editPlaylist.clone) {
      dispatch(isLoadingSuccess(true));
      updatePgAndMongoPlaylist(values.playlist_id, data)
        .then((response) => {
          if (actions.editFromOlympus) {
            dispatch(
              showEditPlaylistSuccess({
                editPlaylist: {
                  status: false,
                  playlistId: 0,
                },
                playlistTitle: "",
                briefStatus: false,
                editFromOlympus: false,
              })
            );
            dispatch(isLoadingSuccess(false));
            goToPlaylists();
          } else if (actions.briefStatus) {
            dispatch(
              showEditPlaylistSuccess({
                editPlaylist: {
                  status: false,
                  playlistId: 0,
                },
                playlistTitle: "",
                briefStatus: false,
              })
            );
            dispatch(isLoadingSuccess(false));
          } else {
            dispatch(hideActionsSuccess());
            dispatch(clearPlaylistSuccess());
            setPlaylist("");
            setTracksInRotation({ total: 0, lists: [] });
            setRefresh(false);
          }
        })
        .catch((error) => {
          dispatch(isLoadingSuccess(false));
          throw new Error(error);
        });
    } else if (
      actions.addPlaylist ||
      (actions.editPlaylist && actions.editPlaylist.status && actions.editPlaylist.clone)
    ) {
      await createPlaylist(data)
        .then((response) => {
          if (actions.briefStatus) {
            dispatch(
              showEditPlaylistSuccess({
                editPlaylist: {
                  status: false,
                  playlistId: 0,
                },
                playlistTitle: "",
                briefStatus: false,
              })
            );
            dispatch(isLoadingSuccess(false));
          } else {
            dispatch(hideActionsSuccess());
            dispatch(clearPlaylistSuccess());
            setPlaylist("");
            setTracksInRotation({ total: 0, lists: [] });
            setRefresh(false);
          }
        })
        .catch((error) => {
          dispatch(isLoadingSuccess(false));
          dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
          throw new Error(error);
        });
    }
  };

  const resetPlaylistContentError = () => {
    setRefresh(true);
    dispatch(showSubmissionErrorSuccess({ submissionError: { status: false, messages: [] } }));
  };

  const handleCloseModal = () => {
    if (actions.editFromOlympus) {
      dispatch(
        showEditPlaylistSuccess({
          editPlaylist: {
            status: false,
            playlistId: 0,
          },
          playlistTitle: "",
          briefStatus: false,
          editFromOlympus: false,
        })
      );
      goToPlaylists();
    } else if (actions.briefStatus) {
      dispatch(
        showEditPlaylistSuccess({
          editPlaylist: {
            status: false,
            playlistId: 0,
          },
          playlistTitle: "",
          briefStatus: false,
        })
      );
    } else {
      dispatch(hideActionsSuccess());
      dispatch(clearPlaylistSuccess());
      setPlaylist("");
      setTracksInRotation({ total: 0, lists: [] });
      setRefresh(false);
    }
  };

  const selectBoxesStyle = {
    height: "35px",
    borderRadius: "3px",
    borderColor: "lightGrey",
    margin: "0 7px 0 0",
  };

  const initialValues: any = {
    ...playlist,
    rules: playlist && playlist.rules,
    composition:
      playlist &&
      playlist.composition.map((details: any) => ({
        ...details,
      })),

    viewableBy: playlist && playlist.viewableBy,
    filterSelected: "all",
  };

  return playlist ? (
    <GlobalModal
      title={actions.playlistTitle}
      size="xl"
      show={(actions.editPlaylist && actions.editPlaylist.status) || actions.addPlaylist}
      handleClose={() => {
        handleCloseModal();
      }}
    >
      <div>
        <div>
          {actions.submissionError.status && (
            <PlaylistSubmissionErrorModal
              show={actions.submissionError.status}
              handleClose={() => resetPlaylistContentError()}
              messages={actions.submissionError.messages}
              footer={
                <GlobalButton format="info" size="sm" onClick={() => resetPlaylistContentError()}>
                  Close
                </GlobalButton>
              }
            />
          )}

          {/* save playlist record */}
          {actions.savePlaylist.status && (
            <CreateOrUpdatePlaylist
              title={actions.playlistTitle}
              show={actions.savePlaylist.status}
              handleClose={() => dispatch(showSavePlaylistSuccess({ savePlaylist: { status: false, values: "" } }))}
              footer={
                <>
                  <div className={locationsStyles.flexRowWrapModalFooter}>
                    <div className={locationsStyles.footerLeft}>
                      <GlobalButton
                        format="white"
                        disabled={actions.actionsIsLoading ? true : false}
                        size="sm"
                        onClick={() =>
                          dispatch(showSavePlaylistSuccess({ savePlaylist: { status: false, values: "" } }))
                        }
                      >
                        No
                      </GlobalButton>
                    </div>
                    <div>
                      <GlobalButton
                        format="success"
                        disabled={actions.actionsIsLoading ? true : false}
                        onClick={() => {
                          setCreateOrUpdate(true);
                          dispatch(showSavePlaylistSuccess({ savePlaylist: { status: false, values: "" } }));
                          updateOrAddPlaylistData(actions.savePlaylist.values);
                        }}
                        size="sm"
                      >
                        Yes
                      </GlobalButton>
                    </div>
                  </div>
                </>
              }
            />
          )}
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={updateOrAddPlaylistData}
          enableReinitialize
          validationSchema={playlistSubmitionForm}
        >
          {({ handleChange, handleBlur, setFieldValue, values, errors, touched }) => {
            // playlist tracks

            let playlistTracksRecord: any = [];
            if ((playlists.playlistTracks && playlists.playlistTracks.length) || playlists.isTracks) {
              playlistTracksRecord = playlists.playlistTracks;
            } else {
              playlistTracksRecord = playlistTracks;
            }

            let getUniquePlaylistTracks = playlistTracksRecord.filter(
              (trackFiter: { id: number }, index: number, arr: []) =>
                trackFiter.id && arr.findIndex((item: any) => item.id === trackFiter.id) === index
            );

            // remove existent core channel composition from the actual core channel list
            const coreChannelsList = coreChannels.filter(
              (coreChannels: any) =>
                values &&
                values.composition &&
                values.composition.findIndex((composition: any) => composition.corechannel_id === coreChannels.id) < 0
            );

            // Get core channel composition
            compositionLength = values && values.composition && values.composition.length;

            // Get core channel composition total percentage
            totalPercentage =
              (values &&
                values.composition &&
                values.composition.reduce(
                  (previous: any, current: any) => parseInt(previous) + parseInt(current.percentage),
                  0
                )) ||
              0;

            // total playlist and tracks count
            const totalViewablebBy =
              values &&
              values.viewableBy &&
              values &&
              values.viewableBy.businesses &&
              values.viewableBy.businesses.length;

            // const totalTracks =
            //   values &&
            //   values.composition.reduce(
            //     (previous: any, current: any) => parseInt(previous) + parseInt(current.number_of_tracks),
            //     0
            //   );

            const totalExcludedTracks = values && values.rules && values.rules.tracks && values.rules.tracks.length;

            let playlistsAndTracksCount = [
              {
                info: true,
                message: "The number of locations this playlist is assigned to and usable for.",
                name: "Available for",
                value: totalViewablebBy || 0,
              },
              {
                info: true,
                message: "The number of tracks contained within this playlist.",
                name: "Total tracks",
                value: getUniquePlaylistTracks.length || 0,
              },
              {
                info: true,
                message:
                  "The number of tracks excluded from the playlist, either from customer feedback or the curator's choice.",
                name: "Excluded Tracks",
                value: totalExcludedTracks || 0,
              },
              {
                info: true,
                message: "The number of tracks currently being temporarily rested.",
                name: "Rested Tracks",
                value: tracksInRotation.total || 0,
              },
              {
                info: true,
                message: "The total duration of all tracks in the playlist.",
                name: "Total Duration",
                value: PlaylistTracksDuration(playlistDuration),
              },
              {
                info: true,
                message:
                  "It refer to whether the playlist is currently being used in an Active schedule (a schedule currently assigned to a player) anywhere across the client's locations.",
                name: "In use",
                value: playlist.inUse ? "Yes" : "No",
              },
            ];

            const removeCoreChannelTracks = (corechannel_id: number) => {
              setRefresh(true);
              let playlistTracksAndChannelTracks = [];
              if ((playlists.playlistTracks && playlists.playlistTracks.length) || playlists.isTracks) {
                playlistTracksAndChannelTracks = playlists.playlistTracks;
              } else {
                playlistTracksAndChannelTracks = playlistTracks;
              }

              playlistTracksAndChannelTracks = playlistTracksAndChannelTracks.filter(
                (obj: any) => obj.core_channel_id !== corechannel_id
              );

              dispatch(playlistTracksSuccess({ playlistTracks: playlistTracksAndChannelTracks, isTracks: true }));
            };

            return (
              <Form>
                <div className="bg-light px-3 pt-3">
                  <div>
                    {actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}
                  </div>
                  {actions.actionsIsLoading && <LoadingData />}
                  <Textarea
                    name="name"
                    id="name"
                    placeholder="Playlist Title"
                    playlistModalStyle="form-control form-control-title"
                    style={{ minHeight: "34px", backgroundColor: "transparent", marginBottom: "-12px" }}
                    rows={1}
                  />
                  <div className="row" style={{ zoom: 0.95 }}>
                    <div className="col-md-10">
                      <MiniCard options={playlistsAndTracksCount} columns="col-lg-2" />
                    </div>
                  </div>

                  {/* Tabs */}
                  <Tabs defaultActiveKey="channels" id="fill-tab-example" className="nav-segment my-3" fill>
                    <Tab eventKey="channels" title="Channels">
                      <div className="card">
                        <FieldArray
                          name="composition"
                          render={(arrayHelpers) => {
                            // Add new composition rule row
                            const addNewComposition = (coreChannelSelected: any) => {
                              // Add blank composition
                              const blankComposition = {
                                corechannel_id: (coreChannelSelected && coreChannelSelected.id) || 0,
                                corechannel_name: (coreChannelSelected && coreChannelSelected.name) || "",
                                number_of_tracks:
                                  (coreChannelSelected && coreChannelSelected.playlists_coreplaylisttrack?.length) ||
                                  "0",
                                percentage: 50,
                              };
                              setRefresh(true);
                              const hasComposition = values.composition.find(
                                (composition: any) => composition.corechannel_id === coreChannelSelected.id
                              );
                              if (hasComposition) {
                                setHasCompositionError("Core channel already in use");
                              } else if (!coreChannelSelected) {
                                setHasCompositionError("Select a core channel");
                              } else if (values.composition && values.composition.length) {
                                arrayHelpers.push(blankComposition);
                                getAllTracks(coreChannelSelected.id, "add");
                                setHasCompositionError("");
                                setCoreChannelSelected("");
                              } else {
                                setHasCompositionError("");
                                arrayHelpers.insert(
                                  values.composition && values.composition.length + 1,
                                  blankComposition
                                ); // insert an empty string at a position
                                getAllTracks(coreChannelSelected.id, "add");
                                setCoreChannelSelected("");
                              }
                            };

                            const getAllTracks = async (selectedId: number, status: string) => {
                              await getCoreChannelTracks(selectedId)
                                .then((response) => {
                                  let channelTracks =
                                    (response.data &&
                                      response.data.core_channel &&
                                      response.data.core_channel.tracks.filter(
                                        (track: { is_active: boolean }) => track.is_active
                                      )) ||
                                    [];

                                  const totalTracks = channelTracks.reduce(
                                    (previous: any, current: any) => parseInt(previous) + parseInt(current.duration),
                                    0
                                  );

                                  if (status === "remove") {
                                    const durationss = playlistDuration - totalTracks;
                                    setPlaylistDuration(durationss);
                                    return;
                                  }

                                  const durationss = playlistDuration + totalTracks;
                                  setPlaylistDuration(durationss);

                                  channelTracks =
                                    channelTracks.length &&
                                    channelTracks.map((obj: any) => ({ ...obj, core_channel_id: selectedId }));

                                  let playlistTracksAndChannelTracks = [];
                                  if (
                                    (playlists.playlistTracks && playlists.playlistTracks.length) ||
                                    playlists.isTracks
                                  ) {
                                    playlistTracksAndChannelTracks = playlists.playlistTracks.concat(channelTracks);
                                  } else {
                                    playlistTracksAndChannelTracks = playlistTracks.concat(channelTracks);
                                  }
                                  setRefresh(true);
                                  dispatch(
                                    playlistTracksSuccess({
                                      playlistTracks: playlistTracksAndChannelTracks,
                                      isTracks: true,
                                    })
                                  );
                                })
                                .catch((error) => {
                                  throw new Error(error);
                                });
                            };

                            // Channel column
                            const channelColumns = [
                              {
                                field: "corechannel_name",
                                headerName: "Channel",
                                wrapText: true,
                                autoHeight: true,
                              },
                              {
                                field: "number_of_tracks",
                                headerName: "Tracks",
                                width: 100,
                                maxWidth: 100,
                              },
                              {
                                field: "percentage",
                                headerName: "Percentage",
                                width: 340,
                                maxWidth: 340,
                                cellRenderer: (params: any) => {
                                  return (
                                    <div className="text-center d-flex align-items-center w-100">
                                      {/* <input
                                        type="range"
                                        className="form-range w-100"
                                        defaultValue={(params.data && params.data.percentage) || 0}
                                      /> */}
                                      <Slider
                                        min={0}
                                        max={100}
                                        defaultValue={(params.data && params.data.percentage) || 0}
                                        className="w-100"
                                        onAfterChange={(value) => {
                                          setFieldValue(`composition.${params.rowIndex}.percentage`, value);
                                        }}
                                      />
                                      <span
                                        style={{
                                          background: "#333",
                                          color: "#fff",
                                          padding: "4px 7px",
                                          borderRadius: "40px",
                                          fontSize: "12px",
                                          marginLeft: "15px",
                                        }}
                                      >
                                        {`${(params.data && params.data.percentage) || 0}%`}
                                      </span>
                                    </div>
                                  );
                                },
                              },
                              {
                                field: "actions",
                                headerName: "Remove",
                                type: "rightAligned",
                                width: 120,
                                maxWidth: 120,
                                cellRenderer: (params: any) => {
                                  return (
                                    <GlobalButton
                                      format="danger"
                                      size="xs"
                                      leftIcon={<BsTrash className="me-1" />}
                                      onClick={() => {
                                        arrayHelpers.remove(params.rowIndex);
                                        removeCoreChannelTracks(params.data.corechannel_id);
                                        getAllTracks(params.data.corechannel_id, "remove");
                                      }} // remove injection from the list
                                      className="ms-auto"
                                    >
                                      Remove
                                    </GlobalButton>
                                  );
                                },
                              },
                            ];

                            return (
                              <>
                                <div className="card-header d-flex justify-content-between">
                                  <div className="mb-2 mb-md-0 d-flex align-items-center">
                                    <div style={{ width: "25rem", display: "flex" }}>
                                      <div className="input-group input-group-merge input-group-flush">
                                        <div style={{ width: "95%" }}>
                                          <SearchWithOptions
                                            placeholder="Add a channel"
                                            id="coreChannel"
                                            options={coreChannelsList}
                                            leftIcon={<BsPlusCircle />}
                                            selected={
                                              (clearSelected &&
                                                coreChannelsList.filter((c: any) => c.id === coreChannelSelected.id)) ||
                                              null
                                            }
                                            labelKey="name"
                                            size="sm"
                                            clearButton
                                            maxResults={30}
                                            onChange={(selected: any) => {
                                              if (selected && selected[0]) {
                                                setCoreChannelSelected(selected[0]);
                                                setHasCompositionError("");
                                                addNewComposition(selected[0]);
                                                setClearSelected(true);
                                              }
                                            }}
                                          />
                                        </div>
                                        {hasCompositionError && <p className="errorMessage">{hasCompositionError}</p>}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-grid d-sm-flex justify-content-md-end align-items-sm-center gap-2">
                                    <span className="me-2 pe-3">
                                      {compositionLength > 0 ? (
                                        <strong
                                          className="text-success lead text-bold me-2"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {compositionLength}
                                        </strong>
                                      ) : (
                                        <strong
                                          className="text-danger lead text-bold me-2"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {compositionLength}
                                        </strong>
                                      )}
                                      Channels
                                    </span>
                                    <span>
                                      {totalPercentage === 100 ? (
                                        <strong
                                          className="text-success lead text-bold me-2"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {`${totalPercentage}%`}
                                        </strong>
                                      ) : (
                                        <strong
                                          className="text-danger lead text-bold me-2"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {`${totalPercentage}%`}
                                        </strong>
                                      )}
                                      Total percentage
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <AgGridReactTable
                                    className="ag-theme-alpine"
                                    rowData={values.composition}
                                    columnDefs={channelColumns}
                                    filter={true}
                                    sortable={true}
                                    animateRows={true}
                                    pagination={false}
                                    height="200px"
                                  />
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>

                      {/* Rest Tracks */}
                      <div className="mt-4">
                        <h4>Rest Tracks</h4>
                        <div className="row d-flex align-items-center">
                          <div className="col-sm-2" style={{ height: "61px" }}>
                            <Toggle
                              type="checkbox"
                              name="rotationRules.active"
                              id="rotationRules.active"
                              label="Rest Tracks"
                              className="mb-4 mt-2"
                              rightLabel={true}
                              checked={true}
                              onChange={(value: any) => {
                                const rotationRulesActive =
                                  value.target && value.target.value === "true" ? false : true;
                                setFieldValue(`rotationRules.active`, rotationRulesActive);
                              }}
                            />
                          </div>
                          <div className="col-sm-10 d-flex">
                            {/* <label className="form-label mb-0 mt-2 me-3">Rotate</label> */}
                            <div className="input-group mb-0 me-3" style={{ width: "auto" }}>
                              <Input
                                id="rotationRules.value"
                                name="rotationRules.value"
                                type="number"
                                placeholder="Rest Percentage"
                                onChange={handleChange("rotationRules.value")}
                                value={values.email}
                                onBlur={handleBlur("rotationRules.value")}
                                size="form-control-sm"
                              />
                              <span className="input-group-text" style={{ height: "37px" }}>
                                %
                              </span>
                            </div>

                            <Select
                              style={selectBoxesStyle}
                              name="rotationRules.recurrence"
                              id="rotationRules.recurrence"
                              disabledPlaceholder={false}
                              placeholder="Select frequency"
                              className="ms-2"
                              options={[
                                {
                                  label: "Daily",
                                  value: "daily",
                                },
                                {
                                  label: "Weekly",
                                  value: "weekly",
                                },
                                {
                                  label: "Every 2 weeks",
                                  value: "biweekly",
                                },
                                {
                                  label: "Monthly",
                                  value: "monthly",
                                },
                                {
                                  label: "Every 2 months",
                                  value: "bimonthly",
                                },
                                {
                                  label: "Every 3 months",
                                  value: "months3",
                                },
                                {
                                  label: "Every 2 weeks",
                                  value: "2-weeks",
                                },
                                {
                                  label: "Every 6 months",
                                  value: "months6",
                                },
                                {
                                  label: "Yearly",
                                  value: "yearly",
                                },
                              ]}
                            />

                            <label className="form-label mb-0 mt-2 ms-4 me-3">From</label>
                            <SingleDatePicker
                              id="rotationRules.start"
                              name="rotationRules.start"
                              error={
                                <ErrorMessage component="span" className="field_error" name={`rotationRules.start`} />
                              }
                              defaultPickerValue={
                                values && values.rotationRules && values.rotationRules.start
                                  ? moment(values.rotationRules.start).startOf("day")
                                  : null
                              }
                              onChange={(values: any) => {
                                setFieldValue(`rotationRules.start`, (values && new Date(values).getTime()) || null);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Tab>

                    {/* Tracks tab */}
                    <Tab eventKey="tracks" title="Tracks">
                      <FieldArray
                        name="rules.tracks"
                        render={(arrayHelpers) => {
                          const getPlaylistTracks = (values && values.rules && values.rules.tracks) || [];
                          let getPlaylistTracksFilter = getUniquePlaylistTracks.filter(
                            (trackFiter: { id: number; title: string; inRotation: boolean; excluded: boolean }) => {
                              // find track index from playlist track
                              const findTrackIndex = getPlaylistTracks.find(
                                (track: any) => track.track_id === trackFiter.id
                              );

                              let filterPass: any = true;

                              if (findTrackIndex && values.filterSelected === "excluded") {
                                filterPass = findTrackIndex;
                              } else if (!findTrackIndex && values.filterSelected === "exclude") {
                                filterPass = true;
                              } else if (values.filterSelected === "all") {
                                filterPass = true;
                              } else {
                                filterPass = false;
                              }
                              // filter by track title
                              if (filterTrack.id && filterTrack.title) {
                                filterPass = trackFiter.title === filterTrack.title;
                              }

                              return filterPass;
                            }
                          );

                          // remove playlist track
                          const removePlaylistTrack = (data: any) => {
                            const findTrackIndex = getPlaylistTracks.findIndex(
                              (track: any) => track.track_id === data.id
                            );

                            if (findTrackIndex >= 0) {
                              arrayHelpers.remove(findTrackIndex);
                              setRefresh(true);
                            }
                          };

                          const addPlaylistTrack = (data: any) => {
                            const findTrackIndex = getPlaylistTracks.findIndex(
                              (track: any) => track.track_id === data.track_id
                            );

                            if (findTrackIndex < 0) {
                              const newPlaylistTack = {
                                artist: data.artist,
                                playlist_id: playlist.playlist_id || playlist.id,
                                rule_id: data.artist_id,
                                title: data.title,
                                track_id: data.id,
                              };
                              arrayHelpers.push(newPlaylistTack);
                              setRefresh(true);
                            }
                          };

                          const audioElemCurrent = audioElem.current;

                          const onPlaying = () => {
                            setcurrentTime(audioElemCurrent && audioElemCurrent.currentTime);
                          };

                          if (
                            audioElemCurrent &&
                            playlists.playlistTrack.audio &&
                            playlists.playlistTrack.status === "play"
                          ) {
                            audioElemCurrent.muted = false;
                            audioElemCurrent && audioElemCurrent.play();
                          } else if (
                            audioElemCurrent &&
                            playlists.playlistTrack.audio &&
                            playlists.playlistTrack.status === "pause"
                          ) {
                            audioElemCurrent.muted = false;
                            audioElemCurrent && audioElemCurrent.pause();
                          } else if (
                            audioElemCurrent &&
                            playlists.playlistTrack.audio &&
                            playlists.playlistTrack.status === "load"
                          ) {
                            audioElemCurrent.muted = false;
                            audioElemCurrent.load();
                          } else if (
                            audioElemCurrent &&
                            playlists.playlistTrack &&
                            playlists.playlistTrack.audio &&
                            playlists.playlistTrack.status === "muted"
                          ) {
                            audioElemCurrent.muted = true;
                          }
                          const audio_link = playlists.playlistTrack && playlists.playlistTrack.audio;

                          // Convert seconds to a duration (minutes and seconds) for ...
                          const seconds = (audioElemCurrent && audioElemCurrent.duration) || 0;
                          const duration: any = intervalToDuration({ start: 0, end: seconds * 1000 });
                          const totalDurationFormatted = `${duration.minutes}:${
                            parseInt(duration.seconds) > 9 ? duration.seconds : `0${duration.seconds}`
                          }`;

                          const secondsCurrentTime = currentTime || 0;
                          const durationCurrentTime: any = intervalToDuration({
                            start: 0,
                            end: secondsCurrentTime * 1000,
                          });
                          const totalDurationFormattedCurrentTime = `${durationCurrentTime.minutes}:${
                            parseInt(durationCurrentTime.seconds) > 9
                              ? durationCurrentTime.seconds
                              : `0${durationCurrentTime.seconds}`
                          }`;

                          const progressBarTime = (secondsCurrentTime * 100) / seconds;

                          let playlistsTableRows: any = [];
                          let playlistsTableRowsExcel: any = [];

                          const handleExportData = () => {
                            const headings = [
                              [
                                "Artist",
                                "Title",
                                "Rested Tracks",
                                "Duration",
                                "Excluded",
                                "Audio",
                                "CDN Mono",
                                "CDN Stereo",
                              ],
                            ];
                            HandleExport(playlistsTableRowsExcel, headings, "playlist-tracks");
                          };

                          getPlaylistTracksFilter.map((data: any) => {
                            // Rotation
                            let inRotation = tracksInRotation.lists.find((track: any) => track.id === data.id);
                            let inRotationValue = false;
                            if (inRotation) {
                              inRotationValue = true;
                            }
                            // Duration
                            const length = data.duration || 0;
                            const trackDuration: any = intervalToDuration({ start: 0, end: length * 1000 });
                            const trackDurationFormatted = `${trackDuration.minutes}:${
                              parseInt(trackDuration.seconds) > 9 ? trackDuration.seconds : `0${trackDuration.seconds}`
                            }`;

                            // Excluded
                            const excluded = getPlaylistTracks.find((track: any) => track.track_id === data.id);
                            let excludedValue = false;
                            if (excluded) {
                              excludedValue = true;
                            }

                            const rowData = {
                              id: data.id,
                              artist_id: data.artist_id,
                              cdn_standard: data.cdn_standard,
                              cdn_hifi: data.cdn_hifi,
                              audio: data.audio,
                              core_channel_id: data.core_channel_id,
                              artist: data.artist,
                              title: data.title,
                              inRotation: inRotationValue,
                              duration: trackDurationFormatted,
                              excluded: excludedValue,
                            };
                            playlistsTableRows.push(rowData);

                            const rowDataExcel = {
                              artist: data.artist,
                              title: data.title,
                              inRotation: (inRotationValue && "Rested Tracks") || "",
                              duration: trackDurationFormatted,
                              excluded: excludedValue ? "Yes" : "No",
                              audio: data.audio,
                              cdn_standard: data.cdn_standard,
                              cdn_hifi: data.cdn_hifi,
                            };
                            playlistsTableRowsExcel.push(rowDataExcel);

                            return rowData;
                          });

                          // set tracks columns
                          const tracksColumns = [
                            {
                              field: "cdn_standard",
                              headerName: "Mono",
                              width: 100,
                              maxWidth: 100,
                              cellRenderer: (params: any) => {
                                return (
                                  params.data.cdn_standard && (
                                    <>
                                      {playlists.playlistTrack &&
                                      playlists.playlistTrack.audio &&
                                      params.data &&
                                      params.data.cdn_standard &&
                                      playlists.playlistTrack.audio === params.data.cdn_standard &&
                                      (playlists.playlistTrack.status === "play" ||
                                        playlists.playlistTrack.status === "muted") ? (
                                        <GlobalButton
                                          format="none"
                                          size="xs"
                                          leftIcon={<BsPauseFill size="24" />}
                                          onClick={() => {
                                            dispatch(
                                              playlistTrackSuccess({
                                                playlistTrack: {
                                                  artist: params.data.artist,
                                                  title: params.data.title,
                                                  audio: params.data.cdn_standard,
                                                  id: params.data.id,
                                                  status: "pause",
                                                },
                                              })
                                            );
                                            setRefresh(true);
                                          }}
                                        ></GlobalButton>
                                      ) : (
                                        <GlobalButton
                                          format="none"
                                          size="xs"
                                          leftIcon={<BsPlayFill size="24" />}
                                          onClick={() => {
                                            dispatch(
                                              playlistTrackSuccess({
                                                playlistTrack: {
                                                  artist: params.data.artist,
                                                  title: params.data.title,
                                                  audio: params.data.cdn_standard,
                                                  id: params.data.id,
                                                  status: "play",
                                                },
                                              })
                                            );
                                            setRefresh(true);
                                          }}
                                        ></GlobalButton>
                                      )}
                                    </>
                                  )
                                );
                              },
                            },
                            {
                              field: "cdn_hifi",
                              headerName: "Hifi",
                              width: 100,
                              maxWidth: 100,
                              cellRenderer: (params: any) => {
                                return (
                                  params.data.cdn_hifi && (
                                    <>
                                      {playlists.playlistTrack &&
                                      playlists.playlistTrack.audio &&
                                      params.data &&
                                      params.data.cdn_hifi &&
                                      playlists.playlistTrack.audio === params.data.cdn_hifi &&
                                      (playlists.playlistTrack.status === "play" ||
                                        playlists.playlistTrack.status === "muted") ? (
                                        <GlobalButton
                                          format="none"
                                          size="xs"
                                          leftIcon={<BsPauseFill size="24" />}
                                          onClick={() => {
                                            dispatch(
                                              playlistTrackSuccess({
                                                playlistTrack: {
                                                  artist: params.data.artist,
                                                  title: params.data.title,
                                                  audio: params.data.cdn_hifi,
                                                  id: params.data.id,
                                                  status: "pause",
                                                },
                                              })
                                            );
                                            setRefresh(true);
                                          }}
                                        ></GlobalButton>
                                      ) : (
                                        <GlobalButton
                                          format="none"
                                          size="xs"
                                          leftIcon={<BsPlayFill size="24" />}
                                          onClick={() => {
                                            dispatch(
                                              playlistTrackSuccess({
                                                playlistTrack: {
                                                  artist: params.data.artist,
                                                  title: params.data.title,
                                                  audio: params.data.cdn_hifi,
                                                  id: params.data.id,
                                                  status: "play",
                                                },
                                              })
                                            );
                                            setRefresh(true);
                                          }}
                                        ></GlobalButton>
                                      )}
                                    </>
                                  )
                                );
                              },
                            },
                            {
                              field: "artist",
                              headerName: "Artist",
                              wrapText: true,
                              autoHeight: true,
                            },
                            {
                              field: "title",
                              headerName: "Track",
                              wrapText: true,
                              autoHeight: true,
                            },
                            {
                              field: "inRotation",
                              headerName: "Rested Tracks",
                              width: 160,
                              maxWidth: 160,
                              cellRenderer: (params: any) => {
                                return (
                                  <>
                                    {params.data.inRotation ? (
                                      <span className="badge bg-soft-dark text-dark">Rested Track</span>
                                    ) : null}
                                  </>
                                );
                              },
                            },
                            {
                              field: "duration",
                              headerName: "Length",
                              width: 100,
                              maxWidth: 100,
                            },
                            {
                              field: "excluded",
                              headerName: "Exclude",
                              type: "rightAligned",
                              width: 120,
                              maxWidth: 120,
                              cellRenderer: (params: any) => {
                                return (
                                  <>
                                    {params.data.excluded ? (
                                      <>
                                        <GlobalButton
                                          format="danger"
                                          size="xs"
                                          onClick={() => removePlaylistTrack(params.data)}
                                          className="me-auto justify-content-center w-100"
                                        >
                                          Excluded
                                        </GlobalButton>
                                      </>
                                    ) : (
                                      <>
                                        <GlobalButton
                                          format="secondary"
                                          size="xs"
                                          onClick={() => addPlaylistTrack(params.data)}
                                          className="me-auto d-flex justify-content-center w-100"
                                        >
                                          Exclude
                                        </GlobalButton>
                                      </>
                                    )}
                                  </>
                                );
                              },
                            },
                          ];

                          return (
                            <>
                              <KeyValueCard
                                title={
                                  <SearchWithOptions
                                    id="playlistTracks"
                                    placeholder="Search tracks..."
                                    options={playlistTracks}
                                    labelKey="title"
                                    leftIcon={<BsSearch />}
                                    clearButton
                                    onChange={(selected: any) => {
                                      setFilterTrack((selected && selected[0]) || "");
                                      setRefresh(true);
                                    }}
                                  />
                                }
                                filter={
                                  <div className="d-flex align-items-center">
                                    {playlists.playlistTrack &&
                                    playlists.playlistTrack.audio &&
                                    (playlists.playlistTrack.status === "play" ||
                                      playlists.playlistTrack.status === "muted") ? (
                                      <GlobalButton
                                        size="xs"
                                        format="white"
                                        leftIcon={<BsPauseFill size="20" />}
                                        className="me-3"
                                        onClick={() => {
                                          dispatch(
                                            playlistTrackSuccess({
                                              playlistTrack: {
                                                artist: playlists.playlistTrack && playlists.playlistTrack.artist,
                                                title: playlists.playlistTrack && playlists.playlistTrack.title,
                                                audio: playlists.playlistTrack && playlists.playlistTrack.audio,
                                                id: playlists.playlistTrack && playlists.playlistTrack.id,
                                                status: "load",
                                              },
                                            })
                                          );
                                          setRefresh(true);
                                        }}
                                      ></GlobalButton>
                                    ) : (
                                      <GlobalButton
                                        size="xs"
                                        format="white"
                                        leftIcon={<BsPlayFill size="20" />}
                                        className="me-3"
                                        onClick={() => {
                                          dispatch(
                                            playlistTrackSuccess({
                                              playlistTrack: {
                                                artist:
                                                  (getPlaylistTracksFilter &&
                                                    getPlaylistTracksFilter.length &&
                                                    getPlaylistTracksFilter[0].artist) ||
                                                  "",
                                                title:
                                                  (getPlaylistTracksFilter &&
                                                    getPlaylistTracksFilter.length &&
                                                    getPlaylistTracksFilter[0].title) ||
                                                  "",
                                                audio:
                                                  (getPlaylistTracksFilter &&
                                                    getPlaylistTracksFilter.length &&
                                                    getPlaylistTracksFilter[0].cdn_standard) ||
                                                  "",
                                                id:
                                                  (getPlaylistTracksFilter &&
                                                    getPlaylistTracksFilter.length &&
                                                    getPlaylistTracksFilter[0].id) ||
                                                  0,
                                                status:
                                                  getPlaylistTracksFilter &&
                                                  getPlaylistTracksFilter.length &&
                                                  getPlaylistTracksFilter[0]
                                                    ? "play"
                                                    : "",
                                              },
                                            })
                                          );
                                          setRefresh(true);
                                        }}
                                      ></GlobalButton>
                                    )}
                                    {playlists.playlistTrack &&
                                    playlists.playlistTrack.audio &&
                                    playlists.playlistTrack.status === "muted" ? (
                                      <GlobalButton
                                        size="xs"
                                        format="white"
                                        leftIcon={<BsVolumeMuteFill size="20" />}
                                        className="me-3"
                                        onClick={() => {
                                          dispatch(
                                            playlistTrackSuccess({
                                              playlistTrack: {
                                                artist: playlists.playlistTrack && playlists.playlistTrack.artist,
                                                title: playlists.playlistTrack && playlists.playlistTrack.title,
                                                audio: playlists.playlistTrack && playlists.playlistTrack.audio,
                                                id: playlists.playlistTrack && playlists.playlistTrack.id,
                                                status: "play",
                                              },
                                            })
                                          );
                                        }}
                                      ></GlobalButton>
                                    ) : (
                                      <GlobalButton
                                        size="xs"
                                        format="white"
                                        leftIcon={<BsVolumeUpFill size="20" />}
                                        className="me-3"
                                        onClick={() => {
                                          playlists.playlistTrack.status === "play" &&
                                            dispatch(
                                              playlistTrackSuccess({
                                                playlistTrack: {
                                                  artist: playlists.playlistTrack && playlists.playlistTrack.artist,
                                                  title: playlists.playlistTrack && playlists.playlistTrack.title,
                                                  audio: playlists.playlistTrack && playlists.playlistTrack.audio,
                                                  id: playlists.playlistTrack && playlists.playlistTrack.id,
                                                  status: "muted",
                                                },
                                              })
                                            );
                                        }}
                                      ></GlobalButton>
                                    )}
                                    <div className="d-flex align-items-center me-3">
                                      <time className="me-2">{totalDurationFormattedCurrentTime || "0:00"}</time>
                                      <input
                                        type="range"
                                        className="form-range"
                                        step="any"
                                        min="0"
                                        max="100"
                                        value={progressBarTime || 0}
                                        style={{ width: "150px" }}
                                        onChange={(value) => {
                                          const currentProgressBarTime: any = value.target.value;
                                          const nowTime = (parseInt(currentProgressBarTime) * seconds) / 100;
                                          audioElemCurrent.currentTime = nowTime;
                                        }}
                                      />

                                      <time className="ms-2">{totalDurationFormatted || "0:00"}</time>
                                    </div>
                                    <Select
                                      name="filterSelected"
                                      id="filterSelected"
                                      style={selectBoxesStyle}
                                      className={styles.tracksSelected}
                                      options={[
                                        {
                                          label: "All",
                                          value: "all",
                                        },
                                        {
                                          label: "Excluded",
                                          value: "excluded",
                                        },
                                        {
                                          label: "Included",
                                          value: "exclude",
                                        },
                                      ]}
                                    />

                                    <GlobalButton
                                      size="sm"
                                      format="white"
                                      leftIcon={<BsDownload className="me-2" />}
                                      className="ms-3"
                                      onClick={handleExportData}
                                    >
                                      Export CSV
                                    </GlobalButton>
                                  </div>
                                }
                              >
                                <div className="row">
                                  <div className="col-sm-12">
                                    <AgGridReactTable
                                      className="ag-theme-alpine"
                                      rowData={playlistsTableRows}
                                      columnDefs={tracksColumns}
                                      filter={true}
                                      sortable={true}
                                      animateRows={true}
                                      // pagination={true}
                                      height="350px"
                                      // paginationPageSize={10}
                                    />
                                  </div>
                                </div>
                              </KeyValueCard>
                              <audio src={audio_link} ref={audioElem} onTimeUpdate={onPlaying} />
                            </>
                          );
                        }}
                      />
                    </Tab>
                    <Tab eventKey="available-for" title="Available For">
                      <FieldArray
                        name="viewableBy.businesses"
                        render={(arrayHelpers) => {
                          // remove existent business from the actual business list
                          const clientBusinessesList = authSlice?.clientBusinessesList.filter(
                            (clientBusiness: any) =>
                              values &&
                              values.viewableBy &&
                              values.viewableBy.businesses &&
                              values.viewableBy.businesses.findIndex(
                                (business: any) => business.business_id === clientBusiness.business_id
                              ) < 0
                          );

                          // remove existent client if all client business list exist
                          const clientsList = authSlice?.clientsList
                            .filter(
                              (client: any) =>
                                !client?.businesses_business?.every(
                                  (business: any) =>
                                    values &&
                                    values.viewableBy &&
                                    values.viewableBy.businesses.some(
                                      (viewer: any) => business.id === viewer.business_id
                                    )
                                )
                            )
                            .map((client: any) => ({
                              client_name: client.name,
                              client_id: client.id,
                              businesses: client?.businesses_business?.map((business: any) => ({
                                client_id: client.id,
                                client_name: client.name,
                                business_id: business.id,
                                business_name: business.name,
                              })),
                            }));

                          // Get client business length
                          clientBusinessesLength =
                            values.viewableBy && values.viewableBy.businesses && values.viewableBy.businesses.length;

                          // Get client length
                          const clientsViewableList =
                            values.viewableBy && values.viewableBy.businesses && values.viewableBy.businesses;

                          const uniqueClientsViewableList = [
                            ...new Set(clientsViewableList.map((client: any) => client.client_id)),
                          ];

                          clientsLength = uniqueClientsViewableList.length;

                          // Add new business viewable rule row
                          const addNewBuinessViewable = (clientBusinessSelected: any) => {
                            // Add blank viewable
                            const blankViewable = {
                              business_id: (clientBusinessSelected && clientBusinessSelected.business_id) || 0,
                              business_name: (clientBusinessSelected && clientBusinessSelected.business_name) || "",
                              client_id: (clientBusinessSelected && clientBusinessSelected.client_id) || 0,
                              client_name: (clientBusinessSelected && clientBusinessSelected.client_name) || "",
                            };

                            const hasClientBusiness =
                              values.viewableBy &&
                              values.viewableBy.businesses.find(
                                (business: any) => business.business_id === clientBusinessSelected.business_id
                              );
                            if (hasClientBusiness) {
                              setHasClientBusinessError("Business already in use");
                            } else if (!clientBusinessSelected) {
                              setHasClientBusinessError("Select a business");
                            } else if (
                              values.viewableBy &&
                              values.viewableBy.businesses &&
                              values.viewableBy.businesses.length
                            ) {
                              arrayHelpers.push(blankViewable);
                            } else {
                              arrayHelpers.insert(
                                values.viewableBy &&
                                  values.viewableBy.businesses &&
                                  values.viewableBy.businesses.length + 1,
                                blankViewable
                              );
                            }
                          };

                          // Add new business viewable rule row
                          const addNewClientBuinessesViewable = (client: any) => {
                            const viewableBy = values.viewableBy && values.viewableBy.businesses;

                            client.businesses
                              .filter(
                                (business: any) =>
                                  !viewableBy.some(
                                    (viewableBusiness: any) => viewableBusiness.business_id === business.business_id
                                  )
                              )
                              .forEach((clientSelected: any) => {
                                // Add blank viewable
                                const blankViewable = {
                                  business_id: (clientSelected && clientSelected.business_id) || 0,
                                  business_name: (clientSelected && clientSelected.business_name) || "",
                                  client_id: (clientSelected && clientSelected.client_id) || 0,
                                  client_name: (clientSelected && clientSelected.client_name) || "",
                                };

                                const hasClientBusiness =
                                  values.viewableBy &&
                                  values.viewableBy.businesses.find(
                                    (business: any) => business.business_id === clientSelected.business_id
                                  );
                                if (hasClientBusiness) {
                                  setHasClientBusinessError("Business already in use");
                                } else if (!clientSelected) {
                                  setHasClientBusinessError("Select a business");
                                } else if (
                                  values.viewableBy &&
                                  values.viewableBy.businesses &&
                                  values.viewableBy.businesses.length
                                ) {
                                  arrayHelpers.push(blankViewable);
                                } else {
                                  arrayHelpers.insert(
                                    values.viewableBy &&
                                      values.viewableBy.businesses &&
                                      values.viewableBy.businesses.length + 1,
                                    blankViewable
                                  );
                                }
                              });
                          };

                          // Viewable by column
                          const availableForByColumns = [
                            {
                              field: "business_name",
                              headerName: "Business Name",
                            },
                            {
                              field: "client_name",
                              headerName: "Client Name",
                            },
                            {
                              field: "actions",
                              headerName: "Remove",
                              type: "rightAligned",
                              width: 120,
                              maxWidth: 120,
                              cellRenderer: (params: any) => {
                                return (
                                  <GlobalButton
                                    format="danger"
                                    size="xs"
                                    leftIcon={<BsXCircle className="me-1" />}
                                    onClick={() => arrayHelpers.remove(params.rowIndex)} // remove injection from the list
                                    className="me-auto"
                                  >
                                    Remove
                                  </GlobalButton>
                                );
                              },
                            },
                          ];

                          return (
                            <KeyValueCard
                              title={
                                <div style={{ width: "35rem", display: "flex" }}>
                                  <div className="input-group input-group-merge input-group-flush">
                                    <div style={{ width: "95%" }}>
                                      <SearchWithOptions
                                        placeholder="Add a client"
                                        id="client_selected"
                                        options={clientsList}
                                        selected={clearSelected && clientsList.filter((c: any) => c.id === null)}
                                        labelKey="client_name"
                                        filterBy={["client_name"]}
                                        leftIcon={<BsPlusCircle />}
                                        size="sm"
                                        clearButton
                                        maxResults={30}
                                        onChange={(selected: any) => {
                                          if (selected && selected[0]) {
                                            setHasClientBusinessError("");
                                            addNewClientBuinessesViewable(selected[0]);
                                            setClearSelected(true);
                                          }
                                        }}
                                        renderMenuItemChildren={(option: any) => {
                                          return (
                                            <div>
                                              {option.client_name}
                                              {option.businesses.map((business: any) => (
                                                <ul key={business.business_name}>
                                                  <li>
                                                    <small>{business.business_name}</small>
                                                  </li>
                                                </ul>
                                              ))}
                                            </div>
                                          );
                                        }}
                                      />
                                    </div>
                                    {hasClientBusinessError && <p className="errorMessage">{hasClientBusinessError}</p>}
                                  </div>
                                  <div className="input-group input-group-merge input-group-flush">
                                    <div style={{ width: "95%" }}>
                                      <SearchWithOptions
                                        placeholder="Add a business"
                                        id="business_selected"
                                        options={clientBusinessesList}
                                        selected={
                                          clearSelected && clientBusinessesList.filter((c: any) => c.id === null)
                                        }
                                        labelKey="business_name"
                                        filterBy={["business_name", "client_name"]}
                                        leftIcon={<BsPlusCircle />}
                                        size="sm"
                                        clearButton
                                        maxResults={30}
                                        onChange={(selected: any) => {
                                          if (selected && selected[0]) {
                                            setHasClientBusinessError("");
                                            addNewBuinessViewable(selected[0]);
                                            setClearSelected(true);
                                          }
                                        }}
                                        renderMenuItemChildren={(option: any) => {
                                          return (
                                            <div>
                                              {option.business_name}
                                              <ul>
                                                <li>
                                                  <small> {option.client_name}</small>
                                                </li>
                                              </ul>
                                            </div>
                                          );
                                        }}
                                      />
                                    </div>
                                    {hasClientBusinessError && <p className="errorMessage">{hasClientBusinessError}</p>}
                                  </div>
                                </div>
                              }
                              filter={
                                <>
                                  {clientBusinessesLength ? (
                                    <strong className="text-success lead text-bold me-2" style={{ fontWeight: "bold" }}>
                                      {clientBusinessesLength}
                                    </strong>
                                  ) : (
                                    <strong className="text-danger lead text-bold me-2" style={{ fontWeight: "bold" }}>
                                      {clientBusinessesLength}
                                    </strong>
                                  )}
                                  {clientBusinessesLength > 1 ? "Businesses" : "Business"}

                                  {clientsLength ? (
                                    <strong
                                      className="text-success lead text-bold me-2 ms-3"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {clientsLength}
                                    </strong>
                                  ) : (
                                    <strong
                                      className="text-danger lead text-bold me-2 ms-3"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {clientsLength}
                                    </strong>
                                  )}
                                  {clientsLength > 1 ? "Clients" : "Client"}
                                </>
                              }
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <AgGridReactTable
                                    className="ag-theme-alpine"
                                    rowData={values && values.viewableBy && values.viewableBy.businesses}
                                    columnDefs={availableForByColumns}
                                    filter={true}
                                    sortable={true}
                                    animateRows={true}
                                    pagination={true}
                                    height="350px"
                                    paginationPageSize={10}
                                  />
                                </div>
                              </div>
                            </KeyValueCard>
                          );
                        }}
                      />
                    </Tab>
                    <Tab eventKey="commercial-rules" title="Commercial Rules">
                      <FieldArray
                        name="rules.commercial"
                        render={(arrayHelpers) => {
                          // Add blank rule
                          const blankRule = {
                            core_channel_id: "",
                            repetition: 5,
                            repetition_unit: "tracks",
                            order: 1,
                            pick_type: "random",
                            startDate: null,
                            endDate: null,
                            injection100: false,
                          };

                          const addNewRules = () => {
                            if (values.rules && values.rules.commercial && values.rules.commercial.length) {
                              arrayHelpers.push(blankRule);
                            } else {
                              arrayHelpers.insert(values.rules.length + 1, blankRule); // insert an empty string at a position
                            }
                          };

                          return (
                            <>
                              <GlobalButton
                                size="sm"
                                format="secondary"
                                className="mb-3"
                                onClick={() => {
                                  addNewRules();
                                }}
                              >
                                Add Rule
                              </GlobalButton>
                              <div className="pb-3">
                                <div className="card">
                                  {/* Commercial Rules Loop here  */}
                                  {values.rules && values.rules.commercial && values.rules.commercial.length > 0 ? (
                                    values.rules.commercial.map((rule: any, index: number) => {
                                      // check if the date is valid
                                      if (rule.startDate && rule.startDate.toString().length < 13) {
                                        rule.startDate = rule.startDate * 1000;
                                        rule.endDate = rule.endDate * 1000;
                                      }

                                      const defaultSelectedCoreChannel = (index: number) => {
                                        const rules = values.rules.commercial;
                                        const selectedCC = rules[index] && rules[index].core_channel_id;
                                        if (selectedCC) {
                                          return coreChannels.filter((channel: any) => channel.id === selectedCC);
                                        }
                                      };

                                      return (
                                        <div key={index}>
                                          <RowCard>
                                            <div className="row g-3 align-items-center">
                                              <div className="col-auto">
                                                <BsXCircle
                                                  size="20"
                                                  className="text-danger"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => arrayHelpers.remove(index)} // remove injection from the list
                                                />
                                              </div>
                                              <div className="col-3">
                                                <SearchWithOptions
                                                  placeholder="Select a core channel..."
                                                  id={`rules.commercial.${index}.core_channel_id`}
                                                  options={coreChannels}
                                                  labelKey="name"
                                                  leftIcon={<BsSearch />}
                                                  clearButton
                                                  maxResults={30}
                                                  selected={defaultSelectedCoreChannel(index) || null}
                                                  onChange={(selected: any) => {
                                                    setFieldValue(
                                                      `rules.commercial.${index}.core_channel_id`,
                                                      selected && selected[0] && selected[0].id
                                                    );
                                                  }}
                                                />
                                              </div>

                                              <div className="col-auto">
                                                <label>Every</label>
                                              </div>
                                              <div className="col-1">
                                                <Input
                                                  min={1}
                                                  id={`rules.${index}.repetition`}
                                                  name={`rules.commercial.${index}.repetition`}
                                                  type="number"
                                                  placeholder="Value"
                                                  onChange={handleChange(`rules.commercial.${index}.repetition`)}
                                                  onBlur={handleBlur(`rules.commercial.${index}.repetition`)}
                                                  autoCapitalize="none"
                                                  error={errors.rules}
                                                  size="form-control-sm"
                                                  onChangeCapture={() =>
                                                    actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))
                                                  }
                                                />
                                              </div>
                                              <div className="col-auto">
                                                <Select
                                                  style={selectBoxesStyle}
                                                  name={`rules.commercial.${index}.repetition_unit`}
                                                  id={`rules.commercial.${index}.repetition_unit`}
                                                  options={[
                                                    {
                                                      label: "Tracks",
                                                      value: "tracks",
                                                    },
                                                    {
                                                      label: "Minutes",
                                                      value: "minutes",
                                                    },
                                                  ]}
                                                />
                                              </div>
                                              <div className="col-auto">
                                                <Select
                                                  style={selectBoxesStyle}
                                                  name={`rules.commercial.${index}.pick_type`}
                                                  id={`rules.commercial.${index}.pick_type`}
                                                  options={[
                                                    {
                                                      label: "No Repeats",
                                                      value: "random-exclusive",
                                                    },
                                                    {
                                                      label: "Allow Repeats",
                                                      value: "random",
                                                    },
                                                  ]}
                                                />
                                              </div>
                                              <div className="col-auto" style={{ marginTop: "33px" }}>
                                                <DateRangePicker
                                                  id={`rules.commercial.${index}.startDate`}
                                                  error={
                                                    <ErrorMessage
                                                      component="span"
                                                      className="field_error"
                                                      name={`rules.commercial.${index}.startDate`}
                                                    />
                                                  }
                                                  defaultPickerValue={[
                                                    rule.startDate ? moment(rule.startDate).startOf("day") : null,
                                                    rule.endDate ? moment(rule.endDate).startOf("day") : null,
                                                  ]}
                                                  onChange={(values: any) => {
                                                    setFieldValue(
                                                      `rules.commercial.${index}.startDate`,
                                                      (values && values[0] && new Date(values[0]).getTime()) || null
                                                    );
                                                    setFieldValue(
                                                      `rules.commercial.${index}.endDate`,
                                                      (values && values[1] && new Date(values[1]).getTime()) || null
                                                    );
                                                  }}
                                                />
                                              </div>
                                              <div className="col-auto">
                                                <Checkbox
                                                  type="checkbox"
                                                  name={`rules.commercial.${index}.injection100`}
                                                  id={`rules.commercial.${index}.injection100`}
                                                  value="100% Injection"
                                                />
                                              </div>
                                            </div>
                                          </RowCard>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div>
                                      <h1 className="my-4" style={{ textAlign: "center" }}>
                                        No rules
                                      </h1>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        }}
                      />
                    </Tab>
                  </Tabs>
                </div>
                <div className="modal-footer">
                  <div>
                    <GlobalButton
                      format="white"
                      className="me-3"
                      onClick={() => {
                        handleCloseModal();
                      }}
                    >
                      Close
                    </GlobalButton>
                  </div>
                  <div>
                    {actions.editPlaylist && actions.editPlaylist.status && !actions.editPlaylist.clone ? (
                      <GlobalButton
                        format="secondary"
                        onClick={() => {
                          dispatch(showSavePlaylistSuccess({ status: true, values: values }));
                        }}
                      >
                        Save
                      </GlobalButton>
                    ) : (
                      <GlobalButton
                        format="primary"
                        onClick={() => {
                          dispatch(showSavePlaylistSuccess({ status: true, values: values }));
                        }}
                      >
                        Save & Publish
                      </GlobalButton>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </GlobalModal>
  ) : null;
};

export const PlaylistSubmissionErrorModal = ({ show, messages = [], handleClose, footer }: PlaylistModalProps) => {
  return (
    <GlobalModal
      title="Error Contents"
      show={show}
      handleClose={handleClose}
      className={styles.errorModal}
      titleClassName={styles.titleClassName}
    >
      <div style={{ margin: "0 20px 20px 20px" }}>
        <ul>
          {messages.length
            ? messages.map((message: any, index: number) => {
                return <li key={index}>{message.message}</li>;
              })
            : null}
        </ul>
        {footer && <div className={styles.closeButton}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Unpublish playlist
 */
export const UnpublishPlaylist = ({ footer, show, handleClose }: PlaylistModalProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Unpublish Playlist" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>Are you sure you want to unpublish the selected playlist?</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Unpublish playlist
 */
export const NoExcludedTracks = ({ footer, show, handleClose }: PlaylistModalProps) => {
  return (
    <GlobalModal title="No Excluded Tracks" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>No Excluded Tracks!</p>

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Publish playlist
 */
export const PublishPlaylist = ({ footer, show, handleClose }: PlaylistModalProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Publish Playlist" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>Are you sure you want to publish the selected playlist?</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Clone playlist
 */
export const ClonePlaylist = ({ footer, show, handleClose }: PlaylistModalProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Clone Playlist" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>Are you sure you want to clone the selected playlist?</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Create or update playlist
 */
export const CreateOrUpdatePlaylist = ({ footer, show, title, handleClose }: PlaylistModalProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title={title} show={show} handleClose={handleClose} footer={footer} className={styles.errorModal}>
      <div className={styles.modal}>
        <p>Are you sure you want to save?</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Convert seconds to a duration (hours and minutes)
 */
export const PlaylistTracksDuration = (playlistDuration: number) => {
  const seconds = playlistDuration;
  const duration: any = intervalToDuration({ start: 0, end: seconds * 1000 });
  const formatted = `${
    duration.hours && duration.days * 24 > 9
      ? `${duration.days * 24 + duration.hours}h`
      : `${duration.hours ? `${duration.hours}h:` : ""}`
  }${duration.minutes}m`;

  return formatted;
};
