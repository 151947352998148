import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// style components

export interface LocationsState {
  locations: [];
  playerStatus: [];
  playerStatusWithLocations: [];
  locationsAreasStatus: [];
}
const initialState: LocationsState = {
  locations: [],
  playerStatus: [],
  playerStatusWithLocations: [],
  locationsAreasStatus: [],
};

const PlaylistsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    locationsSuccess: (state, action: PayloadAction<any>) => {
      state.locations = action.payload.locations;
    },
    playerStatusSuccess: (state, action: PayloadAction<any>) => {
      state.playerStatus = action.payload.playerStatus;
    },
    playerStatusWithLocationsSuccess: (state, action: PayloadAction<any>) => {
      state.playerStatusWithLocations = action.payload.playerStatusWithLocations;
    },
    locationsAreasStatusSuccess: (state, action: PayloadAction<any>) => {
      state.locationsAreasStatus = action.payload.locationsAreasStatus;
    },
  },
});

export const { locationsSuccess, playerStatusSuccess, playerStatusWithLocationsSuccess, locationsAreasStatusSuccess } =
  PlaylistsSlice.actions;
export default PlaylistsSlice.reducer;
