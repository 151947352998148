import React from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import Select from "react-select";

// style components
import { GlobalModal } from "../../../../components/modal";
import { RootState } from "../../../../redux-toolkit/store";
import modalStyles from "../../../locations/components/location-actions/location-actions.module.scss";
import { CLONE_OPTIONS } from "../../../../constants/defaultValues";
import locationStyles from "../../../locations/locations.module.scss";
import { Input } from "../../../../components/fields/input";
import { GlobalButton } from "../../../../components/button";
// import { Toggle } from "../../../../components/fields/toggle";
import { Checkbox } from "../../../../components/fields/checkbox";
import { addScheduleForm } from "../../../../components/formValidation/formValidation";

interface ScheduleProps {
  size?: "sm" | "lg" | "xl";
  backdrop?: "static" | true | false;
  centered?: true | false;
  scrollable?: true | false;
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  footer?: any;
  title?: string;
  show: boolean;
  location?: { name: string; email: string; id: number };
  handleClose: any;
  locations: [];
  handleAddSchedule: any; // function
  handleCloseAddSchedule: any; // function
}

/**
 * Add new schedule
 */
export const ScheduleAddSchedulePopup = ({
  footer,
  show,
  handleClose,
  locations,
  handleAddSchedule,
  handleCloseAddSchedule,
}: ScheduleProps) => {
  const schedulesSlice = useSelector((state: RootState) => state.schedulesSlice);

  const _handleAdd = (values: any) => {
    if (!values.isCloneFromSource) {
      handleAddSchedule(values.name);
    } else if (values.isCloneFromSource) {
      handleAddSchedule(values.name, values.source, values.ref);
    }
  };

  let areasList = [].concat(
    ...locations.map((item: any) =>
      item.areas.map((i: any) => {
        return i;
      })
    )
  );

  return (
    <GlobalModal
      title="Create a new weekly schedule for current client"
      show={show}
      handleClose={handleClose}
      className="add-new-schedule"
      titleClassName="add-new-schedule-title"
      footer={footer}
    >
      <div className={modalStyles.modal}>
        <div>
          <Formik
            form
            initialValues={{
              name: "",
              cloneSchedule: true,
              cloneArea: false,
              ref: "",
              source: "schedule",
              isCloneFromSource: false,
            }}
            onSubmit={_handleAdd}
            enableReinitialize
            validationSchema={addScheduleForm}
          >
            {({ handleChange, handleBlur, setFieldValue, values, errors }) => (
              <Form>
                <div className="row mt-4">
                  <label className="col-sm-6 col-form-label">Name</label>
                  <div className="col-sm-6">
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Type the schedule name here...."
                      onChange={handleChange("name")}
                      value={values.name}
                      onBlur={handleBlur("name")}
                      autoCapitalize="none"
                      error={errors.name}
                      size="form-control-sm"
                      // onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <label htmlFor="isCloneFromSource" className="col-sm-6 col-form-label">
                    Import from existing source
                  </label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <Checkbox type="checkbox" name="isCloneFromSource" id="isCloneFromSource" />
                  </div>
                </div>
                {values.isCloneFromSource && (
                  <>
                    {/* <div className="col-sm-6 add-schedule-popup-form-element">Choose source to clone from</div>
                    <div className="col-sm-6 add-schedule-popup-form-element">
                      <label>
                        <Toggle
                          type="checkbox"
                          name="cloneSchedule"
                          id="cloneSchedule"
                          label="Schedule"
                          className="mb-4 mt-2"
                          labelStyle="add-new-schedule-title"
                          rightLabel={true}
                          checked={values.ref === CLONE_OPTIONS.SCHEDULE && true}
                          disabled={values.ref === CLONE_OPTIONS.SCHEDULE}
                          onChangeCapture={() => {
                            setFieldValue("ref", "");
                            setFieldValue("cloneArea", false);
                            setFieldValue("source", "schedule");
                          }}
                           onChange={(value: any) => {
                        const targetValue = value.target && value.target.value === "true" ? false : true;
                        setFieldValue(`cloneSchedule`, targetValue);
                      }}
                        />
                      </label>

                      <label>
                        <Toggle
                          type="checkbox"
                          name="cloneArea"
                          id="cloneArea"
                          label="Area"
                          className="mb-4 mt-2"
                          labelStyle="add-new-schedule-title"
                          rightLabel={true}
                          checked={values.ref === CLONE_OPTIONS.AREA ? true : false}
                          disabled={values.ref === CLONE_OPTIONS.AREA}
                          onChangeCapture={() => {
                            setFieldValue("ref", "");
                            setFieldValue("cloneSchedule", false);
                            setFieldValue("source", "area");
                          }}
                           onChange={(value: any) => {
                        const targetValue = value.target && value.target.value === "true" ? false : true;
                        setFieldValue(`cloneArea`, targetValue);
                      }}
                        />
                      </label>
                    </div> */}

                    {values.source === CLONE_OPTIONS.SCHEDULE && (
                      <div className="row mt-3">
                        <label className="col-sm-6 col-form-label">Clone from this schedule</label>
                        <div className="col-sm-6">
                          <Select
                            options={schedulesSlice.schedules.map((item: any, index) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                            onChange={(selcted) => {
                              setFieldValue("ref", selcted?.value);
                            }}
                          />
                          <ErrorMessage component="span" className="field_error" name={`ref`} />
                        </div>
                      </div>
                    )}

                    {values.source === CLONE_OPTIONS.AREA && (
                      <div className="row mt-3">
                        <label className="col-sm-6 col-form-label">Clone the old schedule for this area</label>
                        <div className="col-sm-6">
                          <Select
                            options={areasList.map((item: any, index) => ({
                              value: item.area_id,
                              label: `${item.name} (${item.parent_location})`,
                            }))}
                            onChange={(selcted) => {
                              setFieldValue("ref", selcted?.value);
                            }}
                          />
                          <ErrorMessage component="span" className="field_error" name={`ref`} />
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className={locationStyles.flexRowWrapModalFooter} style={{ marginTop: "40px" }}>
                  <div className={locationStyles.footerLeft}>
                    <GlobalButton format="white" onClick={() => handleCloseAddSchedule()}>
                      Cancel
                    </GlobalButton>
                  </div>
                  <div>
                    <GlobalButton format="success" type="submit">
                      Add New Schedule
                    </GlobalButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </GlobalModal>
  );
};
