import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// style components
import { Input } from "../../../../components/fields/input";
import { renameLocationForm, renameUserForm } from "../../../../components/formValidation/formValidation";
import { GlobalModal } from "../../../../components/modal";
import {
  hideActionsSuccess,
  showErrorMessageSuccess,
  isLoadingSuccess,
} from "../../../../redux-toolkit/reducers/actionsSlice";
import { addLocation, getLocation, updateLocationRecord } from "../../../../services/locationsServices";
import styles from "./location-actions.module.scss";
import { RootState } from "../../../../redux-toolkit/store";
import { GlobalErrorMessage, SuccessMessage } from "../../../users/components/errorMessage";
import { LoadingData } from "../../../../components/loading";
import { ACTIONS_ERROR_MESSAGE } from "../../../../constants/globalText";
import { WebSocketContext } from "../../../../components/websocket/websocket";
import { Panel } from "../../../../components/panel";
import panelStyles from "../../../../components/panel/panel.module.scss";
import { GlobalButton } from "../../../../components/button";

interface LocationActionsProps {
  size?: "sm" | "lg" | "xl";
  backdrop?: "static" | true | false;
  centered?: true | false;
  scrollable?: true | false;
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  footer?: any;
  title?: string;
  show: boolean;
  link?: string;
  location?: { name: string; email: string; id: number };
  handleClose: any;
  children?: any;
  frozenLocationMessage?: string;
}

/**
 * Rename business name
 */
export const RenameLocation = ({ footer, show, location, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);

  const [locationName, setLocationName] = useState<string>("");
  const dispatch = useDispatch();
  const socket = useContext(WebSocketContext);

  useEffect(() => {
    const getLocationData = async () => {
      await getLocation(actions.location.id)
        .then((response) => {
          setLocationName(response.data && response.data.name);
        })
        .catch((error) => {});
    };

    socket.on("updateLocation", (payload: any) => {
      if (searchClient.clientId === payload.parent_id) getLocationData();
    });

    getLocationData();

    return () => {
      socket.off("updateLocation", (payload: any) => {
        if (searchClient.clientId === payload.parent_id) getLocationData();
      });
    };
  }, [locationName, actions, searchClient]);

  const renameLocationNameData = async (values: any) => {
    dispatch(isLoadingSuccess(true));
    await updateLocationRecord(actions.location.id, { name: values.locationName })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  return (
    <GlobalModal title="Rename Location" show={show} handleClose={handleClose}>
      <div className={styles.modal}>
        <Formik
          form
          initialValues={{ locationName: locationName }}
          validationSchema={renameLocationForm}
          onSubmit={renameLocationNameData}
          enableReinitialize
        >
          {({ handleChange, handleBlur, values, errors }) => (
            <Form>
              <div>
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <div>
                  <Input
                    id="locationName"
                    name="locationName"
                    type="text"
                    placeholder="Location name"
                    onChange={handleChange("locationName")}
                    value={values.locationName}
                    onBlur={handleBlur("locationName")}
                    autoCapitalize="none"
                    error={errors.locationName}
                    size="form-control-sm"
                    onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                  />
                </div>

                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GlobalModal>
  );
};

/**
 * Send password reset link to the business email address
 */
export const SendPasswordResetEmail = ({ footer, show, location, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Send Password Reset Email" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Do you want to send a password reset link to <b>{location && location.email}</b> email address?
        </p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Generate reset password link
 */
export const GenerateSetPasswordLink = ({ footer, show, location, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Generate Set Password Link" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Do you want to generate a password reset link for <b>{location && location.name}</b>?
        </p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Copy a generated reset password link
 */
export const CopyGenerateSetPasswordLink = ({ footer, link, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Generate Set Password Link" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <div>{actions.actionsSuccessMessage && <SuccessMessage message={actions.actionsSuccessMessage} />}</div>
        <p>{link}</p>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Deactivate location
 */
export const DeactivateLocation = ({ footer, show, location, handleClose }: LocationActionsProps) => {
  return (
    <GlobalModal title="Deactivate Location" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Are you sure you want to deactivate <span style={{ fontWeight: "bold" }}>{location && location.name}</span>{" "}
          location?
        </p>

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Activate location
 */
export const ActivateLocation = ({ footer, show, location, handleClose }: LocationActionsProps) => {
  return (
    <GlobalModal title="Deactivate Location" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Are you sure you want to activate <span style={{ fontWeight: "bold" }}>{location && location.name}</span>{" "}
          location?
        </p>

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Add new location
 */
export const AddLocation = ({ show }: { show: boolean }) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const searchClient = useSelector((state: RootState) => state.searchClientSlice);
  const dispatch = useDispatch();

  const addLocationData = async (values: any) => {
    const data = {
      name: values.name,
      parent_id: searchClient.clientId,
    };

    dispatch(isLoadingSuccess(true));
    await addLocation(data)
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  const initialValues = {
    name: "",
  };

  return (
    <Formik
      form
      initialValues={initialValues}
      validationSchema={renameUserForm}
      onSubmit={addLocationData}
      enableReinitialize
    >
      {({ handleChange, handleBlur, setFieldValue, values, errors }) => (
        <Form>
          <Panel
            show={show}
            handleClose={() => {
              dispatch(hideActionsSuccess());
            }}
            title="Add New Location"
            width="50%"
            maxWidth="500px"
            footer={
              <div className={panelStyles.footer}>
                <div className={panelStyles.footer_left}>
                  <GlobalButton
                    format="white"
                    size="sm"
                    onClick={() => {
                      dispatch(hideActionsSuccess());
                    }}
                  >
                    Close
                  </GlobalButton>
                </div>
                <div>
                  <GlobalButton type="submit" format="primary" size="sm">
                    Add Location
                  </GlobalButton>
                </div>
              </div>
            }
          >
            <div>
              <div>
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <div>
                  <div style={{ marginBottom: 20 }}>
                    <Input
                      label="Name"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Name"
                      onChange={handleChange("name")}
                      value={values.name}
                      onBlur={handleBlur("name")}
                      autoCapitalize="none"
                      error={errors.name}
                      size="form-control-sm"
                      onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Form>
      )}
    </Formik>
  );
};

/**
 * Freeze loation
 */
export const FreezeLocation = ({
  footer,
  show,
  location,
  frozenLocationMessage,
  handleClose,
}: LocationActionsProps) => {
  return (
    <GlobalModal title={`${frozenLocationMessage} Location`} show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Are you sure you want to {frozenLocationMessage} <span style={{ fontWeight: "bold" }}>{location?.name}</span>{" "}
          location?
        </p>

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};
