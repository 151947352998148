import React, { useState } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";

// style components
import { Textarea } from "../../../../components/fields/texarea";
import { switchPlaylistForm } from "../../../../components/formValidation/formValidation";
import { GlobalModal } from "../../../../components/modal";
import {
  hideActionsSuccess,
  showErrorMessageSuccess,
  isLoadingSuccess,
  showSendFeedbackSuccess,
  showNegativeFeedbackActionSuccess,
} from "../../../../redux-toolkit/reducers/actionsSlice";
import { revertOverride, sendOverride } from "../../../../services/locationsServices";
import styles from "../location-actions/location-actions.module.scss";
import miniStyles from "./miniplayer.module.scss";
import { RootState } from "../../../../redux-toolkit/store";
import { GlobalErrorMessage } from "../../../users/components/errorMessage";
import { LoadingData } from "../../../../components/loading";
import { ACTIONS_ERROR_MESSAGE } from "../../../../constants/globalText";
import { GlobalButton } from "../../../../components/button";
import { SearchWithOptions } from "../../../../components/fields/search";
import { getSwitchPlaylist, storeSwitchPlaylist } from "../../../../utils/localStorage";
import { sendFeedback } from "../../../../services/feedbackServices";
import classNames from "classnames";

interface MiniPlayerActionsProps {
  size?: "sm" | "lg" | "xl";
  footer?: any;
  title?: string;
  show: boolean;
  playlistSelectedTrack?: any;
  handleClose: any;
  area?: any;
}
/**
 * Switch playlist
 */
export const SwitchPlaylistModal = ({ footer, show, handleClose, area }: MiniPlayerActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const schedulesSlice = useSelector((state: RootState) => state.schedulesSlice);

  const [selectedOverrideDuration, setSelectedOverrideDuration] = useState<number>(0);
  const DURATIONS = [0.25, 0.5, 1, 2, 3, 4, 5, 6, 7, 8]; // TODO -> These should be environment variables

  const dispatch = useDispatch();

  const playlists = getSwitchPlaylist();
  const findAreaIndex = playlists.findIndex((track: any) => track.areaId === actions.area.id);

  // Revert override
  const revertOverrideData = async () => {
    dispatch(isLoadingSuccess(true));
    const overrideRecord = {
      areaId: actions.area.id,
      tlgVersion: actions.area.tlgVersion,
      playlistId: area?.device?.current_track?.playlist_id || 0,
    };
    await revertOverride(actions.area.serial, overrideRecord)
      .then(() => {
        if (findAreaIndex >= 0) {
          playlists.splice(findAreaIndex, 1);
          storeSwitchPlaylist(playlists);
        }
        storeSwitchPlaylist(playlists);

        dispatch(isLoadingSuccess(false));
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  // Request override
  const sendOverrideData = async (values: any) => {
    dispatch(isLoadingSuccess(true));
    const overrideRecord = {
      areaId: actions.area.id,
      playlistId: values.playlistSelected,
      tlgVersion: actions.area.tlgVersion,
      time: values.duration,
    };
    await sendOverride(actions.area.serial, overrideRecord)
      .then(() => {
        if (findAreaIndex >= 0) {
          playlists.splice(findAreaIndex, 1);
          storeSwitchPlaylist(playlists);
        }

        const switchplaylistTimeMs = values.duration * 60 * 60;
        let nowDate = new Date();
        const track = {
          areaId: actions.area.id,
          duration: nowDate.setSeconds(nowDate.getSeconds() + switchplaylistTimeMs),
        };
        playlists.push(track);
        storeSwitchPlaylist(playlists);

        dispatch(isLoadingSuccess(false));
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  const handleSelectOverrideDuration = (duration: number) => {
    setSelectedOverrideDuration(duration);
  };

  return (
    <GlobalModal title="Select a Playlist" show={show} handleClose={handleClose}>
      <div className={styles.modal}>
        <Formik
          initialValues={{ playlistSelected: 0, duration: 0 }}
          validationSchema={switchPlaylistForm}
          onSubmit={sendOverrideData}
        >
          {({ handleChange, handleBlur, setFieldValue, values, errors }) => (
            <Form>
              <div>
                {actions.switchPlaylistStatus && (
                  <GlobalButton onClick={() => revertOverrideData()}>Revert back to schedule</GlobalButton>
                )}
                <p>This action will not affect the schedule</p>
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <div>
                  <SearchWithOptions
                    id="playlistSelected"
                    placeholder="Select a playlist..."
                    options={schedulesSlice.currentChannelsForClient}
                    labelKey="name"
                    leftIcon={<BsSearch />}
                    clearButton
                    maxResults={8}
                    highlightOnlyResult
                    size="sm"
                    onChange={(selected: any) => {
                      values.playlistSelected = selected && selected[0] && selected[0].playlist_id;
                    }}
                  />

                  {DURATIONS.map((d) => (
                    <div key={`${d}-container`} className="checkbox pill">
                      <Field
                        onChange={() => {
                          handleSelectOverrideDuration(d);
                          values.duration = d;
                        }}
                        checked={selectedOverrideDuration === d}
                        type="checkbox"
                        id={`${d}-radio`}
                        name="duration"
                      />
                      <label htmlFor={`${d}-radio`}>{d < 1 ? `${60 * d} mins` : `${d} hours`}</label>
                    </div>
                  ))}
                  <ErrorMessage component="span" className={styles.errorMessage} name={`duration`} />
                </div>

                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GlobalModal>
  );
};

/**
 * Display feedback actions
 */
export const FeedbackAction = ({ footer, show, handleClose }: MiniPlayerActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const dispatch = useDispatch();
  const currentTrackHasField = actions.playlistTrack.current_track;
  const previousTrackHasField = actions.playlistTrack.current_track;

  return (
    <GlobalModal title="Select a track" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <h5>Which track are you leaving feedback on?</h5>
        <div className={miniStyles.tracks}>
          <div className={miniStyles.feedbackTrack}>
            <div style={{ justifyContent: "center", flex: 1 }}>
              <p className={miniStyles.currentTrackMessage}>Current Track</p>
              <p className={miniStyles.trackTitle}>{currentTrackHasField && currentTrackHasField.title}</p>
              <p className={miniStyles.trackArtist}>{currentTrackHasField && currentTrackHasField.artist}</p>
            </div>
            <button
              className={miniStyles.actionButton}
              onClick={() =>
                dispatch(
                  showSendFeedbackSuccess({
                    sendFeedback: true,
                    playlistSelectedTrack: { ...actions.playlistTrack.current_track, feedback: "positive" },
                  })
                )
              }
            >
              <AiFillLike size={32} style={{ color: "limegreen" }} />
            </button>
            <button
              className={miniStyles.actionButton}
              onClick={() =>
                dispatch(
                  showNegativeFeedbackActionSuccess({
                    negativeFeedbackAction: true,
                    playlistSelectedTrack: actions.playlistTrack.current_track,
                  })
                )
              }
            >
              <AiFillDislike size={32} style={{ color: "red" }} />
            </button>
          </div>

          {/* <div className={miniStyles.horizontalLine}>
            <hr />
          </div> */}
          <div className={miniStyles.horizontalLine} />

          <div className={miniStyles.feedbackTrack}>
            <div style={{ justifyContent: "center", flex: 1 }}>
              <p>Previous Track</p>
              <p className={miniStyles.trackTitle}>{previousTrackHasField && previousTrackHasField.title}</p>
              <p className={miniStyles.trackArtist}>{previousTrackHasField && previousTrackHasField.artist}</p>
            </div>

            <button
              className={miniStyles.actionButton}
              onClick={() =>
                dispatch(
                  showSendFeedbackSuccess({
                    sendFeedback: true,
                    playlistSelectedTrack: { ...actions.playlistTrack.current_track, feedback: "positive" },
                  })
                )
              }
            >
              <AiFillLike size={32} style={{ color: "limegreen" }} />
            </button>
            <button
              className={miniStyles.actionButton}
              onClick={() =>
                dispatch(
                  showNegativeFeedbackActionSuccess({
                    negativeFeedbackAction: true,
                    playlistSelectedTrack: actions.playlistTrack.current_track,
                  })
                )
              }
            >
              <AiFillDislike size={32} style={{ color: "red" }} />
            </button>
          </div>
        </div>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}
      </div>
    </GlobalModal>
  );
};

/**
 * Send feedback
 */
export const SendFeedback = ({ footer, show, handleClose }: MiniPlayerActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const dispatch = useDispatch();

  type FeedbackMessageParams = { feedbackMessage: string };

  interface SendFeedbackProps {
    deviceId: string;
    track: string;
    artist: string;
    playlist: string;
    playlist_id: number;
    type: string;
    message: string;
    application: string;
  }
  async function submitFeedbackData(values: FeedbackMessageParams) {
    const playlistSelectedTrack = actions.playlistSelectedTrack;
    const report: SendFeedbackProps = {
      deviceId: playlistSelectedTrack && playlistSelectedTrack.device_id,
      track: playlistSelectedTrack && playlistSelectedTrack.title,
      artist: playlistSelectedTrack && playlistSelectedTrack.artist,
      playlist: playlistSelectedTrack && playlistSelectedTrack.playlist,
      playlist_id: playlistSelectedTrack && playlistSelectedTrack.playlist_id,
      type: playlistSelectedTrack && playlistSelectedTrack.feedback,
      message: values.feedbackMessage,
      application: "admin-portal",
    };
    dispatch(isLoadingSuccess(true));
    sendFeedback(report)
      .then((response) => {
        dispatch(hideActionsSuccess());
        dispatch(isLoadingSuccess(false));
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  }

  return (
    <GlobalModal
      title={
        actions.playlistSelectedTrack && actions.playlistSelectedTrack.feedback === "positive"
          ? "Glad you like it!"
          : "Sorry it's not quite right!"
      }
      show={show}
      handleClose={handleClose}
    >
      <div className={styles.modal}>
        <Formik form initialValues={{ feedbackMessage: "" }} onSubmit={submitFeedbackData}>
          {({ handleChange, handleBlur, setFieldValue, values, errors, touched }) => (
            <Form>
              <div>
                <p>
                  {`You are leaving "${
                    actions.playlistSelectedTrack && actions.playlistSelectedTrack.feedback
                  }" feedback for ${actions.playlistSelectedTrack && actions.playlistSelectedTrack.title} by ${
                    actions.playlistSelectedTrack && actions.playlistSelectedTrack.artist
                  }`}
                </p>
                <p style={{ color: "black", fontWeight: "bold", marginTop: "20px" }}>
                  Feel free to leave any additional comments below to help our curation team improve your music.
                </p>
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <div>
                  <Textarea name="feedbackMessage" id="feedbackMessage" rows={3} placeholder="Write your comment" />
                </div>

                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GlobalModal>
  );
};

/**
 * get negative feedback actions
 */
export const NegativeFeedbackAction = ({ footer, show, handleClose }: MiniPlayerActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const dispatch = useDispatch();

  const reasons = ["Bad language", "Annoying", "Poor quality", "Too fast", "Too slow", "Overplayed", "Other"];
  return (
    <GlobalModal title="What did we get wrong?" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>The track is...</p>
        <div className={miniStyles.feedbackNegativeButtonContainer}>
          {reasons.map((reason) => (
            <button
              key={reason}
              onClick={() =>
                dispatch(
                  showSendFeedbackSuccess({
                    sendFeedback: true,
                    playlistSelectedTrack: { ...actions.playlistSelectedTrack, feedback: reason },
                  })
                )
              }
              className={classNames(miniStyles.reasonButton, miniStyles.btnSoftDarkNavy, "btn btn-sm")}
            >
              {reason}
            </button>
          ))}
        </div>

        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}
      </div>
    </GlobalModal>
  );
};

/**
 * Skip track
 */
export const SkipTrack = ({ footer, show, handleClose }: MiniPlayerActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Skip Track" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>Are you sure you want to skip this track?</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * deveice status
 */
export const DeviceStatus = ({ footer, show, handleClose }: MiniPlayerActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal
      title={`${
        actions.deviceStatus && actions.deviceStatus.charAt(0).toLocaleUpperCase() + actions.deviceStatus.slice(1)
      } Track`}
      show={show}
      handleClose={handleClose}
      footer={footer}
    >
      <div className={styles.modal}>
        <p>Are you sure you want to {actions.deviceStatus} the track?</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};
