import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

// style components
import FirstLevelDropdown from "./FirstLevelDropdown";
import styles from "./firstLevel.module.scss";

interface MenuItemsProps {
  items?: any;
  dropdown?: any;
  depthLevel?: any;
  onChange?: any;
}

const MenuItems = ({ items, depthLevel }: MenuItemsProps) => {
  const [dropdown, setDropdown] = useState(false);

  let ref: any = useRef();

  useEffect(() => {
    const handler = (event: any) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  return (
    <li
      className={classNames(`${styles.firstLevelMenuItems} nav-item`)}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.submenu ? (
        <>
          <NavLink
            onClick={() => setDropdown((prev) => !prev)}
            to={items.routePath}
            className="nav-link dropdown-item dropdown-toggle"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
          >
            {items.title} {depthLevel > 0 && <span className={styles.dropdownIcon}></span>}
          </NavLink>
          <FirstLevelDropdown depthLevel={depthLevel} submenus={items.submenu} dropdown={dropdown} />
        </>
      ) : (
        <NavLink to={items.routePath} className="dropdown-item nav-link">
          {items.title}
        </NavLink>
      )}
    </li>
  );
};

export default MenuItems;
