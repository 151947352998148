import * as yup from "yup";
import { showErrorMessageSuccess } from "../../redux-toolkit/reducers/actionsSlice";
import { store } from "../../redux-toolkit/store";

/**
 * login form validation
 */
export const loginForm = yup.object().shape({
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});

/**
 * forgot password form validation
 */
export const forgotPasswordForm = yup.object().shape({
  email: yup.string().email("Invalid email address").required("Email is required"),
});

/**
 * reset password form validation
 */
export const resetPasswordform = yup.object().shape({
  password: yup.string().required("New password is required"),
  password_confirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm new password is required"),
});

/**
 * action form validation
 */
export const renameLocationForm = yup.object().shape({
  locationName: yup.string().required("Location name is required"),
});

export const addOrEditUserForm = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email address").required("Email is required"),
  permissionLevelId: yup.string().required("Permission is required").nullable(),
});
export const addOrEditClientForm = yup.object().shape({
  name: yup.string().required("Name is required"),
  curatorOwnerId: yup.string().required("Curation owner is required").nullable(),
  accountOwnerId: yup.string().required("Customer Owner is required").nullable(),
});

export const renameUserForm = yup.object().shape({
  name: yup.string().required(" Name is required"),
});

export const renameAreaForm = yup.object().shape({
  areaName: yup.string().required("Area name is required"),
});

export const changeAudioSettingForm = yup.object().shape({
  audioSetting: yup.string().required("Audio setting is required"),
});

export const assignDeviceForm = yup.object().shape({
  deviceId: yup
    .string()
    .min(7, "Device number must be 7 characters")
    .max(7, "Device number must be 7 characters")
    .matches(/^(f|c|p)-[0-9]{5}$/i, "Invalid device number")
    .ensure()
    .when("deviceType", {
      is: "fake-device",
      then: yup.string().matches(/^f-[0-9]{5}$/i, "It must be fake device"),
    })
    .when("deviceType", {
      is: "zoetrope-device",
      then: yup.string().matches(/^(c|p)-[0-9]{5}$/i, "It must be ambie player device"),
    })
    .required("Device number is required"),
  deviceType: yup.string().required("Device type is required"),
});

export const renameClientForm = yup.object().shape({
  clientName: yup.string().required("Client name is required"),
});

/**
 * client and areas commercial rule validation
 */
export const commercialInjectionRulesForm = yup.object().shape({
  rules: yup.array().of(
    yup.object().shape({
      repetition: yup.number().required("Required"),
      core_channel_id: yup.number().required("Core channel is required"),
      startDate: yup.number().required("Date is required").nullable(),
      endDate: yup.number().when("startDate", (startDate, schema) =>
        schema.test({
          test: function (endDate: number) {
            const rulesArray = this.options?.from[1]?.value?.rules;
            const currentIndex = rulesArray.indexOf(this.parent);
            for (let i = 0; i < rulesArray.length; i++) {
              if (i !== currentIndex) {
                const otherRule = rulesArray[i];
                const otherStartDate = otherRule.startDate;
                const otherEndDate = otherRule.endDate;
                if (
                  (startDate >= otherStartDate && startDate <= otherEndDate) ||
                  (endDate >= otherStartDate && endDate <= otherEndDate) ||
                  (startDate <= otherStartDate && endDate >= otherEndDate)
                ) {
                  store.dispatch(showErrorMessageSuccess("Start and end dates overlap with another rule."));
                  return;
                }
              }
            }
            store.dispatch(showErrorMessageSuccess(""));
            return true;
          },
        })
      ),
    })
  ),
});

export const switchPlaylistForm = yup.object().shape({
  playlistSelected: yup.number().min(1, "Playlist is required"),
  duration: yup.number().min(0.25, "Duration is required"),
});

/**
 * client and areas commercial rule validation
 */
export const playlistSubmitionForm = yup.object().shape({
  name: yup.string().max(50, "Maximun length of 50").required("Playlist is required"),
  rotationRules: yup.object().shape({
    value: yup.number().min(1, "Minimum 1").max(100, "Maximum 100"),
    start: yup
      .string()
      .nullable()
      .ensure()
      .when("value", (value) => {
        return value > 0 && value <= 100
          ? yup.string().required("Date is required").nullable()
          : yup.string().nullable();
      }),
    recurrence: yup
      .string()
      .ensure()
      .when("value", (value) => {
        return value > 0 && value <= 100 ? yup.string().required("Recurrence is required") : yup.string();
      }),
  }),
  rules: yup.object().shape({
    commercial: yup.array().of(
      yup.object().shape({
        repetition: yup.string().required("Required"), // these constraints take precedence
        core_channel_id: yup.string().required("Core channel is required"),
        startDate: yup.string().required("Date is required").nullable(),
      })
    ),
  }),
});

export const addScheduleForm = yup.object().shape({
  ref: yup
    .string()
    .nullable()
    .ensure()
    .when(["isCloneFromSource", "source"], {
      is: (isCloneFromSource: boolean, source: string) => isCloneFromSource === true && source === "schedule",
      then: yup.string().required("Schedule is required"),
    })
    .when(["isCloneFromSource", "source"], {
      is: (isCloneFromSource: boolean, source: string) => isCloneFromSource === true && source === "area",
      then: yup.string().required("Field is required"),
    }),
  name: yup.string().required("Name is required"),
});
