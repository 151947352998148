/**
 * Default values
 */
export const DEFAULT_TIMEOUT = 1000 * 5;
export const DEFAULT_TIMEOUT_AXIOS_INTERCEPTOR = 9000 * 15;
export const DEFAULT_REFRESH_INTERVAL_TIME = 1000 * 15;
export const DISPLAY_VOLUME_INTERVAL_TIME = 1000 * 10;
export const LOCATION_AREAR_INTERVAL_TIME = 1000 * 360;
export const TIMEOUT_INTERVAL_TIME = 1000 * 2;
export const TIMEOUT_INTERVAL_SHAKE_ANIMATION = 750;
export const TIMEOUT_FOR_LOADING_DEVICE_PAGE = 1000 * 60;
export const MAX_SKIPS = 3;
export const PAGINATION_COUNT = 5;
export const CLONE_OPTIONS = {
  SCHEDULE: "schedule",
  AREA: "area",
};

/**
 * Async local storage values
 */
export const LOCAL_STORAGE_USER = "AMBIE_USER";
export const LOCAL_STORAGE_CLIENT = "AMBIE_CLIENT";
export const LOCAL_STORAGE_USER_TOKEN = "AMBIE_USER_TOKEN";
export const LOCAL_STORAGE_USER_REFRESH_TOKEN = "AMBIE_USER_REFRESH_TOKEN";
export const LOCAL_STORAGE_ONBOARDING_SCREEN_VALUE = "AMBIE_ONBOARDING_SCREEN_VALUE";
export const LOCAL_STORAGE_SKIP_TRACK = "AMBIE_SKIP_TRACK";
export const LOCAL_STORAGE_SWITCH_PLAYLIST = "AMBIE_SWITCH_PLAYLIST";
