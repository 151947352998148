import { configureStore, createSerializableStateInvariantMiddleware, getDefaultMiddleware } from "@reduxjs/toolkit";
import { rootReducers } from "./reducers";

// Augment middleware to consider Immutable.JS iterables serializable

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  ignoredActions: ["schedulesSlice", "times", "schedulesSlice"],
});

export const store = configureStore({
  reducer: rootReducers,
  middleware: [serializableMiddleware, ...getDefaultMiddleware({ serializableCheck: false })],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
